<template>
    <div class="col-12">
        <div class="card mb-3">
            <div class="card-body text-center p-5">
                <img src="@/assets/images/no-data.svg" class="w120" alt="No Data">
                <div class="mt-4 mb-3">
                    <span class="text-muted">No data to show</span>
                </div>
                <button type="button" class="btn btn-white border lift mr-1">Get Started</button>
                <button type="button" class="btn btn-primary border lift text-white">Back to Home</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"StaterPageComponent",
}
</script>
<style scoped>

</style>