<template>
    <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="tab-content">
                <div class="tab-pane fade" id="Invoice-list" :class="{ 'show active': currentTab == 'Invoice List'}">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="card mb-3" v-for="(list, index) in invoice_list" :key="index">
                                <div class="card-body d-sm-flex justify-content-between">
                                    <a href="javascript:void(0);" class="d-flex">
                                        <img class="avatar rounded" :src="`${list.img}`" alt="">
                                        <div class="flex-fill ms-3 text-truncate">
                                            <h6 class="d-flex justify-content-between mb-0"><span>{{ list.name }}</span></h6>
                                            <span class="text-muted">{{ list.invoice_list }}</span>
                                        </div>
                                    </a>
                                    <div class="text-end d-none d-md-block">
                                        <p class="mb-1"><i class="fa fa-map-marker ps-1"></i>{{ list.location}}</p>
                                        <span class="text-muted"><i class="fa fa-money ps-1"></i> {{ list.currency }}</span>
                                    </div>
                                </div>
                                <div class="card-footer justify-content-between d-flex align-items-center">
                                    <div class="d-none d-md-block">
                                        <strong>{{ list.applied_title }}:</strong>
                                        <span>{{ list.date }}</span>
                                    </div>
                                    <div class="card-hover-show">
                                        <a class="btn btn-sm btn-white border lift mr-1" href="#" v-for="(btn, index) in list.button" :key="index">
                                            {{ btn }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <nav aria-label="Page navigation">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    prev-text="Previous"
                                    next-text="Next"
                                    hide-goto-end-buttons
                                    hide-ellipsis
                                ></b-pagination>
                            </nav>
                        </div>
                    </div>  <!-- Row end  -->
                </div> <!-- tab end  -->
                <div class="tab-pane fade" id="Invoice-Simple" :class="{ 'show active': currentTab == 'Simple invoice'}">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="card p-xl-5 p-lg-4 p-0">
                                <div class="card-body">
                                    <div class="mb-3 pb-3 border-bottom">
                                        {{ simple_invoice.invoice_title }}
                                        <strong>{{ simple_invoice.invoice_date }}</strong>
                                        <span class="float-right"> <strong>Status:</strong> {{ simple_invoice.status}}</span>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="col-sm-6">
                                            <h6 class="mb-3">From:</h6>
                                            <div><strong>{{ simple_invoice.from[0].from_name }}</strong></div>
                                            <div>{{ simple_invoice.from[0].line1 }}</div>
                                            <div>{{ simple_invoice.from[0].line2 }}</div>
                                            <div>Email: {{ simple_invoice.from[0].email }}</div>
                                            <div>Phone: {{ simple_invoice.from[0].phone }}</div>
                                        </div>
                                        
                                        <div class="col-sm-6">
                                            <h6 class="mb-3">To:</h6>
                                            <div><strong>{{ simple_invoice.to[0].to_name }}</strong></div>
                                            <div>Attn: {{ simple_invoice.to[0].line1 }}</div>
                                            <div>{{ simple_invoice.to[0].line2 }}</div>
                                            <div>Email:{{ simple_invoice.to[0].email }}</div>
                                            <div>Phone: {{ simple_invoice.to[0].phone }}</div>
                                        </div>
                                    </div> <!-- Row end  -->
                                    
                                    <div class="table-responsive-sm">
                                        <b-table 
                                            striped
                                            responsive 
                                            :items="items" 
                                            :fields="fields"
                                        >
                                            <template #cell(id)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template #row-details="row">
                                                <b-card>
                                                <ul>
                                                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                                </ul>
                                                </b-card>
                                            </template>
                                        </b-table>
                                    </div>
    
                                    <div class="row">
                                        <div class="col-lg-4 col-sm-5">
                                        
                                        </div>
                        
                                        <div class="col-lg-4 col-sm-5 ms-auto">
                                            <table class="table table-clear">
                                                <tbody>
                                                    <tr>
                                                        <td ><strong>Subtotal</strong></td>
                                                        <td class="text-end">$8.497,00</td>
                                                    </tr>
                                                    <tr>
                                                        <td ><strong>VAT (10%)</strong></td>
                                                        <td class="text-end">$679,76</td>
                                                    </tr>
                                                    <tr>
                                                        <td ><strong>Total</strong></td>
                                                        <td class="text-end"><strong>$7.477,36</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> <!-- Row end  -->
    
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6>Terms &amp; Condition</h6>
                                            <p class="text-muted">You know, being a test pilot isn't always the healthiest business
                                            in the world. We predict too much for the next year and yet far
                                            too little for the next 10.</p>
                                        </div>
                                        <div class="col-lg-12 text-end">
                                            <button type="button" class="btn btn-outline-secondary btn-lg my-1 mr-1"><i class="fa fa-print"></i> Print</button>
                                            <button type="button" class="btn btn-info btn-lg my-1"><i class="fa fa-paper-plane-o"></i> Send Invoice</button>
                                        </div>
                                    </div> <!-- Row end  -->
                                </div>
                            </div>
                        </div>
                    </div> <!-- Row end  -->
                </div> <!-- tab end  -->
                <div class="tab-pane fade" id="Invoice-Email" :class="{ 'show active': currentTab == 'Email invoice'}">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="d-flex justify-content-center">
                                <table class="card p-5">
                                    <tr>
                                        <td></td>
                                        <td style="text-align: center;">
                                            <table width="100%" cellpadding="0" cellspacing="0">
                                                <tr>
                                                    <td style="text-align: center;">
                                                        <h2>$33.98 Paid</h2>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom: 30px 0; padding-top: 10px; text-align: center;">
                                                        <h4>Thanks for using TTM Inc.</h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 30px 0;">
                                                        <table width="100%">
                                                            <tr>
                                                                <td>
                                                                    Attn: <strong>Daniel Marek</strong> 43-190 Mikolow, Poland<br>
                                                                    Email: marek@daniel.com<br>
                                                                    Phone: +48 123 456 789<br>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="padding-top: 40px;">
                                                                    <table class="table table-bordered" cellpadding="0" cellspacing="0">
                                                                        <tr>
                                                                            <td style="text-align: left;">Extended License</td>
                                                                            <td style="text-align: right;">$ 19.99</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="text-align: left;">1 year subcription</td>
                                                                            <td style="text-align: right;">$ 9.99</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="text-align: left;">Instalation and Customization</td>
                                                                            <td style="text-align: right;">$ 4.00</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="text-align: right;" width="80%"><strong>Total</strong></td>
                                                                            <td style="text-align: right;">$ 33.98</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 10px 0; text-align: center;">
                                                        <a href="#">View in browser</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="padding: 0; text-align: center;">
                                                        TTM Inc. 70 Bowman St. South Windsor, CT 06074
                                                    </td>
                                                </tr>
                                            </table>
                                            <table width="100%" style="text-align: center; margin-top: 50px;">
                                                <tr>
                                                    <td class="aligncenter content-block">Questions? Email <a href="mailto:">info@thememakker.com</a></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div> <!-- Row end  -->
                </div> <!-- tab end  -->
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/js/event-bus.js';
export default {
    name:'InvoicesComponent',
    data (){
        return {
            currentTab:'Invoice List',
            invoice_list:[
                {
                    img: require(`@/assets/images/xs/avatar1.jpg`),
                    name:'Maryam Amiri',
                    designation:'Front-end developer',
                    location:'123 6th St. Melbourne, FL 32904',
                    currency:' $60 per hour',
                    applied_title:'Applied on',
                    date:'21 Jan, 2019',
                    button:[
                        'Download',
                        'Send',
                        'Delete'
                    ]
                },
                {
                    img: require(`@/assets/images/xs/avatar2.jpg`),
                    name:'Brian Swader',
                    designation:'Front-end developer',
                    location:'70 Bowman St. South Windsor, CT 06074',
                    currency:' $45 per hour',
                    applied_title:'Applied on',
                    date:'21 Feb, 2019',
                    button:[
                        'Download',
                        'Send',
                        'Delete'
                    ]
                },
                {
                    img: require(`@/assets/images/xs/avatar3.jpg`),
                    name:'Maryam Amiri',
                    designation:'Front-end developer',
                    location:'44 Shirley Ave. West Chicago, IL 60185',
                    currency:' $25 per hour',
                    applied_title:'Applied on',
                    date:'21 Jun, 2019',
                    button:[
                        'Download',
                        'Send',
                        'Delete'
                    ]
                },
                {
                    img: require(`@/assets/images/xs/avatar4.jpg`),
                    name:'Orlando Lentz',
                    designation:'Front-end developer',
                    location:'123 6th St. Melbourne, FL 32904',
                    currency:' $25 per hour',
                    applied_title:'Applied on',
                    date:'21 Jun, 2020',
                    button:[
                        'Download',
                        'Send',
                        'Delete'
                    ]
                },
                {
                    img: require(`@/assets/images/xs/avatar5.jpg`),
                    name:'Robert Hammer',
                    designation:'Front-end developer',
                    location:'123 6th St. Melbourne, FL 32904',
                    currency:' $25 per hour',
                    applied_title:'Applied on',
                    date:'21 Jun, 2020',
                    button:[
                        'Download',
                        'Send',
                        'Delete'
                    ]
                },
                {
                    img: require(`@/assets/images/xs/avatar2.jpg`),
                    name:'Brian Swader',
                    designation:'Front-end developer',
                    location:'70 Bowman St. South Windsor, CT 06074',
                    currency:' $45 per hour',
                    applied_title:'Applied on',
                    date:'21 Feb, 2019',
                    button:[
                        'Download',
                        'Send',
                        'Delete'
                    ]
                },
            ],
            rows: 100,
            perPage: 5,
            currentPage: 1,
            simple_invoice:
            {
                invoice_title: 'Invoice',
                invoice_date:'01/Nov/2020',
                status:'Pending',
                from:[
                    {
                        from_name:'Webz Poland',
                        line1:'Madalinskiego 8',
                        line2:'71-101 Szczecin, Poland',
                        email:'info@webz.com.pl',
                        phone:'+48 444 666 3333'
                    }
                ],
                to:[
                    {
                        to_name:'Bob Mart',
                        line1:'Daniel Marek',
                        line2:'43-190 Mikolow, Poland',
                        email:'marek@daniel.com',
                        phone:'+48 123 456 789'
                    }
                ]
            },
            items: [
                {item_name:'Origin License',des:'Extended License',cost:'$999,00',qty:'1',total:'$999,00'},
                {item_name:'Custom Services',des:'Instalation and Customization (cost per hour)',cost:'$150,00',qty:'20',total:'$3.000,00'},
                {item_name:'Hosting',des:'1 year subcription',cost:'$499,00',qty:'1',total:'$499,00'},
                {item_name:'Platinum Support',des:'1 year subcription 24/7',cost:'$3.999,00',qty:'1',total:'$3.999,00'} 
            ],
            fields: [
                { key: 'id', label: '#' ,thClass:'text-center',tdClass:'text-center'},
                { key: 'item_name', label: 'ITEM' },
                { key: 'des', label: 'DESCRIPTION'},
                { key: 'cost', label: 'UNIT COST',thClass:'text-end',tdClass:'text-end'},
                { key: 'qty', label: 'QTY',thClass:'text-center',tdClass:'text-center'},
                { key: 'total', label: 'TOTAL',thClass:'text-end',tdClass:'text-end'},
            ],
        }
    },mounted() {
        EventBus.$on('currentTab', (tab) => {
            this.currentTab = tab
        });
    },beforeDestroy(){
        EventBus.$off('currentTab')
    }
}
</script>
<style scoped>

</style>