<template>
  <div :class="className" class="px-md-1 pointer" > 
    <div class="shadow_card rounded-right card-div-prod full"  data-aos="fade-right" >
      <img class="productimg" :src="product.url == null || product.url == 'null' ? '../images/img2.png' : product.url" alt="">
      <div class="px-3 py-3 ">
        <div style="height:50px !important">
        <p class="trn" style="white-space: normal !important;line-height:1.1;"><b>{{'#'+product.id + '-'+ product.nombre.toUpperCase().substring(0,22) }}</b></p>
        <!-- <span v-if="product.descripcion" style="white-space: normal !important;"><b>{{product.descripcion}}</b></span><br> -->
        </div>
        <div class="text-left">
          <span v-if="product.precio_compra" class="mt-2 h5 text-primary" style="white-space: normal !important;"><b>${{product.precio_compra.toFixed(2)}}</b></span><br>
          <span v-if="product.existencia" class="mt-2" style="white-space: normal !important;"><b>Stock: {{product.existencia}}</b></span>
        </div>
        <!-- <button @click="goto(product.ruta)" class="btn btn-primary"> Agregar pr</button> -->
      </div>
    </div>
    <!-- <div class="shadow_card  card-div-prod movil px-0"   >
      <img class="productimg" :src="product.url == null || product.url == 'null' ? '../images/img2.png' : product.url" alt="">
      <div class="px-3 py-3 ">
        <div style="height:40px !important">
        <p class="trn text-center" style="white-space: normal !important;line-height:1.1;"><b>{{'#'+product.id + '-'+ product.nombre.toUpperCase().substring(0,22) }}</b></p>
        
        </div>
        <div class="text-left">
          <span v-if="product.precio_venta" class="mt-2 h5 text-primary" style="white-space: normal !important;"><b>${{product.precio_venta.toFixed(2)}}</b></span><br>
          <span v-if="product.existencia" class="mt-2" style="white-space: normal !important;"><b>Stock: {{product.existencia}}</b></span>
        </div>
        
      </div>
    </div> -->
    <div class="shadow_card  card-div-prod movil px-0"   >
      <div class="row">
        <div class="col-12 ">
          <div class="">
            <p class="trn text-center bg-rgb-primary py-2 mb-0" ><b>{{'#'+product.id + '-'+ product.nombre.toUpperCase().substring(0,22) }}</b></p>        
          </div>
        </div>
        <div class="col-6">
          <img class="productimg" :src="product.url == null || product.url == 'null' ? '../images/img2.png' : product.url" alt="">
        </div>
        <div class="col-6 pl-0 my-5">
          <div class="">
            
            <div class="text-center">
              <span v-if="product.precio_venta" class="mt-2 h3 text-primary" ><b>${{product.precio_venta.toFixed(2)}}</b></span><br>
              <span v-if="product.existencia" class="mt-2"><b>Stock: {{product.existencia}}</b></span>
            </div>
            
          </div>
        </div>
      </div>
      
      
    </div>
    <!-- <section :style="`background:url(${product.picture}); background-size:100% 100%;  height:10rem; margin:1rem; background-repeat: no-repeat;`"/> -->
      <!-- <img class="productimg" :src="product.picture != null ? product.picture : 'https://www.clipartmax.com/png/middle/250-2502047_question-mark-question-mark.png'"  alt="ModeGt">
      <br>
      <span style="margin:auto; display:table;font-size:0.8rem" class="mb-1">{{ 'SKU ' + product.code }}</span>
      <h6 style="height:2.5rem; font-weight:bold" class="text-center mb-5">{{product.name}}</h6>
      <h6 v-if="product.offerPrice" class="text-center text-muted mt-3"><del> Antes: Q{{Number(product.price).toFixed(2)}}</del></h6>
      <h4 class="text-center" v-if="user"><span v-if="product.offerPrice " style="font-size:1rem">Ahora</span> Q{{Number(product.offerPrice || product.price).toFixed(2)}}</h4> -->
  </div>
</template>

<script>
export default {
  props: ["product", "theme", "type"],
  data: () => ({
    className: "product",
    user: null
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods:{
    goto(rutaWeb){
      console.log('goto ruta: ', rutaWeb)
      this.$router.push(rutaWeb)
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: rgba(255, 0, 119, 1);
.trn{
  position: relative;
    line-height: 18px;
    height: 36px;
    overflow: hidden;
}
.trn::after{
  font-weight:bold;
    position:absolute;
    bottom:0;
    right:0;
    padding:0 20px 1px 45px;
}
.shadow_card {
  background: transparent;
  box-shadow: 0 .185rem .30rem rgba(0, 0, 0, 0.452)!important;
 
  
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
}
.productimg{
  height:5rem; 
  width:100%; 
  display:block; 
  //   margin-left:auto;
  //  margin-right: auto;
   object-fit: cover;
  // border-radius: 15px; padding:10px

}

@media screen and (max-width: 750px) {
  .productimg{
    height:10rem; 
    width:100%; 
    display:block; 
    //   margin-left:auto;
    //  margin-right: auto;
    object-fit: cover;
    // border-radius: 15px; padding:10px

  }
  .card-div-prod{
    
    height:auto !important;
    width:100% !important;
    
  }

}
.card-div-prod{
  
  height:auto !important;
  width:164px ;
  
}
</style>