<template>
    <div class="col-12">
        <div class="bd-content ps-lg-4">
            <h2 id="how-it-works">How it works</h2>
            <p>Use our custom progress component for displaying simple or complex progress bars, featuring support for horizontally stacked bars, animated backgrounds, and text labels.</p>
             <div class="alert alert-danger" role="alert">
                <strong>Popovers</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/progress" target="_blank">Bootstrap Popovers documentation <i class="fa fa-external-link"></i></a>
            </div>
            <h2 id="how-it-works">Value</h2>
            <p>Set the maximum value with the <code>max</code> prop (default is <code>100</code>), and the current value via the <code>value</code> prop (default <code>0</code>).</p>
            <p>When creating multiple bars in a single process, place the value prop on the individual <code>&lt;b-progress-bar&gt;</code> sub components (see the <strong>Multiple Bars</strong> section below for more details)</p>
            <p>Put that all together, and you have the following examples.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :value="0" :max="100" class="mb-2"></b-progress>
                <b-progress :value="25" :max="100" class="mb-2" ></b-progress>
                <b-progress :value="50" :max="100" class="mb-2" ></b-progress>
                <b-progress :value="75" :max="100" class="mb-2" ></b-progress> 
                <b-progress :value="100" :max="100" class="mb-2" ></b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :value=&quot;0&quot; :max=&quot;100&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;25&quot; :max=&quot;100&quot; class=&quot;mb-2&quot; &gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;50&quot; :max=&quot;100&quot; class=&quot;mb-2&quot; &gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;75&quot; :max=&quot;100&quot; class=&quot;mb-2&quot; &gt;&lt;/b-progress&gt; 
&lt;b-progress :value=&quot;100&quot; :max=&quot;100&quot; class=&quot;mb-2&quot; &gt;&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <p>Bootstrap provides a handful of <a href="https://bootstrap-vue.org/docs/reference/size-props#sizing-props-and-classes">utilities for setting width</a>. Depending on your needs, these may help with quickly configuring progress.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :value="75" :max="100" class="w-75"></b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :value=&quot;75&quot; :max=&quot;100&quot; class=&quot;w-75&quot;&gt;&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <h4 id="labels">Labels</h4>
            <p>Need more control over the label? Provide your own label by using the default slot within a <code>&lt;b-progress-bar&gt;</code> sub-component, or by using the <code>label</code> or <code>label-html</code> property on <code>&lt;b-progress-bar&gt;</code>:</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :max="100">
                    <b-progress-bar :value="25" :label="`${((25 / 100) * 100)}%`"></b-progress-bar>
                </b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :max=&quot;100&quot;&gt;
    &lt;b-progress-bar :value=&quot;25&quot; :label=&quot;`${((25 / 100) * 100)}%`&quot;&gt;&lt;/b-progress-bar&gt;
&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <h4 id="height">Height</h4>
            <p>The height of the progress bar can be controlled with the <code>height</code> prop. The height value should be a standard CSS dimension (<code>px</code>, <code>rem</code>, <code>em</code>, etc.). The default height is <code>1rem</code>.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress height="1px" :value="25" class="mb-3"></b-progress>
                <b-progress height="5px" :value="35" class="mb-3"></b-progress>
                <b-progress height="20px" :value="25" class="mb-3"></b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress height=&quot;1px&quot; :value=&quot;25&quot; class=&quot;mb-3&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress height=&quot;5px&quot; :value=&quot;35&quot; class=&quot;mb-3&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress height=&quot;20px&quot; :value=&quot;25&quot; class=&quot;mb-3&quot;&gt;&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <h4 id="backgrounds">Backgrounds</h4>
            <p>Use background variants to change the appearance of individual progress bars. The default variant is <code>primary</code>.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :value="25" variant="success" class="mb-2"></b-progress>
                <b-progress :value="50" variant="info" class="mb-2"></b-progress>
                <b-progress :value="75" variant="warning" class="mb-2"></b-progress>
                <b-progress :value="100" variant="danger" class="mb-2"></b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :value=&quot;25&quot; variant=&quot;success&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;50&quot; variant=&quot;info&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;75&quot; variant=&quot;warning&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;100&quot; variant=&quot;danger&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <h4 id="multiple-bars">Multiple bars</h4>
            <p>Include multiple <code>&lt;b-progress-bar&gt;</code> sub-components in a <code>&lt;b-progress&gt;</code> component to build a horizontally stacked set of progress bars.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :max="100">
                    <b-progress-bar variant="primary" :value="15"></b-progress-bar>
                    <b-progress-bar variant="success" :value="30"></b-progress-bar>
                    <b-progress-bar variant="info" :value="20"></b-progress-bar>
                </b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :max=&quot;100&quot;&gt;
    &lt;b-progress-bar variant=&quot;primary&quot; :value=&quot;15&quot;&gt;&lt;/b-progress-bar&gt;
    &lt;b-progress-bar variant=&quot;success&quot; :value=&quot;30&quot;&gt;&lt;/b-progress-bar&gt;
    &lt;b-progress-bar variant=&quot;info&quot; :value=&quot;20&quot;&gt;&lt;/b-progress-bar&gt;
&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <h4 id="striped">Striped</h4>
            <p>Set <code>striped</code> to apply a stripe via CSS gradient over the progress bar's background variant.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :value="10" :striped="striped" class="mb-2"></b-progress>
                <b-progress :value="25" variant="success" :striped="striped" class="mb-2"></b-progress>
                <b-progress :value="50" variant="info" :striped="striped" class="mb-2"></b-progress>
                <b-progress :value="75" variant="warning" :striped="striped" class="mb-2"></b-progress>
                <b-progress :value="100" variant="danger" :striped="striped" class="mb-2"></b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :value=&quot;10&quot; :striped=&quot;striped&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;25&quot; variant=&quot;success&quot; :striped=&quot;striped&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;50&quot; variant=&quot;info&quot; :striped=&quot;striped&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;75&quot; variant=&quot;warning&quot; :striped=&quot;striped&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;100&quot; variant=&quot;danger&quot; :striped=&quot;striped&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;</code>
</pre>
            </div>
            
            <h4 id="animated-stripes">Animated stripes</h4>
            <p>The striped gradient can also be animated by setting the <code>animated</code>prop.</p>
            <div class="bd-example card p-3 mb-3">
                <b-progress :value="10" striped :animated="animate" class="mb-2"></b-progress>
                <b-progress :value="25" variant="success" striped :animated="animate" class="mb-2"></b-progress>
                <b-progress :value="50" variant="info" striped :animated="animate" class="mb-2"></b-progress>
                <b-progress :value="75" variant="warning" striped :animated="animate" class="mb-2"></b-progress>
                <b-progress :value="100" variant="danger" :animated="animate" class="mb-2"></b-progress>
<pre>
<code class="language-html" data-lang="html">&lt;b-progress :value=&quot;10&quot; striped :animated=&quot;animate&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;25&quot; variant=&quot;success&quot; striped :animated=&quot;animate&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;50&quot; variant=&quot;info&quot; striped :animated=&quot;animate&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;75&quot; variant=&quot;warning&quot; striped :animated=&quot;animate&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;
&lt;b-progress :value=&quot;100&quot; variant=&quot;danger&quot; :animated=&quot;animate&quot; class=&quot;mb-2&quot;&gt;&lt;/b-progress&gt;</code>
</pre>
            </div>
            
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'ProgressComponent',
    data() {
      return {
        striped: true,
        animate: true 
      }
    },methods:{
        
    },mounted() {
        Prism.highlightAll()
        
    }
}
</script>
