var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":("" + _vm.currentLayout)}},[(
      !['login', 'register', 'fpassword', 'verification', '404'].includes(
        _vm.$route.name
      )
    )?_c('NavigationComponent'):_vm._e(),(
      !['login', 'register', 'fpassword', 'verification', '404'].includes(
        _vm.$route.name
      )
    )?_c('HeaderComponent',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentLayout != 'layout-h'),expression:"currentLayout != 'layout-h'"}]}):_vm._e(),(
      !['login', 'register', 'fpassword', 'verification', '404'].includes(
        _vm.$route.name
      )
    )?_c('SidebarComponent'):_vm._e(),_c(_vm.layout,{tag:"component"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }