<template>
    <div class="col-12">
        <div class="bd-content">
            <h2 id="example">Example</h2>
            <p>Badges scale to match the size of the immediate parent element by using relative font sizing and <code>em</code> units. As of v5, badges no longer have focus or hover styles for links.</p>
            <div class="bd-example mb-5">
                <h1>Example heading <b-badge variant="warning">New</b-badge></h1>
                <h2>Example heading <b-badge variant="warning">New</b-badge></h2>
                <h3>Example heading <b-badge variant="warning">New</b-badge></h3>
                <h4>Example heading <b-badge variant="warning">New</b-badge></h4>
                <h5>Example heading <b-badge variant="warning">New</b-badge></h5>
                <h6>Example heading <b-badge variant="warning">New</b-badge></h6>
<pre>
<code class="language-html" data-lang="html">&lt;h1&gt;Example heading &lt;b-badge variant=&quot;warning&quot;&gt;New&lt;/b-badge&gt;&lt;/h1&gt;
&lt;h2&gt;Example heading &lt;b-badge variant=&quot;warning&quot;&gt;New&lt;/b-badge&gt;&lt;/h2&gt;
&lt;h3&gt;Example heading &lt;b-badge variant=&quot;warning&quot;&gt;New&lt;/b-badge&gt;&lt;/h3&gt;
&lt;h4&gt;Example heading &lt;b-badge variant=&quot;warning&quot;&gt;New&lt;/b-badge&gt;&lt;/h4&gt;
&lt;h5&gt;Example heading &lt;b-badge variant=&quot;warning&quot;&gt;New&lt;/b-badge&gt;&lt;/h5&gt;
&lt;h6&gt;Example heading &lt;b-badge variant=&quot;warning&quot;&gt;New&lt;/b-badge&gt;&lt;/h6&gt;</code>
</pre>
            </div> <!-- example end  -->
            <p>Badges can be used as part of links or buttons to provide a counter.</p>
            <div class="bd-example mb-5">
                <b-button variant="primary">
                    Notifications <b-badge variant="warning">4</b-badge>
                </b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary&quot;&gt;
    Notifications &lt;b-badge variant=&quot;warning&quot;&gt;4&lt;/b-badge&gt;
&lt;/b-button&gt;</code>
</pre>
            </div> <!-- example end  -->
            <p>Note that depending on how they are used, badges may be confusing for users of screen readers and similar assistive technologies. While the styling of badges provides a visual cue as to their purpose, these users will simply be presented with the content of the badge. Depending on the specific situation, these badges may seem like random additional words or numbers at the end of a sentence, link, or button.</p>
            <p>Unless the context is clear (as with the “Notifications” example, where it is understood that the “4” is the number of notifications), consider including additional context with a visually hidden piece of additional text.</p>
            <div class="bd-example mb-5">
                <b-button variant="primary">
                    Notifications <b-badge variant="warning">9</b-badge>
                </b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary&quot;&gt;
    Notifications &lt;b-badge variant=&quot;warning&quot;&gt;9&lt;/b-badge&gt;
&lt;/b-button&gt;</code>
</pre>
            </div> <!-- example end  -->
            <h2 id="background-colors">Background colors</h2>
            <p>Use our background utility classes to quickly change the appearance of a badge. Please note that when using Bootstrap’s default <code>.bg-light</code>, you’ll likely need a text color utility like <code>.text-dark</code> for proper styling. This is because background utilities do not set anything but <code>background-color</code>.</p>                        
            <div class="bd-example mb-5">
                <b-badge variant="primary mr-1">Primary</b-badge>
                <b-badge variant="secondary mr-1">Secondary</b-badge>
                <b-badge variant="success mr-1">Success</b-badge>
                <b-badge variant="danger mr-1">Danger</b-badge>
                <b-badge variant="warning mr-1">Warning</b-badge>
                <b-badge variant="info mr-1">Info</b-badge>
                <b-badge variant="light mr-1 text-dark">Light</b-badge>
                <b-badge variant="dark mr-1">Dark</b-badge>
<pre>
<code class="language-html" data-lang="html">&lt;b-badge variant=&quot;primary mr-1&quot;&gt;Primary&lt;/b-badge&gt;
&lt;b-badge variant=&quot;secondary mr-1&quot;&gt;Secondary&lt;/b-badge&gt;
&lt;b-badge variant=&quot;success mr-1&quot;&gt;Success&lt;/b-badge&gt;
&lt;b-badge variant=&quot;danger mr-1&quot;&gt;Danger&lt;/b-badge&gt;
&lt;b-badge variant=&quot;warning text-dark mr-1&quot;&gt;Warning&lt;/b-badge&gt;
&lt;b-badge variant=&quot;info mr-1&quot;&gt;Info&lt;/b-badge&gt;
&lt;b-badge variant=&quot;light text-dark mr-1&quot;&gt;Light&lt;/b-badge&gt;
&lt;b-badge variant=&quot;dark mr-1&quot;&gt;Dark&lt;/b-badge&gt;</code>
</pre>
            </div> <!-- example end  -->
            <div class="bd-callout bd-callout-info">
                <h5 id="conveying-meaning-to-assistive-technologies">Conveying meaning to assistive technologies</h5>
                <p>Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden with the <code>.visually-hidden</code> class.</p>
            </div>
            <h2 id="pill-badges">Pill badges</h2>
            <p>Use the <code>.rounded-pill</code> utility class to make badges more rounded with a larger <code>border-radius</code>.</p>
            <div class="bd-example mb-5">
                <b-badge pill variant="primary mr-1">Primary</b-badge>
                <b-badge pill variant="secondary mr-1">Secondary</b-badge>
                <b-badge pill variant="success mr-1">Success</b-badge>
                <b-badge pill variant="danger mr-1">Danger</b-badge>
                <b-badge pill variant="warning mr-1">Warning</b-badge>
                <b-badge pill variant="info mr-1">Info</b-badge>
                <b-badge pill variant="light text-dark mr-1">Light</b-badge>
                <b-badge pill variant="dark mr-1">Dark</b-badge>
<pre>
<code class="language-html" data-lang="html">&lt;b-badge pill variant=&quot;primary mr-1&quot;&gt;Primary&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;secondary mr-1&quot;&gt;Secondary&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;success mr-1&quot;&gt;Success&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;danger mr-1&quot;&gt;Danger&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;warning text-dark mr-1&quot;&gt;Warning&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;info mr-1&quot;&gt;Info&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;light text-dark mr-1&quot;&gt;Light&lt;/b-badge&gt;
&lt;b-badge pill variant=&quot;dark mr-1&quot;&gt;Dark&lt;/b-badge&gt;</code>
</pre>
            </div> <!-- example end  -->
        </div> 
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'BadgesComponent',
    mounted() {
        Prism.highlightAll()
    }
}
</script>
<style scoped>
.badge-pill {
    border-radius: 10rem !important;
}
</style>