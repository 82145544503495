<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card p-4 mb-4">
        <div class="row py-3 justify-content-between">
          <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
            <div>
              <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Nuevo Producto" />
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha_alta)="data">
            <span>{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(insumo)="data">
            <span>{{ data.item.insumo == 1 ? "Si" : "No" }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button data-toggle="tooltip" data-placement="top" title="Editar producto" @click="showModal(data.item, 2)" class="btn btn-primary ml-1"><i class="fa fa-pencil"></i></button>
            </span>
            <span
              ><button data-toggle="tooltip" data-placement="top" title="Insumos ó materia prima" @click="showSuppliesModal(data.item)" class="btn btn-secondary ml-1"><i class="fa fa-pencil"></i></button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash"></i></button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-secondary mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-secondary mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="exampleModalCenter" tabindex="-1" class="" size="lg" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-8 mb-2"><label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre" /></div>
            <div class="col-md-4 mb-2"><label for="codigo" class="">Código de barras</label><input required v-model="row.codigo" class="form-control" type="text" name="codigo" id="codigo" /></div>
            <div class="col-md-4 mb-2"><label for="precio compra" class="">Precio compra</label><input required class="form-control" v-model="row.precio_compra" type="text" name="precio compra" id="precio compra" /></div>
            <div class="col-md-4 mb-2"><label for="precio venta" class="">Precio venta</label><input required class="form-control" v-model="row.precio_venta" type="text" name="precio venta" id="precio venta" /></div>
            <div class="col-md-4 mb-2"><label for="existencia" class="">Existencia</label><input required class="form-control" v-model="row.existencia" type="text" name="existencia" id="exisistencia" /></div>
            <div class="col-md-6 mb-2"><label for="descripcion" class="">Descripción</label><input class="form-control" v-model="row.descripcion" type="text" name="descripcion" id="descripcion" /></div>
            <div class="col-md-6 mb-2">
              <label for="categoria" class="">Categoría</label>
              <b-form-select required class="form-control select text-black" v-model="row.categoria_id" :options="categories" value-field="id" text-field="nombre"> </b-form-select>
            </div>
            <div class="col-md-12 mb-2">
              <label for="url" class="">url Imagen</label><br />
              <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('prod')" /><br />
              <input class="form-control" v-model="row.url" type="text" name="url" id="url" />
              <img :src="row.url" width="300" />
            </div>
            <div class="col-md-12 mb-2 mt-2 ml-4">
              <b-form-checkbox size="lg" id="checkbox-2" v-model="row.visible_en_ventas" name="checkbox-2" value="1" unchecked-value="0">
                <span for="" class="h6">visible en ventas</span>
              </b-form-checkbox>
            </div>
            <div class="col-md-12 mb-2 mt-2 ml-4">
              <b-form-checkbox size="lg" id="checkbox-3" v-model="row.insumo" name="checkbox-3" value="1" unchecked-value="0">
                <span for="" class="h6">Insumo ó materia prima.</span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>

    <!-- supplies modal -->
    <b-modal id="suppliesModal" tabindex="-1" class="" size="lg" centered title="Insumos ó materia prima" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <div class="row">
          <div class="col-md-6 mb-2">
            <label for="categoria" class="">Selecciona un insumo</label>
            <b-form-select @change="selectSupply($event)" required class="form-control select text-black" :options="suppliesList" value-field="id" text-field="nombre"> </b-form-select>
          </div>
          <div class="col-md-6 mb-2 mt-4">
            <button data-toggle="tooltip" data-placement="top" title="" @click="visibleSupply = true" class="btn btn-primary ml-1"><i class="fa fa-plus"></i></button>
          </div>
          <div class="row mb-3" v-if="visibleSupply">
            <div class="col-md-6">
              <label for="cantidad" class="">Cantidad</label>
              <input class="form-control" type="number" step="0.01" v-model="supply.cantidad" placeholder="Cantidad" />
            </div>

            <div class="col-md-6">
              <label for="costo_unitario" class="">Costo unitario</label>
              <input class="form-control" type="number" step="0.01" v-model="supply.costo_unitario" placeholder="costo unitario" />
            </div>

            <div class="col-md-6 mt-1">
              <label for="costo_total" class="">Costo total</label>
              <input class="form-control" type="number" step="0.01" :value="supply.cantidad * supply.costo_unitario" placeholder="costo total" />
            </div>

            <div class="col-md-6">
              <button v-if="visibleSupply" data-toggle="tooltip" data-placement="top" title="Cancelar" @click="visibleSupply = false" class="btn btn-secondary ml-1 mt-2">Cancelar</button>
              <button v-if="visibleSupply" data-toggle="tooltip" data-placement="top" title="Guardar insumo" @click="addSupply()" class="btn btn-primary ml-1 mt-2">Guardar insumo</button>
            </div>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <table>
            <tr>
              <th>Insumo</th>
              <th>Cantidad</th>
              <th>Costo unitario</th>
              <th>Costo Total</th>
              <th></th>
            </tr>
            <tr v-for="it of supplies" :key="it.id">
              <td>{{ it.nombre }}</td>
              <td>{{ it }}</td>
              <td>{{ it.costo_unitario }}</td>
              <td>{{ it.costo_total }}</td>
              <td>
                <i @click="deleteSupply(it.id)" class="fa fa-trash btn-danger"></i>
              </td>
            </tr>
          </table>

          <!-- <button @click="deleteItem(data.item)" class="btn btn-danger ml-1">
              </button> -->

          <!-- <div class="col-md-4">{{ it }}</div> -->
        </div>

        <div class="text-right">
          <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('suppliesModal')">Cerrar</b-button>
          <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CardActions from "@/components/core/CardActions.vue";
export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  data() {
    return {
      fields: [
        // { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
        { key: "nombre", label: "Nombre", sortable: true, class: "text-center" },
        { key: "codigo", label: "Código", sortable: true, class: "text-center" },
        { key: "insumo", label: "Insumo", sortable: true, class: "text-center" },
        { key: "existencia", label: "Existencia", sortable: true, class: "text-center" },
        { key: "precio_venta", label: "Precio venta", sortable: true, class: "text-center" },
        // { key: 'fecha_alta', label: 'fecha alta', sortable: true, class: 'text-center' },
        // { key: 'telefono', label: 'Telefono', sortable: true, class: 'text-center' },
        // { key: 'email', label: 'email', sortable: true, class: 'text-center' },
        // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
        { key: "action", label: "Acciones", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      ///////
      table: "meca_productos",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo Producto",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      categories: [],
      filename1: null,
      supplies: [],
      suppliesList: [],
      supply: {
        insumo_id: null,
        producto_id: null,
        cantidad: null,
        costo_unitario: null,
        costo_total: null,
      },
      visibleSupply: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");

    await this.getData();
    await this.getCategories();
    this.totalRows = this.items.length;
  },
  methods: {
    async addSupply() {
      try {
        // let data = {
        //   insumo_id:0,
        //   producto_id:0,
        //   cantidad:0,
        //   costo_unitario:0,
        //   costo_total:0
        // }
        this.supply.costo_total = this.supply.cantidad * this.supply.costo_unitario;
        let producto_id = this.supply.producto_id;
        this.$isLoading(true);
        await this.$store.dispatch("post", { path: "supplies/new", data: this.supply });
        this.$isLoading(false);

        this.visibleSupply = false;
        this.supply = {
          insumo_id: null,
          producto_id: null,
          cantidad: null,
          costo_unitario: null,
          costo_total: null,
        };
        this.supply.producto_id = producto_id;
        this.visibleSupply = false;
        this.supplies = [];
        this.suppliesList = [];
        await this.getSuppliesList(producto_id);
        await this.getSuppliesByCompany();
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async getSuppliesByCompany() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "supplies/getAll/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.suppliesList = res;
        }
      } catch (error) {
        this.suppliesList = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getSuppliesList(id) {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "supplies/getByProductId/" + id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.supplies = res;
        }
      } catch (error) {
        this.supplies = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async selectSupply(it) {
      this.supply.insumo_id = it;
      console.log("---.", it);
      // console.log(it.target.value)
    },
    async onFileSelected(type) {
      try {
        this.file1 = event.target.files[0];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        // this.filename1 = type + "_" + this.filename1 + ".jpg";
        let extension = this.file1.name.split(".").pop();
        this.filename1 = type + "_" + this.filename1 + "." + extension;
        console.log(this.filename1);
      } catch (error) {
        console.log("error", error);
      }
    },
    async getCategories() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_categorias/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.categories = res;
        }
      } catch (error) {
        this.$isLoading(false);
        this.categories = [];
        console.error("error", error);
      }
    },
    async showSuppliesModal(item) {
      console.log("----->", item);
      //this.row = item.id;
      // this.modalTitle = "Editar Producto";
      (this.supply = {
        insumo_id: null,
        producto_id: null,
        cantidad: null,
        costo_unitario: null,
        costo_total: null,
      }),
        (this.supply.producto_id = item.id);
      this.visibleSupply = false;
      this.supplies = [];
      this.suppliesList = [];
      await this.getSuppliesList(item.id);
      await this.getSuppliesByCompany();
      this.$bvModal.show("suppliesModal");
      console.log(this.row);
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nuevo Producto";
        this.row = { visible_en_ventas: 1 };
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.modalTitle = "Editar Producto";
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
    },
    async getData() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.items = res;
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        let uploadImg;
        let urlimg = this.row.url;
        console.log("filename1 != null ???", uploadImg);
        this.$isLoading(true);
        if (this.filename1 != null) {
          console.log("entró en el if");
          this.filename1 = this.company_id + "_" + this.filename1;
          uploadImg = await this.$store.dispatch("subirarchivosCustom", { filename: this.filename1, file: this.file1, type: "products" });
          console.log("uploadimg ", uploadImg);
          if (uploadImg == true) {
            console.log("entró en el if 2 es true");
            urlimg = "https://admin.siscoper.net/img/products/" + this.filename1;
          }
        }
        console.log("save");
        let data = Object.assign({}, this.row);
        data.empresa_id = this.company_id;
        data.url = urlimg;
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
        } else {
          res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
        }
        this.$isLoading(false);
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.getData();
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.filename1= null
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          this.$isLoading(true);
          let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
          this.$isLoading(false);
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            this.getData();
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.$isLoading(false);
          this.items = [];
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped></style>
