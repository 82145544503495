<template>
    <div class="col-12">
        <div class="row justify-content-between">
            <div class="col-lg-8 col-sm-12">

                <h2 id="about">About</h2>
                <p>A <strong>card</strong> is a flexible and extensible content container. It includes options for headers and footers, a wide variety of content, contextual background colors, and powerful display options. If you’re familiar with Bootstrap 3, cards replace our old panels, wells, and thumbnails. Similar functionality to those components is available as modifier classes for cards.</p>
                
                <h2 id="example">Example</h2>
                <p>Cards are built with as little markup and styles as possible, but still manage to deliver a ton of control and customization. Built with flexbox, they offer easy alignment and mix well with other components.</p>
                <p><code>&lt;b-card&gt;</code> has no fixed width to start, so they'll naturally fill the full width of its parent element. This is easily customized via styles or standard Bootstrap v4 sizing classes.</p>
                <p>Change the default <code>div</code> root tag to any other HTML element by specifying via the <code>tag</code> prop</p>
                <div class="bd-example mb-5">
                    <b-card
                        title="Card Title"
                        :img-src="require('@/assets/images/gallery/1.jpg')"
                        img-alt="Image"
                        img-top
                        tag="div"
                        style="width: 18rem;"
                    >
                        <b-card-text>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                        </b-card-text>

                        <b-button href="#" variant="primary">Go somewhere</b-button>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card
    title=&quot;Card Title&quot;
    img-src=&quot;assets/images/gallery/1.jpg&quot;
    img-alt=&quot;Image&quot;
    img-top
    tag=&quot;div&quot;
    style=&quot;width: 18rem;&quot;
&gt;
&lt;b-card-text&gt;
    Some quick example text to build on the card title and make up the bulk of the card&#039;s content.
&lt;/b-card-text&gt;

&lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h2 id="content-types">Content types</h2>
                <p>Cards support a wide variety of content, including images, text, list groups, links and more. The following are examples of what's supported inside a <code>&lt;b-card&gt;</code></p>
                
                <h3 id="body">Body<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#body" style="padding-left: 0.375em;"></a></h3>
                <p>The building block of a <code>&lt;b-card&gt;</code> is the <code>&lt;b-card-body&gt;</code> section which provides a padded section within a card.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-card-text>
                         This is some text within a card body.
                        </b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-card-text&gt;
        This is some text within a card body.
    &lt;/b-card-text&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="titles-text-and-links">Titles, text, and links</h3>
                <p>By default the <code>&lt;b-card&gt;</code> content is automatically placed in a <code>&lt;b-card-body&gt;</code> section:</p>
                <p>With sub-component <code>&lt;b-card-text&gt;</code>, paragraph text can be added to the card. The last <code>&lt;b-card-text&gt;</code> in the card body will have its bottom margin automatically removed (via CSS). Text within <code>&lt;b-card-text&gt;</code> can also be styled with the standard HTML tags.</p>
                <p>Links can be added and placed next to each other by adding the <code>.card-link</code> class to a <code>&lt;a&gt;</code> tag (or <code>&lt;b-link&gt;</code> component).</p>
                <div class="bd-example mb-5">
                    <b-card title="Card title" sub-title="Card subtitle"  style="width: 18rem;">
                        <b-card-text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </b-card-text>
                        <a href="#" class="card-link">Card link</a>
                        <b-link href="#" class="card-link">Another link</b-link>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Card title&quot; sub-title=&quot;Card subtitle&quot;  style=&quot;width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;
        Some quick example text to build on the card title and make up the bulk of the card&#039;s content.
    &lt;/b-card-text&gt;
    &lt;a href=&quot;#&quot; class=&quot;card-link&quot;&gt;Card link&lt;/a&gt;
    &lt;b-link href=&quot;#&quot; class=&quot;card-link&quot;&gt;Another link&lt;/b-link&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="images">Images</h3>
                <p>The <code>&lt;b-card&gt;</code> prop <code>img-src</code> places an image on the top of the card, and use the <code>img-alt</code> prop to specify a string to be placed in the image's <code>alt</code> attribute. The image specified by the <code>img-src</code> prop will be responsive and will adjust its width when the width of the card is changed.</p>
                <div class="bd-example mb-5">
                    <b-card
                        title="Card Title"
                        :img-src="require('@/assets/images/gallery/2.jpg')"
                        img-alt="Image"
                        img-top
                        tag="div"
                        style="width: 18rem;"
                    >
                        <b-card-text>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                        </b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card
    title=&quot;Card Title&quot;
    img-src=&quot;assets/images/gallery/2.jpg&quot;
    img-alt=&quot;Image&quot;
    img-top
    tag=&quot;div&quot;
    style=&quot;width: 18rem;&quot;
&gt;
    &lt;b-card-text&gt;
    Some quick example text to build on the card title and make up the bulk of the card&#039;s content.
    &lt;/b-card-text&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="list-groups">List groups</h3>
                <p>Create lists of content in a card with a flush <code>b-list-group</code></p>
                <div class="bd-example mb-5">
                    <b-list-group style="width: 18rem;">
                        <b-list-group-item>Cras justo odio</b-list-group-item>
                        <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
                        <b-list-group-item>Vestibulum at eros</b-list-group-item>
                    </b-list-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-list-group style=&quot;width: 18rem;&quot;&gt;
    &lt;b-list-group-item&gt;Cras justo odio&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>
</pre>
                </div>
                <div class="bd-example mb-5">
                    <b-card no-body header="Featured" style="width: 18rem;">
                        <b-list-group flush>
                        <b-list-group-item href="#">Cras justo odio</b-list-group-item>
                        <b-list-group-item href="#">Dapibus ac facilisis in</b-list-group-item>
                        <b-list-group-item href="#">Vestibulum at eros</b-list-group-item>
                        </b-list-group>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card no-body header=&quot;Featured&quot; style=&quot;width: 18rem;&quot;&gt;
    &lt;b-list-group flush&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Cras justo odio&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
    &lt;/b-list-group&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                <div class="bd-example mb-5">
                    <b-card no-body footer="Card footer" style="width: 18rem;">
                        <b-list-group flush>
                        <b-list-group-item href="#">Cras justo odio</b-list-group-item>
                        <b-list-group-item href="#">Dapibus ac facilisis in</b-list-group-item>
                        <b-list-group-item href="#">Vestibulum at eros</b-list-group-item>
                        </b-list-group>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card no-body footer=&quot;Card footer&quot; style=&quot;width: 18rem;&quot;&gt;
    &lt;b-list-group flush&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Cras justo odio&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
    &lt;/b-list-group&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h3 id="kitchen-sink">Kitchen sink</h3>
                <p>Mix and match multiple content types to create the card you need, or throw everything in there. Shown below are image styles, blocks, text styles, and a list group—all wrapped in a fixed-width card.</p>
                <div class="bd-example mb-5">
                    <b-card
                        no-body
                        title="Card Title"
                        :img-src="require('@/assets/images/gallery/6.jpg')"
                        img-alt="Image"
                        img-top
                        tag="div"
                        style="width: 18rem;"
                    >
                        <b-card-body>
                            <b-card-text>
                                Some quick example text to build on the card title and make up the bulk of the card's
                                content.
                            </b-card-text>
                        </b-card-body>

                        <b-list-group flush>
                        <b-list-group-item>Cras justo odio</b-list-group-item>
                        <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
                        <b-list-group-item>Vestibulum at eros</b-list-group-item>
                        </b-list-group>

                        <b-card-body>
                        <a href="#" class="card-link">Card link</a>
                        <a href="#" class="card-link">Another link</a>
                        </b-card-body>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card
    no-body
    title=&quot;Card Title&quot;
    :img-src=&quot;assets/images/gallery/6.jpg&quot;
    img-alt=&quot;Image&quot;
    img-top
    tag=&quot;div&quot;
    style=&quot;width: 18rem;&quot;
    &gt;
    &lt;b-card-body&gt;
        &lt;b-card-text&gt;
            Some quick example text to build on the card title and make up the bulk of the card&#039;s
            content.
        &lt;/b-card-text&gt;
    &lt;/b-card-body&gt;

    &lt;b-list-group flush&gt;
    &lt;b-list-group-item&gt;Cras justo odio&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
    &lt;/b-list-group&gt;

    &lt;b-card-body&gt;
    &lt;a href=&quot;#&quot; class=&quot;card-link&quot;&gt;Card link&lt;/a&gt;
    &lt;a href=&quot;#&quot; class=&quot;card-link&quot;&gt;Another link&lt;/a&gt;
    &lt;/b-card-body&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h3 id="header-and-footer">Header and footer</h3>
                <p>Add an optional header and/or footer within a card via the <code>header</code>/<code>footer</code> props or named slots. You can control the wrapper element tags used by setting the <code>header-tag</code> and <code>footer-tag</code> props (both default is <code>div</code>)</p>
                <div class="bd-example mb-5">
                    <b-card header="Featured"  title="Special title treatment">
                        <b-card-text>
                            With supporting text below as a natural lead-in to additional content.
                        </b-card-text>
                        <b-link href="#" class="btn btn-primary">Go somewhere</b-link>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card header=&quot;Featured&quot;  title=&quot;Special title treatment&quot;&gt;
    &lt;b-card-text&gt;
        With supporting text below as a natural lead-in to additional content.
    &lt;/b-card-text&gt;
    &lt;b-link href=&quot;#&quot; class=&quot;btn btn-primary&quot;&gt;Go somewhere&lt;/b-link&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <p>Card headers can be styled by adding <code>.card-header</code> class.</p>
                <div class="bd-example mb-5">
                    <b-card header="Featured"  title="Special title treatment">
                        <b-card-text>
                            With supporting text below as a natural lead-in to additional content.
                        </b-card-text>
                        <b-link href="#" class="btn btn-primary">Go somewhere</b-link>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card header=&quot;Featured&quot;  title=&quot;Special title treatment&quot;&gt;
    &lt;b-card-text&gt;
        With supporting text below as a natural lead-in to additional content.
    &lt;/b-card-text&gt;
    &lt;b-link href=&quot;#&quot; class=&quot;btn btn-primary&quot;&gt;Go somewhere&lt;/b-link&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                <div class="bd-example mb-5">
                    <b-card header="Quote">
                        <blockquote class="blockquote mb-0">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                            <footer class="blockquote-footer">
                            Someone famous in <cite title="Source Title">Source Title</cite>
                            </footer>
                        </blockquote>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card header=&quot;Quote&quot;&gt;
    &lt;blockquote class=&quot;blockquote mb-0&quot;&gt;
        &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.&lt;/p&gt;
        &lt;footer class=&quot;blockquote-footer&quot;&gt;
        Someone famous in &lt;cite title=&quot;Source Title&quot;&gt;Source Title&lt;/cite&gt;
        &lt;/footer&gt;
    &lt;/blockquote&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                <div class="bd-example mb-5">
                    <b-card header="Featured"  title="Special title treatment" class="text-center">
                        <b-card-text>
                            With supporting text below as a natural lead-in to additional content.
                        </b-card-text>
                        <b-link href="#" class="btn btn-primary">Go somewhere</b-link>
                        <template #footer>
                           2 days ago
                        </template>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card header=&quot;Featured&quot;  title=&quot;Special title treatment&quot; class=&quot;text-center&quot;&gt;
    &lt;b-card-text&gt;
        With supporting text below as a natural lead-in to additional content.
    &lt;/b-card-text&gt;
    &lt;b-link href=&quot;#&quot; class=&quot;btn btn-primary&quot;&gt;Go somewhere&lt;/b-link&gt;
    &lt;template #footer&gt;
        2 days ago
    &lt;/template&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h2 id="sizing">Sizing</h2>
                <p>Cards assume no specific <code>width</code> to start, so they’ll be 100% wide unless otherwise stated. You can change this as needed with custom CSS, grid classes, grid Sass mixins, or utilities.</p>
                <h3 id="using-grid-markup">Using grid markup<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#using-grid-markup" style="padding-left: 0.375em;"></a></h3>
                <p>Using the grid, wrap cards in columns and rows as needed.</p>
                <div class="bd-example mb-5">
                    <div class="row">
                        <div class="col-sm-6">
                            <b-card title="Special title treatment">
                                <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                                <b-button href="#" variant="primary">Go somewhere</b-button>
                            </b-card>
                        </div>
                        <div class="col-sm-6">
                            <b-card title="Special title treatment">
                                <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                                <b-button href="#" variant="primary">Go somewhere</b-button>
                            </b-card>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;div class=&quot;row&quot;&gt;
&lt;div class=&quot;col-sm-6&quot;&gt;
    &lt;b-card title=&quot;Special title treatment&quot;&gt;
        &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
        &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
    &lt;/b-card&gt;
&lt;/div&gt;
&lt;div class=&quot;col-sm-6&quot;&gt;
    &lt;b-card title=&quot;Special title treatment&quot;&gt;
        &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
        &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
    &lt;/b-card&gt;
&lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>

                <h3 id="using-utilities">Using utilities</h3>
                <p>Width and height utilities are generated from the utility API in <code>_utilities.scss</code>. Includes support for <code>25%</code>, <code>50%</code>, <code>75%</code>, <code>100%</code>, and <code>auto</code> by default. Modify those values as you need to generate different utilities here.</p>
                <div class="bd-example mb-5">
                    <b-card title="Card title" class="w-75">
                        <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                        <b-button href="#" variant="primary">Button</b-button>
                    </b-card>
                    <b-card title="Card title" class="w-50">
                        <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                        <b-button href="#" variant="primary">Button</b-button>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Card title&quot; class=&quot;w-75&quot;&gt;
    &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Button&lt;/b-button&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Card title&quot; class=&quot;w-50&quot;&gt;
    &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Button&lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h3 id="using-custom-css">Using custom CSS</h3>
                <p>Use custom CSS in your stylesheets or as inline styles to set a width.</p>
                <div class="bd-example mb-5">
                    <b-card title="Special title treatment" style="width: 18rem;">
                        <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                        <b-button href="#" variant="primary">Go somewhere</b-button>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Special title treatment&quot; style=&quot;width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h2 id="text-alignment">Text alignment</h2>
                <p>You can quickly change the text alignment of any card—in its entirety or specific parts—with our <a href="https://bootstrap-vue.org/docs/components/card#overview">text align classes</a>.</p>
                <div class="bd-example mb-5">
                    <b-card title="Special title treatment" style="width: 18rem;">
                        <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                        <b-button href="#" variant="primary">Go somewhere</b-button>
                    </b-card>

                    <b-card title="Special title treatment" style="width: 18rem;" class="text-center">
                        <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                        <b-button href="#" variant="primary">Go somewhere</b-button>
                    </b-card>

                    <b-card title="Special title treatment" style="width: 18rem;" class="text-end">
                        <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
                        <b-button href="#" variant="primary">Go somewhere</b-button>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Special title treatment&quot; style=&quot;width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;

&lt;b-card title=&quot;Special title treatment&quot; style=&quot;width: 18rem;&quot; class=&quot;text-center&quot;&gt;
    &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;

&lt;b-card title=&quot;Special title treatment&quot; style=&quot;width: 18rem;&quot; class=&quot;text-end&quot;&gt;
    &lt;b-card-text&gt;With supporting text below as a natural lead-in to additional content.&lt;/b-card-text&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h2 id="navigation">Navigation</h2>
               <p>Integrate <a href="https://bootstrap-vue.org/docs/components/nav" class="font-weight-bold"><code>&lt;b-nav&gt;</code></a> into card headers easily.</p>
                <div class="bd-example mb-5">
                    <b-card title="Special title treatment" body-class="text-center" header-tag="nav">
                        <template #header>
                        <b-nav card-header tabs>
                            <b-nav-item active>Active</b-nav-item>
                            <b-nav-item>Link</b-nav-item>
                            <b-nav-item disabled>Disabled</b-nav-item>
                        </b-nav>
                        </template>

                        <b-card-text>
                        With supporting text below as a natural lead-in to additional content.
                        </b-card-text>

                        <b-button variant="primary">Go somewhere</b-button>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Special title treatment&quot; body-class=&quot;text-center&quot; header-tag=&quot;nav&quot;&gt;
&lt;template #header&gt;
&lt;b-nav card-header tabs&gt;
    &lt;b-nav-item active&gt;Active&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;
&lt;/template&gt;

&lt;b-card-text&gt;
With supporting text below as a natural lead-in to additional content.
&lt;/b-card-text&gt;

&lt;b-button variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                <div class="bd-example mb-5">
                    <b-card title="Special title treatment" body-class="text-center" header-tag="nav">
                        <template #header>
                        <b-nav card-header pills>
                            <b-nav-item active>Active</b-nav-item>
                            <b-nav-item>Link</b-nav-item>
                            <b-nav-item disabled>Disabled</b-nav-item>
                        </b-nav>
                        </template>

                        <b-card-text>
                        With supporting text below as a natural lead-in to additional content.
                        </b-card-text>

                        <b-button variant="primary">Go somewhere</b-button>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Special title treatment&quot; body-class=&quot;text-center&quot; header-tag=&quot;nav&quot;&gt;
&lt;template #header&gt;
&lt;b-nav card-header pills&gt;
    &lt;b-nav-item active&gt;Active&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;
&lt;/template&gt;

&lt;b-card-text&gt;
    With supporting text below as a natural lead-in to additional content.
&lt;/b-card-text&gt;

&lt;b-button variant=&quot;primary&quot;&gt;Go somewhere&lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h2 id="images-1">Images</h2>
                <p>The <code>&lt;b-card&gt;</code> prop <code>img-src</code> places an image on the top of the card, and use the <code>img-alt</code> prop to specify a string to be placed in the image's <code>alt</code> attribute. The image specified by the <code>img-src</code> prop will be responsive and will adjust its width when the width of the card is changed.</p>
                <h3 id="image-caps">Image caps<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#image-caps" style="padding-left: 0.375em;"></a></h3>
                <p>Alternatively you can manually place images inside <code>&lt;b-card&gt;</code> using the sub-component <code>&lt;b-card-img&gt;</code>. See the kitchen sink example below for usage.</p>
                <div class="bd-example mb-5">
                    <b-card title="Card title" :img-src="require('@/assets/images/gallery/10.jpg')" img-alt="Card image" img-top>
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                        </b-card-text>
                        <b-card-text>
                           <small class="text-muted">Last updated 3 mins ago</small>
                        </b-card-text>
                    </b-card>
                    <b-card title="Card title" :img-src="require('@/assets/images/gallery/8.jpg')" img-alt="Card image" img-bottom>
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                        </b-card-text>
                        <b-card-text>
                           <small class="text-muted">Last updated 3 mins ago</small>
                        </b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/10.jpg&quot; img-alt=&quot;Card image&quot; img-top&gt;
    &lt;b-card-text&gt;
        This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
    &lt;/b-card-text&gt;
    &lt;b-card-text&gt;
        &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
    &lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/8.jpg&quot; img-alt=&quot;Card image&quot; img-bottom&gt;
    &lt;b-card-text&gt;
        This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
    &lt;/b-card-text&gt;
    &lt;b-card-text&gt;
        &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
    &lt;/b-card-text&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="image-overlays">Image overlays</h3>
                <p>Place the image in the background of the card by setting the boolean prop <code>overlay</code>:</p>
                <div class="bd-example mb-5">
                    <b-card
                        overlay
                        :img-src="require('@/assets/images/gallery/2.jpg')"
                        img-alt="Card Image"
                        text-variant="white"
                        title="Card title"
                        >
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                        </b-card-text>
                        <b-card-text>
                            <p class="card-text">Last updated 3 mins ago</p>
                        </b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card
    overlay
    :img-src=&quot;assets/images/gallery/2.jpg&quot;
    img-alt=&quot;Card Image&quot;
    text-variant=&quot;white&quot;
    title=&quot;Card title&quot;
&gt;
&lt;b-card-text&gt;
    This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
&lt;/b-card-text&gt;
&lt;b-card-text&gt;
    &lt;p class=&quot;card-text&quot;&gt;Last updated 3 mins ago&lt;/p&gt;
&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;pre&gt;</code>
</pre>
                </div>
                <div class="card shadow-sm p-3 mb-5">
                    Note that content should not be larger than the height of the image. If content is larger than the image the content will be displayed outside the image.
                </div>
                
                <h2 id="horizontal">Horizontal</h2>
                <p>Using a combination of grid components, utility classes and individual card sub-components, cards can be made horizontal in a mobile-friendly and responsive way.</p>
                <p>In the example below, we remove the row grid gutters with the <code>no-gutters</code> prop on <code>&lt;b-row&gt;</code> and use <code>md</code> props on <code>&lt;b-col&gt;</code> to make the card horizontal at the <code>md</code> breakpoint. Class <code>rounded-0</code> removes the rounding of the <code>&lt;b-card-img&gt;</code> corners while class <code>overflow-hidden</code> on <code>&lt;b-card&gt;</code> will appropriately clip the image's corners based on the border-radius of the card. Further adjustments may be needed depending on your card content.</p>
                <div class="bd-example mb-5">
                    <b-card class="mb-3" style="max-width: 540px;">
                        <b-row class="g-0">
                            <b-col md-4>
                                <b-card-img img-top :src="require('@/assets/images/gallery/5.jpg')" alt="Image"></b-card-img>
                            </b-col>
                            <b-col md="8">
                                <b-card-body title="Card title">
                                <b-card-text>
                                    This is a wider card with supporting text as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                                <b-card-text>
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </b-card-text>
                                </b-card-body>
                            </b-col>
                        </b-row>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card class=&quot;mb-3&quot; style=&quot;max-width: 540px;&quot;&gt;
&lt;b-row class=&quot;g-0&quot;&gt;
    &lt;b-col md-4&gt;
        &lt;b-card-img img-top :src=&quot;assets/images/gallery/5.jpg&quot; alt=&quot;Image&quot;&gt;&lt;/b-card-img&gt;
    &lt;/b-col&gt;
    &lt;b-col md=&quot;8&quot;&gt;
        &lt;b-card-body title=&quot;Card title&quot;&gt;
        &lt;b-card-text&gt;
            This is a wider card with supporting text as a natural lead-in to additional content.
            This content is a little bit longer.
        &lt;/b-card-text&gt;
        &lt;b-card-text&gt;
            &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
        &lt;/b-card-text&gt;
        &lt;/b-card-body&gt;
    &lt;/b-col&gt;
&lt;/b-row&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h2 id="card-styles">Card styles</h2>
                <p>Cards include their own variant style for quickly changing the background-color and of a card via the <code>bg-variant</code> and <code>border-variant</code> props. Darker solid variants may require setting the prop <code>text-variant</code> to adjust the text color.</p>
                <h3 id="background-and-color">Background and color</h3>
                <p>Use <a href="https://bootstrap-vue.org/docs/components/card#background-and-border-variants">text and background utilities</a> to change the appearance of a card.</p>
                <div class="bd-example mb-5">
                    <b-card title="Primary card title" bg-variant="primary" text-variant="white" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card title="Secondary card title" bg-variant="secondary" text-variant="white" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card title="Success card title" bg-variant="success" text-variant="white" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                     <b-card title="Danger card title" bg-variant="danger" text-variant="white" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card title="Warning card title" bg-variant="warning" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card title="Info card title" bg-variant="info" text-variant="body" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                     <b-card title="Light card title" bg-variant="light" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card title="Dark card title" bg-variant="dark" text-variant="white" header="Header" class="mb-3" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card title=&quot;Primary card title&quot; bg-variant=&quot;primary&quot; text-variant=&quot;white&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Secondary card title&quot; bg-variant=&quot;secondary&quot; text-variant=&quot;white&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Success card title&quot; bg-variant=&quot;success&quot; text-variant=&quot;white&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
    &lt;b-card title=&quot;Danger card title&quot; bg-variant=&quot;danger&quot; text-variant=&quot;white&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Warning card title&quot; bg-variant=&quot;warning&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Info card title&quot; bg-variant=&quot;info&quot; text-variant=&quot;body&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
    &lt;b-card title=&quot;Light card title&quot; bg-variant=&quot;light&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card title=&quot;Dark card title&quot; bg-variant=&quot;dark&quot; text-variant=&quot;white&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <div class="card shadow-sm p-3">
                    <h5 id="conveying-meaning-to-assistive-technologies">Conveying meaning to assistive technologies</h5>
                    <p>Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden with the <code>.visually-hidden</code> class.</p>
                </div>
                
                <h3 id="border">Border</h3>
                <p>Use <a href="https://v5.getbootstrap.com/docs/5.0/utilities/borders/">border utilities</a> to change just the <code>border-color</code> of a card. Note that you can put <code>.text-{color}</code> classes on the parent <code>.card</code> or a subset of the card’s contents as shown below.</p>
                <div class="bd-example mb-5">
                    <b-card border-variant="primary" header="Header" class="mb-3" header-text-variant="dark" text-variant="primary" title="Primary card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="secondary" header="Header" class="mb-3" header-text-variant="dark" text-variant="warning" title="Secondary card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="success" header="Header" class="mb-3" header-text-variant="dark" text-variant="success" title="Success card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="danger" header="Header" class="mb-3" header-text-variant="dark" text-variant="danger" title="Danger card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="warning" header="Header" class="mb-3" title="Warning card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="info" header="Header" class="mb-3" title="Info card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="light" header="Header" class="mb-3" title="Light card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
                    <b-card border-variant="dark" header="Header" class="mb-3" title="Dark card title" style="max-width: 18rem;">
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card border-variant=&quot;primary&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; header-text-variant=&quot;dark&quot; text-variant=&quot;primary&quot; title=&quot;Primary card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;secondary&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; header-text-variant=&quot;dark&quot; text-variant=&quot;warning&quot; title=&quot;Secondary card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;success&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; header-text-variant=&quot;dark&quot; text-variant=&quot;success&quot; title=&quot;Success card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;danger&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; header-text-variant=&quot;dark&quot; text-variant=&quot;danger&quot; title=&quot;Danger card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;warning&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; title=&quot;Warning card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;info&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; title=&quot;Info card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;light&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; title=&quot;Light card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;
&lt;b-card border-variant=&quot;dark&quot; header=&quot;Header&quot; class=&quot;mb-3&quot; title=&quot;Dark card title&quot; style=&quot;max-width: 18rem;&quot;&gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="mixins-utilities">Mixins utilities</h3>
                <p>You can also apply the solid and border variants individually to card headers and footers via the <code>header-bg-variant</code>, <code>header-border-variant</code>, <code>header-text-variant</code>, <code>footer-bg-variant</code>, <code>footer-border-variant</code>, and <code>footer-text-variant</code> props.</p>
                <div class="bd-example mb-5">
                    <b-card
                        border-variant="success"
                        text-variant="success"
                        header="Header"
                        header-text-variant="dark"
                        header-bg-variant="transparent"
                        footer="Footer"
                        footer-bg-variant="transparent"
                        title="Success card title"
                        footer-text-variant="dark"
                        style="max-width: 18rem;"
                    >
                        <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card
    border-variant=&quot;success&quot;
    text-variant=&quot;success&quot;
    header=&quot;Header&quot;
    header-text-variant=&quot;dark&quot;
    header-bg-variant=&quot;transparent&quot;
    footer=&quot;Footer&quot;
    footer-bg-variant=&quot;transparent&quot;
    title=&quot;Success card title&quot;
    footer-text-variant=&quot;dark&quot;
    style=&quot;max-width: 18rem;&quot;
    &gt;
    &lt;b-card-text&gt;Some quick example text to build on the card title and make up the bulk of the card&#039;s content.&lt;/b-card-text&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>

                <h2 id="card-layout">Card layout</h2>
                <p>Use card groups to render cards as a single, attached element with equal width and height columns. Card groups use display: flex; to achieve their uniform sizing.</p>
                <p>When using card groups with footers, their content will automatically line up.</p>
                <h3 id="card-groups">Card groups</h3>
                <p>In addition to styling the content within cards, BootstrapVue includes a <code>&lt;b-card-group&gt;</code> component for laying out series of cards. For the time being, these layout options are not yet responsive.</p>
                <div class="bd-example mb-5">
                    <b-card-group>
                        <b-card title="Card title" :img-src="require('@/assets/images/gallery/6.jpg')" img-alt="Image" img-top>
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content.
                            This content is a little bit longer.
                        </b-card-text>
                        <b-card-text>
                            <small class="text-muted">Last updated 3 mins ago</small>
                        </b-card-text>
                        </b-card>

                        <b-card title="Card title" :img-src="require('@/assets/images/gallery/2.jpg')" img-alt="Image" img-top>
                        <b-card-text>
                            This card has supporting text below as a natural lead-in to additional content.
                        </b-card-text>
                        <b-card-text>
                            <small class="text-muted">Last updated 3 mins ago</small>
                        </b-card-text>
                        </b-card>

                        <b-card title="Card title" :img-src="require('@/assets/images/gallery/5.jpg')" img-alt="Image" img-top>
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content.
                            This card has even longer content than the first to show that equal height action.
                        </b-card-text>
                        <b-card-text>
                            <small class="text-muted">Last updated 3 mins ago</small>
                        </b-card-text>
                        </b-card>
                    </b-card-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-card-group&gt;
    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/6.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
    &lt;b-card-text&gt;
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This content is a little bit longer.
    &lt;/b-card-text&gt;
    &lt;b-card-text&gt;
        &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
    &lt;/b-card-text&gt;
    &lt;/b-card&gt;

    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/2.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
    &lt;b-card-text&gt;
        This card has supporting text below as a natural lead-in to additional content.
    &lt;/b-card-text&gt;
    &lt;b-card-text&gt;
        &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
    &lt;/b-card-text&gt;
    &lt;/b-card&gt;

    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/5.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
    &lt;b-card-text&gt;
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This card has even longer content than the first to show that equal height action.
    &lt;/b-card-text&gt;
    &lt;b-card-text&gt;
        &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
    &lt;/b-card-text&gt;
    &lt;/b-card&gt;
&lt;/b-card-group&gt;</code>
</pre>
                </div>
                
                <p>When using card groups with footers, their content will automatically line up.</p>
                <div class="bd-example mb-5">
                    <b-card-group>
                        <b-card title="Card title" :img-src="require('@/assets/images/gallery/2.jpg')" img-alt="Image" img-top>
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content.
                            This content is a little bit longer.
                        </b-card-text>
                        <template #footer>
                            <small class="text-muted">Last updated 3 mins ago</small>
                        </template>
                        </b-card>

                        <b-card title="Card title" :img-src="require('@/assets/images/gallery/6.jpg')" img-alt="Image" img-top>
                        <b-card-text>
                            This card has supporting text below as a natural lead-in to additional content.
                        </b-card-text>
                        <template #footer>
                            <small class="text-muted">Last updated 3 mins ago</small>
                        </template>
                        </b-card>

                        <b-card title="Card title" :img-src="require('@/assets/images/gallery/8.jpg')" img-alt="Image" img-top>
                        <b-card-text>
                            This is a wider card with supporting text below as a natural lead-in to additional content.
                            This card has even longer content than the first to show that equal height action.
                        </b-card-text>
                        <template #footer>
                            <small class="text-muted">Last updated 3 mins ago</small>
                        </template>
                        </b-card>
                    </b-card-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-card-group&gt;
&lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/2.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
&lt;b-card-text&gt;
    This is a wider card with supporting text below as a natural lead-in to additional content.
    This content is a little bit longer.
&lt;/b-card-text&gt;
&lt;template #footer&gt;
    &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
&lt;/template&gt;
&lt;/b-card&gt;

&lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/6.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
&lt;b-card-text&gt;
    This card has supporting text below as a natural lead-in to additional content.
&lt;/b-card-text&gt;
&lt;template #footer&gt;
    &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
&lt;/template&gt;
&lt;/b-card&gt;

&lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/8.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
&lt;b-card-text&gt;
    This is a wider card with supporting text below as a natural lead-in to additional content.
    This card has even longer content than the first to show that equal height action.
&lt;/b-card-text&gt;
&lt;template #footer&gt;
    &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
&lt;/template&gt;
&lt;/b-card&gt;
&lt;/b-card-group&gt;;</code>
</pre>
                </div>

                <h3 id="grid-cards">Grid cards</h3>
                <p>Cards can be organized into Masonry-like columns with by wrapping them in a <code class="text-nowrap" translate="no">&lt;b-card-group&gt;</code> with the prop <code class="text-nowrap" translate="no">columns</code> set. Cards are built with CSS column properties instead of flexbox for easier alignment. Cards are ordered from top to bottom and left to right.</p>
                <div class="bd-example mb-5">
                    <div class="row row-cols-1 row-cols-md-2 g-4">
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/1.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                           <b-card title="Card title" :img-src="require('@/assets/images/gallery/9.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/2.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/3.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;div class=&quot;row row-cols-1 row-cols-md-2 g-4&quot;&gt;
&lt;div class=&quot;col&quot;&gt;
    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/1.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
        &lt;b-card-text&gt;
            This is a wider card with supporting text below as a natural lead-in to additional content.
            This content is a little bit longer.
        &lt;/b-card-text&gt;
    &lt;/b-card&gt;
&lt;/div&gt;
&lt;div class=&quot;col&quot;&gt;
    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/9.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
        &lt;b-card-text&gt;
            This is a wider card with supporting text below as a natural lead-in to additional content.
            This content is a little bit longer.
        &lt;/b-card-text&gt;
    &lt;/b-card&gt;
&lt;/div&gt;
&lt;div class=&quot;col&quot;&gt;
    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/2.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
        &lt;b-card-text&gt;
            This is a wider card with supporting text below as a natural lead-in to additional content.
            This content is a little bit longer.
        &lt;/b-card-text&gt;
    &lt;/b-card&gt;
&lt;/div&gt;
&lt;div class=&quot;col&quot;&gt;
    &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/3.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
        &lt;b-card-text&gt;
            This is a wider card with supporting text below as a natural lead-in to additional content.
            This content is a little bit longer.
        &lt;/b-card-text&gt;
    &lt;/b-card&gt;
&lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>
                
                <p>Change it to <code>.row-cols-3</code> and you’ll see the fourth card wrap.</p>
                <div class="bd-example mb-5">
                    <div class="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/10.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/2.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/3.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" :img-src="require('@/assets/images/gallery/9.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;div class=&quot;row row-cols-1 row-cols-md-3 g-4&quot;&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/10.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/2.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/3.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; :img-src=&quot;assets/images/gallery/9.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>
                
                <p>When you need equal height, add <code>.h-100</code> to the cards. If you want equal heights by default, you can set <code>$card-height: 100%</code> in Sass.</p>
                <div class="bd-example mb-5">
                    <div class="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/1.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/5.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/7.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/4.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                            </b-card>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;div class=&quot;row row-cols-1 row-cols-md-3 g-4&quot;&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/1.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/5.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/7.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/4.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>

                <p>Just like with card groups, card footers will automatically line up.</p>
                <div class="bd-example mb-5">
                    <div class="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/2.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                                <template #footer>
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </template>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/5.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                                <template #footer>
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </template>
                            </b-card>
                        </div>
                        <div class="col">
                            <b-card title="Card title" class="h-100" :img-src="require('@/assets/images/gallery/6.jpg')" img-alt="Image" img-top>
                                <b-card-text>
                                    This is a wider card with supporting text below as a natural lead-in to additional content.
                                    This content is a little bit longer.
                                </b-card-text>
                                <template #footer>
                                    <small class="text-muted">Last updated 3 mins ago</small>
                                </template>
                            </b-card>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;div class=&quot;row row-cols-1 row-cols-md-3 g-4&quot;&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/2.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
            &lt;template #footer&gt;
                &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
            &lt;/template&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/5.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
            &lt;template #footer&gt;
                &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
            &lt;/template&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-card title=&quot;Card title&quot; class=&quot;h-100&quot; :img-src=&quot;assets/images/gallery/6.jpg&quot; img-alt=&quot;Image&quot; img-top&gt;
            &lt;b-card-text&gt;
                This is a wider card with supporting text below as a natural lead-in to additional content.
                This content is a little bit longer.
            &lt;/b-card-text&gt;
            &lt;template #footer&gt;
                &lt;small class=&quot;text-muted&quot;&gt;Last updated 3 mins ago&lt;/small&gt;
            &lt;/template&gt;
        &lt;/b-card&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>

                <h3 id="masonry">Masonry</h3>
                <p>In <code>v4</code> we used a CSS-only technique to mimic the behavior of <a href="https://bootstrap-vue.org/docs/components/card#background-and-border-variants">Masonry</a>-like columns, but this technique came with lots of unpleasant <a href="https://bootstrap-vue.org/docs/components/card#background-and-border-variants">side effects</a>. If you want to have this type of layout in <code>v5</code>, you can just make use of Masonry plugin. <strong>Masonry is not included in Bootstrap</strong>, but we’ve made a <a href="https://bootstrap-vue.org/docs/components/card#background-and-border-variants">demo example</a> to help you get started.</p>
            </div>
            <div class="col-lg-3 col-sm-12 d-none d-sm-block">
                <div class="sticky-lg-top">
                    <strong class="d-block h6 my-2 pb-2 border-bottom">On this page</strong>
                    <nav>
                        <ul>
                            <li><a href="#about">About</a></li>
                            <li><a href="#example">Example</a></li>
                            <li><a href="#content-types">Content types</a>
                                <ul>
                                    <li><a href="#body">Body</a></li>
                                    <li><a href="#titles-text-and-links">Titles, text, and links</a></li>
                                    <li><a href="#images">Images</a></li>
                                    <li><a href="#list-groups">List groups</a></li>
                                    <li><a href="#kitchen-sink">Kitchen sink</a></li>
                                    <li><a href="#header-and-footer">Header and footer</a></li>
                                </ul>
                            </li>
                            <li><a href="#sizing">Sizing</a>
                                <ul>
                                    <li><a href="#using-grid-markup">Using grid markup</a></li>
                                    <li><a href="#using-utilities">Using utilities</a></li>
                                    <li><a href="#using-custom-css">Using custom CSS</a></li>
                                </ul>
                            </li>
                            <li><a href="#text-alignment">Text alignment</a></li>
                            <li><a href="#navigation">Navigation</a></li>
                            <li><a href="#images-1">Images</a>
                                <ul>
                                    <li><a href="#image-caps">Image caps</a></li>
                                    <li><a href="#image-overlays">Image overlays</a></li>
                                </ul>
                            </li>
                            <li><a href="#horizontal">Horizontal</a></li>
                            <li><a href="#card-styles">Card styles</a>
                                <ul>
                                    <li><a href="#background-and-color">Background and color</a></li>
                                    <li><a href="#border">Border</a></li>
                                    <li><a href="#mixins-utilities">Mixins utilities</a></li>
                                </ul>
                            </li>
                            <li><a href="#card-layout">Card layout</a>
                                <ul>
                                    <li><a href="#card-groups">Card groups</a></li>
                                    <li><a href="#grid-cards">Grid cards</a></li>
                                    <li><a href="#masonry">Masonry</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div> <!-- Row end  -->
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'CardsComponent',
    mounted() {
        Prism.highlightAll()
    }
}
</script>
<style lang="scss">

</style>