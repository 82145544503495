<template>
        <div class="col-12">
            <div class="bd-content">
                <h2 id="about">About</h2>
                <p class="bd-lead">The <code class="text-nowrap" translate="no">&lt;b-spinner&gt;</code> component can be used to show the loading state in your projects. They're rendered only with basic HTML and CSS as a lightweight Vue functional component. Their appearance, alignment, and sizing can be easily customized with a few built-in props and/or Bootstrap v4 utility classes.</p>
                <div class="card card-callout p-3">
                    <span>The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/reference/accessibility#reduced-motion">reduced motion section of our accessibility documentation</a>.</span>
                </div>

                <div class="border-top mt-5 pt-3">
                    <h4 id="border-spinner">Border spinner</h4>
                    <p>Use the default <code class="text-nowrap" translate="no">border</code> type spinners for a lightweight loading indicator.</p>
                    <b-tabs nav-class="tab-card px-3 border-bottom-0">
                        <b-tab title="Preview" active>
                            <b-card class="mb-3">
                                    <b-spinner variant="primary"></b-spinner>
                                    <b-spinner></b-spinner>
                                    <b-spinner variant="success"></b-spinner>
                                    <b-spinner variant="danger"></b-spinner>
                                    <b-spinner variant="warning"></b-spinner>
                                    <b-spinner variant="info"></b-spinner>
                                    <b-spinner variant="light"></b-spinner>
                                    <b-spinner variant="dark"></b-spinner>
                            </b-card>
                        </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-spinner variant=&quot;primary&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner&gt;&lt;/b-spinner&gt;
&lt;b-spinner variant=&quot;success&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner variant=&quot;danger&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner variant=&quot;warning&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner variant=&quot;info&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner variant=&quot;light&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner variant=&quot;dark&quot;&gt;&lt;/b-spinner&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                    </b-tabs>
                    <div class="card card-callout p-3">
                        <p><strong>Why not use <code class="text-nowrap" translate="no">border-color</code> utilities?</strong> Each <code class="text-nowrap" translate="no">border</code> spinner specifies a <code class="text-nowrap" translate="no">transparent</code> border for at least one side, so <code class="text-nowrap" translate="no">.border-{color}</code> utilities would override that.</p>
                    </div>
                </div>

                <div class="border-top mt-5 pt-3">
                    <h4 id="growing-spinner">Growing spinner</h4>
                    <p>If you don't fancy a <code class="text-nowrap" translate="no">border</code> spinner, switch to the <code class="text-nowrap" translate="no">grow</code> spinner by setting the prop <code class="text-nowrap" translate="no">type</code> to <code class="text-nowrap" translate="no">'grow'</code>. While it doesn't technically spin, it does repeatedly grow!</p>
                    <b-tabs nav-class="tab-card px-3 border-bottom-0">
                        <b-tab title="Preview" active>
                            <b-card class="mb-3">
                                    <b-spinner type="grow" variant="primary"></b-spinner>
                                    <b-spinner type="grow" ></b-spinner>
                                    <b-spinner type="grow" variant="success"></b-spinner>
                                    <b-spinner type="grow" variant="danger"></b-spinner>
                                    <b-spinner type="grow" variant="warning"></b-spinner>
                                    <b-spinner type="grow" variant="info"></b-spinner>
                                    <b-spinner type="grow" variant="light"></b-spinner>
                                    <b-spinner type="grow" variant="dark"></b-spinner>
                            </b-card>
                        </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-spinner type=&quot;grow&quot; variant=&quot;primary&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; &gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; variant=&quot;success&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; variant=&quot;danger&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; variant=&quot;warning&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; variant=&quot;info&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; variant=&quot;light&quot;&gt;&lt;/b-spinner&gt;
&lt;b-spinner type=&quot;grow&quot; variant=&quot;dark&quot;&gt;&lt;/b-spinner&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                    </b-tabs>
                </div>

                <div class="border-top mt-5 pt-3">
                    <h2 id="size">Size</h2>
                    <p>Set the prop <code class="text-nowrap" translate="no">small</code> to <code class="text-nowrap" translate="no">true</code> to make a smaller spinner that can quickly be used within other components.</p>
                    <b-tabs nav-class="tab-card px-3 border-bottom-0">
                        <b-tab title="Preview" active>
                            <b-card class="mb-3">
                                    <b-spinner small class="me-3"></b-spinner>
                                    <b-spinner class="me-3"></b-spinner>
                                    <b-spinner style="width: 3rem; height: 3rem;" class="me-3"></b-spinner>
                                    <b-spinner small  type="grow"  class="me-3"></b-spinner>
                                    <b-spinner type="grow"  class="me-3"></b-spinner>
                                    <b-spinner type="grow" style="width: 3rem; height: 3rem;"  class="me-3"></b-spinner>
                            </b-card>
                        </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-tab title=&quot;Preview&quot; active&gt;
    &lt;b-card class=&quot;mb-3&quot;&gt;
            &lt;b-spinner small class=&quot;me-3&quot;&gt;&lt;/b-spinner&gt;
            &lt;b-spinner class=&quot;me-3&quot;&gt;&lt;/b-spinner&gt;
            &lt;b-spinner style=&quot;width: 3rem; height: 3rem;&quot; class=&quot;me-3&quot;&gt;&lt;/b-spinner&gt;
            &lt;b-spinner small  type=&quot;grow&quot;  class=&quot;me-3&quot;&gt;&lt;/b-spinner&gt;
            &lt;b-spinner type=&quot;grow&quot;  class=&quot;me-3&quot;&gt;&lt;/b-spinner&gt;
            &lt;b-spinner type=&quot;grow&quot; style=&quot;width: 3rem; height: 3rem;&quot;  class=&quot;me-3&quot;&gt;&lt;/b-spinner&gt;
    &lt;/b-card&gt;
&lt;/b-tab&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                    </b-tabs>
                </div>

                <div class="border-top mt-5 pt-3">
                    <h4 id="buttons">Buttons</h4>
                    <p>Use spinners within buttons to indicate an action is currently processing or taking place. You may also swap the text out of the spinner element and utilize button text as needed.</p>
                     <b-tabs nav-class="tab-card px-3 border-bottom-0">
                        <b-tab title="Preview" active>
                            <b-card class="mb-3">
                                    <b-button  class="mr-1" variant="primary">
                                        <b-spinner small></b-spinner>
                                    </b-button>
                                    <b-button  class="mr-1" variant="primary" disabled>
                                        <b-spinner small></b-spinner>
                                    </b-button>
                                    <b-button  class="mr-1" variant="light" disabled>
                                        <b-spinner small></b-spinner>
                                    </b-button>
                                    <b-button  class="mr-1" variant="danger" disabled>
                                        <b-spinner small type="grow"></b-spinner>
                                    </b-button>
                                    <b-button  class="mr-1" variant="light" disabled>
                                        <b-spinner small type="grow"></b-spinner>
                                    </b-button>
                                    <b-button  class="mr-1" variant="warning" disabled>
                                        <b-spinner small type="grow"></b-spinner>
                                        Loading...
                                    </b-button>
                                    <b-button  class="mr-1" variant="light" disabled>
                                        <b-spinner small type="grow"></b-spinner>
                                        Loading...
                                    </b-button>
                                    <b-button  class="mr-1" variant="success" disabled>
                                        <b-spinner small type="grow"></b-spinner>
                                        Loading...
                                    </b-button>
                                    <b-button  class="mr-1" variant="light" disabled>
                                        <b-spinner small type="grow"></b-spinner>
                                        Loading...
                                    </b-button>
                            </b-card>
                        </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;primary&quot;&gt;
            &lt;b-spinner small&gt;&lt;/b-spinner&gt;
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;primary&quot; disabled&gt;
            &lt;b-spinner small&gt;&lt;/b-spinner&gt;
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;light&quot; disabled&gt;
            &lt;b-spinner small&gt;&lt;/b-spinner&gt;
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;danger&quot; disabled&gt;
            &lt;b-spinner small type=&quot;grow&quot;&gt;&lt;/b-spinner&gt;
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;light&quot; disabled&gt;
            &lt;b-spinner small type=&quot;grow&quot;&gt;&lt;/b-spinner&gt;
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;warning&quot; disabled&gt;
            &lt;b-spinner small type=&quot;grow&quot;&gt;&lt;/b-spinner&gt;
            Loading...
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;light&quot; disabled&gt;
            &lt;b-spinner small type=&quot;grow&quot;&gt;&lt;/b-spinner&gt;
            Loading...
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;success&quot; disabled&gt;
            &lt;b-spinner small type=&quot;grow&quot;&gt;&lt;/b-spinner&gt;
            Loading...
        &lt;/b-button&gt;
        &lt;b-button  class=&quot;mr-1&quot; variant=&quot;light&quot; disabled&gt;
            &lt;b-spinner small type=&quot;grow&quot;&gt;&lt;/b-spinner&gt;
            Loading...
        &lt;/b-button&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'SpinnersComponent',
    data() {
      return {
        
      }
    },methods: {
      
    },mounted() {
        Prism.highlightAll()
        
    }
}
</script>
