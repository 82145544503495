<template>
    <div class="row clearfix">
        <div class="col-md-12">
            <div class="card p-4 mb-4">
               <div class="row py-3 justify-content-between">
                <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                    <div>
                        <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Nuevo cliente">
                    </div>
                    <card-actions></card-actions>
                </div>

                   <div class="col-md-5">
                       <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-5">
                        <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
               </div>
                <b-table 
                    hover
                    responsive 
                    :items="items" 
                    :fields="fields" 
                    :current-page="currentPage" 
                    :per-page="perPage" 
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                >
                <template #cell(name)="row">
                    {{ row.value.first }} {{ row.value.last }}
                </template>
                        <template #cell(fecha_alta)="data">
                            <span  >{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : '' }}</span>
                        </template>
                        <template #cell(action)="data">
                            <span><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1"><i class="fa fa-pencil"></i> </button> </span>
                            <span><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash"></i> </button> </span>

                            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
                            <!-- <button type="button" class="btn btn-secondary mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-secondary mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

                            <!-- <table-action></table-action> -->
                        </template>
                <template #row-details="row">
                    <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                    </b-card>
                </template>
                </b-table>
                 <div>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
                </div>
            </div> 
        </div>

        <b-modal id="exampleModalCenter" tabindex="-1" class="modal-lg" centered  :title="modalTitle" hide-footer>
            <template v-slot:modal-header-close>
                <button type="button" class="btn btn-close"></button>
            </template>
            <div class="modal-body bg-gray">
                <form @submit.prevent="saveData()">
                    <div class="row">
                        <div class="col-md-12 mb-2">
                            <label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="email" class="">Correo</label><input  class="form-control" v-model="row.email" type="email" name="email" id="email">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="telefono" class="">Teléfono</label><input class="form-control" v-model="row.telefono" type="text" name="telefono" id="telefono">
                        </div>
                        <div class="col-md-6">
                            <label for="contacto" class="">Contacto</label><input class="form-control" v-model="row.contacto" type="text" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="estado" class="">Estado</label>
                            <b-form-select required class="form-control select text-black"  v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado">
                            </b-form-select>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label for="observaciones" class="">Observaciones</label><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones">
                        </div>
                    </div>
                <div class="text-right">
                    <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
                    <input class="btn btn-primary mr-2" type="submit" value="Guardar">
                </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>
<script>
import CardActions from '@/components/core/CardActions.vue'
export default {
    name:'TableComponent',
    components: {
        // 'table-action': TableAction,
        'card-actions': CardActions,
    },data() {
      return {
        fields: [
            { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
            { key: 'nombre', label: 'Nombre', sortable: true, class: 'text-center' },
            { key: 'fecha_alta', label: 'fecha alta', sortable: true, class: 'text-center' },
            { key: 'telefono', label: 'Telefono', sortable: true, class: 'text-center' },
            { key: 'email', label: 'email', sortable: true, class: 'text-center' },
            { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
            { key: 'action', label: '',tdClass:'dt-body-right'},

          {
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true
          },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, { value: 100, text: 100 }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
        ///////
        table: 'meca_clientes',
        items: [],
        row:{},
        company_id: 0,
        userId:0,
        rol:0,
        modalTitle: 'Nuevo Cliente',
        listaEstado:[
            {estado: "Activo"},
            {estado: "Inactivo"},
        ]
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    async mounted() {
      this.userId = localStorage.getItem('user_id');
      this.company_id = localStorage.getItem('company_id');
      this.rol = localStorage.getItem('rol');

      await this.getData()
    },
    methods: {
        showModal(item, id){
            console.log('----->',item)
            if(id==1){
                this.modalTitle = 'Nuevo Cliente'
                this.row = {}
                this.$bvModal.show('exampleModalCenter')
            }
            else{
                this.row = item
                this.modalTitle = 'Editar Cliente'
                this.$bvModal.show('exampleModalCenter')
                console.log(this.row)
            }
        },
        async getData(){
            try {
                this.$isLoading(true)
                let res = await this.$store.dispatch('get', {path: this.table + '/getAllByCompany/' + this.company_id});
                this.$isLoading(false)
                if (res.length >0){
                    this.items = res
                    this.totalRows = this.items.length

                }
                else{
                    this.items = []
                    this.totalRows = 0

                }
            } catch (error) {
                this.items = []
                this.totalRows = 0
                this.$isLoading(false)
                console.error('error', error)
            }
        },    
        async saveData(){
            try {
                this.$isLoading(true)
                console.log('save')
                let data = Object.assign({} , this.row)
                data.empresa_id = this.company_id
                let res
                if(this.row.id){
                    delete(data.id)
                    res = await this.$store.dispatch('put', {path: this.table + '/modify/'+ this.row.id , data: data});
                }
                else{
                    res = await this.$store.dispatch('post', {path: this.table + '/new', data: data});
                }
                this.$isLoading(false)
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.getData()
                    this.row={}
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                    this.$bvModal.hide('exampleModalCenter')

                }
            } catch (error) {
                this.items = []
                this.$isLoading(false)
                console.error('error', error)
            }
        },
        async deleteItem(item){
            const {isConfirmed} = await this.$swal({
                title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                icon:"question",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                reverseButtons: true

            })
            if(isConfirmed){
                this.$isLoading(true)
                try {
                    let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                    this.$isLoading(false)
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.getData()
                        this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.$isLoading(false)
                    this.items = []
                    console.error('error--->', error)
                }                    
            }
        },

        ///////////////
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>
<style scoped>

</style>