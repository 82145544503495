<template>
    <div class="row">
        <div class="col-12">
            <!-- Gallery: images -->
            <div class="row g-1">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" v-for="(image, i) in images" :key="i">
                    <a title="Image 1" href="#" @click="changeEvent(image)">
                        <img class="thumbnail img-fluid" :src="image" v-b-modal.myModalGallery>
                    </a>
                </div>
            </div> <!-- Row end  -->
            <!-- Gallery: carousel popups -->
            <b-modal id="myModalGallery" tabindex="-1" title="Image Gallery" :hide-footer="true" centered size='lg'>
                <template v-slot:modal-header-close>
                    <button type="button" class="btn btn-close"></button>
                </template>
                <div id="modalCarousel" class="carousel slide carousel-fade" data-ride="carousel">
                    <div class="carousel-inner"><img class="thumbnail img-fluid" :src='imagesrc'></div>

                    <a class="carousel-control-prev left" href="#modalCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </a>
                    <a class="carousel-control-next right" href="#modalCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </a>
                </div>
            </b-modal>
        </div>
    </div> <!-- Row end  -->
</template>
<script>
export default {
    name:'ImageGalleryComponent',
    data (){
        return{
            imagesrc:'',
            images: [
                require(`@/assets/images/gallery/1.jpg`),
                require(`@/assets/images/gallery/2.jpg`),
                require(`@/assets/images/gallery/3.jpg`),
                require(`@/assets/images/gallery/4.jpg`),
                require(`@/assets/images/gallery/5.jpg`),
                require(`@/assets/images/gallery/6.jpg`),
                require(`@/assets/images/gallery/7.jpg`),
                require(`@/assets/images/gallery/8.jpg`),
                require(`@/assets/images/gallery/9.jpg`),
                require(`@/assets/images/gallery/10.jpg`),
                require(`@/assets/images/gallery/11.jpg`),
                require(`@/assets/images/gallery/12.jpg`),
            ],
        }
    },methods:{
        changeEvent(image){
            this.imagesrc = image
        }
    }
}
</script>
<style scoped>

</style>