<template>
    <div class="col-12">
        <div class="bd-content">
            <h2 id="base-nav">Base nav</h2>
            <p class="bd-lead">Navigation available in Bootstrap share general markup and styles, from the base <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> class to the <code class="text-nowrap" translate="no">active</code> and <code class="text-nowrap" translate="no">disabled</code> states. Swap modifier props to switch between each style.</p>
            <div class="alert alert-danger" role="alert">
                <strong>Navs</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/nav" target="_blank">Bootstrap Navs documentation <i class="fa fa-external-link"></i></a>
            </div>
            <div class="card card-callout p-3">
                <p class="mb-0">The base <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> component is built with flexbox and provides a strong foundation for building all types of navigation components. It includes some style overrides (for working with lists), some link padding for larger hit areas, and basic disabled styling. No active states are included in the base nav.</p>
            </div>
            <div class="border-top mt-5 pt-3">
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card>
                            <b-nav>
                                <b-nav-item>Active</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item disabled>Disabled</b-nav-item>
                            </b-nav>
                            <b-nav class="justify-content-center">
                                <b-nav-item>Active</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item disabled>Disabled</b-nav-item>
                            </b-nav>
                                <b-nav class="justify-content-end">
                                <b-nav-item>Active</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item disabled>Disabled</b-nav-item>
                            </b-nav>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;b-nav&gt;
    &lt;b-nav-item&gt;Active&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;
&lt;b-nav class=&quot;justify-content-center&quot;&gt;
    &lt;b-nav-item&gt;Active&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;
&lt;b-nav class=&quot;justify-content-end&quot;&gt;
    &lt;b-nav-item&gt;Active&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>

                <p>Classes are used throughout, so your markup can be super flexible. Use <code>&lt;ul&gt;</code>s like above, <code>&lt;ol&gt;</code> if the order of your items is important, or roll your own with a <code>&lt;nav&gt;</code> element. Because the <code>.nav</code> uses <code>display: flex</code>, the nav links behave the same as nav items would, but without the extra markup.</p>
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card>
                            <b-nav>
                                <b-nav-item>Active</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item>Link</b-nav-item>
                                <b-nav-item disabled>Disabled</b-nav-item>
                            </b-nav>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;b-nav&gt;
    &lt;b-nav-item&gt;Active&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
    &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card>
                             <div class="row">
                                <div class="col-md-6 col-12">
                                    <b-nav vertical class="w-25">
                                        <b-nav-item active>Active</b-nav-item>
                                        <b-nav-item>Link</b-nav-item>
                                        <b-nav-item>Another Link</b-nav-item>
                                        <b-nav-item disabled>Disabled</b-nav-item>
                                    </b-nav>
                                </div>
                                <div class="col-md-6 col-12">
                                    <b-nav vertical class="w-25">
                                        <b-nav-item active>Active</b-nav-item>
                                        <b-nav-item>Link</b-nav-item>
                                        <b-nav-item>Another Link</b-nav-item>
                                        <b-nav-item disabled>Disabled</b-nav-item>
                                    </b-nav>
                                </div>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;div class=&quot;col-md-6 col-12&quot;&gt;
    &lt;b-nav vertical class=&quot;w-25&quot;&gt;
        &lt;b-nav-item active&gt;Active&lt;/b-nav-item&gt;
        &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
        &lt;b-nav-item&gt;Another Link&lt;/b-nav-item&gt;
        &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
    &lt;/b-nav&gt;
&lt;/div&gt;
&lt;div class=&quot;col-md-6 col-12&quot;&gt;
    &lt;b-nav vertical class=&quot;w-25&quot;&gt;
        &lt;b-nav-item active&gt;Active&lt;/b-nav-item&gt;
        &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
        &lt;b-nav-item&gt;Another Link&lt;/b-nav-item&gt;
        &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
    &lt;/b-nav&gt;
&lt;/div&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h3 id="tabs">Tabs</h3>
                <p>Takes the basic nav from above and adds the <code>.nav-tabs</code> class to generate a tabbed interface.</p>
                <b-tabs>
                    <b-card class="mb-3">
                        <b-tab title="Preview" active>
                            Takes the basic nav from above and adds the <code>.nav .nav-tabs .px-3 .border-bottom-0</code> class to generate a tabbed interface.
                        </b-tab>
                        <b-tab title="HTML">
<pre class="language-html" data-lang="html">
<code>&lt;b-tab title=&quot;Preview&quot; active&gt;
    &lt;b-card&gt;
        Takes the basic nav from above and adds the &lt;code&gt;.nav .nav-tabs .px-3 .border-bottom-0&lt;/code&gt; class to generate a tabbed interface.
    &lt;/b-card&gt;
&lt;/b-tab&gt;
&lt;b-tab title=&quot;Link&quot; disabled&gt;&lt;b-link href=&quot;#&quot;&gt;Link&lt;/b-link&gt;&lt;/b-tab&gt;
&lt;b-tab title=&quot;Disabled&quot; disabled&gt;&lt;p&gt;I&apos;m a disabled tab!&lt;/p&gt;&lt;/b-tab&gt;</code>
</pre>                 
                        </b-tab>
                        <b-tab title="Link" disabled><b-link href="#">Link</b-link></b-tab>
                        <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
                    </b-card>

                    <b-card class="mb-3">
                    <b-tabs nav-class="tab-body-header rounded d-inline-flex" content-class="mt-2">
                            <b-tab title="Preview" active>
                                Takes the basic nav from above and adds the <code>.nav .nav-tabs .px-3 .border-bottom-0</code> class to generate a tabbed interface.
                            </b-tab>
                            <b-tab title="HTML">
    <pre class="language-html" data-lang="html">
    <code>&lt;b-tabs nav-class=&quot;tab-body-header rounded d-inline-flex&quot; content-class=&quot;mt-2&quot;&gt;
            &lt;b-tab title=&quot;Preview&quot; active&gt;
                Takes the basic nav from above and adds the &lt;code&gt;.nav .nav-tabs .px-3 .border-bottom-0&lt;/code&gt; class to generate a tabbed interface.
            &lt;/b-tab&gt;
            &lt;b-tab title=&quot;Link&quot; disabled&gt;&lt;b-link href=&quot;#&quot;&gt;Link&lt;/b-link&gt;&lt;/b-tab&gt;
            &lt;b-tab title=&quot;Disabled&quot; disabled&gt;&lt;p&gt;I&apos;m a disabled tab!&lt;/p&gt;&lt;/b-tab&gt;
    &lt;/b-tabs&gt;</code>
    </pre>                 
                            </b-tab>
                            <b-tab title="Link" disabled><b-link href="#">Link</b-link></b-tab>
                            <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
                    </b-tabs>
                    </b-card>

                    <b-card class="mb-3">
                        <b-tabs nav-class="tab-card" content-class="mt-2">
                            <b-tab title="Preview" active>
                                Takes the basic nav from above and adds the <code>.nav nav-tabs .tab-card</code> class to generate a tabbed interface.
                            </b-tab>
                            <b-tab title="HTML">
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-tabs nav-class=&quot;tab-card&quot; content-class=&quot;mt-2&quot;&gt;
        &lt;b-tab title=&quot;Preview&quot; active&gt;
            Takes the basic nav from above and adds the &lt;code&gt;.nav nav-tabs .tab-card&lt;/code&gt; class to generate a tabbed interface.
        &lt;/b-tab&gt;
        &lt;b-tab title=&quot;Link&quot; disabled&gt;&lt;b-link href=&quot;#&quot;&gt;Link&lt;/b-link&gt;&lt;/b-tab&gt;
        &lt;b-tab title=&quot;Disabled&quot; disabled&gt;&lt;p&gt;I&apos;m a disabled tab!&lt;/p&gt;&lt;/b-tab&gt;
    &lt;/b-tabs&gt;
&lt;/b-card&gt;</code>
</pre>                 
                            </b-tab>
                            <b-tab title="Link" disabled><b-link href="#">Link</b-link></b-tab>
                            <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
                        </b-tabs>
                    </b-card>

                    <b-card class="mb-3">
                        <b-tabs nav-class="tab-card" content-class="mt-2">
                            <b-tab title="Preview" active>
                                Takes the basic nav from above and adds the <code>.nav nav-tabs .tab-card</code> class to generate a tabbed interface.
                            </b-tab>
                            <b-tab title="HTML">
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-tabs nav-class=&quot;tab-card&quot; content-class=&quot;mt-2&quot;&gt;
        &lt;b-tab title=&quot;Preview&quot; active&gt;
            Takes the basic nav from above and adds the &lt;code&gt;.nav nav-tabs .tab-card&lt;/code&gt; class to generate a tabbed interface.
        &lt;/b-tab&gt;
        &lt;b-tab&gt;
            &lt;template #title&gt;
                &lt;b-dropdown text=&quot;Dropdown&quot; variant=&quot;transparent&quot;&gt;
                    &lt;b-dropdown-item&gt;Action&lt;/b-dropdown-item&gt;
                    &lt;b-dropdown-item&gt;Another action&lt;/b-dropdown-item&gt;
                    &lt;b-dropdown-item&gt;Something else here&lt;/b-dropdown-item&gt;
                    &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
                    &lt;b-dropdown-item&gt;Separated link&lt;/b-dropdown-item&gt;
                &lt;/b-dropdown&gt;
            &lt;/template&gt;
        &lt;/b-tab&gt;
        &lt;b-tab title=&quot;Link&quot; disabled&gt;&lt;b-link href=&quot;#&quot;&gt;Link&lt;/b-link&gt;&lt;/b-tab&gt;
        &lt;b-tab title=&quot;Disabled&quot; disabled&gt;&lt;p&gt;I&apos;m a disabled tab!&lt;/p&gt;&lt;/b-tab&gt;
    &lt;/b-tabs&gt;
&lt;/b-card&gt;</code>
    </pre>                 
                            </b-tab>
                            <b-tab>
                                 <template #title>
                                    <b-dropdown text="Dropdown" variant="transparent">
                                        <b-dropdown-item>Action</b-dropdown-item>
                                        <b-dropdown-item>Another action</b-dropdown-item>
                                        <b-dropdown-item>Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item>Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-tab>
                            <b-tab title="Link" disabled><b-link href="#">Link</b-link></b-tab>
                            <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
                        </b-tabs>
                    </b-card>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h3 id="Pills">Pills</h3>
                <p>Take that same HTML, but use <code>.nav-pills</code> instead:</p>
                <b-card class="mb-3">
                    <b-tabs pills nav-class="mb-3">
                        <b-tab title="Preview" active>
                            Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud.
                        </b-tab>
                        <b-tab title="HTML">
<pre class="language-html" data-lang="html">
<code> &lt;b-tabs pills nav-class=&quot;mb-3&quot;&gt;
    &lt;b-tab title=&quot;Preview&quot; active&gt;
        Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud.
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Link&quot; disabled&gt;&lt;b-link href=&quot;#&quot;&gt;Link&lt;/b-link&gt;&lt;/b-tab&gt;
    &lt;b-tab title=&quot;Disabled&quot; disabled&gt;&lt;p&gt;I&apos;m a disabled tab!&lt;/p&gt;&lt;/b-tab&gt;
&lt;/b-tabs&gt;</code>
</pre>                 
                        </b-tab>
                        <b-tab title="Link" disabled><b-link href="#">Link</b-link></b-tab>
                        <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
                    </b-tabs>
                </b-card>
                <b-card class="mb-3">
                    <b-tabs pills nav-class="mb-3" vertical class="d-flex align-items-start">
                        <b-tab title="Preview" active>
                            Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud.
                        </b-tab>
                        <b-tab title="HTML">
<pre class="language-html" data-lang="html">
<code><i rel="pre">&lt;b-tabs pills nav-class=&quot;mb-3&quot; vertical class=&quot;d-flex align-items-start&quot;&gt;
    &lt;b-tab title=&quot;Preview&quot; active&gt;
        Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud.
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;HTML&quot;&gt;
        ..........
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Messages&quot;&gt;
        ..........
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Settings&quot;&gt;
        ..........
    &lt;/b-tab&gt;
&lt;/b-tabs&gt;</i></code>
</pre>                 
                        </b-tab>
                        <b-tab title="Messages"><pre class="language-html" data-lang="html">
<code><i rel="pre">&lt;b-tabs pills nav-class=&quot;mb-3&quot; vertical class=&quot;d-flex align-items-start&quot;&gt;
    &lt;b-tab title=&quot;Preview&quot; active&gt;
        Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud.
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;HTML&quot;&gt;
        ..........
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Messages&quot;&gt;
        ..........
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Settings&quot;&gt;
        ..........
    &lt;/b-tab&gt;
&lt;/b-tabs&gt;</i></code>
</pre>  </b-tab>
                        <b-tab title="Settings"><pre class="language-html" data-lang="html">
<code><i rel="pre">&lt;b-tabs pills nav-class=&quot;mb-3&quot; vertical class=&quot;d-flex align-items-start&quot;&gt;
    &lt;b-tab title=&quot;Preview&quot; active&gt;
        Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud.
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;HTML&quot;&gt;
        ..........
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Messages&quot;&gt;
        ..........
    &lt;/b-tab&gt;
    &lt;b-tab title=&quot;Settings&quot;&gt;
        ..........
    &lt;/b-tab&gt;
&lt;/b-tabs&gt;</i></code>
</pre>  </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'NavsComponent',
    data() {
        return {

        }
    },methods:{
        
    },mounted() {
        Prism.highlightAll()
        const element = document.getElementsByClassName('align-items-start')[0];
        element.classList.remove("row","tabs")
    }
}
</script>
<style lang="scss">

</style>