<template>
    <b-modal id="notificationsModal" scrollable dialog-class="modal-dialog-vertical" body-class="p-0" header-class="px-md-5" modal-class="modal-dialog-vertical" :hide-footer="true">
            <template #modal-header="{ close }">
                 <h5 class="modal-title">Notifications <span class="badge bg-danger ms-2" >14</span></h5>
                <button type="button" class="btn btn-close" data-dismiss="modal" aria-label="Close"  @click="close()"></button>
            </template>
            <div class="bg-light border-bottom px-2 px-md-5 py-3">
                <ul class="nav nav-pills nav-fill" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);"  v-on:click="setActiveNavtab('Message')" :class="{ 'active': currentTab == 'Message'}">Message</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);" v-on:click="setActiveNavtab('Events')" :class="{ 'active': currentTab == 'Events'}">Events</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0);" v-on:click="setActiveNavtab('Logs')" :class="{ 'active': currentTab == 'Logs'}">Logs</a>
                    </li>
                </ul>
            </div>
            <div class="modal-body px-md-5">
                <div class="tab-content p-0">
                    <div class="tab-pane fade" id="Noti-tab-Message" role="tabpanel" :class="{ 'active show': currentTab == 'Message'}">
                        <ul class="list-unstyled list mb-0">
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" src="../../../assets/images/xs/avatar1.jpg" alt="">
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Chris Fox</span> <small>2MIN</small></p>
                                        <span class="text-muted">changed an issue from "In Progress" to <span class="badge bg-success">Review</span></span>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded-circle no-thumbnail">RH</div>
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Robert Hammer</span> <small>13MIN</small></p>
                                        <span class="text-muted">It is a long established fact that a reader will be distracted</span>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" src="../../../assets/images/xs/avatar3.jpg" alt="">
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Orlando Lentz</span> <small>1HR</small></p>
                                        <span class="text-muted">There are many variations of passages</span>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" src="../../../assets/images/xs/avatar4.jpg" alt="">
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Barbara Kelly</span> <small>1DAY</small></p>
                                        <span class="text-muted">Contrary to popular belief <span class="badge bg-danger">Code</span></span>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" src="../../../assets/images/xs/avatar5.jpg" alt="">
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Robert Hammer</span> <small>13MIN</small></p>
                                        <span class="text-muted">making it over 2000 years old</span>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" src="../../../assets/images/xs/avatar6.jpg" alt="">
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Orlando Lentz</span> <small>1HR</small></p>
                                        <span class="text-muted">There are many variations of passages</span>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" src="../../../assets/images/xs/avatar7.jpg" alt="">
                                    <div class="flex-fill ms-3">
                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">Rose Rivera</span> <small class="">1DAY</small></p>
                                        <span class="text-muted">The generated Lorem Ipsum</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="Noti-tab-Events" role="tabpanel" :class="{ 'active show': currentTab == 'Events'}">
                        <ul class="list-unstyled list mb-0">
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded no-thumbnail"><i class="fa fa-info-circle fa-lg"></i></div>
                                    <div class="flex-fill ms-3">
                                        <p class="mb-0 text-muted">Campaign <strong class="text-primary">Holiday Sale</strong> is nearly reach budget limit.</p>
                                        <small class="text-muted">10:00 AM Today</small>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded no-thumbnail"><i class="fa fa-thumbs-up fa-lg"></i></div>
                                    <div class="flex-fill ms-3">
                                        <p class="mb-0 text-muted">Your New Campaign <strong class="text-primary">Holiday Sale</strong> is approved.</p>
                                        <small class="text-muted">11:30 AM Today</small>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded no-thumbnail"><i class="fa fa-pie-chart fa-lg"></i></div>
                                    <div class="flex-fill ms-3">
                                        <p class="mb-0 text-muted">Website visits from Twitter is <strong class="text-danger">27% higher</strong> than last week.</p>
                                        <small class="text-muted">04:00 PM Today</small>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4 border-bottom">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded no-thumbnail"><i class="fa fa-warning fa-lg"></i></div>
                                    <div class="flex-fill ms-3">
                                        <p class="mb-0 text-muted"><strong class="text-warning">Error</strong> on website analytics configurations</p>
                                        <small class="text-muted">Yesterday</small>
                                    </div>
                                </a>
                            </li>
                            <li class="py-4">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded no-thumbnail"><i class="fa fa-thumbs-up fa-lg"></i></div>
                                    <div class="flex-fill ms-3">
                                        <p class="mb-0 text-muted">Your New Campaign <strong class="text-primary">Holiday Sale</strong> is approved.</p>
                                        <small class="text-muted">11:30 AM Today</small>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="Noti-tab-Logs" role="tabpanel" :class="{ 'active show': currentTab == 'Logs'}">
                        <h4>No Logs right now!</h4>
                    </div>
                </div>
            </div>
    </b-modal>
</template>
<script>
export default {
    name:"NotificationsMComponent",
    data() {
        return {
            currentTab: 'Message',
        }
    },methods:{
        setActiveNavtab: function (tab) {
            this.currentTab = tab 
        },
    }
}
</script>
<style scoped>
::v-deep #notificationsModal > .modal-dialog {
        max-height: 100%;
} 
</style>