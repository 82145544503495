<template>
    <div class="col-12">
        <div v-for="(content, index) in timeline_content" :key="index" class="timeline-item border-bottom ms-2" :class="content.ti_class">
            <timeline-component
                :img = "content.img"
                :text = "content.text"
                :strong_text = "content.strong_text"
                :time_detals = "content.time_detals"
                :strong_bold = "content.strong_bold"
                :classes = "content.class"
                :text_areacontent = "content.text_areacontent"
                :teams = "content.team"
            ></timeline-component>
        </div>
    </div>
</template>
<script>
import Timeline from '@/components/core/Timeline.vue'
export default {
    name:'TimelineComponent',
    components: {
        'timeline-component':Timeline
    },data(){
        return {
            timeline_content:[
                {
                    img:require(`@/assets/images/xs/avatar1.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'You',
                    text_areacontent:'I’ve prepared all sizes for you. Can you take a look tonight so we can prepare my final invoice?',
                    ti_class:'ti-danger',
                    class:'',
                },
                {
                    img:require(`@/assets/images/xs/avatar2.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'Orlando',
                    text_areacontent:'',
                    ti_class:'ti-info',
                    class:'',
                },
                {
                    img:require(`@/assets/images/xs/avatar1.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'You',
                    text_areacontent:'',
                    ti_class:'ti-success',
                    class:'',
                },
                {
                    img:require(`@/assets/images/xs/avatar1.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'You',
                    text_areacontent:'What do you think about these? Should I continue in this style?',
                    ti_class:'ti-primary',
                    class:'',
                    team:[
                        require(`@/assets/images/xs/avatar3.jpg`),
                        require(`@/assets/images/xs/avatar1.jpg`),
                        require(`@/assets/images/xs/avatar7.jpg`),
                        require(`@/assets/images/xs/avatar9.jpg`),
                    ]
                },
                {
                    img:require(`@/assets/images/xs/avatar4.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'Chris',
                    text_areacontent:'I’ve prepared all sizes for you. Can you take a look tonight so we can prepare my final invoice?',
                    ti_class:'ti-warning',
                    class:'alert-success',
                },
            ]
        }
    }
}
</script>
<style scoped>

</style>