<template>
  <div class="modal-body bg-gray">
    <!-- <button type="button" class="btn btn-close"></button> -->
    <!-- <form @submit.prevent="saveData()"> -->
    <b-modal id="modalClientes" tabindex="-1" class="modal-lg" centered title="Seleccionar cliente" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form>
          <div class="row">
            <div class="col-md-12 mb-2">
              <b-table
                hover
                responsive
                :items="listaClientes"
                :fields="fieldsCustomer"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
              >
                <template #cell(action)="data">
                  <span
                    ><button @click.prevent="selCustomer(data.item)" class="btn btn-primary ml-1">
                      <i class="fa fa-check"></i>
                    </button>
                  </span>
                </template>
              </b-table>
              <!-- <div>
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
                            </div> -->
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('modalClientes')">Cerrar</b-button>
            <!-- <input class="btn btn-primary mr-2" type="submit" value="Agregar"> -->
          </div>
        </form>
      </div>
    </b-modal>

    <div class="full">
      <form>
        <div class="row">
          <!-- categorias -->
          <div class="col-sm-12 p-0 col-md-8 border border-2">
            <div class="bg-primary py-0">
              <h5 class="mt-1 text-light" style="padding: 3px">Categorías</h5>
            </div>
            <!-- <h4> panel cat y prod</h4> -->
            <div class="row">
              <div class="inner only-this-horizon-scrollbar" style="">
                <div class="scroll-container diff-size">
                  <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                  <div @click="getProductByCategory(item.id)" class="scroll-section" v-for="item in card_home" :key="item.id">
                    <categories-vue :product="item" class="zoom" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- panel derecho -->
          <div class="">
            <div class="col-sm-12 col-md-4 pr-0 span6 cel_big pull-right text-center">
              <div class="bg-primary py-0">
                <h5 class="mt-1 text-light" style="padding: 3px">Resumen</h5>
              </div>
              <div class="col-12 mt-2">
                <div class="text-left mb-1">
                  <span>Fecha:</span>
                </div>
                <input class="form-control" type="date" v-model="fecha" name="" id="" />
              </div>
              <div class="border">
                <b-list-group>
                  <!-- <b-list-group-item v-for="it of cart" :key="it.id" button>{{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}}</b-list-group-item> -->
                  <div v-for="it of cart" :key="it.id" button>
                    <!-- {{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}} -->
                    <div class="px-3 hover">
                      <div class="border-top border-bottom row py-3 hover">
                        <div @click="deleteProduct(it)" class="col-1 text-right pointer">
                          <i class="fa fa-trash text-red" />
                        </div>
                        <!-- <div class="col-7 text-left">{{it.nombre + ' / $' + it.precio_venta}} | </div> -->
                        <div class="col-7 text-left">{{ it.nombre }}</div>
                        <!-- <div class="col-1">{{it.cantidad}} | </div> -->
                        <div class="col-2 text-left">${{ (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </b-list-group>
                <div class="row mt-3 px-4">
                  <div class="col-md-12 mb-2 mt-2 text-left">
                    <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="observaciones" class="h6">Iva</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 text-left mb-1">
                    <span>sub-total:</span>
                  </div>
                  <div class="col-6 text-left mb-1">${{ subtotal.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1">iva:</div>
                  <div class="col-6 text-left mb-1">${{ iva.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>TOTAL:</b>
                  </div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>${{ total.toFixed(2) }}</b>
                  </div>
                  <hr class="hr" />
                  <div class="col-12 text-left">
                    <label for="categoria" class="">Forma de pago</label>
                    <b-form-select required class="form-control select text-black" v-model="formaPago" :options="formasDePago" value-field="nombre" text-field="nombre"> </b-form-select>
                  </div>
                </div>
                <div class="row mt-2 px-4">
                  <div class="col-10">
                    <div class="text-left mb-1">
                      <span>Cliente:</span>
                    </div>
                    <input class="form-control" type="text" v-model="nombreCliente" name="" id="" />
                  </div>
                  <div class="col-2 mt-4 pl-0">
                    <b-button class="btn btn-secondary w-10" @click="gotoClientes()">+</b-button>
                  </div>
                  <div class="col-12 mt-2">
                    <div class="text-left mb-1">
                      <span>Descripción / Referencia:</span>
                    </div>
                    <input class="form-control" type="text" v-model="descripcion" name="" id="" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-left mt-2">
                    <label for="categoria" class="">Cuenta origen</label>
                    <b-form-select required class="form-control select text-black" v-model="cuentaOrigen" :options="accountsList" value-field="cuenta" text-field="titulo"> </b-form-select>
                  </div>
                  <div class="col-12 text-left mt-2">
                    <label for="categoria" class="">Cuenta destino</label>
                    <b-form-select required class="form-control select text-black" v-model="cuentaDestino" :options="accountsList" value-field="cuenta" text-field="titulo"> </b-form-select>
                  </div>
                </div>
                <div class="row my-3 px-3">
                  <div class="col-6">
                    <b-button @click="cleanSale()" class="btn btn-secondary w-100">Cancelar</b-button>
                  </div>
                  <div class="col-6">
                    <b-button @click="saveData()" class="btn btn-primary w-100">Guardar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- tabla productos -->
          <div class="row m-0 p-0">
            <div class="col-sm-12 p-0 col-md-8">
              <div class="bg-primary py-0">
                <h5 class="mt-1 text-light text-center" style="padding: 3px">
                  Productos
                </h5>
              </div>
            </div>
          </div>

          <div class="row mt-0 mr-0">
            <div class="col-md-8 pr-0">
              <div class="row text-center">
                <!-- <div class="bg-primary py-0">
                  <h5 class="mt-1 text-light" style="padding: 3px">
                    Productos
                  </h5>
                </div> -->
                <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                <div>
                  <input class="form-control" @keyup="buscar()" type="text" v-model="textoABuscar" name="" id="" placeholder="Texto a buscar..." />
                </div>
                <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-3">
                  <h5 class="text-alert pt-2"><i class="fa fa-exclamation-triangle"></i> No hay productos en esta categoría</h5>
                </div>
                <div v-if="category_id == 0" class="bg-alert-primary mt-2">
                  <h5 class="text-alert pt-1"><i class="fa fa-exclamation-triangle"></i> Selecciona una categoría</h5>
                </div>

                <div @click="showModal(item)" class="col-lg-3 py-2 col-md-4 py-0 px-2 mt-0" v-for="item in productList" :key="item.id">
                  <product-vue :product="item" class="zoom" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal id="exampleModalCenter" tabindex="-1" class="modal-lg" centered title="Agregar producto" hide-footer>
          <template v-slot:modal-header-close>
            <button type="button" class="btn btn-close"></button>
          </template>
          <div class="modal-body bg-gray">
            <form @submit.prevent="addProductToCart()">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <label for="nombre" class="">Producto:</label>
                  <input readonly v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Cantidad:</label>
                  <input type="number" min="1" step="0.01" v-model="row.cantidad" class="form-control" name="cantidad" id="cantidad" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Precio venta:</label>
                  <input required v-model="row.precio_venta" class="form-control" type="text" name="precio_venta" id="precio_venta" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Total:</label>
                  <input readonly required :value="'$' + row.precio_venta * (row.cantidad ? row.cantidad : 1)" class="form-control" type="text" name="precio_venta" id="precio_venta" />
                </div>
              </div>
              <div class="text-right">
                <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('exampleModalCenter')">Cancelar</b-button>
                <input class="btn btn-primary mr-2" type="submit" value="Agregar" />
              </div>
            </form>
          </div>
        </b-modal>
      </form>
    </div>
    <div class="movil">
      <form>
        <div class="row">
          <!-- categorias -->
          <div class="p-0 border border-2">
            <div class="bg-primary py-1">
              <h4 class="mt-1 text-light text-center">Categorías</h4>
            </div>
            <!-- <h4> panel cat y prod</h4> -->
            <div class="col-12 p-0 border-2">
              <div class="inner only-this-horizon-scrollbar" style="">
                <div class="scroll-container diff-size">
                  <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                  <div @click="getProductByCategory(item.id)" class="scroll-section" v-for="item in card_home" :key="item.id">
                    <categories-vue :product="item" class="zoom" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- panel derecho -->

          <!-- tabla productos -->
          <div class="row mt-5 m-0">
            <div class="col-md-8 p-0">
              <div class="row text-center">
                <div class="bg-primary py-1">
                  <h4 class="mt-1 text-light">Productos</h4>
                </div>
                <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-5">
                  <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle"></i> No hay productos en esta categoría</h6>
                </div>
                <div v-if="category_id == 0" class="bg-alert-primary mt-5">
                  <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle"></i> Selecciona una categoría</h6>
                </div>

                <div class="mt-3 p-1">
                  <div @click="showModal(item)" class="col-lg-3 py-2 col-md-4 py-0 px-2 mt-3" v-for="item in productList" :key="item.id">
                    <product-vue :product="item" class="zoom" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4 mt-4 px-0 span6 cel_big pull-right text-center">
            <div class="ml-1">
              <div class="bg-primary py-1">
                <h4 class="mt-1 text-light">Resumen</h4>
              </div>
              <div class="border">
                <b-list-group>
                  <!-- <b-list-group-item v-for="it of cart" :key="it.id" button>{{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}}</b-list-group-item> -->
                  <div v-for="it of cart" :key="it.id" button>
                    <!-- {{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}} -->
                    <div class="px-3 hover">
                      <div class="border-top border-bottom row py-3 hover">
                        <div @click="deleteProduct(it)" class="col-1 text-right">
                          <i class="fa fa-trash text-red" />
                        </div>
                        <div class="col-6 text-left">
                          {{ it.nombre + "/$" + it.precio_venta }}
                        </div>
                        <div class="col-1">{{ it.cantidad }}</div>
                        <div class="col-2 text-left">${{ (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </b-list-group>
                <div class="row mt-3 px-3">
                  <div class="col-md-12 mb-2 mt-2 text-left">
                    <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="observaciones" class="h6">Iva</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 text-left mb-1">
                    <span>sub-total:</span>
                  </div>
                  <div class="col-6 text-left mb-1">${{ subtotal.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1">iva:</div>
                  <div class="col-6 text-left mb-1">${{ iva.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>TOTAL:</b>
                  </div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>${{ total.toFixed(2) }}</b>
                  </div>
                  <hr class="hr" />
                  <div class="col-12 text-left">
                    <label for="categoria" class="">Forma de pago</label>
                    <b-form-select required class="form-control select text-black" v-model="formaPago" :options="formasDePago" value-field="nombre" text-field="nombre"> </b-form-select>
                  </div>
                </div>
                <div class="row mt-3 mb-3 px-4">
                  <div class="col-10">
                    <div class="text-left mb-1">
                      <span>Cliente:</span>
                    </div>
                    <input class="form-control" type="text" v-model="nombreCliente" name="" id="" />
                  </div>
                  <div class="col-2 mt-4 pl-0">
                    <b-button class="btn btn-secondary w-10" @click="gotoClientes()">+</b-button>
                  </div>
                </div>
                <div class="row mt-3 mb-5 px-3">
                  <div class="col-6">
                    <b-button @click="cleanSale()" class="btn btn-secondary w-100">Cancelar</b-button>
                  </div>
                  <div class="col-6">
                    <b-button @click="saveData()" class="btn btn-primary w-100">Guardar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <b-modal id="exampleModalCenter" tabindex="-1" class="modal-lg" centered  title="Agregar producto" hide-footer>
                    <template v-slot:modal-header-close>
                        <button type="button" class="btn btn-close"></button>
                    </template>
                    <div class="modal-body bg-gray">
                        <form @submit.prevent="addProductToCart()">
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <label for="nombre" class="">Producto:</label>
                                    <input readonly v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Cantidad:</label>
                                    <input type="number" step="0.1" min="1"  v-model="row.cantidad" class="form-control"  name="cantidad" id="cantidad">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Precio venta:</label>
                                    <input readonly required :value="'$' + row.precio_venta" class="form-control" type="text" name="precio_venta" id="precio_venta">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Total:</label>
                                    <input readonly required :value="'$' + (row.precio_venta * (row.cantidad ? row.cantidad : 1))" class="form-control" type="text" name="precio_venta" id="precio_venta">
                                </div>
                            </div>
                        <div class="text-right">
                            <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('exampleModalCenter')">Cancelar</b-button>
                            <input class="btn btn-primary mr-2" type="submit" value="Agregar">
                        </div>
                        </form>
                    </div>
                </b-modal> -->
      </form>
    </div>
  </div>
</template>

<script>
import ProductVue from "./Product.vue";
import CategoriesVue from "./Category.vue";

export default {
  components: { ProductVue, CategoriesVue },
  data() {
    return {
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        {
          key: "cliente",
          label: "Cliente",
          sortable: true,
          class: "text-center",
        },
        { key: "fecha", label: "Fecha ", sortable: true, class: "text-center" },
        {
          key: "sucursal",
          label: "Sucursal",
          sortable: true,
          class: "text-center",
        },
        {
          key: "total_venta",
          label: "Total Venta ",
          sortable: true,
          class: "text-center",
        },
        {
          key: "total_costos",
          label: "Total costos ",
          sortable: true,
          class: "text-center",
        },
        {
          key: "Margen",
          label: "Margen",
          sortable: true,
          class: "text-center",
        },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
          class: "text-center",
        },
        { key: "action", label: "", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      fields2: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        {
          key: "articulo",
          label: "Articulo",
          sortable: true,
          class: "text-center",
        },
        {
          key: "cantidad",
          label: "Cantidad ",
          sortable: true,
          class: "text-center",
        },
        {
          key: "precio_unit",
          label: "Precio unit",
          sortable: true,
          class: "text-center",
        },
        {
          key: "sub_total",
          label: "Sub total ",
          sortable: true,
          class: "text-center",
        },

        { key: "action", label: "acción", tdClass: "dt-body-right" },
        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      items: [],
      row: {},
      card_home: [],
      table: "meca_categorias",
      company_id: 0,
      userId: 0,
      rol: 0,
      productList: [],
      productListBk: [],
      cart: [],
      formasDePago: [{ nombre: "Efectivo" }, { nombre: "Tarjeta de crédito" }, { nombre: "Crédito" }],
      formaPago: "Efectivo",
      subtotal: 0,
      total: 0,
      iva: 0,
      cliente_id: 0,
      nombreCliente: "Cliente Genérico",
      con_iva: 1,
      listaClientes: [],
      fieldsCustomer: [
        {
          key: "nombre",
          label: "Cliente",
          sortable: true,
          class: "text-center",
        },
        { key: "action", label: "", tdClass: "dt-body-right" },
      ],
      descripcion: "--",
      currentPage: 0,
      perPage: 0,
      filter: "",
      filterOn: "",
      sortBy: "",
      sortDesc: "",
      sortDirection: "",
      onFiltered: "",
      textoABuscar: "",
      category_id: 0,
      accountsList: [],
      cuentaOrigen: "400001",
      cuentaDestino: "100001",
      fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),

    };
  },
  async mounted() {
    try {
      this.$isLoading(true);

      this.userId = localStorage.getItem("user_id");
      this.company_id = localStorage.getItem("company_id");
      this.rol = localStorage.getItem("rol");
      await this.getCategories();
      await this.getClientes();
      await this.getAccounts();
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
    }
  },
  methods: {
    async getAccounts() {
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_cuentas/getAllByCompany/" + this.company_id,
        });
        console.log(res);
        if (res.length > 0) {
          this.accountsList = res;
        } else {
          this.accountsList = [];
        }
      } catch (error) {
        this.accountsList = [];
        console.error("error", error);
      }
    },
    buscar() {
      //textoABuscar
      if (this.textoABuscar.length > 0) {
        let resultadoFiltro = this.productListBk.filter((x) =>
          x.nombre
            .toUpperCase()
            .trim()
            .includes(this.textoABuscar.toUpperCase().trim())
        );
        if (resultadoFiltro.length > 0) {
          this.productList = resultadoFiltro;
        } else {
          this.productList = [];
        }
      } else {
        this.productList = this.productListBk;
      }
    },
    selCustomer(item) {
      this.cliente_id = item.id;
      this.nombreCliente = item.nombre;
      this.$bvModal.hide("modalClientes");
    },
    async getClientes() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_clientes/getAllByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaClientes = res;
        }
      } catch (error) {
        this.listaClientes = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    cleanSale() {
      this.cart = [];
      this.productList = [];
      this.productListBk = this.productList;
      this.calculateTotal();
    },
    gotoClientes() {
      this.$bvModal.show("modalClientes");
    },
    deleteProduct(item) {
      let tt = this.cart.indexOf(item);
      console.log(tt);
      this.cart.splice(tt, 1);
      this.calculateTotal();
      //alert()
    },
    calculateTotal() {
      this.subtotal = this.cart.map((item) => item.cantidad * item.precio_venta).reduce((p, c) => p + c, 0);
      if (this.con_iva == 1) {
        this.iva = this.subtotal * 0.15;
      } else {
        this.iva = 0;
      }
      this.total = this.subtotal + this.iva;
    },
    addProductToCart() {
      console.log("add");
      this.cart.push(this.row);
      this.row = {};
      this.calculateTotal();
      this.$bvModal.hide("exampleModalCenter");
    },
    showModal(item) {
      this.row = item;
      this.row.cantidad = 1;
      this.$bvModal.show("exampleModalCenter");
    },
    async getProductByCategory(item) {
      this.category_id = item;
      console.log("getProductByCategory");
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAllVisibleByCategoryCompany/" + this.company_id + "/" + item,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          console.log(this.productList);
          this.productList = res;
          this.productListBk = this.productList;
        } else {
          this.productList = [];
          this.productListBk = this.productList;
        }
      } catch (error) {
        this.productList = [];
        this.productListBk = this.productList;
        console.error("error", error);
      }
    },
    async getCategories() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: this.table + "/getAllVisibleByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          this.card_home = res;
        }
      } catch (error) {
        this.card_home = [];
        console.error("error", error);
      }
    },
    async saveData() {
      console.log('',this.total_venta)

      try {
        if(this.cart.length==0){
          this.$swal({
            title: "Aviso",
            text: "debes agregar productos",
            icon: "error",
          });
          return
        }
        if(this.total==0){
          this.$swal({
              title: "Aviso",
              text: "No puedes ingresar venta en 0",
              icon: "error",
            });
            return
        }
        console.log("save");
        //let data = Object.assign({} , this.row)
        //data.empresa_id = this.company_id
        let res;
        this.$isLoading(true);
        let data = {
          invoice: {
            cliente_id: this.cliente_id,
            nombre: this.nombreCliente,
            iva: this.iva,
            subtotal: this.subtotal,
            total_venta: this.total,
            empresa_id: this.company_id,
            usuario_id: this.userId,
            estado: "Pagada",
            descripcion: this.descripcion,
            por_iva: 15,
            fecha_i: this.fecha,
            fecha_f: this.fecha,
            total_costos: this.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            margen: this.total - this.iva - this.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            metodo_pago: this.formaPago
          },
          detail: this.cart,
          accounts: {
            cuentaOrigen: this.cuentaOrigen,
            cuentaDestino: this.cuentaDestino,
          },
        };
        res = await this.$store.dispatch("post", {
          path: "meca_ventas/new",
          data: data,
        });
        this.$isLoading(false);

        if (res.error) {
          this.$isLoading(false);
          this.$swal({
            title: "Aviso",
            text: "Error: " + res.error,
            icon: "error",
          });
        } else {
          this.cleanSale();
          this.$swal({
            title: "Aviso",
            text: "Datos almacenados correctamente",
            icon: "success",
          });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", {
            path: this.table + "/delete/" + item.id,
          });
          if (res.error) {
            this.$swal({
              title: "Aviso",
              text: "Error: " + res.error,
              icon: "error",
            });
          } else {
            this.getData();
            this.$swal({
              title: "Aviso",
              text: "Eliminado correctamente",
              icon: "success",
            });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },
  },
};
</script>

<style>
.border-table {
  border: 1px solid rgb(216, 216, 216) !important;
  border-radius: 5px;
  /* padding:5px; */
  text-align: center !important;
}

/* carusel */
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 98%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}

.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

.cel_big {
  right: 0px;
  /* border:1px solid red; */
  /* //height: 100% !important; */
  /* background-color: rgb(231, 231, 231); */
  position: absolute;
  height: 800px;
  bottom: 20px;
  top: 15px;
  min-height: 600px;
}
.hr {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.text-red {
  color: red;
}
@media screen and (max-width: 720px) {
  .cel_big {
    position: static;
    min-height: 0px !important;
    height: 0px !important;
  }
}
</style>
