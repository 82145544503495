<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12">
            <div class="row clearfix row-deck">
                <div  v-for="basicLine in analiticalLineChartDetail" :key="basicLine.title" class="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                    <analitical-area-chart  
                        :title = "basicLine.title"
                        :price = "basicLine.price"
                        :details = "basicLine.details"
                        :width = "basicLine.width"
                        :options= basicLine.options
                        :series= basicLine.series
                    ></analitical-area-chart>
                </div>
            </div> 
        </div>

<!-- 
            <div class="card mb-4 border-0 lift">
                <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                    <div>
                        <h6 class="m-0">{{AudienceOverview.title}}</h6>
                        <small class="text-muted">Or you can <a href="#">sync data to Dashboard</a> to ensure your data is always up-to-date.</small>
                    </div>
                    <card-actions></card-actions>
                </div>
                <div class="card-body">
                    <div class="card-header border">
                        <div class="d-flex flex-row align-items-center">
                            <div v-for="(rate,index) in AudienceOverview.rate" :key="index" :class="rate.class">
                                <h6 class="mb-0 fw-bold">{{rate.no}}</h6>
                                <small class="text-muted font-11">{{rate.rating}}</small>
                            </div>
                            <div class="d-none d-sm-block ms-auto">
                                <div class="btn-group" role="group">
                                    <input type="radio" class="btn-check" name="btnradio"  v-for="(tabs,index) in AudienceOverview.tab"  :key="'bar_id'+index" v-bind:id="'btnradio' + index"  :checked="index == 2">
                                    <label class="btn btn-outline-secondary"  v-for="(tabs,index) in AudienceOverview.tab"  :key="'bars_id'+index" v-bind:for="'btnradio' + index">{{ tabs.title }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <line-column-chart
                        :options= AudienceOverview.options
                        :series= AudienceOverview.series
                        :height="300">
                    </line-column-chart>
                </div>
            </div> 

            <div class="card mb-4 border-0 lift">
                <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                    <div>
                        <h6 class="m-0">{{bubbleChart.title}}</h6>
                        <small class="text-muted">Or you can <a href="#">sync data to Dashboard</a> to ensure your data is always up-to-date.</small>
                    </div>
                    <card-actions></card-actions>
                </div>
                <div class="card-body">
                    <bubble-chart
                        :options= bubbleChart.options
                        :series= bubbleChart.series
                        :height="360">
                    </bubble-chart>
                </div>
            </div> 

            <div class="card mb-4 border-0 lift">
                <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                    <div>
                        <h6 class="m-0">{{table_heading}}</h6>
                    </div>
                    <card-actions></card-actions>
                </div>
                <div class="card-body">
                        <b-table 
                            table-class="table-borderless mb-0"
                            responsive 
                            :items="items" 
                            :fields="fields"
                        >
                        <template #cell(id)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(rate)="data">
                            {{ data.value+''+'%' }}
                        </template>
                        <template #cell(source)="data">
                            <a :href="`${data.value}`" target="_blank">
                            {{data.value}}</a>
                        </template>
                        <template #row-details="row">
                            <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                            </b-card>
                        </template>
                    </b-table>
                </div>
            </div> 
        </div>

        <div class="col-lg-4 col-md-12">
            <div class="row row-deck">
                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="card mb-4 overflow-hidden border-0 lift">
                        <div class="card-body">
                            <span class="text-uppercase">{{GoalComplitionChart.title}}</span>
                            <h4 class="mb-0 mt-2">{{GoalComplitionChart.price}}</h4>
                            <small class="text-muted">{{GoalComplitionChart.detail}}</small>
                        </div>
                        <apexchart height="50"  width="102%" :options="GoalComplitionChart.options" :series="GoalComplitionChart.series"></apexchart>
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="card mb-4 border-0 lift">
                        <div class="card-header py-3 bg-transparent border-0">
                            <h6 class="card-title mb-0">{{activeUserLineChart.title}}</h6>
                            <small class="text-muted">{{activeUserLineChart.content}}</small>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="p-2 flex-fill"  v-for="(tab,index) in activeUserLineChart.tab" :key="index">
                                    <span class="text-muted">{{tab.tab_title}}</span>
                                    <h5>{{tab.price}}</h5>
                                    <small :class="`${tab.class}`"><i :class="`${tab.icon}`"></i>{{tab.per}}%</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <line-chart
                            :options= activeUserLineChart.options
                            :series= activeUserLineChart.series
                            :height="50">
                            </line-chart>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="card mb-4 border-0 lift">
                        <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                            <div>
                                <h6 class="card-title mb-0">{{SessionsByDevice.title}}</h6>
                                <small class="text-muted">{{SessionsByDevice.content}}</small>
                            </div>
                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group" >
                                <input type="radio" class="btn-check" name="Sessionsradio" v-for="(tabs,index) in SessionsByDevice.tab" :key="'session_id'+index" v-bind:id="'Sessionsradio' + index" :checked="index == 0">
                                <label class="btn btn-sm btn-outline-secondary" v-for="(tabs,index) in SessionsByDevice.tab" :key="'sessions_id'+index" v-bind:for="'Sessionsradio' + index">{{tabs.title}}</label>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex text-center">
                                <div class="p-2 flex-fill" v-for="(content,index) in SessionsByDevice.contents" :key="index">
                                    <span class="text-muted">{{content.tab_title}}</span>
                                    <h5>{{content.price}}</h5>
                                    <small :class="`${content.class}`"><i :class="`${content.icon}`"></i>{{content.per}}%</small>
                                </div>
                            </div> 
                        </div>
                        <div class="card-body">
                            <donut-chart 
                            :options= SessionsByDevice.options
                            :series= SessionsByDevice.series
                            :height="250">
                            </donut-chart>
                        </div>
                    </div> 
                </div>
                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="card mb-4 border-0 lift">
                        <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                            <div>
                                <h6 class="m-0">{{reportsoverview.title}}</h6>
                            </div>
                            <card-actions></card-actions>
                        </div>
                        <div class="card-body">
                            <span class="h2 d-block mb-3">{{reportsoverview.price}}</span>
                            <div class="progress rounded-pill mb-2" style="height: 4px;">
                                <div v-for="(progress,index) in reportsoverview.progressbar" :key="index" :class="progress.class" class="progress-bar" role="progressbar" v-bind:style="{ width: progress.width+'%'}" :aria-valuenow="`${progress.width}`" aria-valuemin="0" aria-valuemax="100" ></div>
                            </div>
            
                            <div class="d-flex justify-content-between mb-4">
                                <span>{{reportsoverview.start}}%</span>
                                <span>{{reportsoverview.end}}%</span>
                            </div>
                            <b-table 
                                    table-class="table-lg table-nowrap card-table mb-0"
                                    responsive 
                                    :items="reportsitems" 
                                    :fields="reportsfields"
                                >
                                <template #cell(time)="data">
                                   <span class="badge" :class="`${data.value.class}`">+{{ data.value.text }}%</span>
                                </template>
                                <template #row-details="row">
                                    <b-card>
                                    <ul>
                                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                    </ul>
                                    </b-card>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="card mb-4 overflow-hidden border-0 lift">
                        <div class="card-body p-4">
                            <h6>{{wi_day_lightning.title}}</h6>
                            <span class="text-muted">{{wi_day_lightning.time}}</span>
                        </div>
                        <div class="p-4 alert-danger d-flex justify-content-between align-items-center">
                            <div><span class="fs-1">{{wi_day_lightning.degree}}</span></div>
                            <i class="wi wi-day-lightning fs-1"></i>
                        </div>
                    </div> 
                </div>
            </div>
        </div> -->

    </div> 
</template>
<script>
import AnalyticsAreaChart from '@/components/core/AnalyticsAreaChart.vue'
// import CardActions from '@/components/core/CardActions.vue'
// import AnalyticsLineColumnChart from '@/components/core/AnalyticsLineColumnChart.vue';
// import AnalyticsLineChart from '@/components/core/AnalyticsLineChart.vue';
// import AnalyticsDonutChart from '@/components/core/AnalyticsDonutChart.vue';
// import AnalyticsbubbleChart from '@/components/core/AnalyticsbubbleChart.vue';
export default {
    name:"IndexComponent",
    components: {
        'analitical-area-chart': AnalyticsAreaChart,
        // 'card-actions': CardActions,
        // 'line-column-chart': AnalyticsLineColumnChart,
        // 'line-chart': AnalyticsLineChart,
        // 'donut-chart': AnalyticsDonutChart,
        // 'bubble-chart':AnalyticsbubbleChart,
    },methods:{
        async getData(){
            try {
                let res = await this.$store.dispatch('get', {path: 'reports/balanceByCompany/' + this.company_id});
                if (res.length >0){
                    this.items = res
                    //calcular ingresos , gastos, total
                    this.row.ingresos = this.items.map(item => ( item.tipo == 'I' ? item.total :0 )).reduce((p,c) => p + c, 0)
                    this.row.gastos = this.items.map(item => (item.tipo == 'G' ? item.total :0)).reduce((p,c) => p + c, 0)
                    this.row.total = this.row.ingresos - this.row.gastos //this.items.map(item => (item.total)).reduce((p,c) => p + c, 0)
                    this.analiticalLineChartDetail[0].price = '$'+this.row.ingresos.toFixed(2)
                    this.analiticalLineChartDetail[1].price = '$'+this.row.gastos.toFixed(2)
                    this.analiticalLineChartDetail[2].price = '$'+this.row.total.toFixed(2)

                }
                else{
                    this.row.ingresos = 0
                    this.row.gastos = 0
                    this.row.total = 0
                    this.analiticalLineChartDetail[0].price = '$0.00'
                    this.analiticalLineChartDetail[1].price = '$0.00'
                    this.analiticalLineChartDetail[2].price = '$0.00'
                }
            } catch (error) {
                this.items = []
                this.row.ingresos = 0
                this.row.gastos = 0
                this.row.total = 0
                this.analiticalLineChartDetail[0].price = '$0.00'
                this.analiticalLineChartDetail[1].price = '$0.00'
                this.analiticalLineChartDetail[2].price = '$0.00'

                console.error('error', error)
            }
        },            
        randomizeArray: function (arg) {
            var array = arg.slice();
            var currentIndex = array.length,
            temporaryValue, randomIndex;
    
            while (0 !== currentIndex) {  
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
        
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }  
            return array;
        },
        generateData(baseval, count, yrange) {
            var i = 0;
            var series = [];
            while (i < count) {
                var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
                var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
                var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

                series.push([x, y, z]);
                baseval += 86400000;
                i++;
            }
            return series;
        },
    },data (){
        var sparklineData = [10, 15, 9, 18, 28, 24,19]
        return {
            row:{},
            table_heading:'Website Performance',
            analiticalLineChartDetail:
            [
                {
                    title:"INGRESOS",
                    width:'110%',
                    price:"0",
                    details:"",
                    options: {
                        colors: ['var(--chart-color1)'],
                        chart: {
                            type: 'area',
                            sparkline: {
                                enabled: true
                            },
                        },
                        stroke: {
                            width: 1
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                               gradientToColors: ['var(--chart-color1)'],
                                shadeIntensity: 2,
                                opacityFrom: 0.7,
                                opacityTo: 0.2,
                                stops: [0, 100]
                            },
                        },
                    },
                    series: [{
                        data: this.randomizeArray(sparklineData)
                    }],
                },
                {
                    title:"GASTOS",
                    width:'110%',
                    price:"0",
                    details:"",
                    options: {
                        colors: ['var(--chart-color4)'],
                        chart: {
                            type: 'area',
                            sparkline: {
                                enabled: true
                            },
                        },
                        stroke: {
                            width: 1
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                                gradientToColors: ['var(--chart-color4)'],
                                shadeIntensity: 2,
                                opacityFrom: 0.7,
                                opacityTo: 0.2,
                                stops: [0, 100]
                            },
                        },
                    },
                    series: [{
                        data: this.randomizeArray(sparklineData)
                    }],
                },
                {
                    title:"TOTAL",
                     width:'110%',
                    price:"10K",
                    details:"",
                    options: {
                         colors: ['var(--chart-color3)'],
                        chart: {
                            type: 'area',
                            sparkline: {
                                enabled: true
                            },
                        },
                        stroke: {
                            width: 1
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                                gradientToColors: ['var(--chart-color3)'],
                                shadeIntensity: 2,
                                opacityFrom: 0.7,
                                opacityTo: 0.2,
                                stops: [0, 100]
                            },
                        },
                    },
                    series: [{
                        data: this.randomizeArray(sparklineData)
                    }],
                },
            ],
            AudienceOverview:{
                rate:[
                    {
                        no:'$3,056',
                        rating:'Rate',
                        class:''
                    },
                    {
                        no:'$1,998',
                        rating:'Value',
                        class:"ml-lg-5 ml-md-4 ms-3"
                    },
                ],
                tab:[
                    {
                        title:'Week',
                    },
                    {
                        title:'Month',
                    },
                    {
                        title:'Year',
                    },
                ],
                title:'Audience Overview',
                options :{
                    colors: ['var(--chart-color1)', 'var(--chart-color2)'],
                    chart: {
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                    },
                    stroke: {
                        width: [0, 4]
                    },        
                    labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                    xaxis: {
                        type: 'datetime'
                    },
                    yaxis: [{
                            title: {
                                text: 'Rate',
                            },

                        },{
                            opposite: true,
                            title: {
                                text: 'Value'
                        }
                    }],
                },
                series: [{
                    name: 'Rate',
                    type: 'column',
                    data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
                }, {
                    name: 'Value',
                    type: 'line',
                    data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
                }],
            },
            GoalComplitionChart:{
                title:'GOAL COMPLETIONS',
                price:'$1,22,500',
                detail:'Analytics for last week',
                options: {
                    colors: ['#4b69d2'],
                    chart: {
                        type: 'area',
                        sparkline: {
                            enabled: true
                        },
                    },
                    stroke: {
                        width: 1
                    },
                },
                series: [{
                    data: this.randomizeArray(sparklineData)
                }],
            },
            activeUserLineChart:{
                title:'Active Users',
                content:'How do your users visited in the time.',
                tab:[
                    {
                        tab_title:'Daily (Avg)',
                        price:'1.08K',
                        icon:'fa fa-angle-up',
                        per:'1.03',
                        class:'text-success'

                    },{
                        tab_title:'Weekly',
                        price:'3.20K',
                        icon:'fa fa-angle-down',
                        per:'1.63',
                        class:'text-danger'
                    },
                    {
                        tab_title:'Monthly',
                        price:'8.18K',
                        icon:'fa fa-angle-up',
                        per:'4.33',
                        class:'text-success'
                    }
                ],
                options: {
                    colors: ['var(--chart-color4)'],
                    chart: {
                        type: 'line',
                        sparkline: {
                            enabled: true
                        },
                    },
                    stroke: {
                        width: 3
                    },
                },
                series: [{
                    data: this.randomizeArray(sparklineData)
                }],
            },
            SessionsByDevice:{
                title:'Sessions by Device',
                content:'How do your users visited in the time.',
                tab:[
                    {
                        title:'1W',
                    },
                    {
                        title:'1M',
                    },
                    {
                        title:'1Y',
                    },
                ],
                contents:[
                    {
                        tab_title:'Desktop',
                        price:'1.08K',
                        icon:'fa fa-angle-up',
                        per:'1.03',
                        class:'text-success'

                    },{
                        tab_title:'Mobile',
                        price:'3.20K',
                        icon:'fa fa-angle-down',
                        per:'1.63',
                        class:'text-danger'
                    },
                    {
                        tab_title:'Tablet',
                        price:'8.18K',
                        icon:'fa fa-angle-up',
                        per:'4.33',
                        class:'text-success'
                    }
                ],
                options:{
                    chart: {
                        type: 'donut',
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                        show: false,
                    },
                    colors: ['var(--chart-color1)', 'var(--chart-color2)', 'var(--chart-color3)'],
                 },
                series: [44, 55, 41],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            bubbleChart:{
                title:'Social Media Traffic',
                options:{
                    chart: {
                        type: 'bubble',
                        toolbar: {
                            show: false,
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                    },
                    colors: ['var(--chart-color1)', 'var(--chart-color2)', 'var(--chart-color3)'],
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: 0.8
                    },
                    xaxis: {
                        tickAmount: 12,
                        type: 'category',
                    },
                    yaxis: {
                        max: 30
                    }
                },
                series: [{
                        name: 'Facebook',
                        data: this.generateData(new Date('11 Nov 2020 GMT').getTime(), 22, {
                            min: 5,
                            max: 30
                        })
                    },{
                        name: 'Twitter',
                        data: this.generateData(new Date('11 Nov 2020 GMT').getTime(), 31, {
                            min: 5,
                            max: 30
                        })
                    },{
                        name: 'Dribbble',
                        data: this.generateData(new Date('11 Nov 2020 GMT').getTime(), 18, {
                            min: 5,
                            max: 30
                        })
                    }
                ],
            },
            items: [
                {source:'https://themeforest.net/item/lucid-angular-7-admin-template/23294743', visits:'980', time:'55s	', rate:'5.2' },
                {source:'https://themeforest.net/item/hexabit-bootstrap-4x-admin-template-ui-kit/22611789', visits:'851', time:'16s', rate:'8.0' },
                {source:'https://themeforest.net/item/lucid-hr-and-project-management-admin-template/22308861', visits:'321', time:'10s	', rate:'5.7' },                 
                {source:'https://themeforest.net/item/nexa-bootstrap4-material-design-premium-admin-dashboard/21122489', visits:'205', time:'57s', rate:'5.3' },
                {source:'https://themeforest.net/user/wrraptheme/portfolio', visits:'89', time:'45s', rate:'6.1' }, 
            ],
            fields: [
                { key: 'id', label: '#' },
                { key: 'source', label: 'SOURCE'},
                { key: 'visits', label: 'VISITS'},
                { key: 'time', label: 'AVG. TIME'},
                { key: 'rate', label: 'BOUNCE RATE'},
            ],
            reportsitems: [
                {name:'Gross value', price:'$3,500.71', time:{text:'12.1',class:'bg-success'}},
                {name:'Net volume from sales', price:'$2,980.45', time:{text:'6.9',class:'bg-warning'}},
                {name:'New volume from sales', price:'$950.00', time:{text:'-1.5',class:'bg-danger'}},                 
                {name:'Other', price:'32', time:{text:'1.9',class:'bg-success'}},
            ],
            reportsfields: [
                { key: 'name', label: '' },
                { key: 'price', label: ''},
                { key: 'time', label: ''},
            ],
            reportsoverview:
            {
                title:'Reports overview',
                price:'$7,431.14 USD',
                start:'0',
                end:'100',
                progressbar:[
                    {
                        width:'15',
                        class:''
                    },
                    {
                        width:'30',
                        class:'bg-info'
                    },
                    {
                        width:'20',
                        class:'bg-warning'
                    }
                ]
            },
            wi_day_lightning:
            {
                title:'Melbourne, FL 32904',
                time:'Saturday 16 January',
                degree:'43°',
            },
            userId:0,
            company_id: 0,

        }
    },
    async mounted(){
        this.userId = localStorage.getItem('user_id');
        this.company_id = localStorage.getItem('company_id');
        await this.getData()
    }
}
</script>
