<template >
  <div class="id">
    <h5>{{ dataKanban.titulo }}</h5>
    <div class="flex justify-left">
      <div class="flex overflow-x-scroll overflow-y-scroll column-height" id="now">
        <div v-for="column in columns" :key="column.id" class="bg-gray-100 rounded-lg px-2 py-3 column-width rounded mr-2" style="max-height:300vh">
          <div class="row">
            <div class="col-md-9">
              <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{ column.title }}</p>
            </div>
            <div class="col-md-2"><button @click="showModal(1, column)" class="btn btn-primary">+</button></div>
          </div>
          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
          <draggable :list="column.tasks" :animation="200" ghost-class="ghost-card" group="columns" :groups="columns" @change="updateDrag($event, column)">
            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->

            <div class="bg-white shadow rounded px-2 pt-1 pb-1 border border-white mt-2 cursor-move" @click="showModal(2, column, task)" v-for="task in column.tasks" :key="task.id">
              <div class="flex justify-between">
                <!-- titulo de la tarjeta -->
                <p class="text-gray-700 font-semibold font-sans tracking-wide task-title">{{ task.titulo }}</p>

                <img class="w-6 h-6 rounded-full ml-3" src="https://thumbs.dreamstime.com/b/basic-rgb-165126694.jpg" alt="Avatar" style="height:40px;width:40px"/>
              </div>
              <div class="flex mt-1 justify-between items-center">
                <span class="text-sm text-gray-600" style="font-size:10px">{{
                  $moment(task.fecha)
                    .utc()
                    .format("DD-MM-YYYY")
                }}</span>
                <badge v-if="task.tipo" :color="seekColor(task.tipo)">{{ task.tipo }}</badge>
              </div>
              <div class="flex mt-1 justify-between items-center">
                <badge v-if="task.archivado == 1" color="yellow">{{ task.archivado == 1 ? "Archivado" : "" }}</badge>
              </div>
            </div>

            <!-- <task-card 
                v-for="(task) in column.tasks"
                :key="task.id"
                :task="task"
                class="mt-3 cursor-move"
              ></task-card> -->

            <!-- </transition-group> -->
          </draggable>
        </div>
      </div>
    </div>

    <b-modal class="modal-dialog" id="kanbanModal" tabindex="-1" :size="meca_kanban_archivos.length>0 ? 'xl' : 'md'" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div :class="meca_kanban_archivos.length>0 ? ' col-md-6 ' : 'col-md-12'">
              <div class="row">
                <div class="col-md-12 mb-2"><label for="nombre" class="">Titulo</label><input required v-model="row.titulo" class="form-control" type="text" name="nombre" id="nombre" /></div>
                <div class="col-md-6 mb-2"><label for="codigo" class="">Fecha</label><input required v-model="row.fecha" class="form-control" type="date" name="fecha" id="fecha" /></div>
                <div class="col-md-6 mb-2">
                  <label for="tipo" class="">Tipo</label>
                  <b-form-select required class="form-control select text-black" v-model="row.tipo" :options="listaTipo" value="tipo" value-field="tipo" text-field="tipo"> </b-form-select>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="descripcion" class="">Descripción</label>
                  <textarea class="form-control" v-model="row.descripcion" name="" id="" cols="30" rows="5"></textarea>
                  <!-- <input required class="form-control" v-model="row.descripcion" type="text" name="descripcion" id="descripcion"> -->
                </div>

                <div class="col-md-12 mb-2 mt-2 ml-4">
                  <b-form-checkbox size="lg" id="checkbox-1" v-model="row.archivado" name="checkbox-1" value="1" unchecked-value="0">
                    <span for="Archivado" class="h6">Archivado</span>
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="descripcion" class="">Subir imagen / archivo</label><br />
                  <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('kanban')" /><br />
                  <!-- <input class="form-control" v-model="row.url" type="text" name="url" id="url" /> -->
              </div>                
              </div>
            </div>
            <div class="col-md-6" v-if="meca_kanban_archivos.length > 0">
              <div class="row">
                <div class="col-4" v-for="item of meca_kanban_archivos" :key="item.id">
                  <a target="_blank" :href="item.url">
                    <!-- <img :src="item.url" :key="item.id" alt="" height="50" /> -->

                    <img
                      v-if="
                        item.url.split('.').pop() == 'jpg' ||
                          item.url.split('.').pop() == 'gif' ||
                          item.url.split('.').pop() == 'bmp' ||
                          item.url.split('.').pop() == 'jpeg' ||
                          item.url.split('.').pop() == 'png' ||
                          item.url.split('.').pop() == 'webp'
                      "
                      :src="item.url"
                      alt=""
                      style="margin:5px !important"
                    />
                    <img
                      v-if="
                        item.url.split('.').pop() != 'jpg' &&
                          item.url.split('.').pop() != 'gif' &&
                          item.url.split('.').pop() != 'bmp' &&
                          item.url.split('.').pop() != 'jpeg' &&
                          item.url.split('.').pop() != 'png' &&
                          item.url.split('.').pop() != 'webp'
                      "
                      src="https://upload.wikimedia.org/wikipedia/commons/1/1b/SD_Download_Manager_logo.png"
                      alt=""
                      style="margin:5px !important"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- footer -->
          <div class="text-right">
            <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('kanbanModal')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Badge from "./Badge.vue";

// import TaskCard from "../superAdmin/TaskCard.vue";
export default {
  name: "App",
  components: {
    // TaskCard,
    Badge,
    draggable,
  },
  data() {
    return {
      bgcolor: [
        { nombre: "Desarrollo", color: "blue" },
        { nombre: "Maquetación", color: "cyan" },
        { nombre: "Backend", color: "red" },
        { nombre: "Diseño", color: "green" },
        { nombre: "Investigación", color: "gray" },
        { nombre: "App", color: "gray" },
        { nombre: "Desarrollo", color: "gray" },
        { nombre: "Info", color: "gray" },
        { nombre: "Otros", color: "gray" },
      ],
      columns: [],
      user: {},
      modalTitle: "Nueva tarjeta",
      row: {},
      listaTipo: [{ tipo: "Maquetación" }, { tipo: "Backend" }, { tipo: "Diseño" }, { tipo: "Investigación" }, { tipo: "App" }, { tipo: "Desarrollo" }, { tipo: "Info" }, { tipo: "Otros" }],
      id: 0,
      tipo: 0,
      file1: null,
      finename1: null,
      meca_kanban_archivos: [],
      dataKanban:{}
    };
  },

  async mounted() {
    this.$store.watch(
      (state) => [state.showEditItemsModal].join(),
      () => {
        this.refreshButton();
      }
    );
    this.id = this.$route.params.id;
    this.user = JSON.parse(localStorage.getItem("user")) || {};
    console.log("usuario", this.user.id);
    await this.getData();
  },
  methods: {
    async getFilesKanbanId(id) {
      this.meca_kanban_archivos= []
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("get", { path: "meca_kanban_archivos/getAllById/" + id });
        if (res.length > 0) {
          this.meca_kanban_archivos = res;
        }
        this.$isLoading(false);
        
      } catch (error) {
        this.meca_kanban_archivos = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async onFileSelected(type) {
      try {
        this.file1 = event.target.files[0];
        console.log(this.file1);
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        let extension = this.file1.name.split(".").pop();
        this.filename1 = type + "_" + this.filename1 + "." + extension;
        console.log(this.filename1);
        if (this.filename1 != null) {
          this.$isLoading(true);
          let uploadImg = await this.$store.dispatch("onFileSelected", { filename: this.filename1, file: this.file1 });
          console.log(uploadImg);
          if (uploadImg == true) {
            console.log("----", uploadImg);
            let data = {
              // url: "https://siscoper.mecasite.com/public/img/" + this.filename1,
              url: "https://admin.siscoper.net/img/kanban/" + this.filename1,
              nombre: "",
              kanban_item_id: this.row.id,
              kanban_id: this.row.kanban_id,
              usuario_id: this.user.id,
            };
  
            let res = await this.$store.dispatch("post", { path: "meca_kanban_archivos/new", data: data });
            this.getFilesKanbanId(this.row.id);
  
            console.log("res", res);
          }
          this.$isLoading(false);
          
        }
        
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log('errror upload', error)
      }
    },
    seekColor(tipo) {
      console.log(tipo);
      let resulta = this.bgcolor.filter((x) => x.nombre == tipo);
      resulta = resulta[0].color;
      return resulta;
      //bgcolor.name == task.tipo ? bgcolor.color: ''
    },
    async saveData() {
      try {
        console.log("save");
        let data = Object.assign({}, this.row);
        //data.kanban_id = this.id
        let res;
        this.$isLoading(true);
        if (this.tipo == 1) {
          res = await this.$store.dispatch("post", { path: "meca_kanban_items/new", data: data });
        }
        if (this.tipo == 2) {
          delete this.row.id;
          res = await this.$store.dispatch("put", { path: "meca_kanban_items/modify/" + data.id, data: this.row });
        }
        this.$isLoading(false);
        
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          this.$isLoading(false);
        } else {
          this.row = {};
          this.$isLoading(false);
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          this.$bvModal.hide("kanbanModal");
          await this.getData();
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    showModal(type, column, task) {
      this.meca_kanban_archivos= []
      //alert(type)
      //nuevo
      this.tipo = type;
      if (type == 1) {
        this.modalTitle = "Nueva tarjeta";
        this.row = {};
        this.row.orden = column.tasks.length;
        this.row.kanban_id = column.id;
        this.row.fecha = this.$moment(new Date())
          .utc()
          .format("YYYY-MM-DD");
      }
      //editar
      if (type == 2) {
        this.modalTitle = "Editar tarjeta";
        console.log("tipo 2", task);
        this.row = task;
        this.row.fecha = this.$moment(task.fecha)
          .utc()
          .format("YYYY-MM-DD");
        this.getFilesKanbanId(this.row.id);
      }
      this.$bvModal.show("kanbanModal");
    },
    async updateDrag(e, col) {
      // console.log('-->',e)
      //  console.log('-->',id)
      if (e.moved) {
        console.log("nuevo orden:", col);
        // console.log('element--->',e.moved)
        // console.log('col---->',col)
        await this.$store.dispatch("put", { path: "meca_kanban_orderItems/modify/" + e.moved.element.id, data: col.tasks });
        //await this.getData()
      }
      if (e.added) {
        console.log("element--->", e.added.element);
        console.log("col---->", col);
        let data = {
          kanban_id: col.id,
        };
        await this.$store.dispatch("put", { path: "meca_kanban_items/modify/" + e.added.element.id, data: data });
        await this.getData();
      }
    },
    async getData() {
      try {
        this.$isLoading(true);
        let result = await this.$store.dispatch("get", { path: "meca_kanban/get/" + this.id });
        this.dataKanban = result
        console.log(this.dataKanban)
        let res = await this.$store.dispatch("get", { path: "meca_kanban_lista/getAllById/" + this.id + "/active" });
        if (res.length > 0) {
          this.columns = res;
        }
        this.$isLoading(false);
        
      } catch (error) {
        this.columns = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
  },
};
</script>

<style scoped>
.column-width {
  min-width: 250px;
  width: 250px;
}
.column-height {
  height: 80vh !important;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.task-title {
  font-size: 12px;
}

.modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.id{
  /* background-image: url('/images/fondo33.jpg'); */
}
</style>
