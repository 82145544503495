<template>
    <div class="col-12">
        <div class="bd-content">
            <h2 id="how-it-works">How it works</h2>
            <p class="bd-lead">The component <code>&lt;b-navbar&gt;</code> is a wrapper that positions branding, navigation, and other elements into a concise header. It's easily extensible and thanks to the <code>&lt;b-collapse&gt;</code> component, it can easily integrate responsive behaviors.</p>
            <div class="alert alert-danger" role="alert">
                <strong>Navbar</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/navbar" target="_blank">Bootstrap Navbar documentation <i class="fa fa-external-link"></i></a>
            </div>
            <ul>
                <li>Navbars require a wrapping <code>.navbar</code> with <code>.navbar-expand{-sm|-md|-lg|-xl|-xxl}</code> for responsive collapsing and <a href="#color-schemes">color scheme</a> classes.</li>
                <li>Navbars and their contents are fluid by default. Change the <a href="#containers">container</a> to limit their horizontal width in different ways.</li>
                <li>Use our <a href="https://bootstrap-vue.org/docs/reference/spacing-classes#spacing">spacing.</a></li>
            </ul>
            <div class="card card-callout p-3">
                <span>The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/reference/accessibility#reduced-motion">reduced motion section of our accessibility documentation</a>.</span>
            </div>
            <p>Read on for an example and list of supported sub-components.</p>
            
            <div class="border-top mt-5 pt-3">
                <h4 id="supported-content">Supported content</h4>
                <p>Navbars come with built-in support for a handful of sub-components. Choose from the following as needed:</p>
                <ul>
                    <li><code>&lt;b-navbar-brand&gt;</code> for your company, product, or project name.</li>
                    <li><code>&lt;b-navbar-toggle&gt;</code> for use with the <code>&lt;b-collapse is-nav&gt;</code> component.</li>
                    <li><code>&lt;b-collapse is-nav&gt;</code> for grouping and hiding navbar contents by a parent breakpoint.</li>
                    <li><code>&lt;b-navbar-nav&gt;</code> for a full-height and lightweight navigation (including support for dropdowns). The following sub-components inside <code>&lt;b-navbar-nav&gt;</code> are supported:<ul> <li><code>&lt;b-nav-item&gt;</code> for link (and router-link) action items</li> <li><code>&lt;b-nav-item-dropdown&gt;</code> for nav dropdown menus</li> <li><code>&lt;b-nav-text&gt;</code> for adding vertically centered strings of text.</li> <li><code>&lt;b-nav-form&gt;</code> for any form controls and actions.</li> </ul> </li>
                </ul>
                <p>Here’s an example of all the sub-components included in a responsive light-themed navbar that automatically collapses at the <code>lg</code> (large) breakpoint.</p>
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-navbar type="light" variant="light" tag="div" toggleable="lg">
                                <div class="container-fluid">
                                    <b-navbar-brand href="#">NavBar</b-navbar-brand>
                                    <b-navbar-toggle class="navbar-toggler" target="navbarSupportedContent">
                                        <span class="navbar-toggler-icon"></span>
                                    </b-navbar-toggle>
                                    <b-collapse id="navbarSupportedContent" is-nav>
                                        <b-navbar-nav class="me-auto mb-2 mb-lg-0"> 
                                            <b-nav-item href="#">Home</b-nav-item>
                                            <b-nav-item href="#">Link</b-nav-item>
                                            <b-nav-item-dropdown text="Dropdown" variant="transparent">
                                                <b-dropdown-item>Action</b-dropdown-item>
                                                <b-dropdown-item>Another action</b-dropdown-item>
                                                <b-dropdown-divider></b-dropdown-divider>
                                                <b-dropdown-item>Something else here</b-dropdown-item>
                                            </b-nav-item-dropdown>
                                            <b-nav-item href="#" disabled>Disabled</b-nav-item>
                                        </b-navbar-nav>
                                        <b-form class="d-flex">
                                            <b-form-input class="me-2" placeholder="Search"></b-form-input>
                                            <b-button variant="outline-success" type="submit">Search</b-button>
                                        </b-form>
                                    </b-collapse>
                                </div>
                            </b-navbar> 
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-navbar type=&quot;light&quot; variant=&quot;light&quot; tag=&quot;div&quot; toggleable=&quot;lg&quot;&gt;
        &lt;div class=&quot;container-fluid&quot;&gt;
            &lt;b-navbar-brand href=&quot;#&quot;&gt;NavBar&lt;/b-navbar-brand&gt;
            &lt;b-navbar-toggle class=&quot;navbar-toggler&quot; target=&quot;navbarSupportedContent&quot;&gt;
                &lt;span class=&quot;navbar-toggler-icon&quot;&gt;&lt;/span&gt;
            &lt;/b-navbar-toggle&gt;
            &lt;b-collapse id=&quot;navbarSupportedContent&quot; is-nav&gt;
                &lt;b-navbar-nav class=&quot;me-auto mb-2 mb-lg-0&quot;&gt; 
                    &lt;b-nav-item href=&quot;#&quot;&gt;Home&lt;/b-nav-item&gt;
                    &lt;b-nav-item href=&quot;#&quot;&gt;Link&lt;/b-nav-item&gt;
                    &lt;b-nav-item-dropdown text=&quot;Dropdown&quot; variant=&quot;transparent&quot;&gt;
                        &lt;b-dropdown-item&gt;Action&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item&gt;Another action&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
                        &lt;b-dropdown-item&gt;Something else here&lt;/b-dropdown-item&gt;
                    &lt;/b-nav-item-dropdown&gt;
                    &lt;b-nav-item href=&quot;#&quot; disabled&gt;Disabled&lt;/b-nav-item&gt;
                &lt;/b-navbar-nav&gt;
                &lt;b-form class=&quot;d-flex&quot;&gt;
                    &lt;b-form-input class=&quot;me-2&quot; placeholder=&quot;Search&quot;&gt;&lt;/b-form-input&gt;
                    &lt;b-button variant=&quot;outline-success&quot; type=&quot;submit&quot;&gt;Search&lt;/b-button&gt;
                &lt;/b-form&gt;
            &lt;/b-collapse&gt;
        &lt;/div&gt;
    &lt;/b-navbar&gt; 
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
                <p>This example uses <a href="https://bootstrap-vue.org/docs/components/navbar#b-navbar-toggle-and-b-collapse-is-nav">color</a> (<code>variant</code>) and <a href="https://bootstrap-vue.org/docs/reference/spacing-classes#spacing">spacing</a> (<code>my-2</code>, <code>my-lg-0</code>, <code>me-sm-0</code>, <code>my-sm-0</code>) utility classes.</p>
                
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-navbar toggleable="lg">
                                <div class="container-fluid">
                                    <a href="index.html" class="me-3 me-lg-4 brand-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 64 80" fill="none">
                                            <path d="M58.8996 22.7L26.9996 2.2C23.4996 -0.0999999 18.9996 0 15.5996 2.5C12.1996 5 10.6996 9.2 11.7996 13.3L15.7996 26.8L3.49962 39.9C-3.30038 47.7 3.79962 54.5 3.89962 54.6L3.99962 54.7L36.3996 78.5C36.4996 78.6 36.5996 78.6 36.6996 78.7C37.8996 79.2 39.1996 79.4 40.3996 79.4C42.9996 79.4 45.4996 78.4 47.4996 76.4C50.2996 73.5 51.1996 69.4 49.6996 65.6L45.1996 51.8L58.9996 39.4C61.7996 37.5 63.3996 34.4 63.3996 31.1C63.4996 27.7 61.7996 24.5 58.8996 22.7ZM46.7996 66.7V66.8C48.0996 69.9 46.8996 72.7 45.2996 74.3C43.7996 75.9 41.0996 77.1 37.9996 76L5.89961 52.3C5.29961 51.7 1.09962 47.3 5.79962 42L16.8996 30.1L23.4996 52.1C24.3996 55.2 26.5996 57.7 29.5996 58.8C30.7996 59.2 31.9996 59.5 33.1996 59.5C35.0996 59.5 36.9996 58.9 38.6996 57.8C38.7996 57.8 38.7996 57.7 38.8996 57.7L42.7996 54.2L46.7996 66.7ZM57.2996 36.9C57.1996 36.9 57.1996 37 57.0996 37L44.0996 48.7L36.4996 25.5V25.4C35.1996 22.2 32.3996 20 28.9996 19.3C25.5996 18.7 22.1996 19.8 19.8996 22.3L18.2996 24L14.7996 12.3C13.8996 8.9 15.4996 6.2 17.3996 4.8C18.4996 4 19.8996 3.4 21.4996 3.4C22.6996 3.4 23.9996 3.7 25.2996 4.6L57.1996 25.1C59.1996 26.4 60.2996 28.6 60.2996 30.9C60.3996 33.4 59.2996 35.6 57.2996 36.9Z" fill="black"></path>
                                        </svg>
                                    </a>
                                    <div class="h-right d-flex align-items-center me-lg-0 order-1">
                                        <div class="d-flex">
                                            <a class="nav-link text-muted collapsed" v-b-toggle.main-search href="#" title="Search this chat">
                                                <i class="fa fa-search"></i>
                                            </a>
                                            <a class="nav-link text-primary" href="#" v-b-modal.LayoutModal>
                                                <i class="fa fa-sliders"></i>
                                            </a>
                                            <a class="nav-link text-primary" href="#" title="Settings" v-b-modal.SettingsModal><i class="fa fa-gear"></i></a>
                                        </div>
                                        <b-dropdown variant="link" no-caret class="notifications" toggle-class="nav-link pulse" menu-class="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                            <template slot="button-content">
                                                <i class="fa fa-bell"></i>
                                                <span class="pulse-ring"></span>
                                            </template>
                                            <b-card class="border-0 w380" no-body>
                                                <b-tabs content-class="mt-3" nav-wrapper-class="border-0 p-3" card nav-class="border-bottom-0">
                                                    <b-tab title="Message" active>
                                                        <ul class="list-unstyled list mb-0" v-if="notificationsObj.messages.length > 0">
                                                            <template v-for="(message,index) in notificationsObj.messages">
                                                                <li class="py-2 mb-1" :class="{'border-bottom': index < (notificationsObj.messages.length-1)}" :key="index">
                                                                    <a href="javascript:void(0);" class="d-flex">
                                                                        <img class="avatar rounded-circle" :src="require('@/assets/images/xs/' + message.avtar)" alt="" v-if="message.avtar">
                                                                        <div class="avatar rounded-circle no-thumbnail" v-if="!message.avtar">{{ message.name | short_hand }}</div>
                                                                        <div class="flex-fill ml-3">
                                                                            <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">{{ message.name }}</span> <small>{{ message.time }}</small></p>
                                                                            <span class="text-muted" v-html="message.message"></span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </b-tab>
                                                    <b-tab title="Events">
                                                        <ul class="list-unstyled list mb-0" v-if="notificationsObj.events.length > 0">
                                                            <template v-for="(event,index) in notificationsObj.events">
                                                                <li class="py-2 mb-1 " :class="{'border-bottom': index < (notificationsObj.events.length-1)}" :key="index">
                                                                    <a href="javascript:void(0);" class="d-flex">
                                                                        <div class="avatar rounded no-thumbnail"><i :class="`fa fa-lg ${event.faIcon}`"></i></div>
                                                                        <div class="flex-fill ml-3">
                                                                            <p class="mb-0 text-muted" v-html="event.event">\</p>
                                                                            <small class="text-muted">{{ event.time }}</small>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <h4 v-if="notificationsObj.events.length == 0">No Events right now!</h4>
                                                    </b-tab>
                                                    <b-tab title="Logs"><ul class="list-unstyled list mb-0" v-if="notificationsObj.logs.length > 0">
                                                        <template v-for="(log,index) in notificationsObj.logs">
                                                            <li class="py-2 mb-1 " :class="{'border-bottom': index < (notificationsObj.logs.length-1)}" :key="index">
                                                                <a href="javascript:void(0);" class="d-flex">
                                                                    <div class="avatar rounded no-thumbnail"><i :class="`fa fa-lg ${log.faIcon}`"></i></div>
                                                                    <div class="flex-fill ml-3">
                                                                        <p class="mb-0 text-muted" v-html="log.event">\</p>
                                                                        <small class="text-muted">{{ log.time }}</small>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                    <h4 v-if="notificationsObj.logs.length == 0">No Logs right now!</h4></b-tab>
                                                </b-tabs>
                                                <a class="card-footer text-center border-top-0" href="Javascript:void(0);"> View all notifications</a>
                                            </b-card>
                                        </b-dropdown>
                                        <b-dropdown class="user-profile ms-2 ms-sm-3" toggle-tag="a" no-caret variant="transparent" menu-class="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                            <template #button-content>
                                                <img class="avatar rounded-circle img-thumbnail" src="@/assets/images/profile_av.png" alt="">
                                            </template>
                                            <b-card class="border-0 w240" body-class="border-bottom">
                                                    <div class="d-flex py-1">
                                                        <img class="avatar rounded-circle" src="@/assets/images/profile_av.png" alt="">
                                                        <div class="flex-fill ms-3">
                                                            <p class="mb-0 text-muted"><span class="font-weight-bold">Chris Fox</span></p>
                                                            <small class="text-muted">chris.fox@gamil.com</small>
                                                            <div>
                                                                <a href="#" class="card-link">Sign out</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div class="list-group m-2">
                                                    <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-user"></i>Profile &amp; account</a>
                                                    <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-gear"></i>Settings</a>
                                                    <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-tag"></i>Customization</a>
                                                    <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-users"></i>Manage team</a>
                                                    <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-calendar"></i>My Events</a>
                                                    <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-credit-card"></i>My Statements</a>
                                                </div>
                                            </b-card>
                                        </b-dropdown>
                                    </div>
                                   
                                    <b-navbar-toggle class="navbar-toggler p-0 border-0" target="mainHeaders">
                                        <span class="fa fa-bars"></span>
                                    </b-navbar-toggle>
                                        
                                    <b-collapse class="navbar-collapse order-0" id="mainHeaders">
                                        <b-navbar-nav class="me-auto">
                                            <b-nav-item>
                                                <i class="fa fa-dashboard me-2"></i><span>Dashboard</span>
                                            </b-nav-item>
                                            <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                <template #button-content>
                                                        <i class="fa fa-slack me-2"></i><span>Apps</span>
                                                </template>
                                                <b-dropdown-item href="#">Calendar</b-dropdown-item>
                                                <b-dropdown-item href="#">Chat app</b-dropdown-item>
                                                <b-dropdown-item href="#">Inbox</b-dropdown-item>
                                            </b-nav-item-dropdown>
                                            <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                 <template #button-content>
                                                        <i class="fa fa-file me-2"></i><span>Pages</span>
                                                </template>
                                                <b-dropdown-item href="#">Profile</b-dropdown-item>
                                                <b-dropdown-item href="#">Timeline</b-dropdown-item>
                                                <b-dropdown-item href="#">Image Gallery</b-dropdown-item>
                                                <b-dropdown-item href="#">Invoices</b-dropdown-item>
                                                <b-dropdown-item href="#">Pricing</b-dropdown-item>
                                                <b-dropdown-item href="#">Teams Board</b-dropdown-item>
                                                <b-dropdown-item href="#">FAQs</b-dropdown-item>
                                                <b-dropdown-item href="#">Widget's</b-dropdown-item>
                                            </b-nav-item-dropdown>
                                            <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                 <template #button-content>
                                                        <i class="fa fa-lock me-2"></i><span>Authentication</span>
                                                </template>
                                                <b-dropdown-item href="#">Sign in</b-dropdown-item>
                                                <b-dropdown-item href="#">Sign up</b-dropdown-item>
                                                <b-dropdown-item href="#">Password reset</b-dropdown-item>
                                                <b-dropdown-item href="#">2-Step Authentication</b-dropdown-item>
                                                <b-dropdown-item href="#">404</b-dropdown-item>
                                            </b-nav-item-dropdown>
                                            <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                 <template #button-content>
                                                        <i class="fa fa-file-text me-2"></i><span>Docs</span>
                                                </template>
                                                <b-dropdown-item href="#">Stater page</b-dropdown-item>
                                                <b-dropdown-item href="#">Documentation</b-dropdown-item>
                                                <b-dropdown-item href="#">Changelog</b-dropdown-item>
                                            </b-nav-item-dropdown>
                                        </b-navbar-nav>
                                    </b-collapse>
                                </div>
                            </b-navbar>
                            <b-collapse class="border-bottom px-lg-5 px-md-2 collapse bg-primary" id="main-search">
                                <div class="container py-4">
                    
                                    <div class="input-group">
                                        <input type="text" class="form-control border-0 p-0 bg-transparent" placeholder="Search. Components, Layouts, etc...">
                    
                                        <div class="input-group-append ms-3">
                                            <button class="btn btn-light" type="submit">Search</button>
                                        </div>
                                    </div>
                    
                                </div>
                            </b-collapse> 
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-navbar toggleable=&quot;lg&quot;&gt;
        &lt;div class=&quot;container-fluid&quot;&gt;
            &lt;a href=&quot;index.html&quot; class=&quot;me-3 me-lg-4 brand-icon&quot;&gt;
                &lt;svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;24&quot; viewBox=&quot;0 0 64 80&quot; fill=&quot;none&quot;&gt;
                    &lt;path d=&quot;M58.8996 22.7L26.9996 2.2C23.4996 -0.0999999 18.9996 0 15.5996 2.5C12.1996 5 10.6996 9.2 11.7996 13.3L15.7996 26.8L3.49962 39.9C-3.30038 47.7 3.79962 54.5 3.89962 54.6L3.99962 54.7L36.3996 78.5C36.4996 78.6 36.5996 78.6 36.6996 78.7C37.8996 79.2 39.1996 79.4 40.3996 79.4C42.9996 79.4 45.4996 78.4 47.4996 76.4C50.2996 73.5 51.1996 69.4 49.6996 65.6L45.1996 51.8L58.9996 39.4C61.7996 37.5 63.3996 34.4 63.3996 31.1C63.4996 27.7 61.7996 24.5 58.8996 22.7ZM46.7996 66.7V66.8C48.0996 69.9 46.8996 72.7 45.2996 74.3C43.7996 75.9 41.0996 77.1 37.9996 76L5.89961 52.3C5.29961 51.7 1.09962 47.3 5.79962 42L16.8996 30.1L23.4996 52.1C24.3996 55.2 26.5996 57.7 29.5996 58.8C30.7996 59.2 31.9996 59.5 33.1996 59.5C35.0996 59.5 36.9996 58.9 38.6996 57.8C38.7996 57.8 38.7996 57.7 38.8996 57.7L42.7996 54.2L46.7996 66.7ZM57.2996 36.9C57.1996 36.9 57.1996 37 57.0996 37L44.0996 48.7L36.4996 25.5V25.4C35.1996 22.2 32.3996 20 28.9996 19.3C25.5996 18.7 22.1996 19.8 19.8996 22.3L18.2996 24L14.7996 12.3C13.8996 8.9 15.4996 6.2 17.3996 4.8C18.4996 4 19.8996 3.4 21.4996 3.4C22.6996 3.4 23.9996 3.7 25.2996 4.6L57.1996 25.1C59.1996 26.4 60.2996 28.6 60.2996 30.9C60.3996 33.4 59.2996 35.6 57.2996 36.9Z&quot; fill=&quot;black&quot;&gt;&lt;/path&gt;
                &lt;/svg&gt;
            &lt;/a&gt;
            &lt;div class=&quot;h-right d-flex align-items-center me-lg-0 order-1&quot;&gt;
                &lt;div class=&quot;d-flex&quot;&gt;
                    &lt;a class=&quot;nav-link text-muted collapsed&quot; v-b-toggle.main-search href=&quot;#&quot; title=&quot;Search this chat&quot;&gt;
                        &lt;i class=&quot;fa fa-search&quot;&gt;&lt;/i&gt;
                    &lt;/a&gt;
                    &lt;a class=&quot;nav-link text-primary&quot; href=&quot;#&quot; v-b-modal.LayoutModal&gt;
                        &lt;i class=&quot;fa fa-sliders&quot;&gt;&lt;/i&gt;
                    &lt;/a&gt;
                    &lt;a class=&quot;nav-link text-primary&quot; href=&quot;#&quot; title=&quot;Settings&quot; v-b-modal.SettingsModal&gt;&lt;i class=&quot;fa fa-gear&quot;&gt;&lt;/i&gt;&lt;/a&gt;
                &lt;/div&gt;
                &lt;b-dropdown variant=&quot;link&quot; no-caret class=&quot;notifications&quot; toggle-class=&quot;nav-link pulse&quot; menu-class=&quot;rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0&quot;&gt;
                    &lt;template slot=&quot;button-content&quot;&gt;
                        &lt;i class=&quot;fa fa-bell&quot;&gt;&lt;/i&gt;
                        &lt;span class=&quot;pulse-ring&quot;&gt;&lt;/span&gt;
                    &lt;/template&gt;
                    &lt;b-card class=&quot;border-0 w380&quot; no-body&gt;
                        &lt;b-tabs content-class=&quot;mt-3&quot; nav-wrapper-class=&quot;border-0 p-3&quot; card nav-class=&quot;border-bottom-0&quot;&gt;
                            &lt;b-tab title=&quot;Message&quot; active&gt;
                                &lt;ul class=&quot;list-unstyled list mb-0&quot; v-if=&quot;notificationsObj.messages.length &gt; 0&quot;&gt;
                                    &lt;template v-for=&quot;(message,index) in notificationsObj.messages&quot;&gt;
                                        &lt;li class=&quot;py-2 mb-1&quot; :class=&quot;{&#039;border-bottom&#039;: index &lt; (notificationsObj.messages.length-1)}&quot; :key=&quot;index&quot;&gt;
                                            &lt;a href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
                                                &lt;img class=&quot;avatar rounded-circle&quot; :src=&quot;require(&#039;@/assets/images/xs/&#039; + message.avtar)&quot; alt=&quot;&quot; v-if=&quot;message.avtar&quot;&gt;
                                                &lt;div class=&quot;avatar rounded-circle no-thumbnail&quot; v-if=&quot;!message.avtar&quot;&gt;message&lt;/div&gt;
                                                &lt;div class=&quot;flex-fill ml-3&quot;&gt;
                                                    &lt;p class=&quot;d-flex justify-content-between mb-0 text-muted&quot;&gt;&lt;span class=&quot;font-weight-bold&quot;&gt;message&lt;/span&gt; &lt;small&gt;Robert Hammer&lt;/small&gt;&lt;/p&gt;
                                                    &lt;span class=&quot;text-muted&quot; v-html=&quot;message.message&quot;&gt;&lt;/span&gt;
                                                &lt;/div&gt;
                                            &lt;/a&gt;
                                        &lt;/li&gt;
                                    &lt;/template&gt;
                                &lt;/ul&gt;
                            &lt;/b-tab&gt;
                            &lt;b-tab title=&quot;Events&quot;&gt;
                                &lt;ul class=&quot;list-unstyled list mb-0&quot; v-if=&quot;notificationsObj.events.length &gt; 0&quot;&gt;
                                    &lt;template v-for=&quot;(event,index) in notificationsObj.events&quot;&gt;
                                        &lt;li class=&quot;py-2 mb-1 &quot; :class=&quot;{&#039;border-bottom&#039;: index &lt; (notificationsObj.events.length-1)}&quot; :key=&quot;index&quot;&gt;
                                            &lt;a href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
                                                &lt;div class=&quot;avatar rounded no-thumbnail&quot;&gt;&lt;i :class=&quot;`fa fa-lg ${event.faIcon}`&quot;&gt;&lt;/i&gt;&lt;/div&gt;
                                                &lt;div class=&quot;flex-fill ml-3&quot;&gt;
                                                    &lt;p class=&quot;mb-0 text-muted&quot; v-html=&quot;event.event&quot;&gt;\&lt;/p&gt;
                                                    &lt;small class=&quot;text-muted&quot;&gt;events&lt;/small&gt;
                                                &lt;/div&gt;
                                            &lt;/a&gt;
                                        &lt;/li&gt;
                                    &lt;/template&gt;
                                &lt;/ul&gt;
                                &lt;h4 v-if=&quot;notificationsObj.events.length == 0&quot;&gt;No Events right now!&lt;/h4&gt;
                            &lt;/b-tab&gt;
                            &lt;b-tab title=&quot;Logs&quot;&gt;&lt;ul class=&quot;list-unstyled list mb-0&quot; v-if=&quot;notificationsObj.logs.length &gt; 0&quot;&gt;
                                &lt;template v-for=&quot;(log,index) in notificationsObj.logs&quot;&gt;
                                    &lt;li class=&quot;py-2 mb-1 &quot; :class=&quot;{&#039;border-bottom&#039;: index &lt; (notificationsObj.logs.length-1)}&quot; :key=&quot;index&quot;&gt;
                                        &lt;a href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
                                            &lt;div class=&quot;avatar rounded no-thumbnail&quot;&gt;&lt;i :class=&quot;`fa fa-lg ${log.faIcon}`&quot;&gt;&lt;/i&gt;&lt;/div&gt;
                                            &lt;div class=&quot;flex-fill ml-3&quot;&gt;
                                                &lt;p class=&quot;mb-0 text-muted&quot; v-html=&quot;log.event&quot;&gt;\&lt;/p&gt;
                                                &lt;small class=&quot;text-muted&quot;&gt;log.time&lt;/small&gt;
                                            &lt;/div&gt;
                                        &lt;/a&gt;
                                    &lt;/li&gt;
                                &lt;/template&gt;
                            &lt;/ul&gt;
                            &lt;h4 v-if=&quot;notificationsObj.logs.length == 0&quot;&gt;No Logs right now!&lt;/h4&gt;&lt;/b-tab&gt;
                        &lt;/b-tabs&gt;
                        &lt;a class=&quot;card-footer text-center border-top-0&quot; href=&quot;Javascript:void(0);&quot;&gt; View all notifications&lt;/a&gt;
                    &lt;/b-card&gt;
                &lt;/b-dropdown&gt;
                &lt;b-dropdown class=&quot;user-profile ms-2 ms-sm-3&quot; toggle-tag=&quot;a&quot; no-caret variant=&quot;transparent&quot; menu-class=&quot;rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0&quot;&gt;
                    &lt;template #button-content&gt;
                        &lt;img class=&quot;avatar rounded-circle img-thumbnail&quot; src=&quot;@/assets/images/profile_av.png&quot; alt=&quot;&quot;&gt;
                    &lt;/template&gt;
                    &lt;b-card class=&quot;border-0 w240&quot; body-class=&quot;border-bottom&quot;&gt;
                            &lt;div class=&quot;d-flex py-1&quot;&gt;
                                &lt;img class=&quot;avatar rounded-circle&quot; src=&quot;@/assets/images/profile_av.png&quot; alt=&quot;&quot;&gt;
                                &lt;div class=&quot;flex-fill ms-3&quot;&gt;
                                    &lt;p class=&quot;mb-0 text-muted&quot;&gt;&lt;span class=&quot;font-weight-bold&quot;&gt;Chris Fox&lt;/span&gt;&lt;/p&gt;
                                    &lt;small class=&quot;text-muted&quot;&gt;chris.fox@gamil.com&lt;/small&gt;
                                    &lt;div&gt;
                                        &lt;a href=&quot;#&quot; class=&quot;card-link&quot;&gt;Sign out&lt;/a&gt;
                                    &lt;/div&gt;
                                &lt;/div&gt;
                            &lt;/div&gt;
                        &lt;div class=&quot;list-group m-2&quot;&gt;
                            &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-user&quot;&gt;&lt;/i&gt;Profile &amp; account&lt;/a&gt;
                            &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-gear&quot;&gt;&lt;/i&gt;Settings&lt;/a&gt;
                            &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-tag&quot;&gt;&lt;/i&gt;Customization&lt;/a&gt;
                            &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-users&quot;&gt;&lt;/i&gt;Manage team&lt;/a&gt;
                            &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-calendar&quot;&gt;&lt;/i&gt;My Events&lt;/a&gt;
                            &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-credit-card&quot;&gt;&lt;/i&gt;My Statements&lt;/a&gt;
                        &lt;/div&gt;
                    &lt;/b-card&gt;
                &lt;/b-dropdown&gt;
            &lt;/div&gt;
            
            &lt;b-navbar-toggle class=&quot;navbar-toggler p-0 border-0&quot; target=&quot;mainHeader&quot;&gt;
                &lt;span class=&quot;fa fa-bars&quot;&gt;&lt;/span&gt;
            &lt;/b-navbar-toggle&gt;
                
            &lt;b-collapse class=&quot;navbar-collapse order-0&quot; id=&quot;mainHeader&quot;&gt;
                &lt;b-navbar-nav class=&quot;me-auto&quot;&gt;
                    &lt;b-nav-item&gt;
                        &lt;i class=&quot;fa fa-dashboard me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Dashboard&lt;/span&gt;
                    &lt;/b-nav-item&gt;
                    &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                        &lt;template #button-content&gt;
                                &lt;i class=&quot;fa fa-slack me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Apps&lt;/span&gt;
                        &lt;/template&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Calendar&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Chat app&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Inbox&lt;/b-dropdown-item&gt;
                    &lt;/b-nav-item-dropdown&gt;
                    &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                &lt;i class=&quot;fa fa-file me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Pages&lt;/span&gt;
                        &lt;/template&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Profile&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Timeline&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Image Gallery&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Invoices&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Pricing&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Teams Board&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;FAQs&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Widget&#039;s&lt;/b-dropdown-item&gt;
                    &lt;/b-nav-item-dropdown&gt;
                    &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                &lt;i class=&quot;fa fa-lock me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Authentication&lt;/span&gt;
                        &lt;/template&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Sign in&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Sign up&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Password reset&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;2-Step Authentication&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;404&lt;/b-dropdown-item&gt;
                    &lt;/b-nav-item-dropdown&gt;
                    &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                &lt;i class=&quot;fa fa-file-text me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Docs&lt;/span&gt;
                        &lt;/template&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Stater page&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Documentation&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Changelog&lt;/b-dropdown-item&gt;
                    &lt;/b-nav-item-dropdown&gt;
                &lt;/b-navbar-nav&gt;
            &lt;/b-collapse&gt;
        &lt;/div&gt;
    &lt;/b-navbar&gt;
    &lt;b-collapse class=&quot;border-bottom px-lg-5 px-md-2 collapse bg-primary&quot; id=&quot;main-search&quot;&gt;
        &lt;div class=&quot;container py-4&quot;&gt;

            &lt;div class=&quot;input-group&quot;&gt;
                &lt;input type=&quot;text&quot; class=&quot;form-control border-0 p-0 bg-transparent&quot; placeholder=&quot;Search. Components, Layouts, etc...&quot;&gt;

                &lt;div class=&quot;input-group-append ms-3&quot;&gt;
                    &lt;button class=&quot;btn btn-light&quot; type=&quot;submit&quot;&gt;Search&lt;/button&gt;
                &lt;/div&gt;
            &lt;/div&gt;

        &lt;/div&gt;
    &lt;/b-collapse&gt; 
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>

                 <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-transparent">
                        <div class="header shadow-sm">
                            <b-navbar class="bg-secondary py-2 py-md-3 px-lg-5 px-md-2">
                                <div class="container-fluid">
                                    <a href="index.html" class="me-3 me-lg-4 brand-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 64 80" fill="none">
                                            <path d="M58.8996 22.7L26.9996 2.2C23.4996 -0.0999999 18.9996 0 15.5996 2.5C12.1996 5 10.6996 9.2 11.7996 13.3L15.7996 26.8L3.49962 39.9C-3.30038 47.7 3.79962 54.5 3.89962 54.6L3.99962 54.7L36.3996 78.5C36.4996 78.6 36.5996 78.6 36.6996 78.7C37.8996 79.2 39.1996 79.4 40.3996 79.4C42.9996 79.4 45.4996 78.4 47.4996 76.4C50.2996 73.5 51.1996 69.4 49.6996 65.6L45.1996 51.8L58.9996 39.4C61.7996 37.5 63.3996 34.4 63.3996 31.1C63.4996 27.7 61.7996 24.5 58.8996 22.7ZM46.7996 66.7V66.8C48.0996 69.9 46.8996 72.7 45.2996 74.3C43.7996 75.9 41.0996 77.1 37.9996 76L5.89961 52.3C5.29961 51.7 1.09962 47.3 5.79962 42L16.8996 30.1L23.4996 52.1C24.3996 55.2 26.5996 57.7 29.5996 58.8C30.7996 59.2 31.9996 59.5 33.1996 59.5C35.0996 59.5 36.9996 58.9 38.6996 57.8C38.7996 57.8 38.7996 57.7 38.8996 57.7L42.7996 54.2L46.7996 66.7ZM57.2996 36.9C57.1996 36.9 57.1996 37 57.0996 37L44.0996 48.7L36.4996 25.5V25.4C35.1996 22.2 32.3996 20 28.9996 19.3C25.5996 18.7 22.1996 19.8 19.8996 22.3L18.2996 24L14.7996 12.3C13.8996 8.9 15.4996 6.2 17.3996 4.8C18.4996 4 19.8996 3.4 21.4996 3.4C22.6996 3.4 23.9996 3.7 25.2996 4.6L57.1996 25.1C59.1996 26.4 60.2996 28.6 60.2996 30.9C60.3996 33.4 59.2996 35.6 57.2996 36.9Z" fill="black"></path>
                                        </svg>
                                    </a>
                                    <div class="h-left d-none d-sm-block">
                                        <div class="input-group border rounded">
                                            <b-dropdown text="Fillter" variant="transparent" toggle-class="btn btn-outline-secondary border-0 d-none d-sm-block" :class="{'text-light': currentLayout == 'layout-f'}">
                                                <b-dropdown-item href="#">Action</b-dropdown-item>
                                                <b-dropdown-item href="#">Another action</b-dropdown-item>
                                                <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                                <b-dropdown-divider></b-dropdown-divider>
                                                <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                            </b-dropdown>
                                                <input type="text" class="form-control bg-transparent border-0" placeholder="Search here...">
                                        </div>
                                    </div>
                                    <div class="h-right flex-grow-1 justify-content-end d-flex align-items-center me-lg-0">
                                    <div class="d-flex">
                                        <a class="nav-link text-primary" href="#" title="Settings" v-b-modal.SettingsModal><i class="fa fa-gear"></i></a>
                                        <a class="nav-link text-primary" href="#" v-b-modal.LayoutModal>
                                            <i class="fa fa-sliders"></i>
                                        </a>
                                    </div>
                                    <b-dropdown variant="link" no-caret class="notifications" toggle-class="nav-link pulse" menu-class="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                        <template slot="button-content">
                                            <i class="fa fa-bell"></i>
                                            <span class="pulse-ring"></span>
                                        </template>
                                        <b-card class="border-0 w380" no-body>
                                            <b-tabs content-class="mt-3" nav-wrapper-class="border-0 p-3" card nav-class="border-bottom-0">
                                                <b-tab title="Message" active>
                                                    <ul class="list-unstyled list mb-0" v-if="notificationsObj.messages.length > 0">
                                                        <template v-for="(message,index) in notificationsObj.messages">
                                                            <li class="py-2 mb-1" :class="{'border-bottom': index < (notificationsObj.messages.length-1)}" :key="index">
                                                                <a href="javascript:void(0);" class="d-flex">
                                                                    <img class="avatar rounded-circle" :src="require('@/assets/images/xs/' + message.avtar)" alt="" v-if="message.avtar">
                                                                    <div class="avatar rounded-circle no-thumbnail" v-if="!message.avtar">{{ message.name | short_hand }}</div>
                                                                    <div class="flex-fill ml-3">
                                                                        <p class="d-flex justify-content-between mb-0 text-muted"><span class="font-weight-bold">{{ message.name }}</span> <small>{{ message.time }}</small></p>
                                                                        <span class="text-muted" v-html="message.message"></span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </b-tab>
                                                <b-tab title="Events">
                                                    <ul class="list-unstyled list mb-0" v-if="notificationsObj.events.length > 0">
                                                        <template v-for="(event,index) in notificationsObj.events">
                                                            <li class="py-2 mb-1 " :class="{'border-bottom': index < (notificationsObj.events.length-1)}" :key="index">
                                                                <a href="javascript:void(0);" class="d-flex">
                                                                    <div class="avatar rounded no-thumbnail"><i :class="`fa fa-lg ${event.faIcon}`"></i></div>
                                                                    <div class="flex-fill ml-3">
                                                                        <p class="mb-0 text-muted" v-html="event.event">\</p>
                                                                        <small class="text-muted">{{ event.time }}</small>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                    <h4 v-if="notificationsObj.events.length == 0">No Events right now!</h4>
                                                </b-tab>
                                                <b-tab title="Logs"><ul class="list-unstyled list mb-0" v-if="notificationsObj.logs.length > 0">
                                                    <template v-for="(log,index) in notificationsObj.logs">
                                                        <li class="py-2 mb-1 " :class="{'border-bottom': index < (notificationsObj.logs.length-1)}" :key="index">
                                                            <a href="javascript:void(0);" class="d-flex">
                                                                <div class="avatar rounded no-thumbnail"><i :class="`fa fa-lg ${log.faIcon}`"></i></div>
                                                                <div class="flex-fill ml-3">
                                                                    <p class="mb-0 text-muted" v-html="log.event">\</p>
                                                                    <small class="text-muted">{{ log.time }}</small>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </template>
                                                </ul>
                                                <h4 v-if="notificationsObj.logs.length == 0">No Logs right now!</h4></b-tab>
                                            </b-tabs>
                                            <a class="card-footer text-center border-top-0" href="Javascript:void(0);"> View all notifications</a>
                                        </b-card>
                                    </b-dropdown>
                                    <b-dropdown class="user-profile ms-2 ms-sm-3" toggle-tag="a" no-caret variant="transparent" menu-class="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                        <template #button-content>
                                            <img class="avatar rounded-circle img-thumbnail" src="@/assets/images/profile_av.png" alt="">
                                        </template>
                                        <b-card class="border-0 w240" body-class="border-bottom">
                                                <div class="d-flex py-1">
                                                    <img class="avatar rounded-circle" src="@/assets/images/profile_av.png" alt="">
                                                    <div class="flex-fill ms-3">
                                                        <p class="mb-0 text-muted"><span class="font-weight-bold">Chris Fox</span></p>
                                                        <small class="text-muted">chris.fox@gamil.com</small>
                                                        <div>
                                                            <a href="#" class="card-link">Sign out</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="list-group m-2">
                                                <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-user"></i>Profile &amp; account</a>
                                                <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-gear"></i>Settings</a>
                                                <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-tag"></i>Customization</a>
                                                <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-users"></i>Manage team</a>
                                                <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-calendar"></i>My Events</a>
                                                <a href="#" class="list-group-item list-group-item-action border-0"><i class="w30 fa fa-credit-card"></i>My Statements</a>
                                            </div>
                                        </b-card>
                                    </b-dropdown>
                                    </div>
                                </div>
                            </b-navbar>
                            <div class="sub-header">
                                <b-navbar toggleable="lg" class="navbar-light p-0">
                                    <div class="container-fluid">
                                        <button class="navbar-toggler p-0 border-0" type="button" v-b-toggle.mainHeader>
                                            <span class="fa fa-bars"></span>
                                        </button>
                                        <b-collapse class="navbar-collapse order-0 py-1 py-md-2 px-lg-5 px-md-4" id="mainHeader">
                                            <b-navbar-nav class="me-auto">
                                                <b-nav-item>
                                                    <i class="fa fa-dashboard me-2"></i><span>Dashboard</span>
                                                </b-nav-item>
                                                <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                    <template #button-content>
                                                            <i class="fa fa-slack me-2"></i><span>Apps</span>
                                                    </template>
                                                    <b-dropdown-item href="#">Calendar</b-dropdown-item>
                                                    <b-dropdown-item href="#">Chat app</b-dropdown-item>
                                                    <b-dropdown-item href="#">Inbox</b-dropdown-item>
                                                </b-nav-item-dropdown>
                                                <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                    <template #button-content>
                                                            <i class="fa fa-file me-2"></i><span>Pages</span>
                                                    </template>
                                                    <b-dropdown-item href="#">Profile</b-dropdown-item>
                                                    <b-dropdown-item href="#">Timeline</b-dropdown-item>
                                                    <b-dropdown-item href="#">Image Gallery</b-dropdown-item>
                                                    <b-dropdown-item href="#">Invoices</b-dropdown-item>
                                                    <b-dropdown-item href="#">Pricing</b-dropdown-item>
                                                    <b-dropdown-item href="#">Teams Board</b-dropdown-item>
                                                    <b-dropdown-item href="#">FAQs</b-dropdown-item>
                                                    <b-dropdown-item href="#">Widget's</b-dropdown-item>
                                                </b-nav-item-dropdown>
                                                <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                    <template #button-content>
                                                            <i class="fa fa-lock me-2"></i><span>Authentication</span>
                                                    </template>
                                                    <b-dropdown-item href="#">Sign in</b-dropdown-item>
                                                    <b-dropdown-item href="#">Sign up</b-dropdown-item>
                                                    <b-dropdown-item href="#">Password reset</b-dropdown-item>
                                                    <b-dropdown-item href="#">2-Step Authentication</b-dropdown-item>
                                                    <b-dropdown-item href="#">404</b-dropdown-item>
                                                </b-nav-item-dropdown>
                                                <b-nav-item-dropdown  menu-class="rounded-lg shadow border-0 dropdown-animation">
                                                    <template #button-content>
                                                            <i class="fa fa-file-text me-2"></i><span>Docs</span>
                                                    </template>
                                                    <b-dropdown-item href="#">Stater page</b-dropdown-item>
                                                    <b-dropdown-item href="#">Documentation</b-dropdown-item>
                                                    <b-dropdown-item href="#">Changelog</b-dropdown-item>
                                                </b-nav-item-dropdown>
                                            </b-navbar-nav>
                                            </b-collapse>
                                    </div>
                                </b-navbar>
                            </div>
                        </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3 bg-transparent&quot;&gt;
&lt;div class=&quot;header shadow-sm&quot;&gt;
    &lt;b-navbar class=&quot;bg-secondary py-2 py-md-3 px-lg-5 px-md-2&quot;&gt;
        &lt;div class=&quot;container-fluid&quot;&gt;
            &lt;a href=&quot;index.html&quot; class=&quot;me-3 me-lg-4 brand-icon&quot;&gt;
                &lt;svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;24&quot; viewBox=&quot;0 0 64 80&quot; fill=&quot;none&quot;&gt;
                    &lt;path d=&quot;M58.8996 22.7L26.9996 2.2C23.4996 -0.0999999 18.9996 0 15.5996 2.5C12.1996 5 10.6996 9.2 11.7996 13.3L15.7996 26.8L3.49962 39.9C-3.30038 47.7 3.79962 54.5 3.89962 54.6L3.99962 54.7L36.3996 78.5C36.4996 78.6 36.5996 78.6 36.6996 78.7C37.8996 79.2 39.1996 79.4 40.3996 79.4C42.9996 79.4 45.4996 78.4 47.4996 76.4C50.2996 73.5 51.1996 69.4 49.6996 65.6L45.1996 51.8L58.9996 39.4C61.7996 37.5 63.3996 34.4 63.3996 31.1C63.4996 27.7 61.7996 24.5 58.8996 22.7ZM46.7996 66.7V66.8C48.0996 69.9 46.8996 72.7 45.2996 74.3C43.7996 75.9 41.0996 77.1 37.9996 76L5.89961 52.3C5.29961 51.7 1.09962 47.3 5.79962 42L16.8996 30.1L23.4996 52.1C24.3996 55.2 26.5996 57.7 29.5996 58.8C30.7996 59.2 31.9996 59.5 33.1996 59.5C35.0996 59.5 36.9996 58.9 38.6996 57.8C38.7996 57.8 38.7996 57.7 38.8996 57.7L42.7996 54.2L46.7996 66.7ZM57.2996 36.9C57.1996 36.9 57.1996 37 57.0996 37L44.0996 48.7L36.4996 25.5V25.4C35.1996 22.2 32.3996 20 28.9996 19.3C25.5996 18.7 22.1996 19.8 19.8996 22.3L18.2996 24L14.7996 12.3C13.8996 8.9 15.4996 6.2 17.3996 4.8C18.4996 4 19.8996 3.4 21.4996 3.4C22.6996 3.4 23.9996 3.7 25.2996 4.6L57.1996 25.1C59.1996 26.4 60.2996 28.6 60.2996 30.9C60.3996 33.4 59.2996 35.6 57.2996 36.9Z&quot; fill=&quot;black&quot;&gt;&lt;/path&gt;
                &lt;/svg&gt;
            &lt;/a&gt;
            &lt;div class=&quot;h-left d-none d-sm-block&quot;&gt;
                &lt;div class=&quot;input-group border rounded&quot;&gt;
                    &lt;b-dropdown text=&quot;Fillter&quot; variant=&quot;transparent&quot; toggle-class=&quot;btn btn-outline-secondary border-0 d-none d-sm-block&quot;&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
                        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
                        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
                    &lt;/b-dropdown&gt;
                        &lt;input type=&quot;text&quot; class=&quot;form-control bg-transparent border-0&quot; placeholder=&quot;Search here...&quot;&gt;
                &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class=&quot;h-right flex-grow-1 justify-content-end d-flex align-items-center me-lg-0&quot;&gt;
            &lt;div class=&quot;d-flex&quot;&gt;
                &lt;a class=&quot;nav-link text-primary&quot; href=&quot;#&quot; title=&quot;Settings&quot; v-b-modal.SettingsModal&gt;&lt;i class=&quot;fa fa-gear&quot;&gt;&lt;/i&gt;&lt;/a&gt;
                &lt;a class=&quot;nav-link text-primary&quot; href=&quot;#&quot; v-b-modal.LayoutModal&gt;
                    &lt;i class=&quot;fa fa-sliders&quot;&gt;&lt;/i&gt;
                &lt;/a&gt;
            &lt;/div&gt;
            &lt;b-dropdown variant=&quot;link&quot; no-caret class=&quot;notifications&quot; toggle-class=&quot;nav-link pulse&quot; menu-class=&quot;rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0&quot;&gt;
                &lt;template slot=&quot;button-content&quot;&gt;
                    &lt;i class=&quot;fa fa-bell&quot;&gt;&lt;/i&gt;
                    &lt;span class=&quot;pulse-ring&quot;&gt;&lt;/span&gt;
                &lt;/template&gt;
                &lt;b-card class=&quot;border-0 w380&quot; no-body&gt;
                    &lt;b-tabs content-class=&quot;mt-3&quot; nav-wrapper-class=&quot;border-0 p-3&quot; card nav-class=&quot;border-bottom-0&quot;&gt;
                        &lt;b-tab title=&quot;Message&quot; active&gt;
                            &lt;ul class=&quot;list-unstyled list mb-0&quot; v-if=&quot;notificationsObj.messages.length &gt; 0&quot;&gt;
                                &lt;template v-for=&quot;(message,index) in notificationsObj.messages&quot;&gt;
                                    &lt;li class=&quot;py-2 mb-1&quot; :class=&quot;{&#039;border-bottom&#039;: index &lt; (notificationsObj.messages.length-1)}&quot; :key=&quot;index&quot;&gt;
                                        &lt;a href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
                                            &lt;img class=&quot;avatar rounded-circle&quot; :src=&quot;require(&#039;@/assets/images/xs/&#039; + message.avtar)&quot; alt=&quot;&quot; v-if=&quot;message.avtar&quot;&gt;
                                            &lt;div class=&quot;avatar rounded-circle no-thumbnail&quot; v-if=&quot;!message.avtar&quot;&gt;message.name | short_hand &lt;/div&gt;
                                            &lt;div class=&quot;flex-fill ml-3&quot;&gt;
                                                &lt;p class=&quot;d-flex justify-content-between mb-0 text-muted&quot;&gt;&lt;span class=&quot;font-weight-bold&quot;&gt;message.name &lt;/span&gt; &lt;small&gt;message.time &lt;/small&gt;&lt;/p&gt;
                                                &lt;span class=&quot;text-muted&quot; v-html=&quot;message.message&quot;&gt;&lt;/span&gt;
                                            &lt;/div&gt;
                                        &lt;/a&gt;
                                    &lt;/li&gt;
                                &lt;/template&gt;
                            &lt;/ul&gt;
                        &lt;/b-tab&gt;
                        &lt;b-tab title=&quot;Events&quot;&gt;
                            &lt;ul class=&quot;list-unstyled list mb-0&quot; v-if=&quot;notificationsObj.events.length &gt; 0&quot;&gt;
                                &lt;template v-for=&quot;(event,index) in notificationsObj.events&quot;&gt;
                                    &lt;li class=&quot;py-2 mb-1 &quot; :class=&quot;{&#039;border-bottom&#039;: index &lt; (notificationsObj.events.length-1)}&quot; :key=&quot;index&quot;&gt;
                                        &lt;a href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
                                            &lt;div class=&quot;avatar rounded no-thumbnail&quot;&gt;&lt;i :class=&quot;`fa fa-lg ${event.faIcon}`&quot;&gt;&lt;/i&gt;&lt;/div&gt;
                                            &lt;div class=&quot;flex-fill ml-3&quot;&gt;
                                                &lt;p class=&quot;mb-0 text-muted&quot; v-html=&quot;event.event&quot;&gt;\&lt;/p&gt;
                                                &lt;small class=&quot;text-muted&quot;&gt;event.time &lt;/small&gt;
                                            &lt;/div&gt;
                                        &lt;/a&gt;
                                    &lt;/li&gt;
                                &lt;/template&gt;
                            &lt;/ul&gt;
                            &lt;h4 v-if=&quot;notificationsObj.events.length == 0&quot;&gt;No Events right now!&lt;/h4&gt;
                        &lt;/b-tab&gt;
                        &lt;b-tab title=&quot;Logs&quot;&gt;&lt;ul class=&quot;list-unstyled list mb-0&quot; v-if=&quot;notificationsObj.logs.length &gt; 0&quot;&gt;
                            &lt;template v-for=&quot;(log,index) in notificationsObj.logs&quot;&gt;
                                &lt;li class=&quot;py-2 mb-1 &quot; :class=&quot;{&#039;border-bottom&#039;: index &lt; (notificationsObj.logs.length-1)}&quot; :key=&quot;index&quot;&gt;
                                    &lt;a href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
                                        &lt;div class=&quot;avatar rounded no-thumbnail&quot;&gt;&lt;i :class=&quot;`fa fa-lg ${log.faIcon}`&quot;&gt;&lt;/i&gt;&lt;/div&gt;
                                        &lt;div class=&quot;flex-fill ml-3&quot;&gt;
                                            &lt;p class=&quot;mb-0 text-muted&quot; v-html=&quot;log.event&quot;&gt;\&lt;/p&gt;
                                            &lt;small class=&quot;text-muted&quot;&gt;log.time &lt;/small&gt;
                                        &lt;/div&gt;
                                    &lt;/a&gt;
                                &lt;/li&gt;
                            &lt;/template&gt;
                        &lt;/ul&gt;
                        &lt;h4 v-if=&quot;notificationsObj.logs.length == 0&quot;&gt;No Logs right now!&lt;/h4&gt;&lt;/b-tab&gt;
                    &lt;/b-tabs&gt;
                    &lt;a class=&quot;card-footer text-center border-top-0&quot; href=&quot;Javascript:void(0);&quot;&gt; View all notifications&lt;/a&gt;
                &lt;/b-card&gt;
            &lt;/b-dropdown&gt;
            &lt;b-dropdown class=&quot;user-profile ms-2 ms-sm-3&quot; toggle-tag=&quot;a&quot; no-caret variant=&quot;transparent&quot; menu-class=&quot;rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0&quot;&gt;
                &lt;template #button-content&gt;
                    &lt;img class=&quot;avatar rounded-circle img-thumbnail&quot; src=&quot;@/assets/images/profile_av.png&quot; alt=&quot;&quot;&gt;
                &lt;/template&gt;
                &lt;b-card class=&quot;border-0 w240&quot; body-class=&quot;border-bottom&quot;&gt;
                        &lt;div class=&quot;d-flex py-1&quot;&gt;
                            &lt;img class=&quot;avatar rounded-circle&quot; src=&quot;@/assets/images/profile_av.png&quot; alt=&quot;&quot;&gt;
                            &lt;div class=&quot;flex-fill ms-3&quot;&gt;
                                &lt;p class=&quot;mb-0 text-muted&quot;&gt;&lt;span class=&quot;font-weight-bold&quot;&gt;Chris Fox&lt;/span&gt;&lt;/p&gt;
                                &lt;small class=&quot;text-muted&quot;&gt;chris.fox@gamil.com&lt;/small&gt;
                                &lt;div&gt;
                                    &lt;a href=&quot;#&quot; class=&quot;card-link&quot;&gt;Sign out&lt;/a&gt;
                                &lt;/div&gt;
                            &lt;/div&gt;
                        &lt;/div&gt;
                    &lt;div class=&quot;list-group m-2&quot;&gt;
                        &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-user&quot;&gt;&lt;/i&gt;Profile &amp; account&lt;/a&gt;
                        &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-gear&quot;&gt;&lt;/i&gt;Settings&lt;/a&gt;
                        &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-tag&quot;&gt;&lt;/i&gt;Customization&lt;/a&gt;
                        &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-users&quot;&gt;&lt;/i&gt;Manage team&lt;/a&gt;
                        &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-calendar&quot;&gt;&lt;/i&gt;My Events&lt;/a&gt;
                        &lt;a href=&quot;#&quot; class=&quot;list-group-item list-group-item-action border-0&quot;&gt;&lt;i class=&quot;w30 fa fa-credit-card&quot;&gt;&lt;/i&gt;My Statements&lt;/a&gt;
                    &lt;/div&gt;
                &lt;/b-card&gt;
            &lt;/b-dropdown&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/b-navbar&gt;
    &lt;div class=&quot;sub-header&quot;&gt;
        &lt;b-navbar toggleable=&quot;lg&quot; class=&quot;navbar-light p-0&quot;&gt;
            &lt;div class=&quot;container-fluid&quot;&gt;
                &lt;button class=&quot;navbar-toggler p-0 border-0&quot; type=&quot;button&quot; data-bs-toggle=&quot;collapse&quot; data-bs-target=&quot;mainHeader&quot;&gt;
                    &lt;span class=&quot;fa fa-bars&quot;&gt;&lt;/span&gt;
                &lt;/button&gt;
                &lt;b-collapse class=&quot;navbar-collapse order-0 py-1 py-md-2 px-lg-5 px-md-4&quot; id=&quot;mainHeader&quot;&gt;
                    &lt;b-navbar-nav class=&quot;me-auto&quot;&gt;
                        &lt;b-nav-item&gt;
                            &lt;i class=&quot;fa fa-dashboard me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Dashboard&lt;/span&gt;
                        &lt;/b-nav-item&gt;
                        &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                    &lt;i class=&quot;fa fa-slack me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Apps&lt;/span&gt;
                            &lt;/template&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Calendar&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Chat app&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Inbox&lt;/b-dropdown-item&gt;
                        &lt;/b-nav-item-dropdown&gt;
                        &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                    &lt;i class=&quot;fa fa-file me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Pages&lt;/span&gt;
                            &lt;/template&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Profile&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Timeline&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Image Gallery&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Invoices&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Pricing&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Teams Board&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;FAQs&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Widget&#039;s&lt;/b-dropdown-item&gt;
                        &lt;/b-nav-item-dropdown&gt;
                        &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                    &lt;i class=&quot;fa fa-lock me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Authentication&lt;/span&gt;
                            &lt;/template&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Sign in&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Sign up&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Password reset&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;2-Step Authentication&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;404&lt;/b-dropdown-item&gt;
                        &lt;/b-nav-item-dropdown&gt;
                        &lt;b-nav-item-dropdown  menu-class=&quot;rounded-lg shadow border-0 dropdown-animation&quot;&gt;
                            &lt;template #button-content&gt;
                                    &lt;i class=&quot;fa fa-file-text me-2&quot;&gt;&lt;/i&gt;&lt;span&gt;Docs&lt;/span&gt;
                            &lt;/template&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Stater page&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Documentation&lt;/b-dropdown-item&gt;
                            &lt;b-dropdown-item href=&quot;#&quot;&gt;Changelog&lt;/b-dropdown-item&gt;
                        &lt;/b-nav-item-dropdown&gt;
                    &lt;/b-navbar-nav&gt;
                    &lt;/b-collapse&gt;
            &lt;/div&gt;
        &lt;/b-navbar&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="external-content">External content</h3>
                <p><code>&lt;b-navbar-toggle&gt;</code> renders the default Bootstrap v4 <em>hamburger</em> (which is a background SVG image). You can supply your own content (such as an icon) via the optionally scoped <code>default</code> slot. The default slot scope contains the property <code>expanded</code>, which will be <code>true</code> when the collapse is expanded, or <code>false</code> when the collapse is collapsed.</p>
                <p>Note that the <code>expanded</code> scope property only works when supplying the <code>target</code> prop as a <code>string</code>, and not an <code>array</code>.</p> 
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                    <b-tab title="Preview" active>
                        <b-card>
                            <b-navbar toggleable type="dark" variant="dark">
                                <b-collapse id="navbar-toggle-collapse" is-nav>
                                    <div class="p-4">
                                        <h5 class="text-white h4">Collapsed content</h5>
                                        <span class="text-muted">Toggleable via the navbar brand.</span>
                                    </div>
                                </b-collapse>
                                <div class="container-fluid">
                                    <b-navbar-toggle  target="navbar-toggle-collapse">
                                        <span class="navbar-toggler-icon"></span>
                                    </b-navbar-toggle>
                                </div>
                            </b-navbar>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card&gt;
    &lt;b-navbar toggleable type=&quot;dark&quot; variant=&quot;dark&quot;&gt;
        &lt;b-collapse id=&quot;navbar-toggle-collapse&quot; is-nav&gt;
            &lt;div class=&quot;p-4&quot;&gt;
                &lt;h5 class=&quot;text-white h4&quot;&gt;Collapsed content&lt;/h5&gt;
                &lt;span class=&quot;text-muted&quot;&gt;Toggleable via the navbar brand.&lt;/span&gt;
            &lt;/div&gt;
        &lt;/b-collapse&gt;
        &lt;div class=&quot;container-fluid&quot;&gt;
            &lt;b-navbar-toggle  target=&quot;navbar-toggle-collapse&quot;&gt;
                &lt;span class=&quot;navbar-toggler-icon&quot;&gt;&lt;/span&gt;
            &lt;/b-navbar-toggle&gt;
        &lt;/div&gt;
    &lt;/b-navbar&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

        </div>
    </div>
</template>
<script>
import { EventBus } from '@/js/event-bus.js';
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import notifications from '@/components/admin/data/notifications.json'

export default {
    name:'NavbarComponent',
    data() {
        return {
            currentLayout:'layout-a',
            notificationCounts:15,
            notificationsObj: notifications.notifications,
        }
    },methods:{
        
    },mounted() {
        EventBus.$on('activeLayout', (activeLayout) => {
            this.currentLayout = activeLayout.currentLayout
        });
        Prism.highlightAll();
        const element = document.getElementsByClassName('bg-secondary')[0];
        element.classList.remove("navbar-expand");
    },filters: {
        short_hand (company_name) {
            // You can put your logic here...
            let words = company_name.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand // <-- The return value as Per logic
        }
    }
}
</script>
