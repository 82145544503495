<template>
    <div class="col-12">
        <div class="card mb-4 shadow-sm border-0">
            <div class="card-body">
                <ul class="nav nav-tabs tab-body-header rounded d-inline-flex" role="tablist">
                    <li class="nav-item"><a class="nav-link"  href="#" role="tab" v-on:click="setActiveNavtab('Buttons')" :class="{ 'active': currentMenu == 'Buttons'}">Buttons</a></li>
                    <li class="nav-item"><a class="nav-link" href="#" role="tab" v-on:click="setActiveNavtab('Buttons Groups')" :class="{ 'active': currentMenu == 'Buttons Groups'}">Buttons Groups</a></li>
                </ul>
            </div>
        </div>

        <div class="tab-content">
            <div class="tab-pane fade" id="btn-normal" role="tabpanel" :class="{ 'active show': currentMenu == 'Buttons'}">
                <div class="row justify-content-between">
                    <div class="col-lg-8 col-sm-12">

                        <h2 id="examples">Examples</h2>
                        <p>BootstrapVue's <code>&lt;b-button&gt;</code> component generates either a <code>&lt;button&gt;</code> element, <code>&lt;a&gt;</code> element, or <code>&lt;router-link&gt;</code> component with the styling of a button.</p>
                        <div class="bd-example mb-5">
                            <b-button variant="primary mr-1">Primary</b-button>
                            <b-button variant="secondary mr-1">Secondary</b-button>
                            <b-button variant="success mr-1">Success</b-button>
                            <b-button variant="danger mr-1">Danger</b-button>
                            <b-button variant="warning mr-1">Warning</b-button>
                            <b-button variant="info mr-1">Info</b-button>
                            <b-button variant="light mr-1">Light</b-button>
                            <b-button variant="dark mr-1">Dark</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary mr-1&quot;&gt;Primary&lt;/b-button&gt;
&lt;b-button variant=&quot;secondary mr-1&quot;&gt;Secondary&lt;/b-button&gt;
&lt;b-button variant=&quot;success mr-1&quot;&gt;Success&lt;/b-button&gt;
&lt;b-button variant=&quot;danger mr-1&quot;&gt;Danger&lt;/b-button&gt;
&lt;b-button variant=&quot;warning mr-1&quot;&gt;Warning&lt;/b-button&gt;
&lt;b-button variant=&quot;info mr-1&quot;&gt;Info&lt;/b-button&gt;
&lt;b-button variant=&quot;light mr-1&quot;&gt;Light&lt;/b-button&gt;
&lt;b-button variant=&quot;dark mr-1&quot;&gt;Dark&lt;/b-button&gt;
&lt;b-button variant=&quot;link mr-1&quot;&gt;Link&lt;/b-button&gt;</code>
</pre>
                        </div>
                        
                        <div class="bd-callout bd-callout-info">
                            <h5 id="conveying-meaning-to-assistive-technologies">Conveying meaning to assistive technologies</h5>
                            <p>Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden with the <code>.visually-hidden</code> class.</p>
                        </div>
                        
                        <h2 id="disable-text-wrapping">Disable text wrapping</h2>
                        <p>If you don’t want the button text to wrap, you can add the <code>.text-nowrap</code> class to the button. In Sass, you can set <code>$btn-white-space: nowrap</code> to disable text wrapping for each button.</p>
                        <h2 id="button-tags">Button tags<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#button-tags" style="padding-left: 0.375em;"></a></h2>
                        <p>The <code>.btn</code> classes are designed to be used with the <code>&lt;b-button&gt;</code> element. However, you can also use these classes on <code>&lt;a&gt;</code> or <code>&lt;input&gt;</code> elements (though some browsers may apply a slightly different rendering).</p>
                        <p>When using button classes on <code>&lt;a&gt;</code> elements that are used to trigger in-page functionality (like collapsing content), rather than linking to new pages or sections within the current page, these links should be given a <code>role="button"</code> to appropriately convey their purpose to assistive technologies such as screen readers.</p>
                        <div class="bd-example mb-5">
                            <b-button variant="primary mr-1" href="#">Link</b-button>
                            <b-button variant="primary mr-1">Button</b-button>
                            
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary mr-1&quot; href=&quot;#&quot; role=&quot;button&quot;&gt;Link&lt;/b-button&gt;
&lt;b-button variant=&quot;primary mr-1&quot; type=&quot;submit&quot;&gt;Button&lt;/b-button&gt;</code>
</pre>
                        </div>


                        <h2 id="outline-buttons">Outline buttons</h2>
                        <p>In need of a button, but not the hefty background colors they bring? Use the <code>outline-*</code> variants to remove all background images and colors on any <code>&lt;b-button&gt;</code></p>
                        <div class="bd-example mb-5">
                        
                            <b-button variant="outline-primary mr-1">Primary</b-button>
                            <b-button variant="outline-secondary mr-1">Secondary</b-button>
                            <b-button variant="outline-success mr-1">Success</b-button>
                            <b-button variant="outline-danger mr-1">Danger</b-button>
                            <b-button variant="outline-warning mr-1">Warning</b-button>
                            <b-button variant="outline-info mr-1">Info</b-button>
                            <b-button variant="outline-light mr-1">Light</b-button>
                            <b-button variant="outline-dark mr-1">Dark</b-button>

<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;outline-primary mr-1&quot;&gt;Primary&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-secondary mr-1&quot;&gt;Secondary&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-success mr-1&quot;&gt;Success&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-danger mr-1&quot;&gt;Danger&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-warning mr-1&quot;&gt;Warning&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-info mr-1&quot;&gt;Info&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-light mr-1&quot;&gt;Light&lt;/b-button&gt;
&lt;b-button variant=&quot;outline-dark mr-1&quot;&gt;Dark&lt;/b-button&gt;</code>
</pre>
                        </div>

                        <h2 id="sizes">Sizes</h2>
                        <p>Fancy larger or smaller buttons? Specify <code>lg</code> or <code>sm</code> via the <code>size</code> prop.</p>
                        <div class="bd-example mb-2">
                            <b-button variant="primary mr-1" size="lg">Large Button</b-button>
                            <b-button variant="secondary mr-1" size="lg">Large Button</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary mr-1&quot; size=&quot;lg&quot;&gt;Large button&lt;/b-button&gt;
&lt;b-button variant=&quot;secondary mr-1&quot; size=&quot;lg&quot;&gt;Large button&lt;/b-button&gt;</code>
</pre>
                        </div>                            
                        <div class="bd-example mb-5">
                            <b-button variant="primary mr-1" size="sm">Small Button</b-button>
                            <b-button variant="secondary mr-1" size="sm">Small Button</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary mr-1&quot; size=&quot;sm&quot;&gt;Small button&lt;/b-button&gt;
&lt;b-button variant=&quot;secondary mr-1&quot; size=&quot;sm&quot;&gt;Small button&lt;/b-button&gt;</code>
</pre>
                        </div>
                        
                        <p>Create block level buttons — those that span the full width of a parent — by setting the <code>block</code> prop.</p>
                        <div class="bd-example mb-5">
                            <b-button block variant="primary" size="lg">Block Level Button</b-button>
                             <b-button block variant="secondary" size="lg">Block Level Button</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button size=&quot;lg&quot; variant=&quot;primary&quot;&gt;Block level button&lt;/b-button&gt;
&lt;b-button size=&quot;lg&quot; variant=&quot;secondary&quot;&gt;Block level button&lt;/b-button&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="disabled-state">Disabled state</h2>
                        <p>Set the <code>disabled</code> prop to disable button default functionality. <code>disabled</code> also works with buttons rendered as <code>&lt;a&gt;</code> elements and <code>&lt;router-link&gt;</code> (i.e. with the <code>href</code> or <code>to</code> prop set).</p>
                        <div class="bd-example mb-5">
                            <b-button disabled size="lg" variant="primary mr-1">Disabled</b-button>
                            <b-button disabled size="lg">Also Disabled</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary mr-1&quot; disabled&gt;Primary button&lt;/b-button&gt;
&lt;b-button disabled&gt;Button&lt;/b-button&gt;</code>
</pre>
                        </div>
                        
                        <div class="bd-example mb-5">
                            <b-button disabled size="lg" href="#" variant="primary mr-1">Primary link</b-button>
                            <b-button disabled size="lg" href="#">Link</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button disabled href=&quot;#&quot; variant=&quot;primary&quot;&gt;Primary link&lt;/b-button&gt;
&lt;b-button disabled href=&quot;#&quot;&gt;link&lt;/b-button&gt;</code>
</pre>
                        </div>

                        
                        <!-- <div class="bd-callout bd-callout-warning">
                            <h5 id="link-functionality-caveat">Link functionality caveat</h5>
                            <p>The <code>.disabled</code> class uses <code>pointer-events: none</code> to try to disable the link functionality of <code>&lt;a&gt;</code>s, but that CSS property is not yet standardized. In addition, even in browsers that do support <code>pointer-events: none</code>, keyboard navigation remains unaffected, meaning that sighted keyboard users and users of assistive technologies will still be able to activate these links. So to be safe, in addition to <code>aria-disabled="true"</code>, also include a <code>tabindex="-1"</code> attribute on these links to prevent them from receiving keyboard focus, and use custom JavaScript to disable their functionality altogether.</p>
                        </div> -->
                        
                        <!-- <h2 id="button-plugin">Button plugin</h2>
                        <p>The button plugin allows you to create simple on/off toggle buttons.</p>
                        <div class="card p-4 mb-5 shadow-sm">
                            Visually, these toggle buttons are identical to the <a href="https://v5.getbootstrap.com/docs/5.0/forms/checks-radios/#checkbox-toggle-buttons">checkbox toggle buttons</a>. However, they are conveyed differently by assistive technologies: the checkbox toggles will be announced by screen readers as “checked”/“not checked” (since, despite their appearance, they are fundamentally still checkboxes), whereas these toggle buttons will be announced as “button”/“button pressed”. The choice between these two approaches will depend on the type of toggle you are creating, and whether or not the toggle will make sense to users when announced as a checkbox or as an actual button.
                        </div> -->
                        
                        <h3 id="toggle-states">Toggle states</h3>
                        <p>Set the <code>disabled</code> prop to disable button default functionality. <code>disabled</code> also works with buttons rendered as <code>&lt;a&gt;</code> elements and <code>&lt;router-link&gt;</code> (i.e. with the <code>href</code> or <code>to</code> prop set).</p>
                        <div class="bd-example mb-5">
                            <b-button variant="primary mr-1">Toggle button</b-button>
                            <b-button variant="primary mr-1 active" aria-pressed="true">Active toggle button</b-button>
                            <b-button variant="primary mr-1" disabled>Disabled toggle button</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button variant=&quot;primary mr-1&quot;&gt;Toggle button&lt;/b-button&gt;
&lt;b-button variant=&quot;primary mr-1 active&quot;&gt;Active toggle button&lt;/b-button&gt;
&lt;b-button variant=&quot;primary mr-1&quot; disabled&gt;Disabled toggle button&lt;/b-button&gt;</code>
</pre>
                        </div>
                        
                        <div class="bd-example mb-5">
                            <b-button href="#" variant="primary mr-1">Toggle link</b-button>
                            <b-button href="#" variant="primary mr-1 active" aria-pressed="true">Active toggle link</b-button>
                            <b-button href="#" variant="primary mr-1" disabled>Disabled toggle link</b-button>
<pre>
<code class="language-html" data-lang="html">&lt;b-button href=&quot;#&quot; variant=&quot;primary mr-1&quot;&gt;Toggle link&lt;/b-button&gt;
&lt;b-button href=&quot;#&quot; variant=&quot;primary mr-1 active&quot;&gt;Active toggle link&lt;/b-button&gt;
&lt;b-button href=&quot;#&quot; variant=&quot;primary mr-1&quot; disabled&gt;Disabled toggle link&lt;/b-button&gt;</code>
</pre>
                        </div>


                        <h3 id="methods">Properties</h3>
                        <p>You can create a button instance with the button constructor, for example:</p>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Properties</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><code>aria-label</code></td>
                                    <td>Sets the value of 'aria-label' attribute on the rendered element</td>
                                </tr>
                                <tr>
                                    <td><code>content</code></td>
                                    <td>The content of the close button</td>
                                </tr>
                                <tr>
                                    <td><code>disabled</code></td>
                                    <td>When set to `true`, disables the component's functionality and places it in a disabled state</td>
                                </tr>
                                <tr>
                                    <td><code>text-variant</code></td>
                                    <td>Applies one of the Bootstrap theme color variants to the text</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>For example, to toggle all buttons</p>
                        <div class="bd-example mb-5">
<pre><code class="language-js" data-lang="js"><span class="nb">document</span><span class="p">.</span><span class="nx">querySelectorAll</span><span class="p">(</span><span class="s1">'.btn'</span><span class="p">)</span><span class="p">.</span><span class="nx">forEach</span><span class="p">(</span><span class="kd">el</span>=> <span class="p">{</span>
    <span class="kd">el</span><span class="p">.</span><span class="k">classList</span><span class="p">.</span><span class="nx">toggle</span><span class="p">()</span>
<span class="p">})</span>
</code></pre>
                        </div>

                    </div>
                    <div class="col-lg-3 col-sm-12 d-none d-sm-block">
                        <div class="sticky-lg-top">
                            <strong class="d-block h6 my-2 pb-2 border-bottom">On this page</strong>
                            <nav>
                                <ul>
                                    <li><a href="#examples">Examples</a></li>
                                    <li><a href="#disable-text-wrapping">Disable text wrapping</a></li>
                                    <li><a href="#button-tags">Button tags</a></li>
                                    <li><a href="#outline-buttons">Outline buttons</a></li>
                                    <li><a href="#sizes">Sizes</a></li>
                                    <li><a href="#disabled-state">Disabled state</a></li>
                                    <li><a href="#button-plugin">Button plugin</a>
                                        <ul>
                                            <li><a href="#toggle-states">Toggle states</a></li>
                                            <li><a href="#methods">Methods</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div> <!-- Row end  -->
            </div>

            <div class="tab-pane fade" id="btn-group" role="tabpanel" :class="{ 'active show': currentMenu == 'Buttons Groups'}">
                <div class="row justify-content-between">
                    <div class="col-lg-8 col-sm-12">
                        <h2 id="basic-example">Basic example</h2>
                        <p>Button groups are an easy way to group a series of buttons together.</p>
                        <div class="bd-example mb-5">
                            <b-button-group>
                                <b-button variant="primary">Left</b-button>
                                <b-button variant="primary">Middle</b-button>
                                <b-button variant="primary">Right</b-button>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Left&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Middle&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Right&lt;/b-button&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        
                        <div class="bd-callout bd-callout-warning">
                            <h5 id="ensure-correct-role-and-provide-a-label">Ensure correct <code>role</code> and provide a label</h5>
                            <p>In order for assistive technologies (such as screen readers) to convey that a series of buttons is grouped, an appropriate <code>role</code> attribute needs to be provided. For button groups, this would be <code>role="group"</code>, while toolbars should have a <code>role="toolbar"</code>.</p>
                            <p>In addition, groups and toolbars should be given an explicit label, as most assistive technologies will otherwise not announce them, despite the presence of the correct role attribute. In the examples provided here, we use <code>aria-label</code>, but alternatives such as <code>aria-labelledby</code> can also be used.</p>
                        </div>
                        
                        <p>These classes can also be added to groups of links, as an alternative to the <a href="/docs/5.0/components/navs/"><code>.nav</code> navigation components</a>.</p>
                        <div class="bd-example mb-5">
                            <b-button-group>
                                <b-button active href="#" variant="primary">Active link</b-button>
                                <b-button href="#" variant="primary">Link</b-button>
                                <b-button href="#" variant="primary">Link</b-button>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;b-button active href=&quot;#&quot; variant=&quot;primary&quot;&gt;Active link&lt;/b-button&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Link&lt;/b-button&gt;
    &lt;b-button href=&quot;#&quot; variant=&quot;primary&quot;&gt;Link&lt;/b-button&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="mixed-styles">Mixed styles</h2>
                        <div class="bd-example mb-5">
                            <b-button-group>
                                <b-button variant="danger">Left</b-button>
                                <b-button variant="warning">Middle</b-button>
                                <b-button variant="success">Right</b-button>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;b-button variant=&quot;danger&quot;&gt;Left&lt;/b-button&gt;
    &lt;b-button variant=&quot;warning&quot;&gt;Middle&lt;/b-button&gt;
    &lt;b-button variant=&quot;success&quot;&gt;Right&lt;/b-button&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="outlined-styles">Outlined styles</h2>
                        <div class="bd-example mb-5">
                            <b-button-group>
                                <b-button variant="outline-primary">Left</b-button>
                                <b-button variant="outline-primary">Middle</b-button>
                                <b-button variant="outline-primary">Right</b-button>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;b-button variant=&quot;outline-primary&quot;&gt;Left&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-primary&quot;&gt;Middle&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-primary&quot;&gt;Right&lt;/b-button&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="checkbox-and-radio-button-groups">Checkbox and radio button groups</h2>
                        <p>Combine button-like checkbox and radio <a href="/docs/5.0/forms/checks-radios/">toggle buttons</a> into a seamless looking button group.</p>
                        <div class="bd-example mb-5">
                            <b-button-group>
                                <input type="checkbox" class="btn-check" id="btncheck1">
                                <label class="btn btn-outline-primary rounded-start" for="btncheck1">Checkbox 1</label>
                            
                                <input type="checkbox" class="btn-check" id="btncheck2">
                                <label class="btn btn-outline-primary" for="btncheck2">Checkbox 2</label>
                            
                                <input type="checkbox" class="btn-check" id="btncheck3">
                                <label class="btn btn-outline-primary" for="btncheck3">Checkbox 3</label>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;input type=&quot;checkbox&quot; class=&quot;btn-check&quot; id=&quot;btncheck1&quot;&gt;
    &lt;label class=&quot;btn btn-outline-primary rounded-start&quot; for=&quot;btncheck1&quot;&gt;Checkbox 1&lt;/label&gt;

    &lt;input type=&quot;checkbox&quot; class=&quot;btn-check&quot; id=&quot;btncheck2&quot;&gt;
    &lt;label class=&quot;btn btn-outline-primary&quot; for=&quot;btncheck2&quot;&gt;Checkbox 2&lt;/label&gt;

    &lt;input type=&quot;checkbox&quot; class=&quot;btn-check&quot; id=&quot;btncheck3&quot;&gt;
    &lt;label class=&quot;btn btn-outline-primary&quot; for=&quot;btncheck3&quot;&gt;Checkbox 3&lt;/label&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>                                    
                        <div class="bd-example mb-5">
                            <b-button-group>
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" checked="">
                                <label class="btn btn-outline-primary rounded-start" for="btnradio1">Radio 1</label>
                            
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2">
                                <label class="btn btn-outline-primary" for="btnradio2">Radio 2</label>
                            
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio3">
                                <label class="btn btn-outline-primary" for="btnradio3">Radio 3</label>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;input type=&quot;radio&quot; class=&quot;btn-check&quot; name=&quot;btnradio&quot; id=&quot;btnradio1&quot; checked=&quot;&quot;&gt;
    &lt;label class=&quot;btn btn-outline-primary rounded-start&quot; for=&quot;btnradio1&quot;&gt;Radio 1&lt;/label&gt;

    &lt;input type=&quot;radio&quot; class=&quot;btn-check&quot; name=&quot;btnradio&quot; id=&quot;btnradio2&quot;&gt;
    &lt;label class=&quot;btn btn-outline-primary&quot; for=&quot;btnradio2&quot;&gt;Radio 2&lt;/label&gt;

    &lt;input type=&quot;radio&quot; class=&quot;btn-check&quot; name=&quot;btnradio&quot; id=&quot;btnradio3&quot;&gt;
    &lt;label class=&quot;btn btn-outline-primary&quot; for=&quot;btnradio3&quot;&gt;Radio 3&lt;/label&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>                                    
                        
                        <h2 id="button-toolbar">Button toolbar</h2>
                        <p>Combine sets of button groups into button toolbars for more complex components. Use utility classes as needed to space out groups, buttons, and more.</p>
                        <div class="bd-example mb-5">
                            <b-button-toolbar>
                                <b-button-group>
                                    <b-button variant="primary">1</b-button>
                                    <b-button variant="primary">2</b-button>
                                    <b-button variant="primary">3</b-button>
                                    <b-button variant="primary mr-1">4</b-button>
                                </b-button-group>
                                <b-button-group>
                                    <b-button>5</b-button>
                                    <b-button>6</b-button>
                                    <b-button>7</b-button>
                                </b-button-group>
                                    <b-button-group>
                                    <b-button variant="info ml-1">8</b-button>
                                </b-button-group>
                            </b-button-toolbar>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-toolbar&gt;
&lt;b-button-group&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;1&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;2&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;3&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary mr-1&quot;&gt;4&lt;/b-button&gt;
&lt;/b-button-group&gt;
&lt;b-button-group&gt;
    &lt;b-button&gt;5&lt;/b-button&gt;
    &lt;b-button&gt;6&lt;/b-button&gt;
    &lt;b-button&gt;7&lt;/b-button&gt;
&lt;/b-button-group&gt;
    &lt;b-button-group&gt;
    &lt;b-button variant=&quot;info ml-1&quot;&gt;8&lt;/b-button&gt;
&lt;/b-button-group&gt;
&lt;/b-button-toolbar&gt;</code>
</pre>
                        </div>
                        
                        <p>Feel free to mix input groups with button groups in your toolbars. Similar to the example above, you’ll likely need some utilities though to space things properly.</p>
                        <div class="bd-example mb-5">
                            <b-button-toolbar>
                                <b-button-group>
                                    <b-button variant="outline-secondary">1</b-button>
                                    <b-button variant="outline-secondary">2</b-button>
                                    <b-button variant="outline-secondary">3</b-button>
                                    <b-button variant="outline-secondary mr-1">4</b-button>
                                </b-button-group>
                                <b-input-group prepend="@">
                                    <b-form-input type="text" placeholder="Input group example"></b-form-input>
                                </b-input-group>
                            </b-button-toolbar>
                            
                            <b-button-toolbar class="justify-content-between mt-3" role="toolbar">
                                <b-button-group>
                                    <b-button variant="outline-secondary">1</b-button>
                                    <b-button variant="outline-secondary">2</b-button>
                                    <b-button variant="outline-secondary">3</b-button>
                                    <b-button variant="outline-secondary">4</b-button>
                                </b-button-group>
                                <b-input-group prepend="@">
                                    <b-form-input type="text" placeholder="Input group example"></b-form-input>
                                </b-input-group>
                            </b-button-toolbar>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-toolbar&gt;
&lt;b-button-group&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;1&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;2&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;3&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-secondary mr-1&quot;&gt;4&lt;/b-button&gt;
&lt;/b-button-group&gt;
&lt;b-input-group prepend=&quot;@&quot;&gt;
    &lt;b-form-input type=&quot;text&quot; placeholder=&quot;Input group example&quot;&gt;&lt;/b-form-input&gt;
&lt;/b-input-group&gt;
&lt;/b-button-toolbar&gt;

&lt;b-button-toolbar class=&quot;justify-content-between mt-3&quot; role=&quot;toolbar&quot;&gt;
&lt;b-button-group&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;1&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;2&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;3&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-secondary&quot;&gt;4&lt;/b-button&gt;
&lt;/b-button-group&gt;
&lt;b-input-group prepend=&quot;@&quot;&gt;
    &lt;b-form-input type=&quot;text&quot; placeholder=&quot;Input group example&quot;&gt;&lt;/b-form-input&gt;
&lt;/b-input-group&gt;
&lt;/b-button-toolbar&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="sizing">Sizing</h2>
                       <p>Set the size prop to <code>lg</code> or <code>sm</code> to render larger or smaller, respectively, buttons. There is no need to specify the size on the individual buttons.</p>
                        <div class="bd-example mb-5">
                            <b-button-group size="lg">
                                <b-button variant="outline-dark">Left</b-button>
                                <b-button variant="outline-dark">Middle</b-button>
                                <b-button variant="outline-dark">Right</b-button>
                            </b-button-group>
                            <div class="mt-2"></div>
                            <b-button-group>
                                <b-button variant="outline-dark">Button 1</b-button>
                                <b-button variant="outline-dark">Button 2</b-button>
                                <b-button variant="outline-dark">Button 3</b-button>
                            </b-button-group>
                            <div class="mt-2"></div>
                            <b-button-group size="sm">
                                <b-button variant="outline-dark">Left</b-button>
                                <b-button variant="outline-dark">Middle</b-button>
                                <b-button variant="outline-dark">Right</b-button>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group size=&quot;lg&quot;&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Left&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Middle&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Right&lt;/b-button&gt;
&lt;/b-button-group&gt;
&lt;div class=&quot;mt-2&quot;&gt;&lt;/div&gt;
&lt;b-button-group&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Button 1&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Button 2&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Button 3&lt;/b-button&gt;
&lt;/b-button-group&gt;
&lt;div class=&quot;mt-2&quot;&gt;&lt;/div&gt;
&lt;b-button-group size=&quot;sm&quot;&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Left&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Middle&lt;/b-button&gt;
    &lt;b-button variant=&quot;outline-dark&quot;&gt;Right&lt;/b-button&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="nesting">Nesting</h2>
                        <p>Place a <code>.btn-group</code> within another <code>.btn-group</code> when you want dropdown menus mixed with a series of buttons.</p>
                        <div class="bd-example mb-5">
                            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <b-button-group>
                                    <b-button variant="primary">1</b-button>
                                    <b-button variant="primary">2</b-button>
                                    <b-dropdown text="Dropdown" variant="primary">
                                        <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                        <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                    </b-dropdown>
                                </b-button-group>
                            </div>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;1&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;2&lt;/b-button&gt;
    &lt;b-dropdown text=&quot;Dropdown&quot; variant=&quot;primary&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        
                        <h2 id="vertical-variation">Vertical variation<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#vertical-variation" style="padding-left: 0.375em;"></a></h2>
                        <p>Make a set of buttons appear vertically stacked rather than horizontally by setting the vertical prop. Split button dropdowns are not supported here.</p>
                        <div class="bd-example mb-5">
                            <b-button-group vertical>
                                <b-button variant="dark">Button</b-button>
                                <b-button variant="dark">Button</b-button>
                                <b-button variant="dark">Bottom</b-button>
                                <b-button variant="dark">Button</b-button>
                                <b-button variant="dark">Button</b-button>
                                <b-button variant="dark">Bottom</b-button>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group vertical&gt;
    &lt;b-button variant=&quot;dark&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-button variant=&quot;dark&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-button variant=&quot;dark&quot;&gt;Bottom&lt;/b-button&gt;
    &lt;b-button variant=&quot;dark&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-button variant=&quot;dark&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-button variant=&quot;dark&quot;&gt;Bottom&lt;/b-button&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>

                        <div class="bd-example mb-5">
                            <b-button-group vertical>
                                <b-button variant="primary">Button</b-button>
                                <b-button variant="primary">Button</b-button>
                                <b-dropdown text="Dropdown " variant="primary" toggle-class="rounded-0">
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                </b-dropdown>
                                <b-button variant="primary">Button</b-button>
                                <b-button variant="primary">Button</b-button>
                                    <b-dropdown text="Dropdown " variant="primary" toggle-class="rounded-0">
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                </b-dropdown>
                                    <b-dropdown text="Dropdown " variant="primary" toggle-class="rounded-0">
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                </b-dropdown>
                                    <b-dropdown text="Dropdown " variant="primary" toggle-class="rounded-0 rounded-bottom">
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                    <b-dropdown-item href="#">Dropdown link</b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group vertical&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-dropdown text=&quot;Dropdown &quot; variant=&quot;primary&quot; toggle-class=&quot;rounded-0&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Button&lt;/b-button&gt;
    &lt;b-button variant=&quot;primary&quot;&gt;Button&lt;/b-button&gt;
        &lt;b-dropdown text=&quot;Dropdown &quot; variant=&quot;primary&quot; toggle-class=&quot;rounded-0&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
        &lt;b-dropdown text=&quot;Dropdown &quot; variant=&quot;primary&quot; toggle-class=&quot;rounded-0&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
        &lt;b-dropdown text=&quot;Dropdown &quot; variant=&quot;primary&quot; toggle-class=&quot;rounded-0 rounded-bottom&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Dropdown link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>
                        <div class="bd-example">
                            <b-button-group vertical>
                                <input type="radio" class="btn-check" name="vbtn-radio" id="vbtn-radio1" autocomplete="off" checked="">
                                <label class="btn btn-outline-danger mb-0 rounded-top" for="vbtn-radio1">Radio 1</label>
                                <input type="radio" class="btn-check" name="vbtn-radio" id="vbtn-radio2" autocomplete="off">
                                <label class="btn btn-outline-danger mb-0" for="vbtn-radio2">Radio 2</label>
                                <input type="radio" class="btn-check" name="vbtn-radio" id="vbtn-radio3" autocomplete="off">
                                <label class="btn btn-outline-danger mb-0" for="vbtn-radio3">Radio 3</label>
                            </b-button-group>
<pre>
<code class="language-html" data-lang="html">&lt;b-button-group vertical&gt;
    &lt;input type=&quot;radio&quot; class=&quot;btn-check&quot; name=&quot;vbtn-radio&quot; id=&quot;vbtn-radio1&quot; autocomplete=&quot;off&quot; checked=&quot;&quot;&gt;
    &lt;label class=&quot;btn btn-outline-danger mb-0 rounded-top&quot; for=&quot;vbtn-radio1&quot;&gt;Radio 1&lt;/label&gt;
    &lt;input type=&quot;radio&quot; class=&quot;btn-check&quot; name=&quot;vbtn-radio&quot; id=&quot;vbtn-radio2&quot; autocomplete=&quot;off&quot;&gt;
    &lt;label class=&quot;btn btn-outline-danger mb-0&quot; for=&quot;vbtn-radio2&quot;&gt;Radio 2&lt;/label&gt;
    &lt;input type=&quot;radio&quot; class=&quot;btn-check&quot; name=&quot;vbtn-radio&quot; id=&quot;vbtn-radio3&quot; autocomplete=&quot;off&quot;&gt;
    &lt;label class=&quot;btn btn-outline-danger mb-0&quot; for=&quot;vbtn-radio3&quot;&gt;Radio 3&lt;/label&gt;
&lt;/b-button-group&gt;</code>
</pre>
                        </div>

                    </div>
                    <div class="col-lg-3 col-sm-12 d-none d-sm-block">
                        <div class="sticky-lg-top">
                            <strong class="d-block h6 my-2 pb-2 border-bottom">On this page</strong>
                            <nav>
                                <ul>
                                    <li><a href="#basic-example">Basic example</a></li>
                                    <li><a href="#mixed-styles">Mixed styles</a></li>
                                    <li><a href="#outlined-styles">Outlined styles</a></li>
                                    <li><a href="#checkbox-and-radio-button-groups">Checkbox and radio button groups</a></li>
                                    <li><a href="#button-toolbar">Button toolbar</a></li>
                                    <li><a href="#sizing">Sizing</a></li>
                                    <li><a href="#nesting">Nesting</a></li>
                                    <li><a href="#vertical-variation">Vertical variation</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div> <!-- Row end  -->
                </div> <!-- Row end  -->
            </div>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'ButtonsComponent',
    data() {
        return {
            currentMenu: 'Buttons',
        }
    },methods:{
        setActiveNavtab: function (menu) {
            this.currentMenu = menu 
        },
    },mounted() {
        Prism.highlightAll()
    }
}
</script>
<style scoped>

</style>