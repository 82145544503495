<template>
    <div class="col-12">
        <div class="row justify-content-between">
            <div class="col-lg-8 col-sm-12">
                <h2 id="how-it-works">How it works</h2>
                <p>By providing a document unique value via the  <code>id</code>, <code>b-carousel</code> will enable accessibility features. It is highly recommended to always add an ID to all components.</p>
                <p>All carousel controls and indicators have aria labels. These can be customized by setting the various <code>label-*</code> props.</p>
                <div class="card card-callout mb-3">
                    <div class="card-body">
                        The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/reference/accessibility#reduced-motion">reduced motion section of our accessibility documentation</a>.
                    </div>
                </div>
                
                <p>Please be aware that nested carousels are not supported, and carousels are generally not compliant with accessibility standards.</p>
                <h2 id="example">Example</h2>
                <p>Carousels don’t automatically normalize slide dimensions. As such, you may need to use additional utilities or custom styles to appropriately size content. While carousels support previous/next controls and indicators, they’re not explicitly required. Add and customize as you see fit.</p>
                
                <h3 id="slides-only">Slides only</h3>
                <p>Here’s a carousel with slides only.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel :controls="false" :indicators="false">
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/1.jpg')"></b-carousel-slide>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/10.jpg')"></b-carousel-slide>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/8.jpg')"></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-carousel :controls=&quot;false&quot; :indicators=&quot;false&quot;&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/1.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/10.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/8.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="with-controls">With controls</h3>
                <p>Adding in the previous and next controls:</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel controls :indicators="false">
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/1.jpg')"></b-carousel-slide>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/10.jpg')"></b-carousel-slide>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/8.jpg')"></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html"> &lt;b-card&gt;
    &lt;b-carousel controls :indicators=&quot;false&quot;&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/1.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/10.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/8.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="with-indicators">With indicators</h3>
                <p>You can also add the indicators to the carousel, alongside the controls, too.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel controls indicators>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/1.jpg')"></b-carousel-slide>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/10.jpg')"></b-carousel-slide>
                            <b-carousel-slide :img-src="require('@/assets/images/gallery/8.jpg')"></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-carousel controls indicators&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/1.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/10.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide :img-src=&quot;assets/images/gallery/8.jpg&quot;&gt;&lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="with-captions">With captions</h3>
                <p>On smaller screens you may want to hide the captions and headings. You can do so via the <code>content-visible-up</code> prop of <code>&lt;b-carousel-slide&gt;</code>. The prop accepts a breakpoint name (i.e. <code>sm</code>, <code>md</code>, <code>lg</code>, <code>xl</code>, or custom breakpoint names if you have defined them), and will hide the headings and captions on screens <em>smaller</em> than the breakpoint.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel fade indicators>
                            <b-carousel-slide
                            caption="First slide"
                            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                            :img-src="require('@/assets/images/gallery/1.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            caption="Second Slide"
                            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                            :img-src="require('@/assets/images/gallery/2.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            caption="Third Slide"
                            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                            :img-src="require('@/assets/images/gallery/3.jpg')"
                            ></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-carousel fade indicators&gt;
        &lt;b-carousel-slide
            caption=&quot;First slide&quot;
            text=&quot;Nulla vitae elit libero, a pharetra augue mollis interdum.&quot;
            :img-src=&quot;assets/images/gallery/1.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            caption=&quot;Second Slide&quot;
            text=&quot;Nulla vitae elit libero, a pharetra augue mollis interdum.&quot;
            :img-src=&quot;assets/images/gallery/2.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            caption=&quot;Third Slide&quot;
            text=&quot;Nulla vitae elit libero, a pharetra augue mollis interdum.&quot;
            :img-src=&quot;assets/images/gallery/3.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="crossfade">Crossfade</h3>
                <p>Set the <code>&lt;b-carousel&gt;</code> <code>fade</code> prop to <code>true</code> to animate slides with a fade transition instead of the default slide animation.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel fade controls>
                            <b-carousel-slide
                            :img-src="require('@/assets/images/gallery/10.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            :img-src="require('@/assets/images/gallery/8.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            :img-src="require('@/assets/images/gallery/1.jpg')"
                            ></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-carousel fade controls&gt;
        &lt;b-carousel-slide
            :img-src=&quot;assets/images/gallery/10.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            :img-src=&quot;assets/images/gallery/8.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            :img-src=&quot;assets/images/gallery/1.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h3 id="individual-carousel-item-interval">Individual <code>.carousel-item</code> interval</h3>
                <p><code>interval</code> props is change to the amount of time to delay between automatically cycling to the next item.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel fade controls  :interval="4000">
                            <b-carousel-slide
                            :img-src="require('@/assets/images/gallery/2.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            :img-src="require('@/assets/images/gallery/5.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            :img-src="require('@/assets/images/gallery/7.jpg')"
                            ></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-carousel fade controls  :interval=&quot;4000&quot;&gt;
        &lt;b-carousel-slide
            :img-src=&quot;assets/images/gallery/2.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            :img-src=&quot;assets/images/gallery/5.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            :img-src=&quot;assets/images/gallery/7.jpg&quot;
        &gt;&lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h2 id="dark-variant">Dark variant</h2>
                <p>Add <code>.carousel-dark</code> to the <code>.carousel</code> for darker controls, indicators, and captions. Controls have been inverted from their default white fill with the <code>filter</code> CSS property. Captions and controls have additional Sass variables that customize the <code>color</code> and <code>background-color</code>.</p>
                <div class="bd-example mb-5">
                    <b-card>
                        <b-carousel fade controls indicators class="carousel-dark">
                            <b-carousel-slide
                            caption="First slide"
                            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                            :img-src="require('@/assets/images/gallery/3.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            caption="Second Slide"
                            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                            :img-src="require('@/assets/images/gallery/8.jpg')"
                            ></b-carousel-slide>
                            <b-carousel-slide
                            caption="Third Slide"
                            text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                            :img-src="require('@/assets/images/gallery/6.jpg')"
                            ></b-carousel-slide>
                        </b-carousel>
                    </b-card>
<pre>
<code class="language-html" data-lang="html">&lt;b-card&gt;
    &lt;b-carousel fade controls indicators class=&quot;carousel-dark&quot;&gt;
        &lt;b-carousel-slide
            caption=&quot;First slide&quot;
            text=&quot;Nulla vitae elit libero, a pharetra augue mollis interdum.&quot;
            :img-src=&quot;assets/images/gallery/3.jpg&quot;&gt;
        &lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            caption=&quot;Second Slide&quot;
            text=&quot;Nulla vitae elit libero, a pharetra augue mollis interdum.&quot;
            :img-src=&quot;assets/images/gallery/8.jpg&quot;&gt;
        &lt;/b-carousel-slide&gt;
        &lt;b-carousel-slide
            caption=&quot;Third Slide&quot;
            text=&quot;Nulla vitae elit libero, a pharetra augue mollis interdum.&quot;
            :img-src=&quot;assets/images/gallery/6.jpg&quot;&gt;
        &lt;/b-carousel-slide&gt;
    &lt;/b-carousel&gt;
&lt;/b-card&gt;</code>
</pre>
                </div>
                
                <h2 id="usage">Usage</h2>
                <div class="bd-example mb-5" id="via-javascript">
<pre><code class="language-js" data-lang="js">import { CarouselPlugin } from &#039;bootstrap-vue&#039;
Vue.use(CarouselPlugin)</code></pre>
                </div>
                
                <h3 id="options">Properties</h3>
                <p>Properties can be passed via props.</p>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 100px;">Properties</th>
                            <th style="width: 50px;">Type</th>
                            <th style="width: 50px;">Default</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>interval</code></td>
                            <td>number</td>
                            <td><code>5000</code></td>
                            <td>Set the delay time (in milliseconds) between slides.</td>
                        </tr>
                        <tr>
                            <td><code>indicators</code></td>
                            <td>Boolean</td>
                            <td><code>false</code></td>
                            <td>Enable the indicator buttons for jumping to specific slides.</td>
                        </tr>
                        <tr>
                            <td><code>controls</code></td>
                            <td>Boolean</td>
                            <td><code>false</code></td>
                            <td>Enable the previous and next controls</td>
                        </tr>
                        <tr>
                            <td><code>fade</code></td>
                            <td>Boolean</td>
                            <td><code>false</code></td>
                            <td>When set, changes the slide animation to a crossfade instead of a sliding effect</td>
                        </tr>
                        <tr>
                            <td><code>no-wrap</code></td>
                            <td>Boolean</td>
                            <td><code>false</code></td>
                            <td>Do not restart the slide show when then end is reached</td>
                        </tr>
                        <tr>
                            <td><code>no-hover-pause</code></td>
                            <td>Boolean</td>
                            <td><code>false</code></td>
                            <td>When set, disables the pausing of the slide show when the current slide is hovered</td>
                        </tr>
                    </tbody>
                </table>
                
                <h3 id="methods">Methods</h3>
                <div class="card card-callout">
                    <div class="card-body">
                        <h4 id="asynchronous-methods-and-transitions">Asynchronous methods and transitions</h4>
                        <p>All API methods are <strong>asynchronous</strong> and start a <strong>transition</strong>. They return to the caller as soon as the transition is started but <strong>before it ends</strong>. In addition, a method call on a <strong>transitioning component will be ignored</strong>.</p>
                        <p><a href="https://bootstrap-vue.org/docs/components/carousel">See our documentation for more information</a>.</p>
                    </div>
                </div>
                
                <p>You can create a manually methods for example</p>
                <div class="bd-example mb-5">
<pre><code class="language-js" data-lang="js">prev() {
    this.$refs.myCarousel.prev()
},
next() {
    this.$refs.myCarousel.next()
}
</code></pre>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Method</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>setSlide(index)</code></td>
                            <td>Go to slide specified by index.</td>
                        </tr>
                        <tr>
                            <td><code>next()</code></td>
                            <td>Go to next slide.</td>
                        </tr>
                        <tr>
                            <td><code>prev()</code></td>
                            <td>Go to previous slide.</td>
                        </tr>
                        <tr>
                            <td><code>pause()</code></td>
                            <td>Pause the slide cycling</td>
                        </tr>
                        <tr>
                            <td><code>start()	</code></td>
                            <td>Start slide cycling (prop <code>interval </code> must have a value).</td>
                        </tr>
                    </tbody>
                </table>
                
                <h3 id="methods">Events</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Events</th>
                            <th>Arguments</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>sliding-end</code></td>
                            <td><code>slide</code> - Slide number that was slid to</td>
                            <td>Emitted when transitioning to a new slide has ended</td>
                        </tr>
                        <tr>
                            <td><code>sliding-start	</code></td>
                            <td><code>slide</code> - Slide number that is being slid to</td>
                            <td>Emitted when transitioning to a new slide has started</td>
                        </tr>
                    </tbody>
                </table>
                <h3 id="change-transition-duration">Change transition duration</h3>
                <p>The transition duration of <code>.carousel-item</code> can be changed with the <code>$carousel-transition</code> Sass variable before compiling or custom styles if you’re using the compiled CSS. If multiple transitions are applied, make sure the transform transition is defined first (eg. <code>transition: transform 2s ease, opacity .5s ease-out</code>).</p>
                
            </div>
            <div class="col-lg-3 col-sm-12 d-none d-sm-block">
                <div class="sticky-lg-top">
                    <strong class="d-block h6 my-2 pb-2 border-bottom">On this page</strong>
                    <nav>
                        <ul>
                            <li><a href="#how-it-works">How it works</a></li>
                            <li><a href="#example">Example</a>
                                <ul>
                                    <li><a href="#slides-only">Slides only</a></li>
                                    <li><a href="#with-controls">With controls</a></li>
                                    <li><a href="#with-indicators">With indicators</a></li>
                                    <li><a href="#with-captions">With captions</a></li>
                                    <li><a href="#crossfade">Crossfade</a></li>
                                    <li><a href="#individual-carousel-item-interval">Individual <code>.carousel-item</code> interval</a></li>
                                </ul>
                            </li>
                            <li><a href="#dark-variant">Dark variant</a></li>
                            <li><a href="#usage">Usage</a>
                                <ul>
                                    <li><a href="#via-javascript">Via Plugin</a></li>
                                    <li><a href="#options">Properties</a></li>
                                    <li><a href="#methods">Methods</a></li>
                                    <li><a href="#events">Events</a></li>
                                    <li><a href="#change-transition-duration">Change transition duration</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div> <!-- Row end  -->
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'CarouselComponent',
    mounted() {
        Prism.highlightAll()
    }
}
</script>
<style lang="scss">

</style>