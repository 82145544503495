<template>
    <b-modal id="SearchModal" tabindex="-1" title="Search" scrollable dialog-class="modal-dialog-vertical"  header-class="modal-header bg-secondary border-bottom-0 px-3 px-md-5" modal-class="modal-dialog-vertical" :hide-footer="true" :header-bg-variant="headerBgVariant">
        <template v-slot:modal-header-close>
            <button type="button" class="btn btn-close"></button>
        </template>
        <div class="card-body-height py-4 px-2 px-md-4">
            <form class="mb-3">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Search...">
                    <button class="btn btn-outline-secondary" type="button"><span class="fa fa-search"></span> Search</button>
                </div>
            </form>

                <small class="dropdown-header">Recent searches</small>              
            <div class="dropdown-item bg-transparent text-wrap my-2">
                <span class="h4 mr-1">
                    <a class="btn btn-sm btn-dark" href="#">Github <i class="fa fa-search ml-1"></i></a>
                </span>
                <span class="h4 mr-1">
                    <a class="btn btn-sm btn-dark" href="#">Notification panel <i class="fa fa-search ml-1"></i></a>
                </span>
                <span class="h4">
                    <a class="btn btn-sm btn-dark" href="#">New project <i class="fa fa-search ml-1"></i></a>
                </span>
            </div>

            <div class="dropdown-divider my-3"></div>
            
            <small class="dropdown-header">Tutorials</small>              
            <a class="dropdown-item py-2" href="#">
                <div class="d-flex align-items-center">
                    <span class="avatar sm no-thumbnail me-2"><i class="fa fa-github"></i></span>
                    <div class="text-truncate">
                        <span>How to set up Github?</span>
                    </div>
                </div>
            </a>              
            <a class="dropdown-item py-2" href="#">
                <div class="d-flex align-items-center">
                    <span class="avatar sm no-thumbnail me-2"><i class="fa fa-paint-brush"></i></span>
                    <div class="text-truncate">
                        <span>How to change theme color?</span>
                    </div>
                </div>
            </a>

            <div class="dropdown-divider my-3"></div>

            <small class="dropdown-header">Members</small>              
            <a class="dropdown-item py-2" href="#">
                <div class="d-flex align-items-center">
                    <img class="avatar sm rounded-circle" src="../../../assets/images/xs/avatar1.jpg" alt="">
                    <div class="text-truncate ms-2">
                        <span>Robert Hammer <i class="fa fa-check-circle text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Top endorsed"></i></span>
                    </div>
                </div>
            </a>              
            <a class="dropdown-item py-2" href="#">
                <div class="d-flex align-items-center">
                    <img class="avatar sm rounded-circle" src="../../../assets/images/xs/avatar2.jpg" alt="">
                    <div class="text-truncate ms-2">
                        <span>Orlando Lentz</span>
                    </div>
                </div>
            </a>              
            <a class="dropdown-item py-2" href="#">
                <div class="d-flex align-items-center">
                    <div class="avatar sm rounded-circle no-thumbnail">RH</div>
                    <div class="text-truncate ms-2">
                        <span>Brian Swader</span>
                    </div>
                </div>
            </a>
        </div>
    </b-modal>
</template>
<script>
export default {
    name:"SearchMComponent",
    data(){
        return{
            headerBgVariant: 'warning',
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep #SearchModal > .modal-dialog {
        max-height: 100%;
    }  
</style>
