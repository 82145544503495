<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card p-4 mb-4">
        <div class="row py-3 justify-content-between">
          <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
            <div>
              <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Asiento contable" />
            </div>
            <card-actions></card-actions>
          </div>
          <div class="col-md-5">
            <b-form-group label="por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row" v-if="this.account != undefined">
          <div class="form-group row">
            <label for="fechai" class="col-sm-2 col-form-label">Desde:</label>
            <div class="col-sm-3">
              <input class="form-control" type="date" v-model="fecha_ini" name="" id="fechai" />
            </div>
            <label for="fechaf" class="col-sm-2 col-form-label">Hasta:</label>
            <div class="col-sm-3">
              <input class="form-control" type="date" v-model="fecha_fin" name="" id="fechaf" />
            </div>
            <input @click="fitrarFecha()" type="button" class="btn btn-primary col-sm-2 col-md-2" value="Filtrar" />

          </div>
        </div>
        <div class="row" v-if="this.account != undefined">
          <div class="col-md-4">
            <label class="text-center" style="color:blue;font-weight: bold;" for="">INGRESOS: $ {{ ingresos.toFixed(2) }}</label>
          </div>
          <div class="col-md-4">
            <label class="text-center" style="color:red;font-weight: bold;" for="">GASTOS: $ {{ gastos.toFixed(2) }}</label>
          </div>
          <div class="col-md-4">
            <label class="text-center" :style="'color:' + (ingresos > gastos ? ' green ' : ' red ') + ';font-weight: bold;'" for="">SALDO: $ {{ saldo.toFixed(2) }}</label>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha_alta)="data">
            <span>{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(fecha)="data">
            <span>{{ data.item.fecha != null ? $moment(data.item.fecha).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(venta)="data">
            <span>{{ data.item.venta != null ? Number(data.item.venta).toFixed(2) : "0.00" }}</span>
          </template>
          <template #cell(gasto)="data">
            <span>{{ data.item.gasto != null ? Number(data.item.gasto).toFixed(2) : "0.00" }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1"><i class="fa fa-pencil"></i></button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash"></i></button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-secondary mr-1" v-b-modal.ledgerModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-secondary mr-1" v-b-modal.ledgerModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="ledgerModalCenter" tabindex="-1" size="lg" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-1 padding-none">
              <b-button @click="addItem()" class="px-4 py-2" variant="primary"><i class="fa fa-plus"></i></b-button>
            </div>
            <div class="col-md-6 mb-2 ">
              <label class="mt-2" :style="'color:' + (descuadre != 0 ? ' red; font-weight:bold ' : ' black ')">Descuadre: C$ {{ descuadre.toFixed(2) }}</label>
              <!-- <input step="0.01" placeholder="0" required v-model="descuadre" class="form-control" type="number" /> -->
              <!-- <b-form-select class="form-select" :value="'estado'" text-value="estado" text-field="estado" v-model="row.estado" :options="options"> </b-form-select> -->
            </div>
            <div class="col-md-5 mb-2 ">
              <input required v-model="fecha" class="form-control" type="date" />
            </div>
            <table class="table mt-1">
              <thead>
                <tr class="text-center">
                  <th scope="col" width="20%">Cuenta</th>
                  <th scope="col" width="40%">Titulo</th>
                  <th scope="col" width="10%">Ingreso</th>
                  <th scope="col" width="10%">Egreso</th>
                  <th scope="col" width="10%">Acción</th>
                </tr>
              </thead>
              <tbody class="">
                <tr class="" v-for="itemRow of rows" :key="itemRow.id">
                  <td class="">
                    <b-form-select class="form-select" value-field="cuenta" text-field="titulo" v-model="itemRow.cuenta" :options="cuentas"></b-form-select>
                  </td>
                  <td class="px-none">
                    <input required v-model="itemRow.titulo" class="form-control" type="text" />
                  </td>
                  <td class="px-none">
                    <input v-on:keyup="calculaDescuadre" step="0.01" placeholder="0" v-model="itemRow.venta" class="form-control" type="number" />
                  </td>
                  <td class="px-none">
                    <input v-on:keyup="calculaDescuadre" step="0.01" placeholder="0" v-model="itemRow.gasto" class="form-control" type="number" />
                  </td>
                  <td class="px-none">
                    <b-button @click="eliminaFila(itemRow)" class="px-4 py-2" variant="danger"><i class="fa fa-trash"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div class="col-md-6">
                            <label for="contacto" class="">Nombre contacto</label><input class="form-control" v-model="row.nombre_contacto" type="text" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6">
                            <label for="contacto" class="">Email</label><input class="form-control" v-model="row.email" type="email" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="estado" class="">Estado</label>
                            <b-form-select required class="form-control select text-black"  v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado">
                            </b-form-select>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label for="observaciones" class="">Observaciones</label><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones">
                        </div> -->
          </div>
          <div class="text-right">
            <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('ledgerModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CardActions from "@/components/core/CardActions.vue";
export default {
  name: "TableComponent",
  //props: ["account"],
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  data() {
    return {
      options: [{ estado: "Nuevo" }, { estado: "Contactado" }, { estado: "En prueba" }, { estado: "Contratado" }, { estado: "Rechazado" }, { estado: "Despedido" }],
      fields: [
        { key: "cuenta", label: "Cuenta", sortable: true, sortDirection: "desc" },
        { key: "titulocuenta", label: "Nombre cuenta", sortable: true, class: "text-center" },
        { key: "asiento", label: "Asiento", sortable: true, class: "text-center" },
        { key: "fecha", label: "fecha", sortable: true, class: "text-center" },
        { key: "titulo", label: "Titulo", sortable: true, class: "text-center" },
        { key: "venta", label: "Ingreso", sortable: true, class: "text-center" },
        { key: "gasto", label: "Egreso", sortable: true, class: "text-center" },
        { key: "saldo", label: "Saldo", sortable: true, class: "text-center" },
        // { key: 'fecha_alta', label: 'fecha alta', sortable: true, class: 'text-center' },
        // { key: 'responsable', label: 'Responsable', sortable: true, class: 'text-center' },
        // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
        { key: "action", label: "Acciones", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      ///////
      table: "meca_diario",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo Asiento contable",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      singleTable: "Asiento contable",
      textTable: "Trabajo",
      rows: [],
      cuentas: [],
      fecha: "",
      descuadre: 0,
      ingresos: 0,
      gastos: 0,
      saldo: 0,
      fecha_ini: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
      fecha_fin: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
      account:"",
      itemsBk:[],
      invoice: 0,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    //this.fecha = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD")
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    this.account = this.$route.params.account
    console.log("cuenta---> ", this.account)
    if (this.account != undefined && this.account!= 'nn') {
      this.getDataByAccount();
    } 
    if(this.$route.params.invoice != undefined){
      this.getDataByInvoice();
    }
    if(this.$route.params.account == undefined && this.$route.params.invoice== undefined){
      this.getData();
    }
      
    this.totalRows = this.items.length;

    this.getCuentas();
  },
  methods: {
    async getDataByInvoice() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_diarioPorUsuarioFactura/" + this.userId + "/" + this.$route.params.invoice });
        if (res.length > 0) {
          let acumulado = 0
          for await(let itemAccount of res){
            acumulado = acumulado + itemAccount.venta
            acumulado = acumulado - itemAccount.gasto
            itemAccount.saldo = acumulado.toFixed(2)
          }
          this.items = res;
          this.itemsBk = this.items
          this.totalRows = res.length;
          await this.calculaSaldo();
        }
        this.$isLoading(false);
      } catch (error) {
        this.items = [];
        this.itemsBk = this.items
        this.totalRows = 0;
        this.$isLoading(false);
        console.error("error", error);
      }
    },    
    fitrarFecha(){
      this.items = []
      this.totalRows = 0
      console.log(this.$moment.tz(this.fecha_ini, "America/Costa_Rica").format("YYYY-MM-DD"))
      console.log(this.$moment.tz(this.fecha_fin, "America/Costa_Rica").format("YYYY-MM-DD"))
      let filtroFecha = this.itemsBk.filter( x => this.$moment.tz(x.fecha, "America/Costa_Rica").format("YYYY-MM-DD") >= 
      this.$moment.tz(this.fecha_ini, "America/Costa_Rica").format("YYYY-MM-DD") && this.$moment.tz(x.fecha, "America/Costa_Rica").format("YYYY-MM-DD")<=
      this.$moment.tz(this.fecha_fin, "America/Costa_Rica").format("YYYY-MM-DD")
      )
      this.items = filtroFecha
      this.totalRows = filtroFecha.length
      console.log('filtro por fecha ',filtroFecha)
    },
    async calculaSaldo() {
      this.ingresos = await this.items.reduce((a, b) => {
        return a + Number(b.venta);
      }, 0);
      this.gastos = await this.items.reduce((a, b) => {
        return a + Number(b.gasto);
      }, 0);
      this.saldo = this.ingresos - this.gastos;
    },
    calculaDescuadre() {
      console.log("validando datos...");
      let sumaIngreso = this.rows.reduce((a, b) => {
        return a + Number(b.venta);
      }, 0);
      let sumaEgreso = this.rows.reduce((a, b) => {
        return a + Number(b.gasto);
      }, 0);
      this.descuadre = sumaIngreso - sumaEgreso;
    },
    eliminaFila(item) {
      let idr = this.rows.indexOf(item);
      this.rows.splice(idr, 1);
    },
    addItem() {
      let cuentaorigen = ""
          if(this.$route.params.account != undefined && this.rows.length==0 )
          {
            cuentaorigen = this.account
          }

          if(this.$route.params.invoice != undefined && this.rows.length==0){
            cuentaorigen = this.items[0].cuenta
          }      
      this.rows.push({
        cuenta: cuentaorigen,
        sucursal_id: 0,
        fecha: this.fecha, //this.$moment(this.fecha).format("YYYY-MM-DD H:mm:ss"),
        fechaingreso: this.$moment().format("YYYY-MM-DD H:mm:ss"),
        fecha_fac: this.$moment().format("YYYY-MM-DD H:mm:ss"),
        empresa_id: this.company_id,
        gasto: 0,
        venta: 0,
        descripcion: "",
        titulo: "",
        usuario_id: this.userId,
      });
    },
    async showModal(item, id) {
      try {
        this.descuadre = 0;
        console.log("----->", item);
        if (id == 1) {
          this.fecha = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD")
          this.modalTitle = "Nuevo " + this.singleTable;
          this.rows = [];
          this.$bvModal.show("ledgerModalCenter");
        } else {
          let items = [...this.items];
          let rows = items.filter((x) => x.asiento == item.asiento);
          this.modalTitle = "Editar " + this.singleTable;
          for await (let ite of rows) {
            console.log("ite", ite.venta);
            console.log("ite", ite.gasto);
            ite.fecha = this.$moment(ite.fecha).format("YYYY-MM-DD");
            ite.venta = ite.venta != null ? Number(ite.venta).toFixed(2) : 0.0;
            ite.gasto = ite.gasto != null ? Number(ite.gasto).toFixed(2) : 0.0;
            //this.$moment(it.fecha).format("DD-MM-YYYY")
          }
          this.rows = rows;
          this.fecha = this.rows[0].fecha;
          this.$bvModal.show("ledgerModalCenter");
          console.log(this.rows);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getCuentas() {
      try {
        let res = await this.$store.dispatch("get", { path: "meca_cuentas/getAllByUser/" + this.userId });
        if (res.length > 0) {
          this.cuentas = res;
        }
      } catch (error) {
        this.cuentas = [];
        console.error("error", error);
      }
    },
    async getDataByAccount() {
      try {
        // let res = await this.$store.dispatch("get", { path: "meca_diarioPorEmpresaCuenta/" + this.company_id + "/" + this.account });
        this.$isLoading(true);

        let res = await this.$store.dispatch("get", { path: "meca_diarioPorUsuarioCuenta/" + this.userId + "/" + this.account });
        if (res.length > 0) {
          let acumulado = 0
          for await(let itemAccount of res){
            acumulado = acumulado + itemAccount.venta
            acumulado = acumulado - itemAccount.gasto
            itemAccount.saldo = acumulado.toFixed(2)
          }
          this.items = res;
          this.itemsBk = this.items
          this.totalRows = res.length;
          await this.calculaSaldo();
        }
        this.$isLoading(false);
      } catch (error) {
        this.items = [];
        this.itemsBk = this.items
        this.totalRows = 0;
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getData() {
      try {
        // let res = await this.$store.dispatch("get", { path: "meca_diarioPorEmpresa/" + this.company_id });
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_diarioPorUsuarioLedger/" + this.userId });
        
        if (res.length > 0) {
          this.items = res;
          this.itemsBk = this.items
          this.totalRows = res.length;
        }
        this.$isLoading(false);
      } catch (error) {
        this.items = [];
        this.itemsBk = this.items
        this.totalRows = 0;
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async validarDatos() {
      console.log("validando datos...");
      //verificar sucursal obligatorio
      // console.log("validar datos----", this.sucursal_id);
      // if (this.sucursal_id == null || this.sucursal_id == 0) {
      //   //alert('Falta sucursal...')
      //   this.color = "danger";
      //   this.textPlay = "Error, Falta sucursal!";
      //   this.snackbarPlay = true;
      //   respuesta = false;
      // }
      let respuesta = true;
      let sumaIngreso = this.rows.reduce((a, b) => {
        return a + Number(b.venta);
      }, 0);
      let sumaEgreso = this.rows.reduce((a, b) => {
        return a + Number(b.gasto);
      }, 0);
      //console.log(this.rowsList)
      //console.log(sumaIngreso)
      //console.log(sumaEgreso)
      this.descuadre = sumaIngreso - sumaEgreso;
      if (sumaIngreso != sumaEgreso) {
        alert("Error, Asiento Descuadrado!");
        // this.color = "danger";
        // this.textPlay = "Error, Asiento Descuadrado!";
        // this.snackbarPlay = true;
        respuesta = false;
      }

      if (this.rows.length == 0) {
        alert("Error, no hay partidas");
        // this.color = "danger";
        // this.textPlay = "Error...!";
        // this.snackbarPlay = true;
        respuesta = false;
      }

      for await (let item of this.rows) {
        console.log("item cuenta", item.cuenta);
        if (!item.cuenta || item.cuenta == "" || item.cuenta == undefined) {
          alert("Error, falta cuenta!");
          // this.color = "danger";
          // this.textPlay = "Error, falta cuenta!";
          // this.snackbarPlay = true;
          respuesta = false;
        }
      }

      return respuesta;
    },

    async saveData() {
      console.log("registrs ", this.rows);
      //this.sucursal_id.id = 0
      let resValida = await this.validarDatos();
      if (resValida === false) return;

      console.log("continua el ejercicio");
      try {
        let ruta = "";
        let response;
        let rows = [...this.rows];

        if (rows[0].id > 0) {
          for await (let item of rows) {
            // if (this.sucursal_id.id) {
            //   item.sucursal_id = item.sucursal_id != this.sucursal_id.id ? this.sucursal_id.id : item.sucursal_id;
            // }
            
            item.fecha = this.$moment(this.fecha).format("YYYY-MM-DD H:mm:ss");
            ////console.log('sucursal.....', this.sucursal_id)
            if (item.cuenta.cuenta) item.cuenta = item.cuenta.cuenta;
            // if (item.sucursal_id.id) item.sucursal_id = this.sucursal_id.id;
            delete item.titulocuenta;
          }
          //console.log('editar--->',this.rowsList)
          ruta = "meca_diarioCustom/";
          response = await this.$store.dispatch("put", { path: ruta, data: rows });
          // response = await api.update(ruta, this.rowsList);
        } else {
          for await (let item of rows) {
            //item.cuenta = item.cuenta;
            item.fecha = this.$moment(this.fecha).format("YYYY-MM-DD H:mm:ss");
            item.sucursal_id = 0; //this.sucursal_id.id;
            //item.fecha = this.fecha; //this.$moment().format('YYYY-MM-DD H:mm:ss')
            item.fechaingreso = this.$moment().format("YYYY-MM-DD H:mm:ss");
            item.fecha_fac = this.fecha; // this.$moment().format('YYYY-MM-DD H:mm:ss')
            //item.empresa_id = this.company_id;
          }
          console.log("rowlist ----->", rows);
          //console.log(this.rowsList)
          ruta = "meca_diarioCustom/" + this.company_id;
          response = await this.$store.dispatch("post", { path: ruta, data: rows });

          // response = await api.insert(ruta, this.rowsList);
        }
        //console.log(response)
        if (response.status == 200 || response.affectedRows > 0) {
          if (this.account != undefined) {
            await this.getDataByAccount();
          } else {
            await this.getData();
          }
          // this.dialog = false;
          this.$bvModal.hide("ledgerModalCenter");
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
        }
      } catch (error) {
        this.$swal({ title: "Aviso", text: "Error, " + error, icon: "error" });
        console.log("error", error);
      }
    },

    // async saveData() {
    //   try {
    //     console.log("save");
    //     let data = Object.assign({}, this.row);
    //     data.empresa_id = this.company_id;
    //     let res;
    //     if (this.row.id) {
    //       delete data.id;
    //       res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
    //     } else {
    //       res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
    //     }
    //     if (res.error) {
    //       this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
    //     } else {
    //       this.getData();
    //       this.row = {};
    //       this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
    //       this.$bvModal.hide("ledgerModalCenter");
    //     }
    //   } catch (error) {
    //     this.items = [];
    //     console.error("error", error);
    //   }
    // },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el asiento?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", { path: "meca_diario/" + item.asiento });
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            if (this.account != undefined) {
              await this.getDataByAccount();
            } else {
              await this.getData();
            }
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.padding-none {
  padding: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.px-none {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
