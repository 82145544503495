<template>
   <div>
        <div class="row justify-content-center text-center mb-5">
            <div class="col-lg-12">
                <h2>{{ price.price_title }}</h2>
                <p class="text-muted mt-4 mx-auto">{{ price.content }}</p>
            </div>
        </div> <!-- Row end  -->
        <div class="row g-2 justify-content-center align-items-center">
            <div class="col-xl-3 col-lg-4 col-sm-12"  v-for="(deus, index) in price.deu" :key="index">
                <div class="card" :class="deus.card_class">
                    <div class="card-body py-4 text-center">
                        <h6 class="text-uppercase">{{ deus.title }}</h6>
                        <div><span class="display-5 text-primary">{{ deus.price }}</span> <span class="text-muted">{{ deus.month }}</span></div>
                    </div> 
                    <div class="card-footer border-top-0 px-5">
                        <p class="d-flex justify-content-between py-1"><span><i :class=" deus.icon"></i> Bandwidth:</span> <span class="font-weight-bold">{{ deus.Bandwidth }}</span></p>
                        <p class="d-flex justify-content-between py-1"><span><i :class=" deus.icon"></i> Onlinespace:</span> <span class="font-weight-bold">{{ deus.Onlinespace }}</span></p>
                        <p class="d-flex justify-content-between py-1"><span><i :class=" deus.icon"></i> Support:</span> <span class="font-weight-bold">{{ deus.Support }}</span></p>
                        <p class="d-flex justify-content-between py-1"><span><i :class=" deus.icon"></i> Domain:</span> <span class="font-weight-bold">{{ deus.Domain }}</span></p>
                        <p class="d-flex justify-content-between py-1"><span><i :class=" deus.icon"></i> Hidden Fees:</span> <span class="font-weight-bold">{{ deus.Hidden_Fees }}</span></p>
                    </div>
                    <div class="card-body text-center">
                        <a href="#" class="btn border lift" :class="`${deus.started_class}`">Get Started</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Pricing: version 2  -->
        <div class="row mb-5 mt-5">
            <div class="col-12">
                <h6 class="fw-bold text-muted mb-4 border-top pt-3">Pricing table design version 2</h6>
            </div>
            <div class="col-12 text-center">
                <h2>{{ price2.price2_title }}</h2>
                <p class="text-muted mt-4 mx-auto">{{ price2.content2 }}</p>
            </div>
        </div> <!-- Row end  -->
        <div class="row row-cols-xl-4 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 g-2 mb-4">
            <div class="col" v-for="(deus, index) in price2.deu" :key="index">
                <div class="card rounded-3 text-center" :class="deus.card_class">
                    <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-light">{{ deus.title }}</span>
                    <div class="bg-transparent card-header pt-4 border-0">
                        <h1 class="h1 fw-normal text-primary text-center mb-0" data-pricing-value="15">{{ deus.pricing_value }}<span class="price">{{ deus.price }}</span><span class="h6 text-muted ml-2">{{ deus.month }}</span></h1>
                    </div>
                    <div class="card-body">
                        <ul class="list-unstyled mb-4">
                            <li class="py-2" v-for="(content, index) in deus.contents" :key="index">{{content}}</li>
                        </ul>
                        <button type="button" class="btn btn-outline-secondary mb-2 text-uppercase" :class="deus.started_class">Order now</button>
                    </div>
                </div>
            </div>
        </div> <!-- Row end  -->
   </div>
</template>
<script>
export default {
    name:'PricingComponent',
     data (){
        return {
            currentTab:'Invoice List',
            price:{
                price_title:'Our Pricing Design',
                content:'It is a long established fact that a reader will be of a page when established fact looking at its layout.',
                deu:[
                    {
                        card_class:'',
                        title:'DELUXE',
                        price:'$9',
                        month:'/Month',
                        icon:'fa fa-check-circle',
                        Bandwidth:'1GB',
                        Onlinespace:'500MB',
                        Support:'No',
                        Domain:'1',
                        Hidden_Fees:'No',
                        started_class:'btn-white'
                    },
                    {
                        card_class:'py-0 py-md-4 shadow',
                        title:'ECONOMY',
                        price:'$29',
                        month:'/Month',
                        icon:'fa fa-check-circle',
                        Bandwidth:'2GB',
                        Onlinespace:'5GB',
                        Support:'Yes',
                        Domain:'1',
                        Hidden_Fees:'No',
                        started_class:'btn-primary'
                    },
                    {
                        card_class:'',
                        title:'ULTIMATE',
                        price:'$39',
                        month:'/Month',
                        icon:'fa fa-check-circle',
                        Bandwidth:'3GB',
                        Onlinespace:'1TB',
                        Support:'Yes',
                        Domain:'Unlimited',
                        Hidden_Fees:'No',
                        started_class:'btn-white'
                    }
                ]
            },
            price2:{
                price2_title:'Our Pricing Design',
                content2:'It is a long established fact that a reader will be of a page when established fact looking at its layout.',
                deu:[
                    {
                        card_class:'',
                        title:'Starter',
                        pricing_value:'$',
                        price:'3',
                        month:'/ per month',
                            contents:[
                                'Up to 5 users',
                                'Basic support on Github',
                                'Monthly updates',
                                'Free cancelation',
                            ],
                        started_class:'btn-white'
                    },
                    {
                        card_class:'border-primary border-3 shadow',
                        title:'Professional',
                        pricing_value:'$',
                        price:'29',
                        month:'/Month',
                            contents:[
                                'Up to 5 users',
                                'Basic support on Github',
                                'Monthly updates',
                                'Free cancelation',
                            ],
                        started_class:'btn-primary'
                    },
                    {
                        card_class:'',
                        title:'Business',
                        pricing_value:'$',
                        price:'39',
                        month:'/Month',
                            contents:[
                                'Up to 5 users',
                                'Basic support on Github',
                                'Monthly updates',
                                'Free cancelation',
                            ],
                        started_class:'btn-white'
                    },
                    {
                        card_class:'',
                        title:'Enterprise',
                        pricing_value:'$',
                        price:'39',
                        month:'/Month',
                            contents:[
                                'Up to 5 users',
                                'Basic support on Github',
                                'Monthly updates',
                                'Free cancelation',
                            ],
                        started_class:'btn-white'
                    }
                ]
            }
        }
    }
}
</script>
<style scoped>

</style>