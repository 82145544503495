<template>
    <div class="col-12">
        <div class="row justify-content-between">
            <div class="col-lg-8 col-sm-12">

                <h2 id="how-it-works">How it works</h2>
                <p>The collapse JavaScript plugin is used to show and hide content. Buttons or anchors are used as triggers that are mapped to specific elements you toggle. Collapsing an element will animate the <code>height</code> from its current value to <code>0</code>. Given how CSS handles animations, you cannot use <code>padding</code> on a <code>.collapse</code> element. Instead, use the class as an independent wrapping element.</p>
                <div class="card card-callout mb-3">
                    <div class="card-body">
                        <p><strong>Note:</strong> The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/components/collapse#accessibility" class="font-weight-bold">reduced motion section of our accessibility documentation</a> for additional details.</p>
                    </div>
                </div>
                
                <h2 id="example">Example</h2>
                <p>Other elements can easily toggle <code>&lt;b-collapse&gt;</code> components using the <a href="/docs/directives/toggle" class="font-weight-bold"><code>v-b-toggle</code> directive</a>.</p>
                <div class="bd-example mb-5">
                    <p>
                        <a class="btn btn-primary mr-1" href="javascript:void(0);" role="button" aria-expanded="false" aria-controls="collapseExample" v-b-toggle.collapseExample>
                            Link with href
                        </a>
                        <button class="btn btn-primary" type="button" data-bs-target="javascript:void(0);" aria-expanded="false" aria-controls="collapseExample" v-b-toggle.collapseExample>
                            Button with data-target
                        </button>
                    </p>
                    <b-collapse class="collapse" id="collapseExample">
                        <div class="card card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                        </div>
                    </b-collapse>
<pre>
<code class="language-html" data-lang="html">&lt;p&gt;
    &lt;a class=&quot;btn btn-primary mr-1&quot; href=&quot;javascript:void(0);&quot; role=&quot;button&quot; aria-expanded=&quot;false&quot; aria-controls=&quot;collapseExample&quot; v-b-toggle.collapseExample&gt;
        Link with href
    &lt;/a&gt;
    &lt;button class=&quot;btn btn-primary&quot; type=&quot;button&quot; data-bs-target=&quot;javascript:void(0);&quot; aria-expanded=&quot;false&quot; aria-controls=&quot;collapseExample&quot; v-b-toggle.collapseExample&gt;
        Button with data-target
    &lt;/button&gt;
&lt;/p&gt;
&lt;b-collapse class=&quot;collapse&quot; id=&quot;collapseExample&quot;&gt;
    &lt;div class=&quot;card card-body&quot;&gt;
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
    &lt;/div&gt;
&lt;/b-collapse&gt;</code>
    </pre>
                </div>

                <h2 id="multiple-targets">Multiple targets</h2>
                <p>A <code>&lt;button&gt;</code> or <code>&lt;a&gt;</code> can show and hide multiple elements by referencing them with a selector in its <code>href</code> or <code>data-target</code> attribute.
                Multiple <code>&lt;button&gt;</code> or <code>&lt;a&gt;</code> can show and hide an element if they each reference it with their <code>href</code> or <code>data-target</code> attribute</p>
                <div class="bd-example mb-5">
                    <p>
                        <a class="btn btn-primary mr-1" data-bs-toggle="collapse" href="javascript:void(0);" role="button" aria-expanded="false" v-b-toggle.multiCollapseExample1>Toggle first element</a>
                        <button class="btn btn-primary mr-1" type="button" data-bs-toggle="collapse" aria-expanded="false" v-b-toggle.multiCollapseExample2>Toggle second element</button>
                        <button class="btn btn-primary mr-1" type="button" data-bs-toggle="collapse" aria-expanded="false" v-b-toggle="['multiCollapseExample1', 'multiCollapseExample2']">Toggle both elements</button>
                    </p>
                    <div class="row">
                        <div class="col">
                            <b-collapse class="collapse multi-collapse" id="multiCollapseExample1">
                                <div class="card card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                </div>
                            </b-collapse>
                        </div>
                        <div class="col">
                            <b-collapse class="collapse multi-collapse" id="multiCollapseExample2">
                                <div class="card card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                </div>
                            </b-collapse>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;p&gt;
    &lt;a class=&quot;btn btn-primary mr-1&quot; data-bs-toggle=&quot;collapse&quot; href=&quot;javascript:void(0);&quot; role=&quot;button&quot; aria-expanded=&quot;false&quot; v-b-toggle.multiCollapseExample1&gt;Toggle first element&lt;/a&gt;
    &lt;button class=&quot;btn btn-primary mr-1&quot; type=&quot;button&quot; data-bs-toggle=&quot;collapse&quot; aria-expanded=&quot;false&quot; v-b-toggle.multiCollapseExample2&gt;Toggle second element&lt;/button&gt;
    &lt;button class=&quot;btn btn-primary mr-1&quot; type=&quot;button&quot; data-bs-toggle=&quot;collapse&quot; aria-expanded=&quot;false&quot; v-b-toggle=&quot;[&#039;multiCollapseExample1&#039;, &#039;multiCollapseExample2&#039;]&quot;&gt;Toggle both elements&lt;/button&gt;
&lt;/p&gt;
&lt;div class=&quot;row&quot;&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-collapse class=&quot;collapse multi-collapse&quot; id=&quot;multiCollapseExample1&quot;&gt;
            &lt;div class=&quot;card card-body&quot;&gt;
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
            &lt;/div&gt;
        &lt;/b-collapse&gt;
    &lt;/div&gt;
    &lt;div class=&quot;col&quot;&gt;
        &lt;b-collapse class=&quot;collapse multi-collapse&quot; id=&quot;multiCollapseExample2&quot;&gt;
            &lt;div class=&quot;card card-body&quot;&gt;
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
            &lt;/div&gt;
        &lt;/b-collapse&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>
                
                <h2 id="accordion-example">Accordion example</h2>
                <p>Using the <a href="https://v5.getbootstrap.com/docs/5.0/components/card/">card</a> component, you can extend the default collapse behavior to create an accordion. To properly achieve the accordion style, be sure to use <code>.accordion</code> as a wrapper.</p>
                <div class="bd-example mb-5">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header p-0" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-light btn-block text-start p-3 rounded-0" type="button" v-b-toggle.collapseOne>
                                        Collapsible Group Item #1
                                    </button>
                                </h2>
                            </div>
                        
                            <b-collapse visible id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                            </b-collapse>
                        </div>
                        <div class="card">
                            <div class="card-header p-0" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-light btn-block text-start collapsed p-3 rounded-0" type="button" v-b-toggle.collapseTwo>
                                        Collapsible Group Item #2
                                    </button>
                                </h2>
                            </div>
                            <b-collapse id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                            </b-collapse>
                        </div>
                        <div class="card">
                            <div class="card-header p-0" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-light btn-block text-start collapsed p-3 rounded-0" type="button" v-b-toggle.collapseThree>
                                        Collapsible Group Item #3
                                    </button>
                                </h2>
                            </div>
                            <b-collapse id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                </div>
                            </b-collapse>
                        </div>
                    </div>
<pre>
<code class="language-html" data-lang="html">&lt;div class=&quot;accordion&quot; id=&quot;accordionExample&quot;&gt;
    &lt;div class=&quot;card&quot;&gt;
        &lt;div class=&quot;card-header p-0&quot; id=&quot;headingOne&quot;&gt;
            &lt;h2 class=&quot;mb-0&quot;&gt;
                &lt;button class=&quot;btn btn-light btn-block text-start p-3 rounded-0&quot; type=&quot;button&quot; v-b-toggle.collapseOne&gt;
                    Collapsible Group Item #1
                &lt;/button&gt;
            &lt;/h2&gt;
        &lt;/div&gt;
    
        &lt;b-collapse visible id=&quot;collapseOne&quot; class=&quot;collapse show&quot; aria-labelledby=&quot;headingOne&quot; data-parent=&quot;#accordionExample&quot;&gt;
            &lt;div class=&quot;card-body&quot;&gt;
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven&#039;t heard of them accusamus labore sustainable VHS.
            &lt;/div&gt;
        &lt;/b-collapse&gt;
    &lt;/div&gt;
    &lt;div class=&quot;card&quot;&gt;
        &lt;div class=&quot;card-header p-0&quot; id=&quot;headingTwo&quot;&gt;
            &lt;h2 class=&quot;mb-0&quot;&gt;
                &lt;button class=&quot;btn btn-light btn-block text-start collapsed p-3 rounded-0&quot; type=&quot;button&quot; v-b-toggle.collapseTwo&gt;
                    Collapsible Group Item #2
                &lt;/button&gt;
            &lt;/h2&gt;
        &lt;/div&gt;
        &lt;b-collapse id=&quot;collapseTwo&quot; class=&quot;collapse&quot; aria-labelledby=&quot;headingTwo&quot; data-parent=&quot;#accordionExample&quot;&gt;
            &lt;div class=&quot;card-body&quot;&gt;
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven&#039;t heard of them accusamus labore sustainable VHS.
            &lt;/div&gt;
        &lt;/b-collapse&gt;
    &lt;/div&gt;
    &lt;div class=&quot;card&quot;&gt;
        &lt;div class=&quot;card-header p-0&quot; id=&quot;headingThree&quot;&gt;
            &lt;h2 class=&quot;mb-0&quot;&gt;
                &lt;button class=&quot;btn btn-light btn-block text-start collapsed p-3 rounded-0&quot; type=&quot;button&quot; v-b-toggle.collapseThree&gt;
                    Collapsible Group Item #3
                &lt;/button&gt;
            &lt;/h2&gt;
        &lt;/div&gt;
        &lt;b-collapse id=&quot;collapseThree&quot; class=&quot;collapse&quot; aria-labelledby=&quot;headingThree&quot; data-parent=&quot;#accordionExample&quot;&gt;
            &lt;div class=&quot;card-body&quot;&gt;
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven&#039;t heard of them accusamus labore sustainable VHS.
            &lt;/div&gt;
        &lt;/b-collapse&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                </div>
                
                <h2 id="accessibility">Accessibility</h2>
                <p>The directive, for accessibility reasons, should be placed on an clickable interactive element such as a <code>&lt;button&gt;</code> or <code>&lt;b-button&gt;</code>, which can easily be accessed by keyboard-only users and screen reader users. Elements that do not natively have an accessibility role of <code>button</code> (or <code>link</code>) will have the attributes <code>role="button"</code> and <code>tabindex="0"</code> applied, and will have the appropriate click handler instantiated. Therefore it is <em>highly recommended</em> to <em>not</em> place the directive on form controls other than buttons.</p>
                <p>The directive applies, and dynamically updates, the following ARIA attributes on the trigger element:</p>
                <ul> <li><code>aria-controls</code> - the ID(s) of the collapse or sidebar component(s) being toggled</li> <li><code>aria-expanded</code> - the visibility state of the collapse or sidebar (see the <a href="#caveats-with-multiple-targets" class="font-weight-bold">caveats section</a> below)</li> </ul>
                <h3 id="via-javascript">Via Plugin</h3>
                <p>Enable manually with:</p>
                <div class="bd-example mb-5">
    <pre><code class="language-js" data-lang="js">import { VBToggle } from &#039;bootstrap-vue&#039;
// Note: Vue automatically prefixes the directive name with &#039;v-&#039;
Vue.directive(&#039;b-toggle&#039;, VBToggle)</code></pre>
                </div>
                
                <h3 id="options">Directive syntax and usage</h3>
                <ul> 
                    <li><code>v-b-toggle.my-collapse</code> - the directive modifier (multiple targets allowed, each modifier is a target ID)</li> 
                    <li><code>v-b-toggle:my-collapse</code> - the directive argument (<a href="https://vuejs.org/v2/guide/syntax.html#Dynamic-Arguments" target="_blank" rel="noopener">Vue dynamic argument</a> is supported) <span class="badge badge-info small" aria-label="Available in BootstrapVue v2.14.0+">v2.14.0+</span></li>
                     <li><code>v-b-toggle="'my-collapse'"</code> - the directive value as a string ID</li> <li><code>v-b-toggle="'my-collapse1 my-collapse2'"</code> - the directive value as a space separated string of IDs <span class="badge badge-info small" aria-label="Available in BootstrapVue v2.14.0+">v2.14.0+</span></li> 
                     <li><code>v-b-toggle="['my-collapse1', 'my-collapse2']"</code> - the directive value as an array of string IDs <span class="badge badge-info small" aria-label="Available in BootstrapVue v2.14.0+">v2.14.0+</span></li>
                </ul>
                
                <h3 id="methods">Usage on links</h3>
                <p>If placing the directive on a link (or a component that renders a link), the target ID can alternatively be specified via the <code>href</code> attribute.</p>
                <p>Note that the browser URL will change and the page may scroll the target into view. To prevent the URL from changing and the page from scrolling, add <code>@click.prevent</code> to the link.</p>
                
                <h3 id="hiding">Hiding and showing content in the toggle trigger element</h3>
                <p>When using the <code>v-b-toggle</code> directive, the class <code>collapsed</code> will automatically be placed on the trigger element when the target component is closed, and removed when open. As of BootstrapVue <code>2.14.0</code>, the class <code>not-collapsed</code> will be applied when the target is <em>not</em> closed.</p>
<pre><code class="language-js" data-lang="js">.collapsed &gt; .when-open,
.not-collapsed &gt; .when-closed {
  display: none;
}</code></pre>
                <h3 id="preventing">Preventing the target from opening or closing</h3>
                <p>To prevent the trigger element from toggling the target, set the <code>disabled</code> prop on <code>&lt;button&gt;</code>, <code>&lt;b-button&gt;</code>, or <code>&lt;b-link&gt;</code> (or components based on from <code>&lt;b-link&gt;</code>) and the toggle event will <em>not</em> dispatched to the target(s).</p>

            </div>
            <div class="col-lg-3 col-sm-12 d-none d-sm-block">
                <div class="sticky-lg-top">
                    <strong class="d-block h6 my-2 pb-2 border-bottom">On this page</strong>
                    <nav>
                        <ul>
                            <li><a href="#how-it-works">How it works</a></li>
                            <li><a href="#example">Example</a></li>
                            <li><a href="#multiple-targets">Multiple targets</a></li>
                            <li><a href="#accordion-example">Accordion example</a></li>
                            <li><a href="#accessibility">Accessibility</a></li>
                            <li><a href="#usage">Usage</a>
                                <ul>
                                    <li><a href="#options">Directive syntax and usage</a></li>
                                    <li><a href="#methods">Usage on links</a></li>
                                    <li><a href="#hiding">Hiding and showing content in the toggle trigger element</a></li>
                                    <li><a href="#preventing">Preventing the target from opening or closing</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div> <!-- Row end  --> 
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'CollapseComponent',
    mounted() {
        Prism.highlightAll()
    }
}
</script>
<style scoped>

</style>