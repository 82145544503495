<template>
    <div class="row clearfix">
        <div class="col-md-12">
            <div class="card p-4 mb-4">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <b><label for="categoria" class="">INGRESOS:</label></b>
                        <input readonly :value="'$ '+row.ingresos.toFixed(2)" type="text" class="form-control inputbox blue" name="" id="">
                    </div>
                    <div class="col-md-4 text-center">
                        <b><label for="categoria" class="">GASTOS:</label></b>
                        <input readonly :value="'$ '+row.gastos.toFixed(2)" type="text" class="form-control inputbox red" name="" id="">
                    </div>
                    <div class="col-md-4 text-center">
                        <b><label for="categoria" class="">SALDO:</label></b>
                        <input readonly :value="'$ '+row.total.toFixed(2)" type="text" :class="'form-control inputbox ' + (row.total>row.gastos ? ' blue ' : row.total == row.gastos ? ' black ' :  ' red ')" name="" id="">
                    </div>
                </div>
               <div class="row py-3 justify-content-between">

                   <div class="col-md-5">
                       <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-5">
                        <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
               </div>
                <b-table 
                    hover
                    responsive 
                    :items="items" 
                    :fields="fields" 
                    :current-page="currentPage" 
                    :per-page="perPage" 
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                >
                <template #cell(name)="row">
                    {{ row.value.first }} {{ row.value.last }}
                </template>
                        <template #cell(fecha)="data">
                            <span  >{{ data.item.fecha != null ? $moment(data.item.fecha).format("DD-MM-YYYY") : '' }}</span>
                        </template>
                        <template #cell(total)="data">
                            <span  >${{ data.item.total != null ? data.item.total.toFixed(2) : 0 }}</span>
                        </template>
                        <template #cell(tipo)="data">
                            <span :style="data.item.tipo == 'G' ? 'color:red' : 'color:blue'" >{{ data.item.tipo == 'G' ? 'Gasto' : 'Ingreso' }}</span>
                        </template>
                        <template #cell(factura)="data">
                            <!-- <span @click="$router.push('/purchasesSales/purchasePreview/' + data.item.factura) "  style="color:blue" >{{ data.item.factura}}</span> -->
                            <span>
                                <!-- <button  @click="$router.resolve({name: '/purchasesSales/purchasePreview/' , query: {id: data.item.factura}});
                            window.open(routeData.href, '_blank');"
                              class="btn btn-primary ml-1"><i class="fa fa-eye"></i> </button>  -->
                              <a :href="'/#/purchasesSales/purchasePreview/' + data.item.factura " target="_blank">{{ data.item.factura }} </a>
                                <!-- Link Text
                                </router-link>                               -->
                            </span>
                        </template>
                        <template #cell(action)="data">
                            <span><button @click="showInvoice(data.item)"  class="btn btn-primary ml-1"><i class="fa fa-eye"></i> </button> </span>
                            <span><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash"></i> </button> </span>

                            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
                            <!-- <button type="button" class="btn btn-secondary mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-secondary mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

                            <!-- <table-action></table-action> -->
                        </template>
                <template #row-details="row">
                    <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                    </b-card>
                </template>
                </b-table>
                 <div>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
                </div>
            </div> 
        </div>

    </div>
</template>
<script>
export default {
    name:'TableComponent',
    components: {
        // 'table-action': TableAction,
    },data() {
      return {
        fields: [
            { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
            { key: 'fecha', label: 'Fecha ', sortable: true, class: 'text-center' },
            // { key: 'sucursal', label: 'Sucursal', sortable: true, class: 'text-center' },
            { key: 'total', label: 'Total', sortable: true, class: 'text-center' },
            { key: 'tipo', label: 'Tipo', sortable: true, class: 'text-center' },
            { key: 'asiento', label: 'asiento', sortable: true, class: 'text-center' },
            { key: 'factura', label: 'factura', sortable: true, class: 'text-center' },

            // { key: 'action', label: '',tdClass:'dt-body-right'},

          {
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true
          },
        ],
        fields2: [
            { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
            { key: 'articulo', label: 'Articulo', sortable: true, class: 'text-center' },
            { key: 'cantidad', label: 'Cantidad ', sortable: true, class: 'text-center' },
            { key: 'precio_unit', label: 'Precio unit', sortable: true, class: 'text-center' },
            { key: 'sub_total', label: 'Sub total ', sortable: true, class: 'text-center' },
            
            { key: 'action', label: 'acción',tdClass:'dt-body-right'},

          {
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true
          },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, { value: 100, text: 100 }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
        ///////
        table: 'meca_ventas',
        items: [],
        row:{
            ingresos:0,gastos:0, total:0
        },
        company_id: 0,
        userId:0,
        rol:0,
        modalTitle: 'Nuevo Cliente',
        listaEstado:[
            {estado: "Activo"},
            {estado: "Inactivo"},
        ],
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    async mounted() {
      this.userId = localStorage.getItem('user_id');
      this.company_id = localStorage.getItem('company_id');
      this.rol = localStorage.getItem('rol');

      await this.getData()
      this.totalRows = this.items.length
    },
    methods: {
        showInvoice(item){
            this.$router.push('/purchasesSales/invoicePreview/' +item.id )
        },
        showModal(item, id){
            console.log('----->',item)
            if(id==1){
                this.modalTitle = 'Nuevo Cliente'
                this.row = {}
                this.$bvModal.show('exampleModalCenter')
            }
            else{
                this.row = item
                this.modalTitle = 'Editar Cliente'
                this.$bvModal.show('exampleModalCenter')
                console.log(this.row)
            }
        },
        async getData(){
            try {
                // let res = await this.$store.dispatch('get', {path: 'reports/balanceByCompany/' + this.company_id});
                let res = await this.$store.dispatch('get', {path: 'reports/balanceAsientosByCompany/' + this.company_id});
                console.log('items', this.items)
                console.log(res)
                if (res.length >0){
                    this.items = res
                    //calcular ingresos , gastos, total
                    this.row.ingresos = this.items.map(item => ( item.tipo == 'I' ? item.total :0 )).reduce((p,c) => p + c, 0)
                    this.row.gastos = this.items.map(item => (item.tipo == 'G' ? item.total :0)).reduce((p,c) => p + c, 0)
                    this.row.total = this.row.ingresos - this.row.gastos //this.items.map(item => (item.total)).reduce((p,c) => p + c, 0)

                }
                else{
                    this.row.ingresos = 0
                    this.row.gastos = 0
                    this.row.total = 0
                }
            } catch (error) {
                this.items = []
                this.row.ingresos = 0
                this.row.gastos = 0
                this.row.total = 0

                console.error('error', error)
            }
        },    
        async saveData(){
            try {
                console.log('save')
                let data = Object.assign({} , this.row)
                data.empresa_id = this.company_id
                let res
                if(this.row.id){
                    delete(data.id)
                    res = await this.$store.dispatch('put', {path: this.table + '/modify/'+ this.row.id , data: data});
                }
                else{
                    res = await this.$store.dispatch('post', {path: this.table + '/new', data: data});
                }
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.getData()
                    this.row={}
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                    this.$bvModal.hide('exampleModalCenter')

                }
            } catch (error) {
                this.items = []
                console.error('error', error)
            }
        },
        async deleteItem(item){
            const {isConfirmed} = await this.$swal({
                title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                icon:"question",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                reverseButtons: true

            })
            if(isConfirmed){
                try {
                    let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.getData()
                        this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.items = []
                    console.error('error--->', error)
                }                    
            }
        },

        ///////////////
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>
<style scoped>
.inputbox{
    height: 60px;
    text-align: center;
}
.blue{
    color:blue;
    font-weight: bold;
}
.red{
    color:red;
    font-weight: bold;
}
.black{
    color:black;
    font-weight: bold;
}
</style>