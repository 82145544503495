<template>
  <div class="main">
    <div class="section">
      <button @click="recordAudio()" id="startAudio" class="button gradient">
        <span>record audio </span>
        <i class="fas fa-microphone-alt"></i></button
      ><br /><br />
      <button @click="stopRecording()" id="stopAudio" class="button gradient">
        <span>stop recording</span>
        <i class="fas fa-stop-circle"></i></button
      ><br /><br />
      <button @click="recordSCreen()" id="startScreen" class="button gradient">
        <span>record screen</span>
        <i class="fas fa-video"></i></button
      ><br /><br />
    </div>

    <div class="gallery">
      <h3>Audios</h3>
      <hr />
      <div v-for="it of audioList" :key="it.id">
        {{ it.value }}
      </div>
      <div id="audio-list" class="container"></div>
      <h3>Videos</h3>
      <hr />
      <div id="video-list" class="container"></div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      startAudioButton: null,
      startScreenButton: null,
      stopButton: null,
      mediaRecorder: null,
      mediaRecorderVideo: null,
      chunks: [],
      chunksVideo: [],
      audioList: null,
      videoList: null,
    };
  },
  mounted() {
    try {
      // const buttons = document.querySelectorAll(".button");
      this.startAudioButton = document.querySelector("#startAudio");
      this.startScreenButton = document.querySelector("#startScreen");
      this.stopButton = document.querySelector("#stopAudio");
      this.audioList = document.querySelector("#audio-list");
      this.videoList = document.querySelector("#video-list");

      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert("Your browser does not support recording!");
      }

      //  this.startAudioButton.addEventListener("click", this.recordAudio());
      // this.stopButton.addEventListener("click", this.stopRecording());
      // this.startScreenButton.addEventListener("click", this.recordSCreen());
    } catch (error) {
      console.log();
    }
  },
  methods: {
    async startCapture() {
      var VideoStreamMerger = require('video-stream-merger')

    //   const getusermedia = require('getusermedia')
    // const screenRecord = require('screen-record')
      let webcamStream = null;
      const constraints = { audio: true, video: { width: 720, height: 480 } };
      try {
        webcamStream = await navigator.mediaDevices.getUserMedia(constraints);
      } catch (err) {
        /* handle the error */
        console.error("Error: " + err);
      }
      let captureStream = null;

      try {
        const displayMediaOptions = null; //set it if you need
        captureStream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
      } catch (err) {
        /* handle the error */
        console.error("Error: " + err);
      }

      const merger = new VideoStreamMerger();

      // Add the screen capture. Position it to fill the whole stream (the default)
      merger.addStream(captureStream, {
        x: 0, // position of the topleft corner
        y: 0,
        width: merger.width,
        height: merger.height,
        mute: true, // we don't want sound from the screen (if there is any)
      });

      // Add the webcam stream. Position it on the bottom left and resize it to 100x100.
      merger.addStream(webcamStream, {
        x: 0,
        y: merger.height - 100,
        width: 100,
        height: 100,
        mute: false,
      });

      // Start the merging. Calling this makes the result available to us
      merger.start();

      // We now have a merged MediaStream!
      //merger.result
      const video = document.querySelector("video");
      video.srcObject = merger.result;
      video.onloadedmetadata = function (e) {
        console.log(e)
        video.play();
      };
    },
    recordAudio() {
      try {
        console.log("record audio started");
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then((stream) => {
            this.stopButton.disabled = false;
            this.startAudioButton.disabled = true;
            this.startScreenButton.disabled = true;
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.ondataavailable = (e) => {
              console.log("record audio", e.data);
              this.chunks.push(e.data);
            };
            this.mediaRecorder.onstop = () => {
              console.log("record screen stopped");
              this.createMediaElement("audio", "audio/mp3", this.audioList);
              // this.videoList.push({ id: this.videoList.length + 1, value: "media" });
            };
            this.mediaRecorder.onerror = (e) => {
              console.log("error then ", e.error);
            };
            this.mediaRecorder.start(1000);
          })
          .catch((err) => {
            alert(`The following error occurred: ${err}`);
          });
      } catch (error) {
        console.log("error... audio", error);
      }
    },

    recordSCreen() {
      console.log("record screen started");

      navigator.mediaDevices
        .getDisplayMedia({
          mediaSource: "screen",
        })
        .then((stream) => {
          // this.recordAudio()
          this.stopButton.disabled = false;
          this.startAudioButton.disabled = true;
          this.startScreenButton.disabled = true;

          this.mediaRecorderVideo = new MediaRecorder(stream);
          this.mediaRecorderVideo.ondataavailable = (e) => {
            console.log("record screen", e.data);
            this.chunksVideo.push(e.data);
          };
          this.mediaRecorderVideo.onstop = () => {
            console.log("record screen stopped");
            this.createMediaElementVideo("video", this.chunksVideo[0].type, this.videoList);
            // this.stopRecording()
          };
          this.mediaRecorderVideo.start();
        })
        .catch((err) => {
          alert(`The following error occurred: ${err}`);
        });
    },

    // stops audio or video
    stopRecordingVideo() {
      console.log("stop recording video");
      //this.stopButton.disabled = true;
      //this.startAudioButton.disabled = false;
      // this.startScreenButton.disabled = false;
      this.mediaRecorderVideo.stop();
    },
    stopRecording() {
      console.log("stop recording audio");
      //this.stopButton.disabled = true;
      //this.startAudioButton.disabled = false;
      // this.startScreenButton.disabled = false;
      this.mediaRecorder.stop();
    },

    // creates the html element with the file
    createMediaElementVideo(mediaType, fileType, placeToAdd) {
      const blob = new Blob(this.chunksVideo, {
        type: fileType,
      });

      const mediaURL = window.URL.createObjectURL(blob);
      console.log("mediaUrl", mediaURL);
      const element = document.createElement(mediaType);
      element.setAttribute("controls", "");
      element.src = mediaURL;
      placeToAdd.insertBefore(element, placeToAdd.firstElementChild);
      this.mediaRecorderVideo = null;
      this.chunksVideo = [];

      this.stopButton.disabled = true;
      this.startAudioButton.disabled = false;
      this.startScreenButton.disabled = false;
    },
    createMediaElement(mediaType, fileType, placeToAdd) {
      const blob = new Blob(this.chunks, {
        type: fileType,
      });

      const mediaURL = window.URL.createObjectURL(blob);
      console.log("mediaUrl", mediaURL);
      const element = document.createElement(mediaType);
      element.setAttribute("controls", "");
      element.src = mediaURL;
      placeToAdd.insertBefore(element, placeToAdd.firstElementChild);
      this.mediaRecorder = null;
      this.chunks = [];

      this.stopButton.disabled = true;
      this.startAudioButton.disabled = false;
      this.startScreenButton.disabled = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
