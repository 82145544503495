<template>
    <div class="row g-0">
        <div class="col-12 d-flex">
            <div class="card card-chat border-right border-top-0 border-bottom-0 order-0 w380" :class="{ open: MobileToggle }">
                <div class="px-4 py-3 py-md-4">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control mb-1" placeholder="Search...">
                    </div>
                    <div class="nav nav-pills justify-content-between text-center" role="tablist">
                        <a class="flex-fill rounded border-0 nav-link"  href="javascript:void(0);"  v-on:click="setActiveNavtab('Chat')" :class="{ 'active': currentTab == 'Chat'}" aria-selected="true">Chat</a>
                        <a class="flex-fill rounded border-0 nav-link"  href="javascript:void(0);"  v-on:click="setActiveNavtab('Groups')" :class="{ 'active': currentTab == 'Groups'}" aria-selected="false">Groups</a>
                        <a class="flex-fill rounded border-0 nav-link"  href="javascript:void(0);"  v-on:click="setActiveNavtab('Contact')" :class="{ 'active': currentTab == 'Contact'}" aria-selected="false">Contact</a>
                    </div>
                </div>
                <div class="tab-content border-top">
                    <div class="tab-pane fade" id="chat-recent" role="tabpanel" :class="{ 'active show': currentTab == 'Chat'}">
                        <ul class="list-unstyled list-group list-group-custom list-group-flush mb-0">
                            <li class="list-group-item px-md-4 py-3 py-md-4" v-for="(list, index) in chat_tab1" :key="index">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" :src="list.img_src" alt="" v-if="list.img_src">
                                    <div class="avatar rounded-circle no-thumbnail" v-if="!list.img_src">{{ list.title | short_hand }}</div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="d-flex justify-content-between mb-0"><span>{{list.title}}</span> <small class="msg-time">{{list.time}}</small></h6>
                                        <span class="text-muted">{{list.title_details}}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="chat-groups" role="tabpanel" :class="{ 'active show': currentTab == 'Groups'}">
                        <ul class="list-unstyled list-group list-group-custom list-group-flush mb-0">
                            <li class="list-group-item px-md-4 py-3 py-md-4" v-for="(list, index) in chat_tab2" :key="index">
                                <a href="javascript:void(0);" class="d-flex">
                                    <div class="avatar rounded-circle no-thumbnail">{{ list.title | short_hand }}</div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="d-flex justify-content-between mb-0"><span>{{list.title}}</span> <small class="msg-time">{{list.time}}</small></h6>
                                        <span class="text-muted">{{list.title_details}}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="chat-contact" role="tabpanel" :class="{ 'active show': currentTab == 'Contact'}">
                        <ul class="list-unstyled list-group list-group-custom list-group-flush mb-0">
                            <li class="list-group-item px-md-4 py-3 py-md-4"  v-for="(list, index) in chat_tab3" :key="index">
                                <a href="javascript:void(0);" class="d-flex">
                                    <img class="avatar rounded-circle" :src="list.img" alt="">
                                    <div class="flex-fill ms-3 text-truncate">
                                        <div class="d-flex justify-content-between mb-0">
                                            <h6 class="mb-0">{{list.name}}</h6>
                                            <div class="text-muted">
                                                <i class="ps-2" :class="list.like"></i>
                                                <i class="ps-2"  :class="list.action"></i>
                                            </div>
                                        </div>
                                        <span class="text-muted">{{list.email}}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card card-chat-body border-0 order-1 w-100 px-4 px-md-5 py-3 py-md-4">
                <div class="chat-header d-flex justify-content-between align-items-center border-bottom pb-3">
                    <div class="d-flex">
                        <a href="javascript:void(0);" title="">
                            <img class="avatar rounded" src="@/assets/images/xs/avatar2.jpg" alt="avatar">
                        </a>
                        <div class="ms-3">
                            <h6 class="mb-0">Orlando Lentz</h6>
                            <small class="text-muted">Last seen: 2 hours ago</small>
                        </div>
                    </div>
                    <div class="d-flex">
                        <a class="nav-link py-2 px-3 text-muted d-none d-lg-block" href="javascript:void(0);"><i class="fa fa-camera"></i></a>
                        <a class="nav-link py-2 px-3 text-muted d-none d-lg-block" href="javascript:void(0);"><i class="fa fa-video-camera"></i></a>
                        <a class="nav-link py-2 px-3 text-muted d-none d-lg-block" href="javascript:void(0);"><i class="fa fa-gear"></i></a>
                        <a class="nav-link py-2 px-3 text-muted d-none d-lg-block" href="javascript:void(0);"><i class="fa fa-info-circle"></i></a>
                        <a class="nav-link py-2 px-3 d-block d-xl-none chatlist-toggle" href="#" @click="MobileToggle = !MobileToggle"><i class="fa fa-bars"></i></a>

                        <!-- Mobile menu -->
                        <div class="nav-item list-inline-item d-block d-xl-none">
                            <b-dropdown  variant="link" no-caret menu-class="shadow border-0" togle-class="nav-link text-muted">
                                <template #button-content>
                                        <i class="fa fa-ellipsis-v"></i>
                                </template>
                                <b-dropdown-item href="#"><i class="fa fa-camera"></i> Share Images</b-dropdown-item>
                                <b-dropdown-item href="#"><i class="fa fa-video-camera"></i> Video Call</b-dropdown-item>
                                <b-dropdown-item href="#"><i class="fa fa-gear"></i>Settings</b-dropdown-item>
                                <b-dropdown-item href="#"><i class="fa fa-info-circle"></i>Info</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <!-- Chat: body -->
                <ul class="chat-history list-unstyled mb-0 py-lg-5 py-md-4 py-3 flex-grow-1" id="dd">
                    <!-- Chat: left -->
                    <template v-for="(message,index) in messages">
                        <li class="mb-3 d-flex flex-row align-items-end" v-if="message.type == 'sent'" :key="index">
                            <div class="max-width-70">
                                <div class="user-info mb-1">
                                    <img class="avatar sm rounded-circle me-1" :src="message.profile_img" alt="avatar">
                                    <span class="text-muted small">{{message.time}}</span>
                                </div>
                                <div class="card border-0 p-3">
                                    <div class="message"> {{message.message}} </div>
                                </div>
                                <div class="card border-0 p-3" v-if="message.attached_img">
                                    <div class="message">
                                        <img class="w120 img-thumbnail mr-1" :src="message.attached_img" alt="" />
                                       <img class="w120 img-thumbnail" :src="message.attached_img1" alt="" />
                                    </div>
                                </div>
                            </div>
                            <!-- More option -->
                            <chat-action></chat-action>
                        </li>
                        <!-- Chat: right -->
                        <li class="mb-3 d-flex flex-row-reverse align-items-end" v-if="message.type == 'received'" :key="index">
                            <div class="max-width-70 text-end">
                                <div class="user-info mb-1">
                                    <span class="text-muted small">{{message.time}}</span>
                                </div>
                                <div class="card border-0 p-3 bg-primary text-light">
                                    <div class="message">{{message.message}}</div>
                                </div>
                            </div>
                            <!-- More option -->
                            <chat-action></chat-action>
                        </li>
                    </template>
                </ul>

                <!-- Chat: Footer -->
                <div class="chat-message">
                    <button class="btn btn-dark" type="button" ref="message" @click="sendMessage()">Send</button>
                    <textarea type="text" class="form-control" placeholder="Enter text here..." v-model="message"></textarea>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import ChatAction from '@/components/core/ChatAction.vue'
export default {
    name:'ChatComponent',
    components: {
        'chat-action':ChatAction,
    },data() {
        return {
            currentTab: 'Chat',
            MobileToggle: false,
            message:'',
            chat_tab1:
            [
                {
                    img_src: require(`@/assets/images/xs/avatar1.jpg`),
                    title: "Chris Fox",
                    title_details: 'changed an issue from "In Progress" to',
                    time:"12:37 PM",
                },
                {
                    img_src: '',
                    title: "Robert Hammer",
                    title_details: 'It is a long established fact that a reader will be distracted',
                    time:"10:45 AM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar3.jpg`),
                    title: "Orlando Lentz",
                    title_details: 'There are many variations of passages',
                    time:"10:11 AM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar4.jpg`),
                    title: "Barbara Kelly",
                    title_details: 'Contrary to popular belief',
                    time:"Sat",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar2.jpg`),
                    title: "Chris Fox",
                    title_details: 'changed an issue from "In Progress" to',
                    time:"12:37 PM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar4.jpg`),
                    title: "Robert Fox",
                    title_details: 'It is a long established fact that a reader will be distracted',
                    time:"10:45 AM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar5.jpg`),
                    title: "Orlando Lentz",
                    title_details: 'There are many variations of passages',
                    time:"10:11 AM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar6.jpg`),
                    title: "Barbara Kelly",
                    title_details: 'Contrary to popular belief',
                    time:"Sat",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar8.jpg`),
                    title: "Chris Fox",
                    title_details: 'changed an issue from "In Progress" to',
                    time:"12:37 PM",
                },
                {
                    img_src: '',
                    title: "Robert Fox",
                    title_details: 'It is a long established fact that a reader will be distracted',
                    time:"10:45 AM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar3.jpg`),
                    title: "Orlando Lentz",
                    title_details: 'There are many variations of passages',
                    time:"10:11 AM",
                },
                {
                    img_src: require(`@/assets/images/xs/avatar4.jpg`),
                    title: "Barbara Kelly",
                    title_details: 'Contrary to popular belief',
                    time:"Sat",
                },
            ],
            chat_tab2:
            [
                {
                    title: "Design UI",
                    title_details: 'The point of using Lorem Ipsum',
                    time:"9/10/2020",
                },
                {
                    title: "Angular Dev Team",
                    title_details: 'If you are going to use a passage',
                    time:"22/8/2020",
                },
                {
                    title: "Account Team",
                    title_details: 'The point of using Lorem Ipsum',
                    time:"11/7/2020",
                },
            ],
            chat_tab3:
            [
                {
                    img: require(`@/assets/images/xs/avatar2.jpg`),
                    name: "Chris Fox",
                    email: 'chris.fox@alui.com',
                    like:'fa fa-heart',
                    action:'fa fa-trash'
                },
                {
                    img: require(`@/assets/images/xs/avatar3.jpg`),
                    name: "Barbara Kelly",
                    email: 'barbara.kelly@alui.com',
                    like:'fa fa-heart-o',
                    action:'fa fa-trash'
                },
                {
                    img: require(`@/assets/images/xs/avatar10.jpg`),
                    name: "Brian Swader",
                    email: 'brian.swader@alui.com',
                    like:'fa fa-heart-o',
                    action:'fa fa-trash'
                },
                {
                    img: require(`@/assets/images/xs/avatar7.jpg`),
                    name: "Richard Foos",
                    email: 'richard.foos@alui.com',
                    like:'fa fa-heart',
                    action:'fa fa-trash'
                },
                {
                    img: require(`@/assets/images/xs/avatar2.jpg`),
                    name: "Frank Camly",
                    email: 'frank.camly@alui.com',
                    like:'fa fa-heart-o',
                    action:'fa fa-trash'
                },
                {
                    img: require(`@/assets/images/xs/avatar2.jpg`),
                    name: "Brian Swader",
                    email: 'brianswader@alui.com',
                    like:'fa fa-heart-o',
                    action:'fa fa-trash'
                },

            ],
            messages:
            [
                {
                    type: 'sent',
                    profile_img: require(`@/assets/images/xs/avatar2.jpg`),
                    time: "10:10 AM, Today",
                    message: "Hi Aiden, how are you?",
                    attached_img: ""
                },
                {
                    type: 'received',
                    time: "10:12 AM, Today",
                    message: "Are we meeting today?"
                },
                {
                    type: 'sent',
                    profile_img: require(`@/assets/images/xs/avatar2.jpg`),
                    time: "10:10 AM, Today",
                    message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
                    attached_img: ""
                },
                {
                    type: 'sent',
                    profile_img: require(`@/assets/images/xs/avatar2.jpg`),
                    time: "10:10 AM, Today",
                    message: "Contrary to popular belief, Lorem Ipsum is not simply random text.",
                    attached_img: ""
                },
                {
                    type: 'received',
                    time: "10:12 AM, Today",
                    message: "Yes, Orlando Allredy done.There are many variations of passages of Lorem Ipsum available"
                },
                {
                    type: 'sent',
                    profile_img: require(`@/assets/images/xs/avatar2.jpg`),
                    time: "10:12 AM, Today",
                    message: "Please find attached images",
                    attached_img:  require(`@/assets/images/gallery/1.jpg`),
                    attached_img1:  require(`@/assets/images/gallery/2.jpg`),
                },
                {
                    type: 'received',
                    time: "10:12 AM, Today",
                    message: "Okay, will check and let you know."
                },
            ]
        }
    },methods:{
        setActiveNavtab: function (tab) {
            this.currentTab = tab 
        },
        sendMessage(){
            const message = {};
            message.type = "received";
            message.profile_img = "assets/images/xs/avatar2.jpg";
            message.time = "11:50";
            message.message = this.message;
            this.messages.push(message);
            this.message = "";
            this.$nextTick(() => {
                var container = this.$el.querySelector("#dd");
                container.scrollTop = container.scrollHeight;
            })
        }
    },filters: {
        short_hand (company_name) {
            let words = company_name.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand
        }
    }
}
</script>
<style scoped>

</style>