<template>
    <div class="col-12">
        <div class="bd-content">
            <h2 id="overview">Overview</h2>
            <p>Use the <code>v-b-tooltip</code> directive on any element or component where you would like a tooltip to appear.</p>
            <p>Things to know when using tooltip component:</p>
            <div class="alert alert-danger" role="alert">
                <strong>Tooltips</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/tooltip" target="_blank">Bootstrap Tooltips documentation <i class="fa fa-external-link"></i></a>
            </div>
            <ul> 
                <li>Tooltips rely on the 3rd party library <a href="https://popper.js.org/" target="_blank" rel="noopener">Popper.js</a> for positioning.</li> 
                <li>Tooltips require BootstrapVue's custom SCSS/CSS in order to function correctly, and for variants.</li> 
                <li>Triggering tooltips on hidden elements will not work.</li> 
                <li>Specify <code>container</code> as <code>null</code> (default, appends to <code>&lt;body&gt;</code>) to avoid rendering problems in more complex components (like input groups, button groups, etc.). You can use container to optionally specify a different element to append the rendered tooltip to.</li> 
                <li>Tooltips for <code>disabled</code> elements must be triggered on a wrapper element.</li> 
                <li>When triggered from hyperlinks that span multiple lines, tooltips will be centered. Use white-space: nowrap; on your <code>&lt;a&gt;</code>s, <code>&lt;b-link&gt;</code>s and <code>&lt;router-link&gt;</code>s to avoid this behavior.</li> 
            </ul>
            <div class="card card-callout p-3">
                <span>The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/reference/accessibility#reduced-motion">reduced motion section of our accessibility documentation</a>.</span>
            </div>
            <div class="border-top mt-5 pt-3">
                <h2 id="examples">Positioning<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#examples" style="padding-left: 0.375em;"></a></h2>
                <p>Twelve options are available for positioning: <code>top</code>, <code>topleft</code>, <code>topright</code>, <code>right</code>, <code>righttop</code>, <code>rightbottom</code>, <code>bottom</code>, <code>bottomleft</code>, <code>bottomright</code>, <code>left</code>, <code>lefttop</code>, and <code>leftbottom</code> aligned. The default position is <code>top</code>. Positioning is relative to the trigger element.</p>
                <b-tabs nav-class="tab-card px-3 border-bottom-0">
                <b-tab title="Preview" active>
                    <b-card class="mb-3">
                        <b-button class="mr-1" v-b-tooltip.hover.top="'Tooltip on top'">Tooltip on top</b-button>
                        <b-button class="mr-1" v-b-tooltip.hover.right="'Tooltip on right'">Tooltip on right</b-button>
                        <b-button class="mr-1" v-b-tooltip.hover.bottom="'Tooltip on bottom'">Tooltip on bottom</b-button>
                        <b-button class="mr-1" v-b-tooltip.hover.left="'Tooltip on left'">Tooltip on left</b-button>
                    </b-card>
                </b-tab>
                <b-tab title="HTML">
                    <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-button class=&quot;mr-1&quot; v-b-tooltip.hover.top=&quot;&#039;Tooltip on top&#039;&quot;&gt;Tooltip on top&lt;/b-button&gt;
&lt;b-button class=&quot;mr-1&quot; v-b-tooltip.hover.right=&quot;&#039;Tooltip on right&#039;&quot;&gt;Tooltip on right&lt;/b-button&gt;
&lt;b-button class=&quot;mr-1&quot; v-b-tooltip.hover.bottom=&quot;&#039;Tooltip on bottom&#039;&quot;&gt;Tooltip on bottom&lt;/b-button&gt;
&lt;b-button class=&quot;mr-1&quot; v-b-tooltip.hover.left=&quot;&#039;Tooltip on left&#039;&quot;&gt;Tooltip on left&lt;/b-button&gt;</code>
</pre>                 
                    </b-card>
                </b-tab>
            </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'TooltipsComponent',
    data() {
      return {
       
      }
    },methods:{
        
    },mounted() {
        Prism.highlightAll()
        
    }
}
</script>
