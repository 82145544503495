<template>
    <div class="col-12">
        <div class="bd-content">

            <h2 id="examples">Examples</h2>
            <p>Alerts are available for any length of text, as well as an optional close button. For proper styling, use one of the eight <strong>required</strong> contextual classes (e.g., <code>.alert-success</code>). For inline dismissal, use the <a href="#dismissing">alerts JavaScript plugin</a>.</p>
            <div class="bd-example mb-5">
            
                <b-alert show variant="primary">A simple primary alert—check it out!</b-alert>
                <b-alert show variant="secondary">A simple secondary alert—check it out!</b-alert>
                <b-alert show variant="success">A simple success alert—check it out!</b-alert>
                <b-alert show variant="danger">A simple danger alert—check it out!</b-alert>
                <b-alert show variant="warning">A simple warning alert—check it out!</b-alert>
                <b-alert show variant="info">A simple info alert—check it out!</b-alert>
                <b-alert show variant="light">A simple light alert—check it out!</b-alert>
                <b-alert show variant="dark">A simple dark alert—check it out!</b-alert>
<pre>
<code class="language-html" data-lang="html">&lt;b-alert role=&quot;alert&quot; show variant=&quot;primary&quot;&gt;A simple primary alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;secondary&quot;&gt;A simple secondary alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;success&quot;&gt;A simple success alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;danger&quot;&gt;A simple danger alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;warning&quot;&gt;A simple warning alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;info&quot;&gt;A simple info alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;light&quot;&gt;A simple light alert&mdash;check it out!&lt;/b-alert&gt;
&lt;b-alert role=&quot;alert&quot; show variant=&quot;dark&quot;&gt;A simple dark alert&mdash;check it out!&lt;/b-alert&gt;</code>
</pre>
            </div> <!-- example end  -->

            <div class="bd-callout bd-callout-info">
                <h5 id="conveying-meaning-to-assistive-technologies">Conveying meaning to assistive technologies</h5>
                <p>Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden with the <code>.visually-hidden</code> class.</p>
            </div>                        
            <h3 id="link-color">Link color</h3>
            <p>Use the <code>.alert-link</code> utility class to quickly provide matching colored links within any alert.</p>
            <div class="bd-example mb-5">
            
                <b-alert show variant="primary" role="alert">
                    A simple primary alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="secondary" role="alert">
                    A simple secondary alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="success" role="alert">
                    A simple success alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="danger" role="alert">
                    A simple danger alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="warning" role="alert">
                    A simple warning alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="info" role="alert">
                    A simple info alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="light" role="alert">
                    A simple light alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
                <b-alert show variant="dark" role="alert">
                    A simple dark alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
<pre>
<code class="language-html" data-lang="html">&lt;b-alert show variant=&quot;primary&quot; role=&quot;alert&quot;&gt;
    A simple primary alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;secondary&quot; role=&quot;alert&quot;&gt;
    A simple secondary alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;success&quot; role=&quot;alert&quot;&gt;
    A simple success alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;danger&quot; role=&quot;alert&quot;&gt;
    A simple danger alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;warning&quot; role=&quot;alert&quot;&gt;
    A simple warning alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;info&quot; role=&quot;alert&quot;&gt;
    A simple info alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;light&quot; role=&quot;alert&quot;&gt;
    A simple light alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;
&lt;b-alert show variant=&quot;dark&quot; role=&quot;alert&quot;&gt;
    A simple dark alert with &lt;a href=&quot;#&quot; class=&quot;alert-link&quot;&gt;an example link&lt;/a&gt;. Give it a click if you like.
&lt;/b-alert&gt;</code>
    </pre>
            </div> <!-- example end  -->


            <h3 id="additional-content">Additional content</h3>
            <p>Alerts can also contain additional HTML elements like headings, paragraphs and dividers.</p>
            <div class="bd-example mb-5">
                <b-alert show variant="success">
                    <h4 class="alert-heading">Well done!</h4>
                    <p>
                    Aww yeah, you successfully read this important alert message. This example text is going to
                    run a bit longer so that you can see how spacing within an alert works with this kind of
                    content.
                    </p>
                    <hr>
                    <p class="mb-0">
                    Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
                    </p>
                </b-alert>
<pre>
<code class="language-html" data-lang="html">&lt;b-alert show variant=&quot;success&quot;&gt;
    &lt;h4 class=&quot;alert-heading&quot;&gt;Well done!&lt;/h4&gt;
    &lt;p&gt;Aww yeah, you successfully read this important alert message. This example text is going torun a bit longer so that you can see how spacing within an alert works with this kind of
    content.&lt;/p&gt;
    &lt;hr&gt;
    &lt;p class=&quot;mb-0&quot;&gt;Whenever you need to, be sure to use margin utilities to keep things nice and tidy.&lt;/p&gt;
&lt;/b-alert&gt;</code>
</pre>
            </div> <!-- example end  -->
            
            
            <h3 id="dismissing">Dismissing</h3>
            <p>Using the alert JavaScript plugin, it’s possible to dismiss any alert inline. Here’s how:</p>
            <ul>
                <li>Be sure you’ve loaded the alert plugin, or the compiled Bootstrap JavaScript.</li>
                <li>Add a <a href="/docs/5.0/components/close-button/">close button</a> and the <code>dismissible</code> class, which adds extra padding to the right of the alert and positions the close button.</li>
                <li>On the close button, add the <code>dismiss-label="alert"</code> attribute, which triggers the JavaScript functionality. Be sure to use the <code>&lt;button&gt;</code> element with it for proper behavior across all devices.</li>
                <li>To animate alerts when dismissing them, be sure to add the <code>.fade</code> and <code>.show</code> classes.</li>
            </ul>
            <p>You can see this in action with a live demo:</p>
            <div class="bd-example mb-5">
                <b-alert show dismissible dismiss-label="alert"  variant="warning">
                   <strong>Holy guacamole!</strong> You should check in on some of those fields below
                </b-alert>
<pre>
<code class="language-html" data-lang="html">&lt;b-alert show dismissible dismiss-label=&quot;alert&quot; variant=&quot;warning&quot;&gt;
    &lt;strong&gt;Holy guacamole!&lt;/strong&gt; You should check in on some of those fields below
&lt;/b-alert&gt;</code>
</pre>
            </div> <!-- example end  -->
            
            <div class="card p-3 mb-3">
                When an alert is dismissed, the element is completely removed from the page structure. If a keyboard user dismisses the alert using the close button, their focus will suddenly be lost and, depending on the browser, reset to the start of the page/document. For this reason, we recommend including additional Properties  that listens for the <code>dismiss</code> event and programmatically sets <code>dismissed</code> to the most appropriate location in the page.
            </div>
            
            <p>Or with <code>data</code> attributes on a button <strong>within the alert</strong>, as demonstrated above:</p>
            <div class="bd-example mb-5">
    <pre><code class="language-html" data-lang="html"><span class="p">&lt;</span><span class="nt">button</span> <span class="na">type</span><span class="o">=</span><span class="s">"button"</span> <span class="na">dismiss-label</span><span class="o">=</span><span class="s">"Close"</span><span class="p">&gt;&lt;/</span><span class="nt">button</span><span class="p">&gt;</span></code></pre>
            </div> <!-- example end  -->

            <p>Note that closing an alert will remove it from the DOM.</p>
            <h3 id="methods">Properties</h3>
             <!-- example end  -->
            <table class="table">
                <thead>
                    <tr>
                        <th>Properties</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                <tbody>
                    <tr>
                        <td>
                        <code>dismiss-label</code>
                        </td>
                        <td>
                        When set, enables the dismiss close button
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <code>dismissible</code>
                        </td>
                        <td>
                        Destroys an element's alert.
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <code>fade</code>
                        </td>
                        <td>
                        When set to `true`, enables the fade animation/transition on the component
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3 id="events">Events</h3>
            <p>Bootstrap’s alert plugin exposes a few events for hooking into alert functionality.</p>
            <table class="table">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><code>dismiss-count-down</code></td>
                        <td>
                        When prop show is a number, this event emits every second on countdown.
                        </td>
                    </tr>
                    <tr>
                        <td><code>dismissed</code></td>
                        <td>
                        Alert dismissed either via the dismiss close button or when the dismiss countdown has expired.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'AlertsComponent',
    mounted() {
        Prism.highlightAll()
    }
}
</script>
<style lang="scss">

</style>