<template>
    <div class="modal-body bg-gray">
        <form @submit.prevent = "saveData" >
            <div class="full">
                <div class="row border mb-3">
                    <div class="col-md-12 pt-0 ">
                        <div class="row py-2 px-3">
                            <div class="col-md-4 mb-2">
                                <label for="nombre" class="">Fecha de compra:</label>
                                <input  v-model="purchase.fecha_compra" class="form-control" type="date" name="nombre" id="nombre">
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="nombre" class="">Fecha de pago:</label>
                                <input  v-model="purchase.fecha_pago" class="form-control" type="date" name="nombre" id="nombre">
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="estado" class="">Proveedor:</label>
                                <b-form-select required class="form-control select text-black"  v-model="purchase.proveedor_id" :options="providers" value-field="id" text-field="nombre">
                                </b-form-select>                     
                            </div>
                        </div>
                    </div>
                </div>       
                <div class="row">
                    <!-- categorias -->
                    
                    <div class="col-sm-12 p-0 col-md-8 border border-2">
                        <div class="bg-primary py-1">
                            <h4 class="mt-1 text-light">Categorías</h4>
                        </div>
                        <!-- <h4> panel cat y prod</h4> -->
                        <div class="row">
                            <div class="inner only-this-horizon-scrollbar" style="">
                                <div class="scroll-container diff-size " >
                                    <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                                    <div @click="getProductByCategory(item.id)" class="scroll-section "  v-for="item in card_home" :key="item.id">
                                        <categories-vue :product="item" class="zoom" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- panel derecho -->
                    <div class="col-sm-12 pr-0 pl-0 pl-lg-3  mt-4 col-md-4 span6 cel_big2 text-center" >
                        <div class="ml-1">
                            <div class="bg-primary py-1">
                                <h4 class="mt-1 text-light">Resumen</h4>
                            </div>
                            <div class="border">
                                
                                <b-list-group>
                                <!-- <b-list-group-item v-for="it of cart" :key="it.id" button>{{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}}</b-list-group-item> -->
                                    <div v-for="it of cart" :key="it.id" button>
                                        <div class=" px-3  hover">
                                            <div class="border-top border-bottom row py-3 hover">
                                                <!-- <div @click="deleteProduct(it)" class="col-1 text-right"><i class="fa fa-trash text-red" /></div>
                                                <div class="col-6 text-left">{{it.nombre + '/$' + it.precio_venta}}</div> -->
                                                <div class="col-6 text-left"> <i @click="deleteProduct(it)"  class="fa fa-trash text-red" />   {{it.nombre + '/$' + it.precio_compra}}</div>                                                
                                                <div class="col-2 text-left">{{it.cantidad}}</div>
                                                <div class="col-3 text-left">${{(it.precio_compra * it.cantidad).toFixed(2) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </b-list-group>
                                <div class="row mt-3 px-4">
                                    <div class="col-md-12 mb-2 mt-2 text-left">
                                        <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0" > 
                                            <span for="observaciones" class="h6">Iva</span>
                                        </b-form-checkbox>
                                    </div>
                                    <div class="col-6 text-left mb-1"><span>sub-total:</span></div>
                                    <div class="col-6 text-left mb-1">${{subtotal.toFixed(2)}}</div>
                                    <div class="col-6 text-left mb-1">iva:</div>
                                    <div class="col-6 text-left mb-1">${{iva.toFixed(2)}}</div>
                                    <div class="col-6 text-left mb-1" style="font-size:16px"><b>TOTAL:</b></div>
                                    <div class="col-6 text-left mb-1" style="font-size:16px"><b>${{total.toFixed(2)}}</b></div>
                                    <hr class="hr my-2">
                                    <div class="col-12 text-left ">
                                        <label for="categoria" class="">Referencia:</label>
                                        <input class="form-control" type="text" v-model="purchase.referencia" name="" id="">
                                    </div>
                                    <div class="col-12 text-left pt-2">
                                        <label for="categoria" class="">Estado:</label>
                                        <b-form-select required class="form-control select text-black"  v-model="purchase.estado" :options="estados" value-field="nombre" text-field="nombre">
                                        </b-form-select>
                                    </div>
                                </div>
                                <!-- <div class="row mt-3">
                                    <span>Cliente:</span>
                                    <div class="col-2">
                                        <b-button class="btn btn-secondary w-10" @click="gotoClientes()">+</b-button>
                                    </div>
                                    <div class="col-10">
                                        <input class="form-control" type="text" v-model="nombreCliente" name="" id="">
                                    </div>
                                </div> -->
                                <div class="row mt-3 p-3">
                                    <div class="col-6">
                                        <b-button @click="cleanSale()" class="btn btn-secondary w-100" >Cancelar</b-button>                        
                                    </div>
                                    <div class="col-6">
                                        <b-button  type="submit" class="btn btn-primary w-100">Guardar</b-button>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tabla productos -->
                    <div class="row mt-5">
                            <div class="col-md-8 p-0">
                                <div class="row text-center">
                                    <div class="bg-primary py-1">
                                        <h4 class="mt-1 text-light">Productos</h4>
                                    </div>
                                    <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                                    <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-3">
                                        <h5 class="text-alert pt-2"><i class="fa fa-exclamation-triangle "></i> No hay productos en esta categoría </h5>
                                    </div>
                                    <div v-if="category_id == 0" class="bg-alert-primary mt-3">
                                        <h5 class="text-alert pt-2"><i class="fa fa-exclamation-triangle "></i> Selecciona una categoría</h5>
                                    </div>
                                    
                                        <div @click="showModal(item)"  class="col-lg-3 py-2 col-md-4 py-0 px-2 mt-3"  v-for="item in productList" :key="item.id">
                                            <product-vue :product="item" class="zoom" />
                                        </div>
                                    
                                </div>
                            </div>
                    </div>
                </div>

                <b-modal id="exampleModalCenter" tabindex="-1" class="modal-lg" centered  title="Agregar producto" hide-footer>
                    <template v-slot:modal-header-close>
                        <button type="button" class="btn btn-close"></button>
                    </template>
                    <div class="modal-body bg-gray">
                        <form @submit.prevent="addProductToCart()">
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <label for="nombre" class="">Producto:</label>
                                    <input readonly v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre">
                                </div>
                                <div class="col-md-12 mb-2">
                                    <label for="nombre" class="">Descripción:</label>
                                    <input v-model="row.descripcion" class="form-control" type="text" name="descripcion" id="descripcion">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Cantidad:</label>
                                    <input type="number" step="0.01" min="0.01"  v-model="row.cantidad" class="form-control"  name="cantidad" id="cantidad">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Precio compra: $</label>
                                    <input  required step="0.01" min="0.01" v-model="row.precio_compra" class="form-control" type="text" name="precio_compra" id="precio_venta">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Precio venta: $</label>
                                    <input  required step="0.01" min="0.01" v-model="row.precio_venta" class="form-control" type="text" name="precio_venta" id="precio_venta">
                                </div>
                                <div class="col-md-4 mb-2">
                                    <label for="nombre" class="">Total:</label>
                                    <input readonly required :value="'$' + (row.precio_compra * (row.cantidad ? row.cantidad : 1))" class="form-control" type="text" name="precio_compra" id="precio_compra">
                                </div>
                            </div>
                        <div class="text-right">
                            <b-button class="btn btn-secondary mr-2" @click="$bvModal.hide('exampleModalCenter')">Cancelar</b-button>
                            <input class="btn btn-primary mr-2" type="submit" value="Agregar">
                        </div>
                        </form>
                    </div>
                </b-modal>
            </div>
            <div class="movil">
                <div class="row border mb-3">
                    <div class="col-md-12 pt-0 ">
                        <div class="row py-2 px-1">
                            <div class="col-md-4 mb-2">
                                <label for="nombre" class="">Fecha de compra:</label>
                                <input  v-model="purchase.fecha_compra" class="form-control" type="date" name="nombre" id="nombre">
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="nombre" class="">Fecha de pago:</label>
                                <input  v-model="purchase.fecha_pago" class="form-control" type="date" name="nombre" id="nombre">
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="estado" class="">Proveedor:</label>
                                <b-form-select required class="form-control select text-black"  v-model="purchase.proveedor_id" :options="providers" value-field="id" text-field="nombre">
                                </b-form-select>                     
                            </div>
                        </div>
                    </div>
                </div>       
                <div class="row">
                    <!-- categorias -->
                    <div class="bg-primary mt-3 py-1">
                        <h4 class="mt-1 text-light text-center">Categorias</h4>
                    </div>
                    <div class="col-12 p-0 border-2">
                        
                        <!-- <h4> panel cat y prod</h4> -->
                        <div class="p-0">
                            <div class="inner p-0 only-this-horizon-scrollbar" style="">
                                <div class="scroll-container diff-size " >
                                    <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                                    <div @click="getProductByCategory(item.id)" class="scroll-section "  v-for="item in card_home" :key="item.id">
                                        <categories-vue :product="item" class="zoom" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- panel derecho -->
                    
                    <!-- tabla productos -->
                    <div class="bg-primary mt-3 py-1">
                        <h4 class="mt-1 text-light text-center">Productos</h4>
                    </div>
                    <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-5">
                        <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle "></i> No hay productos en esta categoría </h6>
                    </div>
                    <div v-if="category_id == 0" class="bg-alert-primary mt-5">
                        <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle "></i> Selecciona una categoría</h6>
                    </div>
                    <div class="mt-3 p-1">
                            <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                        <div @click="showModal(item)" class=" px-0 "  v-for="item in productList" :key="item.id">
                            <product-vue :product="item" class="zoom py-2" />
                        </div>
                        

                    </div>
                    <div class="col-sm-12 pr-0 pl-0 pl-lg-3  mt-4 col-md-4 span6  text-center" >
                        <div class="ml-1">
                            <div class="bg-primary py-1">
                                <h4 class="mt-1 text-light">Resumen</h4>
                            </div>
                            <div class="border">
                                
                                <b-list-group>
                                <!-- <b-list-group-item v-for="it of cart" :key="it.id" button>{{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}}</b-list-group-item> -->
                                    <div v-for="it of cart" :key="it.id" button>
                                        <div class=" px-3  hover">
                                            <div class="border-top border-bottom row py-3 hover">
                                                <!-- <div @click="deleteProduct(it)" class="col-1 text-right m0"></div> -->
                                                <div class="col-6 text-left"> <i @click="deleteProduct(it)"  class="fa fa-trash text-red" />   {{it.nombre + '/$' + it.precio_venta}}</div>
                                                <div class="col-1">{{it.cantidad}}</div>
                                                <div class="col-2 text-left">${{(it.precio_venta * it.cantidad).toFixed(2) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </b-list-group>
                                <div class="row mt-3 px-3">
                                    <div class="col-md-12 mb-2 mt-2 text-left">
                                        <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0" > 
                                            <span for="observaciones" class="h6">Iva</span>
                                        </b-form-checkbox>
                                    </div>
                                    <div class="col-6 text-left mb-1"><span>sub-total:</span></div>
                                    <div class="col-6 text-left mb-1">${{subtotal.toFixed(2)}}</div>
                                    <div class="col-6 text-left mb-1">iva:</div>
                                    <div class="col-6 text-left mb-1">${{iva.toFixed(2)}}</div>
                                    <div class="col-6 text-left mb-1" style="font-size:16px"><b>TOTAL:</b></div>
                                    <div class="col-6 text-left mb-1" style="font-size:16px"><b>${{total.toFixed(2)}}</b></div>
                                    <hr class="hr my-2">
                                    <div class="col-12 text-left ">
                                        <label for="categoria" class="">Referencia:</label>
                                        <input class="form-control" type="text" v-model="purchase.referencia" name="" id="">
                                    </div>
                                    <div class="col-12 text-left ">
                                        <label for="categoria" class="">Estado:</label>
                                        <b-form-select required class="form-control select text-black"  v-model="purchase.estado" :options="estados" value-field="nombre" text-field="nombre">
                                        </b-form-select>
                                    </div>
                                </div>
                                <!-- <div class="row mt-3">
                                    <span>Cliente:</span>
                                    <div class="col-2">
                                        <b-button class="btn btn-secondary w-10" @click="gotoClientes()">+</b-button>
                                    </div>
                                    <div class="col-10">
                                        <input class="form-control" type="text" v-model="nombreCliente" name="" id="">
                                    </div>
                                </div> -->
                                <div class="row mt-3 p-3">
                                    <div class="col-6">
                                        <b-button @click="cleanSale()" class="btn btn-secondary w-100" >Cancelar</b-button>                        
                                    </div>
                                    <div class="col-6">
                                        <b-button  type="submit" class="btn btn-primary w-100">Guardar</b-button>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </form>
    </div>

</template>

<script>
import ProductVue from "./ProductPurchase.vue";
import CategoriesVue from "./Category.vue";

export default {
    components: { ProductVue, CategoriesVue},
    data(){
        return{
            fields: [
                { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
                { key: 'cliente', label: 'Cliente', sortable: true, class: 'text-center' },
                { key: 'fecha', label: 'Fecha ', sortable: true, class: 'text-center' },
                { key: 'sucursal', label: 'Sucursal', sortable: true, class: 'text-center' },
                { key: 'total_venta', label: 'Total Venta ', sortable: true, class: 'text-center' },
                { key: 'total_costos', label: 'Total costos ', sortable: true, class: 'text-center' },
                { key: 'Margen', label: 'Margen', sortable: true, class: 'text-center' },
                { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
                { key: 'action', label: '',tdClass:'dt-body-right'},

            {
                sortable: true,
                sortByFormatted: true,
                filterByFormatted: true
            },
            ],
            fields2: [
                { key: 'id', label: 'id', sortable: true, sortDirection: 'desc' },
                { key: 'articulo', label: 'Articulo', sortable: true, class: 'text-center' },
                { key: 'cantidad', label: 'Cantidad ', sortable: true, class: 'text-center' },
                { key: 'precio_unit', label: 'Precio unit', sortable: true, class: 'text-center' },
                { key: 'sub_total', label: 'Sub total ', sortable: true, class: 'text-center' },
                
                { key: 'action', label: 'acción',tdClass:'dt-body-right'},
                {
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true
                },
            ],
            items: [],
            row:{},
            card_home:[
            ],
            table: 'meca_categorias',
            company_id: 0,
            userId:0,
            rol:0,
            productList: [],
            cart: [],
            estados:[
                {nombre:"Pagada"},
                {nombre:"Pendiente"},
            ],
            subtotal: 0,
            total:0,
            iva:0,
            con_iva:1,
            cliente_id: 0,
            nombreCliente: 'Cliente Genérico',
            providers:[],
            purchase:{},
            referencia: '',
            category_id: 0
        }
    },
    async mounted(){
        try {
            this.$isLoading(true)
            this.cleanSale()
            this.userId = localStorage.getItem('user_id');
            this.company_id = localStorage.getItem('company_id');
            this.rol = localStorage.getItem('rol');
            await this.getCategories()
            await this.getProvider()
            this.$isLoading(false)
        } catch (error) {
            this.$isLoading(false)
        }
    },
    methods:{
        cleanSale(){
            this.purchase.estado="Pagada"
            this.purchase.fecha_compra= this.$moment().utc().format('YYYY-MM-DD')
            this.purchase.fecha_pago=  this.$moment().utc().format('YYYY-MM-DD')
            this.cart = []
            this.productList = []
            this.calculateTotal()

        },
        gotoClientes(){
        },
        deleteProduct(item){
            let tt = this.cart.indexOf(item)
            console.log(tt)
            this.cart.splice(tt,1)
            this.calculateTotal()
            //alert()
        },
        calculateTotal(){
            this.subtotal = this.cart.map(item => (item.cantidad * item.precio_compra )).reduce((p,c) => p + c, 0)
            if(this.con_iva==1){
                this.iva = this.subtotal * 0.15
            }
            else{
                this.iva = 0
            }
            this.total = this.subtotal + this.iva
        },
        addProductToCart(){
            console.log('add')
            this.cart.push(this.row)
            this.row = {}
            this.row.estado="Pagada"
            this.row.fecha_compra= this.$moment().utc().format('YYYY-MM-DD')
            this.row.fecha_pago=  this.$moment().utc().format('YYYY-MM-DD')

            this.calculateTotal()
            this.$bvModal.hide('exampleModalCenter')

        },
        showModal(item){
            this.row = Object.assign({} , item)
            this.row.cantidad = 1
            this.$bvModal.show('exampleModalCenter')
        },
        async getProductByCategory(item){
            console.log('getProductByCategory')
            try {
                this.$isLoading(true)
                let res = await this.$store.dispatch('get', {path: 'meca_productos/getAllByCategoryCompany/' + this.company_id+'/'+ item});
                this.$isLoading(false)
                this.category_id = item.id
                if (res.length >0){
                    console.log(this.productList)
                    this.productList = res
                }
                else{
                    this.productList = []
                }
            } catch (error) {
                this.productList = []
                console.error('error', error)
            }
        },
        async getProvider(){
            try {
                this.$isLoading(true)                
                let res = await this.$store.dispatch('get', {path: 'meca_proveedores/getAllByCompany/' + this.company_id});
                this.$isLoading(false)
                if (res.length >0){
                    this.providers = res
                }
            } catch (error) {
                this.providers = []
                console.error('error', error)
            }
        },
        async getCategories(){
            try {
                this.$isLoading(true)                
                let res = await this.$store.dispatch('get', {path: this.table + '/getAllByCompany/' + this.company_id});
                this.$isLoading(false)
                if (res.length >0){
                    this.card_home = res
                }
            } catch (error) {
                this.card_home = []
                console.error('error', error)
            }
        },
        async saveData(){
            try {
                console.log('save')
                if(this.cart.length == 0){
                    this.$swal({title:"Aviso", text:"Debes seleccionar un producto." , icon:"error"})
                    return
                }
                //let data = Object.assign({} , this.row)
                //data.empresa_id = this.company_id
                let res
                this.$isLoading(true)

                // total:this.total,
                // empresa_id: this.company_id,
                // usuario_id: this.userId,
                // estado: 'Pagada',
                // por_iva: 15,
                // fecha_i: this.$moment().utc(),
                // fecha_f: this.$moment().utc(),
                this.purchase.empresa_id = this.company_id
                this.purchase.usuario_id = this.userId
                this.purchase.total = this.total
                this.purchase.iva = this.iva
                let data = {
                    purchase : this.purchase,
                    detail: this.cart
                }
                res = await this.$store.dispatch('post', {path: 'meca_compras/new', data: data});
                this.$isLoading(false)

                if (res.error){
                    this.$isLoading(false)
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.cleanSale()
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                    this.$bvModal.hide('exampleModalCenter')

                }
            } catch (error) {
                this.items = []
                console.error('error', error)
            }
        },
        async deleteItem(item){
            const {isConfirmed} = await this.$swal({
                title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                icon:"question",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                reverseButtons: true

            })
            if(isConfirmed){
                try {
                    let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.getData()
                        this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.items = []
                    console.error('error--->', error)
                }                    
            }
        },
    }
}
</script>

<style >
.hover{

}
.hover:hover{
    background-color: rgba(75, 133, 105, 0.034);
}

.border-2{
    
    border-radius: 5px;
    /* padding:5px; */
    text-align: center !important;
}

/* carusel */
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 98%;
  
  scroll-snap-type: mandatory;
  scroll-behavior: smooth;
  
  word-wrap: break-word !important; 
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;
  
  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}

.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}
.card-col{
margin-top:10px;
margin-bottom:10px
}

.cel_big2 {
  right:0px;
  /* border:1px solid red; */
  /* //height: 100% !important; */
  /* background-color: rgb(231, 231, 231); */
  position:absolute;
  height: 800px;
  bottom:10px;
  top:100px;
    min-height: 600px;

}
.hr{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.text-red{
    color:red;
}
.movil{
    display: none;
}
.full{
    display: block;
}
@media screen and (max-width: 720px)  {
    .movil{
        display: block;
    }
    .full{
        display: none;
    }
    .cel_big2 {
        position:static;  
        min-height: 0px !important;
    }

}
@media screen and (max-width: 1440px) and (min-width: 720px){ 
  .col-md-1{ width: 8.33%; }    
  .col-md-2{ width: 16.33%; }   
  .col-md-3{ width: 25%; }   
  .col-md-4{ width: 33.33%; }   
  .col-md-5{ width: 41.33%; }  
  .col-md-6{ width: 49%; } 
  .col-md-7{ width: 57.33%; }  
  .col-md-8{ width: 65.33%; }   
  .col-md-9{ width: 73%; } 
  .col-md-10{ width: 81.33%; }  
  .col-md-11{ width: 89.33%; }  
  .col-md-12{ width: 100%; }  
}
</style>