<template>
    <div class="col-12">
        <div class="bd-content">
            <h2 id="overview">Overview</h2>
            <p>Use the <code class="text-nowrap" translate="no">v-b-popover</code> directive on any <strong>element</strong> or <strong>component</strong> where you would like a popover to appear.</p>
            <p>Things to know when using the popover directive:</p>
            <div class="alert alert-danger" role="alert">
                <strong>Popovers</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/directives/popover" target="_blank">Bootstrap Popovers documentation <i class="fa fa-external-link"></i></a>
            </div>
            <ul> 
                <li>Popovers rely on the 3rd party library <a href="https://popper.js.org/" target="_blank" rel="noopener">Popper.js</a> for positioning.</li> 
                <li>Popovers require BootstrapVue's custom SCSS/CSS in order to function correctly, and for variants.</li> 
                <li>If both title and content is not provided (or are an empty string), the popover will not show.</li> 
                <li>Specify container: 'body' (default) to avoid rendering problems in more complex components (like input groups, button groups, etc.).</li>
                <li>Triggering popovers on hidden elements will not work.</li> <li>Popovers for <code class="text-nowrap" translate="no">disabled</code> elements must be triggered on a wrapper element.</li> 
                <li>When triggered from hyperlinks that span multiple lines, popovers will be centered. Use white-space: nowrap; on your <code class="text-nowrap" translate="no">&lt;a&gt;</code>s, <code class="text-nowrap" translate="no">&lt;b-link&gt;</code>s or <code class="text-nowrap" translate="no">&lt;router-link&gt;</code>s b to avoid this behavior.</li> 
                <li>Elements that trigger popovers should be in the document tab sequence. Add <code class="text-nowrap" translate="no">tabindex="0"</code> if required.</li> 
            </ul>
            <div class="card card-callout p-3">
                <span>The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/reference/accessibility#reduced-motion">reduced motion section of our accessibility documentation</a>.</span>
            </div>
            
            <h4 class="mt-5">Example</h4>
            <div class="card mb-3">
                <div class="card-body">
                   <b-button v-b-popover.click.blur="'Content'"  title="Popover" variant="danger" size="lg">Click to toggle popover</b-button>
    <pre class="language-html" data-lang="html">
    <code>&lt;b-button v-b-popover.click.blur=&quot;&#039;Content&#039;&quot;  title=&quot;Popover&quot; variant=&quot;danger&quot; size=&quot;lg&quot;&gt;Click to toggle popover&lt;/b-button&gt;</code>
    </pre>
                </div>
            </div>

            <h5>Four directions</h5>
            <div class="card mb-3">
                <div class="card-body">
                    <b-button v-b-popover.hover.focus.top="'Popover!'" class="mr-1">Popover on top</b-button>
                    <b-button v-b-popover.hover.focus.right="'Popover!'" class="mr-1" >Popover on right</b-button>
                    <b-button v-b-popover.hover.focus.bottom="'Popover!'" class="mr-1" >Popover on bottom</b-button>
                    <b-button v-b-popover.hover.focus.left="'Popover!'" class="mr-1" >Popover on left</b-button>

    <pre class="language-html" data-lang="html">
    <code>&lt;b-button v-b-popover.hover.focus.top=&quot;&#039;Popover!&#039;&quot; class=&quot;mr-1&quot;&gt;Popover on top&lt;/b-button&gt;
    &lt;b-button v-b-popover.hover.focus.right=&quot;&#039;Popover!&#039;&quot; class=&quot;mr-1&quot; &gt;Popover on right&lt;/b-button&gt;
    &lt;b-button v-b-popover.hover.focus.bottom=&quot;&#039;Popover!&#039;&quot; class=&quot;mr-1&quot; &gt;Popover on bottom&lt;/b-button&gt;
    &lt;b-button v-b-popover.hover.focus.left=&quot;&#039;Popover!&#039;&quot; class=&quot;mr-1&quot; &gt;Popover on left&lt;/b-button&gt;</code>
    </pre>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'PopoversComponent',
    data() {
      return {
        
      }
    },methods:{
        
    },mounted() {
        Prism.highlightAll()
        
    }
}
</script>
