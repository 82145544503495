<template>
    <div>
        <div class="row g-2 row-deck mb-2">
            <div class="col-xl-3 col-md-6" v-for="(content, index) in top_content" :key="index">
                <div class="card">
                    <div class="card-body">
                        <div class="float-right">
                            <i class="h4 ms-3" :class="content.icon"></i>
                        </div>
                        <h4 class="mt-0 pt-1">{{ content.count }}</h4>
                        <p class="text-muted mb-0">{{ content.projects }}</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="form-group mb-0">
                                <label>Search</label>
                                <div class="input-group mb-0">
                                    <input type="text" class="form-control" placeholder="Search..." aria-describedby="project-search-addon" />
                                    <div class="input-group-append">
                                        <button class="btn btn-danger" type="button" id="project-search-addon"><i class="fa fa-search search-icon font-12"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row">
            <div class="col-12">
                <b-tabs nav-class="tab-card mb-2 mt-4">
                        <b-tab v-for="(tab, index) in tabs" :key="index">
                            <template #title>
                                {{tab.tab_name}} <span class="badge" :class="tab.tab_class">{{ tab.tab_count }}</span>
                            </template>
                        </b-tab>
                </b-tabs>
                <div class="row g-2 row-deck">
                    <div class="col-lg-4 col-md-6 col-sm-12" v-for="(project, index) in projects" :key="index">
                        <teamsboard-component
                            :title = "project.title"
                            :sub_title = "project.sub_title"
                            :content = "project.content"
                            :items = "project.items"
                            :teams = "project.teams"
                            :start_end = "project.task_completed.start_end"
                            :classes = "project.task_completed.class"
                            :width = "project.task_completed.width"
                        ></teamsboard-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Teamsboard from '@/components/core/Teamsboard.vue'
export default {
    name:'TeamsBoardComponent',
    components: {
        'teamsboard-component':Teamsboard
    },
    data() {
        return {
            top_content:[
                {                
                    count: '24',
                    projects:'Total Projects',
                    icon:'fa fa-archive',
                },
                {                
                    count: '18',
                    projects:'Completed Projects',
                    icon:'fa fa-th',
                },
                {                
                    count: '06',
                    projects:'Pending Projects',
                    icon:'fa fa-file',
                },
            ],
            tabs:[
                {                
                    tab_name: 'Active',
                    tab_count:'5',
                    tab_class:'bg-success',
                },
                {                
                    tab_name: 'Paused',
                    tab_count:'3',
                    tab_class:'bg-info',
                },
                {                
                    tab_name: 'Waiting',
                    tab_count:'5',
                    tab_class:'bg-warning',
                },
                {                
                    tab_name: 'Delete',
                    tab_count:'2',
                    tab_class:'bg-danger',
                },
            ],
            projects:
            [
                {
                    title:'Orange Limited',
                    sub_title:'PSD to HTML onepage',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'124',
                        },
                        {
                            title:'Comments',
                            attachments:'452',
                        },
                        {
                            title:'Tasks',
                            attachments:'85'
                        }
                    ],
                    teams:[
                         require(`@/assets/images/xs/avatar3.jpg`),
                         require(`@/assets/images/xs/avatar1.jpg`),
                         require(`@/assets/images/xs/avatar7.jpg`),
                         require(`@/assets/images/xs/avatar9.jpg`),
                    ],
                    task_completed:
                    {
                        start_end:'78/85',
                        class:'bg-success',
                        width:'95'
                    }
                },
                {
                    title:'Orange Limited',
                    sub_title:'Angular onepage webapp',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'23',
                        },
                        {
                            title:'Comments',
                            attachments:'105',
                        },
                        {
                            title:'Tasks',
                            attachments:'37'
                        }
                    ],
                    teams:[
                        require(`@/assets/images/xs/avatar6.jpg`),
                        require(`@/assets/images/xs/avatar7.jpg`)
                    ],
                    task_completed:
                    {
                        start_end:'19/37',
                        class:'bg-warning',
                        width:'55'
                    }
                },
                {
                    title:'Orange Limited',
                    sub_title:'New iOS Food App Design',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                        items:[
                            {
                                title:'Attachments',
                                attachments:'45',
                            },
                            {
                                title:'Comments',
                                attachments:'210',
                            },
                            {
                                title:'Tasks',
                                attachments:'47'
                            }
                        ],
                        teams:[
                            require(`@/assets/images/xs/avatar2.jpg`),
                            require(`@/assets/images/xs/avatar4.jpg`),
                            require(`@/assets/images/xs/avatar9.jpg`),
                        ],
                        task_completed:
                        {
                            start_end:'12/47',
                            class:'bg-danger',
                            width:'23'
                        }
                },
                {
                    title:'Green Inc.',
                    sub_title:'New Admin Design',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'124',
                        },
                        {
                            title:'Comments',
                            attachments:'452',
                        },
                        {
                            title:'Tasks',
                            attachments:'85'
                        }
                    ],
                    teams:[
                        require(`@/assets/images/xs/avatar3.jpg`),
                        require(`@/assets/images/xs/avatar1.jpg`),
                        require(`@/assets/images/xs/avatar9.jpg`),
                    ],
                    task_completed:
                    {
                        start_end:'55/85',
                        class:'bg-info',
                        width:'80'
                    }
                },
                {
                    title:'Red LLP.',
                    sub_title:'OnePage Landing Page HTML',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'23',
                        },
                        {
                            title:'Comments',
                            attachments:'105',
                        },
                        {
                            title:'Tasks',
                            attachments:'37'
                        }
                    ],
                    teams:[
                        require(`@/assets/images/xs/avatar3.jpg`),
                        require(`@/assets/images/xs/avatar1.jpg`),
                        require(`@/assets/images/xs/avatar3.jpg`),
                        require(`@/assets/images/xs/avatar9.jpg`),
                    ],
                    task_completed:
                    {
                        start_end:'19/37',
                        class:'bg-warning',
                        width:'55'
                    }
                },
                {
                    title:'Black Limited',
                    sub_title:'React Native news app',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'45',
                        },
                        {
                            title:'Comments',
                            attachments:'210',
                        },
                        {
                            title:'Tasks',
                            attachments:'47'
                        }
                    ],
                    teams:[
                        require(`@/assets/images/xs/avatar3.jpg`),
                        require(`@/assets/images/xs/avatar1.jpg`),
                    ],
                    task_completed:
                    {
                        start_end:'9/47',
                        class:'bg-danger',
                        width:'12'
                    }
                }
            ],
        }
    },methods:{
        
    }
}
</script>
