<template>
    <div class="col-12">
        <div class="bd-content">

            <h2>Example</h2>
            <div class="bd-example mb-5">
                <b-breadcrumb>
                   <b-breadcrumb-item active>Home</b-breadcrumb-item>
               </b-breadcrumb>
                
                <b-breadcrumb>
                    <b-breadcrumb-item href="#home">Home</b-breadcrumb-item>
                    <b-breadcrumb-item active>Library</b-breadcrumb-item>
               </b-breadcrumb>
                
                <b-breadcrumb>
                    <b-breadcrumb-item href="#home">Home</b-breadcrumb-item>
                    <b-breadcrumb-item href="#library">Library</b-breadcrumb-item>
                    <b-breadcrumb-item active>Data</b-breadcrumb-item>
               </b-breadcrumb>
<pre>
<code class="language-html" data-lang="html">&lt;b-breadcrumb&gt;
    &lt;b-breadcrumb-item active&gt;Home&lt;/b-breadcrumb-item&gt;
&lt;/b-breadcrumb&gt;

&lt;b-breadcrumb&gt;
    &lt;b-breadcrumb-item href=&quot;#home&quot;&gt;Home&lt;/b-breadcrumb-item&gt;
    &lt;b-breadcrumb-item active&gt;Library&lt;/b-breadcrumb-item&gt;
&lt;/b-breadcrumb&gt;

&lt;b-breadcrumb&gt;
    &lt;b-breadcrumb-item href=&quot;#home&quot;&gt;Home&lt;/b-breadcrumb-item&gt;
    &lt;b-breadcrumb-item href=&quot;#library&quot;&gt;Library&lt;/b-breadcrumb-item&gt;
    &lt;b-breadcrumb-item active&gt;Data&lt;/b-breadcrumb-item&gt;
&lt;/b-breadcrumb&gt;</code>
</pre>
            </div> <!-- example end  -->
    
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'BreadcrumbComponent',
    mounted() {
        Prism.highlightAll()
    }
}
</script>
<style scoped>

</style>