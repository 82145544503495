<template>
    <div class="row clearfix">
        <div class="col-md-12">
            <div class="card p-4 mb-4">
               <div class="row py-3 justify-content-between">
                   <div class="col-md-3">
                       <b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                        <b-form-group label="Search" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
               </div>
                <b-table 
                    hover
                    responsive 
                    :items="items" 
                    :fields="fields" 
                    :current-page="currentPage" 
                    :per-page="perPage" 
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    @filtered="onFiltered"
                >
                <template #cell(name)="row">
                    {{ row.value.first }} {{ row.value.last }}
                </template>

                <template #row-details="row">
                    <b-card>
                    <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                    </b-card>
                </template>
                </b-table>
                 <div>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
                </div>
            </div> <!-- .card end -->
            <div class="card mb-4 border-0">
                <div class="card-header py-3 d-flex flex-wrap  justify-content-between align-items-center bg-transparent border-bottom-0">
                    <div>
                        <h6 class="m-0">Transaction History</h6>
                    </div>
                    <card-actions></card-actions>
                </div>
                <div class="card-body table-responsive">
                    <b-table 
                        hover
                        responsive 
                        :items="transaction_items" 
                        :fields="transaction_fields" 
                    >
                        <template #cell(info)="data">
                            <a href="#" class="d-flex align-items-center">
                                <div class="me-2">
                                    <img class="rounded-circle avatar sm" :src="data.value.img" alt="avatar" title="">
                                </div>
                                <div class="media-body">
                                    {{ data.value.name}}
                                </div>
                            </a>
                        </template>
                        <template #cell(status_detail)="data">
                            <span class="badge" :class="data.value.class">{{ data.value.text }}</span>
                        </template>
                        <template #cell(action)="">
                        <table-action></table-action>
                        </template>
                        <template #row-details="row">
                            <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                            </b-card>
                        </template>
                    </b-table>
                </div>
            </div> <!-- .card end -->
        </div>
    </div>
</template>
<script>
import TableAction from '@/components/core/TableAction.vue'
import CardActions from '@/components/core/CardActions.vue'
export default {
    name:'TableComponent',
    components: {
        'table-action': TableAction,
        'card-actions': CardActions,
    },data() {
      return {
        items: [
                {name:{ first: 'Tiger Nixon'}, position:'System Architect', office:'Edinburgh', age:63, date:'2011/07/25', salary:'$170,750' },
                {name:{ first: 'Garrett Winters'}, position:'Accountant', office:'Tokyo', age:63, date:'2011/07/25', salary:'$170,750' },
                {name:{ first: 'Ashton Cox'},position:'Junior Technical Author',office:'San Francisco',age:66,date:'2009/01/12',salary:'$86,000'},
                {name:{ first: 'Cedric Kelly'},position:'Senior Javascript Developer',office:'Edinburgh',age:22,date:'2012/03/29',salary:'$433,060'},
                {name:{ first: 'Airi Satou'},position:'Accountant',office:'Tokyo',age:33,date:'2008/11/28',salary:'$162,700'},
                {name:{ first: 'Brielle Williamson'},position:'Integration Specialist',office:'New York',age:61,date:'2012/12/02',salary:'$372,000'},
                {name:{ first: 'Herrod Chandler'},position:'Sales Assistant',office:'San Francisco',age:59,date:'2012/08/06',salary:'$137,500'},
                {name:{ first: 'Rhona Davidson'},position:'Integration Specialist',office:'Tokyo',age:55,date:'2010/10/14',salary:'27,900'},
                {name:{ first: 'Colleen Hurst'},position:'Javascript Developer',office:'San Francisco',age:39,date:'2009/09/15',salary:'05,500'},
                {name:{ first: 'Sonya Frost'},position:'Software Engineer',office:'Edinburgh',age:23,date:'2008/12/13',salary:'03,600'},
                {name:{ first: 'Jena Gaines'},position:'Office Manager',office:'London',age:30,date:'2008/12/19',salary:'0,560'},
                {name:{ first: 'Quinn Flynn'},position:'Support Lead',office:'Edinburgh',age:22,date:'2013/03/03',salary:'42,000'},
                {name:{ first: 'Charde Marshall'},position:'Regional Director',office:'San Francisco',age:36,date:'2008/10/16',salary:'70,600'},
                {name:{ first: 'Haley Kennedy'},position:'Senior Marketing Designer',office:'London',age:43,date:'2012/12/18',salary:'13,500'},
                {name:{ first: 'Tatyana Fitzpatrick'},position:'Regional Director',office:'London',age:19,date:'2010/03/17',salary:'85,750'},
                {name:{ first: 'Michael Silva'},position:'Marketing Designer',office:'London',age:66,date:'2012/11/27',salary:'98,500'},
                {name:{ first: 'Paul Byrd'},position:'Chief Financial Officer (CFO)',office:'New York',age:64,date:'2010/06/09',salary:'25,000'},
                {name:{ first: 'Gloria Little'},position:'Systems Administrator',office:'New York',age:59,date:'2009/04/10',salary:'37,500'},
                {name:{ first: 'Bradley Greer'},position:'Software Engineer',office:'London',age:41,date:'2012/10/13',salary:'32,000'},
                {name:{ first: 'Dai Rios'},position:'Personnel Lead',office:'Edinburgh',age:35,date:'2012/09/26',salary:'17,500'},
                {name:{ first: 'Jenette Caldwell'},position:'Development Lead',office:'New York',age:30,date:'2011/09/03',salary:'45,000'},
                {name:{ first: 'Yuri Berry'},position:'Chief Marketing Officer (CMO)',office:'New York',age:40,date:'2009/06/25',salary:'75,000'},
                {name:{ first: 'Caesar Vance'},position:'Pre-Sales Support',office:'New York',age:21,date:'2011/12/12',salary:'06,450'},
                {name:{ first: 'Doris Wilder'},position:'Sales Assistant',office:'Sydney',age:23,date:'2010/09/20',salary:'5,600'},
                {name:{ first: 'Angelica Ramos'},position:'Chief Executive Officer (CEO)',office:'London',age:47,date:'2009/10/09',salary:',200,000'},
                {name:{ first: 'Gavin Joyce'},position:'Developer',office:'Edinburgh',age:42,date:'2010/12/22',salary:'2,575'},
                {name:{ first: 'Jennifer Chang'},position:'Regional Director',office:'Singapore',age:28,date:'2010/11/14',salary:'57,650'},
                {name:{ first: 'Brenden Wagner'},position:'Software Engineer',office:'San Francisco',age:28,date:'2011/06/07',salary:'06,850'},
                {name:{ first: 'Fiona Green'},position:'Chief Operating Officer (COO)',office:'San Francisco',age:48,date:'2010/03/11',salary:'50,000'},
                {name:{ first: 'Shou Itou'},position:'Regional Marketing',office:'Tokyo',age:20,date:'2011/08/14',salary:'63,000'},
                {name:{ first: 'Michelle House'},position:'Integration Specialist',office:'Sydney',age:37,date:'2011/06/02',salary:'5,400'},
                {name:{ first: 'Suki Burks'},position:'Developer',office:'London',age:53,date:'2009/10/22',salary:'14,500'},
                {name:{ first: 'Prescott Bartlett'},position:'Technical Author',office:'London',age:27,date:'2011/05/07',salary:'45,000'},
                {name:{ first: 'Gavin Cortez'},position:'Team Leader',office:'San Francisco',age:22,date:'2008/10/26',salary:'35,500'},
                {name:{ first: 'Martena Mccray'},position:'Post-Sales support',office:'Edinburgh',age:46,date:'2011/03/09',salary:'24,050'},
                {name:{ first: 'Unity Butler'},position:'Marketing Designer',office:'San Francisco',age:47,date:'2009/12/09',salary:'5,675'},
                {name:{ first: 'Howard Hatfield'},position:'Office Manager',office:'San Francisco',age:51,date:'2008/12/16',salary:'64,500'},
                {name:{ first: 'Hope Fuentes'},position:'Secretary',office:'San Francisco',age:41,date:'2010/02/12',salary:'09,850'},
                {name:{ first: 'Vivian Harrell'},position:'Financial Controller',office:'San Francisco',age:62,date:'2009/02/14',salary:'52,500'},
                {name:{ first: 'Timothy Mooney'},position:'Office Manager',office:'London',age:37,date:'2008/12/11',salary:'36,200'},
                {name:{ first: 'Jackson Bradshaw'},position:'Director',office:'New York',age:65,date:'2008/09/26',salary:'45,750'},
                {name:{ first: 'Olivia Liang'},position:'Support Engineer',office:'Singapore',age:64,date:'2011/02/03',salary:'34,500'},
                {name:{ first: 'Bruno Nash'},position:'Software Engineer',office:'London',age:38,date:'2011/05/03',salary:'63,500'},
                {name:{ first: 'Sakura Yamamoto'},position:'Support Engineer',office:'Tokyo',age:37,date:'2009/08/19',salary:'39,575'},
                {name:{ first: 'Thor Walton'},position:'Developer',office:'New York',age:61,date:'2013/08/11',salary:'8,540'},
                {name:{ first: 'Finn Camacho'},position:'Support Engineer',office:'San Francisco',age:47,date:'2009/07/07',salary:'7,500'},
                {name:{ first: 'Serge Baldwin'},position:'Data Coordinator',office:'Singapore',age:64,date:'2012/04/09',salary:'38,575'},
                {name:{ first: 'Zenaida Frank'},position:'Software Engineer',office:'New York',age:63,date:'2010/01/04',salary:'25,250'},
                {name:{ first: 'Zorita Serrano'},position:'Software Engineer',office:'San Francisco',age:56,date:'2012/06/01',salary:'15,000'},
                {name:{ first: 'Jennifer Acosta'},position:'Junior Javascript Developer',office:'Edinburgh',age:43,date:'2013/02/01',salary:'5,650'},
                {name:{ first: 'Cara Stevens'},position:'Sales Assistant',office:'New York',age:46,date:'2011/12/06',salary:'45,600'},
                {name:{ first: 'Hermione Butler'},position:'Regional Director',office:'London',age:47,date:'2011/03/21',salary:'56,250'},
                {name:{ first: 'Lael Greer'},position:'Systems Administrator',office:'London',age:21,date:'2009/02/27',salary:'03,500'},
                {name:{ first: 'Jonas Alexander'},position:'Developer',office:'San Francisco',age:30,date:'2010/07/14',salary:'6,500'},
                {name:{ first: 'Shad Decker'},position:'Regional Director',office:'Edinburgh',age:51,date:'2008/11/13',salary:'83,000'},
                {name:{ first: 'Michael Bruce'},position:'Javascript Developer',office:'Singapore',age:29,date:'2011/06/27',salary:'83,000'},
                {name:{ first: 'Donna Snider'},position:'Customer Support',office:'New York',age:27,date:'2011/01/25',salary:'12,000'},
        ],
        fields: [
            { key: 'name', label: 'name', sortable: true, sortDirection: 'desc' },
            { key: 'position', label: 'Position', sortable: true, class: 'text-center' },
            { key: 'age', label: 'age', sortable: true, class: 'text-center' },
            { key: 'office', label: 'Office', sortable: true, class: 'text-center' },
            { key: 'date', label: 'date', sortable: true, class: 'text-center' },
            { key: 'salary', label: 'Salary', sortable: true, class: 'text-center' },
          {
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true
          },
        ],
        transaction_items:[
            {no:'#B1245',info:{img:require(`@/assets/images/xs/avatar1.jpg`),name:'Chris Fox'},date:'02/11/2020',ref:'SUB-2309232',status_detail:{class:'bg-success',text:'Paid'},amount:'$1,015'},
            {no:'#B4518',info:{img:require(`@/assets/images/xs/avatar2.jpg`),name:'Barbara Kelly'},date:'05/11/2020',ref:'SUB-2304593',status_detail:{class:'bg-success',text:'Paid'},amount:'$8,015'},
            {no:'#B7878',info:{img:require(`@/assets/images/xs/avatar3.jpg`),name:'Brian Swader'},date:'11/11/2020',ref:'SUB-2304093',status_detail:{class:'bg-success',text:'Paid'},amount:'$12,580'},
            {no:'#B2583',info:{img:require(`@/assets/images/xs/avatar4.jpg`),name:'Rose Rivera'},date:'17/11/2020',ref:'SUB-2594093',status_detail:{class:'bg-danger',text:'Canceled'},amount:'$22,180'},
            {no:'#B1010',info:{img:require(`@/assets/images/xs/avatar5.jpg`),name:'Frank Camly'},date:'17/11/2020',ref:'SUB-2594093',status_detail:{class:'bg-warning',text:'Due'},amount:'$13,550'},
        ],
        transaction_fields: [
            { key: 'no', label: 'ORDER NO.',tdClass:'sorting_1'},
            { key: 'info', label: 'CUSTOMER'},
            { key: 'date', label: 'DATE'},
            { key: 'ref', label: 'REF'},
            { key: 'status_detail', label: 'STATUS'},
            { key: 'amount', label: 'AMOUNT'},
            { key: 'action', label: '',tdClass:'dt-body-right'},
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, { value: 100, text: 100 }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>
<style scoped>

</style>