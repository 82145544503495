import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Vue.use(require('vue-moment'));

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(VueMoment, {
  moment,
})

import loading from 'vuejs-loading-screen'

//Vue.use(loading)
Vue.use(loading, {
  bg: '#beebe5ad',
  slot: `
    <div class="px-5 py-3 bg-gray-800 rounded">
      <h3 class="text-3xl text-white"><i class="fa fa-spinner fa-spin"></i> ${ 'Espere...' }</h3>
    </div>
  `,
})

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

import Vuex from 'vuex'

Vue.use(Vuex)

//VueRouter
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//BootstrapVue
import { BootstrapVue} from 'bootstrap-vue'
Vue.use(BootstrapVue)

//VueApexCharts 
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)



Vue.config.productionTip = false

new Vue({
  // router:router,
  router,
  store,  
  render: h => h(App),
}).$mount('#app')
