<template>
   <div class="row">
        <div class="col-xl-5 col-lg-12 col-md-12">
            <div class="card mb-4">
                <ul class="resume-box">
                    <li v-for="(widget, index) in widgets" :key="index">
                        <div class="icon text-center">
                            <i class="fa fa-twitter"></i>
                        </div>
                        <span class="time">{{ widget.title }}</span>
                        <h6 class="fw-bold">{{ widget.bold_text }}</h6>
                        <span>{{ widget.content }}</span>
                    </li>
                </ul>
            </div> 
            <div class="card mb-4 overflow-hidden">
                <div class="bg-primary py-5 px-4 text-light">
                    <h4>{{ widgets1.title }}</h4>
                    <span>{{ widgets1.bold_text }}</span>
                </div>
                <div class="p-4">
                    <p v-html="widgets1.innerhtml"></p>
                    <span>{{ widgets1.content }}</span>
                    <div class="mt-4 mb-2">
                        <button type="button" class="btn btn-primary">{{ widgets1.button_title}}</button>
                    </div>
                    <span class="text-muted" v-html="widgets1.innerhtml1"></span>
                </div>
            </div> 
            <div class="mb-4">
                <div v-for="(project, index) in projects" :key="index">
                    <teamsboard-component
                        :title = "project.title"
                        :sub_title = "project.sub_title"
                        :content = "project.content"
                        :items = "project.items"
                        :teams = "project.teams"
                        :start_end = "project.task_completed.start_end"
                        :classes = "project.task_completed.class"
                        :width = "project.task_completed.width"
                    ></teamsboard-component>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header py-3">
                    <h6 class="mb-0 fw-bold">{{ timeline.title }}</h6>
                    <span>{{ timeline.sub_title }}</span>
                </div>
                <div class="card-body">
                    <div class="timeline-item border-bottom ms-2" v-for="(content, index) in timeline_content" :key="index" :class="content.ti_class">
                        <timeline-component
                            :img = "content.img"
                            :text = "content.text"
                            :strong_text = "content.strong_text"
                            :time_detals = "content.time_detals"
                            :strong_bold = "content.strong_bold"
                            :classes = "content.class"
                            :text_areacontent = "content.text_areacontent"
                            :teams = "content.team"
                        ></timeline-component>
                    </div>
                </div>
            </div> 
            <div class="card mb-4 text-center">
                <div class="card-body">
                    <div class="p-4">
                        <div class="fs-1 text-uppercase">{{ coupon.title }}</div>
                        <span>{{ coupon.content }}</span>
                    </div>
                    <div class="form-group mb-3 p-4 d-grid gap-2">
                        <input class="form-control" type="text" placeholder="ENTER YOUR EMAIL" name="email">
                        <button class="btn btn-primary btn-block" type="button">GET IT NOW!</button>
                    </div>
                    <div class="mt-3">
                        <a href="#" title="">{{coupon.msg }}</a>
                        <p class="mt-2">{{ coupon.purchase }}</p>
                    </div>
                </div>
            </div> 
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12">
            <div class="card mb-4">
                <div class="card-header bg-transparent py-3 d-flex justify-content-between align-items-center">
                    <h6 class="card-title mb-0">{{ timeline_sharing.heading }}</h6>
                    <timeline-action></timeline-action>
                </div>
                <div class="card-body">
                    <h6><strong>{{ timeline_sharing.title }}</strong></h6>
                    <p class="text-muted">{{ timeline_sharing.sub_title }}</p>
                    <div class="input-group mb-4">
                        <input type="text" class="form-control" placeholder="invite someone">
                        <b-dropdown text="can edit" variant="outline-secondary" menu-class="border-0 shadow">
                            <b-dropdown-item href="#">Action</b-dropdown-item>
                            <b-dropdown-item href="#">Another action</b-dropdown-item>
                            <b-dropdown-item href="#">Something else here</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="d-flex justify-content-between mb-3" v-for="(grp, index) in timeline_sharing.group" :key="index">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Makers">
                            <label class="form-check-label" for="Makers">
                                <strong>{{ grp.name }}</strong>
                                <span class="text-muted d-flex small">{{ grp.sub_cotent }}</span>
                            </label>
                        </div>
                        <div>
                            <img class="avatar sm rounded ms-1 lift" v-for="(img, index) in grp.images" :key="index" :src="img"  v-b-tooltip.hove title="Avatar Name" alt="">
                        </div>
                    </div>
                    <div class="px-4 mt-3">
                        <button type="button" class="btn btn-primary btn-sm mr-1">Save</button>
                        <button type="button" class="btn btn-secondary btn-sm">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body text-center p-4">
                    <img src="@/assets/images/profile_av.png" alt="" class="rounded-circle mb-3 img-thumbnail shadow-sm">
                    <h5 class="mb-0">{{ profile.name }}</h5><span class="small text-uppercase text-muted">{{ profile.designation}}</span>
                    <ul class="social mb-0 list-inline mt-3">
                        <li class="list-inline-item"  v-for="(icon, index) in profile.icons" :key="index">
                            <a href="#" class="p-2"><i :class="icon"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5 class="mb-0">{{ server.title }}</h5>
                            <small class="small">{{ server.content }}</small>
                        </div>
                        <div class="text-end">
                            <h2 class="mb-0">{{ server.per }}%</h2>
                            <small class="info">{{ server.tb }}</small>
                        </div>
                    </div>
                    <div class="progress mt-3">
                        <div class="progress-bar" :class="server.class" role="progressbar" aria-valuenow="62" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: server.width+'%'}"></div>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header bg-transparent py-3 d-flex justify-content-between">
                    <h6 class="card-title mb-0">{{ recent_Files.file_title }}</h6>
                    <a href="#" title="">{{ recent_Files.view }}</a>
                </div>
                <ul class="list-unstyled list-group list-group-custom list-group-flush mb-0">
                    <li class="list-group-item py-3" v-for="(list, index) in recent_Files.lists" :key="index">
                        <div class="d-flex align-items-center">
                            <div class="avatar lg rounded-circle no-thumbnail"><i :class="list.class"></i></div>
                            <div class="flex-fill ms-3 text-truncate">
                                <h6 class="mb-0">{{ list.title }}</h6>
                                <span class="text-muted">{{ list.mb }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card mb-4">
                <div class="card-header bg-transparent py-3 d-flex justify-content-between">
                    <h6 class="card-title mb-0">{{ latest_news.title }}</h6>
                    <a href="#" title="">{{ latest_news.view }}</a>
                </div>
                <ul class="list-group list-group-flush list-group-custom">
                    <li class="list-group-item py-3" v-for="(details, index) in latest_news.news_detals" :key="index">
                        <a href="javascript:void(0);" class="d-flex">
                            <img class="avatar rounded-circle" :src="details.img" alt="">
                            <div class="flex-fill ms-3">
                                <span class="text-primary">{{ details.text }}</span>
                                <div><small class="text-muted">{{ details.time }}</small></div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card mb-4">
                <div class="card-header bg-transparent py-3 d-flex justify-content-between">
                    <h6 class="card-title mb-0">{{ notifications_details.title }}</h6>
                    <a href="#" title="">{{ notifications_details.view }}</a>
                </div>
                <ul class="list-unstyled list mb-0">
                    <li class="p-3 border-bottom" v-for="(list, index) in notifications_details.lists" :key="index">
                        <a href="javascript:void(0);" class="d-flex">
                            <div class="avatar rounded no-thumbnail"><i class="fa-lg" :class="list.icon"></i></div>
                            <div class="flex-fill ms-3">
                                <p class="mb-0 text-muted" v-html="list.innerhtml"></p>
                                <small class="text-muted">{{ list.time }}</small>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="alert alert-primary p-4 mb-4">
                <div class="fs-3">{{ job_alert.title }}</div>
                <p>{{ job_alert.sub_title }}</p>
                <div class="my-3">
                    <input type="email" class="form-control" placeholder="Enter job keyword">
                </div>
                <button class="btn btn-primary">{{ job_alert.button_title }}</button>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-12">
            <div class="card mb-4 bg-primary">
                <div class="card-body p-5 text-light text-center">
                    <h2>{{ new_items.count }}</h2>
                    <span>{{ new_items.items_title }}</span>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <h5>{{ notification_preferences.title }}</h5>
                    <span class="text-muted">{{ notification_preferences.sub_content }}</span>
                    <div class="mt-4">
                        <div class="form-check form-switch mt-2" v-for="(list, index) in notification_preferences.lists" :key="index">
                            <input class="form-check-input" type="checkbox" id="np-Newsletter" :checked="index == 2 ? true: false">
                            <label class="form-check-label" for="np-Newsletter">{{ list }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5 class="mb-0">{{ domain.title }}</h5>
                            <small class="small">{{ domain.sub_title }}</small>
                        </div>
                        <div class="text-end">
                            <h2 class="mb-0">{{ domain.count }}</h2>
                        </div>
                    </div>
                    <div class="progress mt-3" style="height: 3px;">
                        <div class="progress-bar" :class="domain.class" role="progressbar" aria-valuenow="62" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: domain.width+'%'}"></div>
                    </div>
                </div>
            </div>
            <div class="card mb-4" v-for="(growth,index) in growths" :key="index">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="avatar lg rounded-circle no-thumbnail text-light" :class="growth.class"><i class="fa-lg" :class="growth.icon"></i></div>
                        <div class="flex-fill ms-3 text-truncate">
                            <div class="text-muted">{{ growth.title }}</div>
                            <h4 class="mb-0">{{ growth.per }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-4 border-0 bg-transparent">
                <div class="card-body alert-success alert ">
                    <div class="d-flex align-items-center">
                        <div class="avatar lg rounded no-thumbnail bg-success text-light"><i class="fa  fa-dollar fa-lg"></i></div>
                        <div class="flex-fill ms-3 text-truncate">
                            <div class="fs-6 mb-0">{{ received.title }}</div>
                            <span class="small">{{ received.time }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body p-4">
                    <i class="fa fa-google-plus fa-2x"></i>
                    <p>{{ google.month }}</p>
                    <h4 v-html="google.innerhtml"></h4>
                    <div class="mt-3 text-muted"><i>{{ google.icontitle }}</i></div>
                </div>
            </div>
            <div class="card mb-4 p-4 text-center">
                <div class="icon"><i class="fa fa-facebook fa-3x"></i></div>
                <div class="mt-2">
                    <div class="text">{{ facebook.title }}</div>
                    <div class="number"><i class="fa fa-heart me-1"></i>{{ facebook.count }}</div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header border-bottom-0">
                    <h6 class="card-title">{{ author.title }}</h6>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center mb-3">
                        <div class="me-3">
                            <img class="rounded" :src="author.img" alt="avatar lg" title="">
                        </div>
                        <div class="media-body">
                            <h6 class="mb-0" v-html="author.innerhtml"></h6>
                        </div>
                    </div>
                    <span>{{ author.sub_title }}</span>
                </div>
            </div>
            <div class="card mb-4 text-center">
                <!-- Group Hover action -->
                <div class="btn-group position-absolute top-0 end-0">
                    <b-dropdown  variant="link" toggle-class="text-decoration-none" no-caret right >
                        <template #button-content>
                            <a href="#" class="nav-link py-2 px-3 text-muted" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        </template>
                        <b-dropdown-item href="#">Edit</b-dropdown-item>
                        <b-dropdown-item href="#">Share</b-dropdown-item>
                        <b-dropdown-item href="#">Delete</b-dropdown-item>
                    </b-dropdown>
                </div>                                            
                <div class="card-body d-flex align-items-center justify-content-between flex-column">
                    <div class="me-auto ms-auto py-4">
                        <img class="avatar rounded m-1 lift" v-for="(image,index) in sourcing.images" :key="index" :src="image"  v-b-tooltip.hove title="Avatar Name" alt="">
                    </div>
                    <div class="mt-2">
                        <h6 class="mb-0">{{ sourcing.title }}</h6>
                        <small class="text-muted">{{ sourcing.contact }}</small>
                    </div>
                </div>
            </div>
            <div class="card mb-4 p-lg-3 p-0">
                <div class="card-body">
                    <i class="fa fa-cart-arrow-down fa-lg float-right text-muted"></i>
                    <h6 class="text-muted text-uppercase mt-0">{{ order.title }}</h6>
                    <h2>{{ order.count }}</h2>
                    <span class="badge bg-primary">+{{ order.per }}% </span>
                    <span class="text-muted">{{ order.sub_title }}</span>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <h6 class="mb-0">{{ work_reports.title }}</h6>
                    <ul class="list-unstyled mt-4 mb-0">
                        <li v-for="(work_report,index) in work_reports.lists" :key="index" :class="work_report.li_class">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <h6 class="mb-0">{{ work_report.count }}</h6>
                                <span class="small text-muted">{{ work_report.task}}</span>
                            </div>
                            <div class="progress" style="height: 2px;">
                                <div class="progress-bar" :class="work_report.icon_class" data-transitiongoal="87" aria-valuenow="87" v-bind:style="{ width: work_report.width+'%'}"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="d-flex py-1">
                        <img class="avatar rounded-circle" :src="profiles.img" alt="">
                        <div class="flex-fill ms-3">
                            <div class="h6 mb-0 text-muted"><span class="font-weight-bold">{{ profiles.name }}</span></div>
                            <small class="text-muted">{{ profiles.email }}</small>
                            <div>
                                <a href="#" class="card-link">{{ profiles.logout }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-group">
                    <a href="#" class="list-group-item list-group-item-action border-0" v-for="(list,index) in profiles.lists" :key="index">
                        <i class="w30" :class="list.icon"></i>{{ list.title }}
                    </a>
                </div>
            </div>
            <div class="card mb-4 overflow-hidden">
                <div class="p-4 bg-danger d-flex justify-content-between text-light align-items-center">
                    <div><span class="fs-1">{{ card_lightning.degree }}</span></div>
                    <i class="wi wi-day-lightning fs-1"></i>
                </div>
                <div class="card-body p-4">
                    <h6>{{ card_lightning.location }}</h6>
                    <span class="text-muted">{{ card_lightning.month }}</span>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import Teamsboard from '@/components/core/Teamsboard.vue'
import Timeline from '@/components/core/Timeline.vue'
import TimelineAction from '@/components/core/TimelineAction.vue'

export default {
    name:'WidgetComponent',
    components: {
        'teamsboard-component':Teamsboard,
        'timeline-component':Timeline,
        'timeline-action':TimelineAction
    },
    data (){
        return {
            widgets:[
                {
                    title: '2019 - Present',
                    bold_text: 'Sr. Art Director - Twitter Inc',
                    content:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                },
                {
                    title: '2018 - 2019',
                    bold_text: 'Art Director - Facebook Inc',
                    content:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                },
                {
                    title: '2016 - 2018',
                    bold_text: 'Jr. Art Director - Google Inc',
                    content:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                },
            ],
            widgets1:
            {
                title: 'thememakker.com',
                bold_text: 'Warning: You are approaching your limit. Please upgrade.',
                innerhtml:'You have <strong>1 free</strong> report remaining.',
                innerhtml1:'Thanks for choosing <strong class="text-warning">ThemeMakker</strong> Admin.',
                button_title:'Upgrade my account',
                content:'Add your credit card now to upgrade your account to a premium plan to ensure you don not miss out on any reports.',
            },
            projects:
            [
                {
                    title:'Orange Limited',
                    sub_title:'PSD to HTML onepage',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'124',
                        },
                        {
                            title:'Comments',
                            attachments:'452',
                        },
                        {
                            title:'Tasks',
                            attachments:'85'
                        }
                    ],
                    teams:[
                         require(`@/assets/images/xs/avatar3.jpg`),
                         require(`@/assets/images/xs/avatar1.jpg`),
                         require(`@/assets/images/xs/avatar7.jpg`),
                         require(`@/assets/images/xs/avatar9.jpg`),
                    ],
                    task_completed:
                    {
                        start_end:'78/85',
                        class:'bg-success',
                        width:'55'
                    }
                }
            ],
            timeline:{
                title:'Activity Timeline',
                sub_title:'Nellie Maxwell'
            },
            timeline_content:[
                {
                    img:require(`@/assets/images/xs/avatar1.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'You',
                    text_areacontent:'I’ve prepared all sizes for you. Can you take a look tonight so we can prepare my final invoice?',
                    ti_class:'ti-danger',
                    class:'',
                },
                {
                    img:require(`@/assets/images/xs/avatar2.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'Orlando',
                    text_areacontent:'',
                    ti_class:'ti-info',
                    class:'',
                },
                {
                    img:require(`@/assets/images/xs/avatar1.jpg`),
                    text:'Gerald Vaughn changed the status to QA on',
                    strong_text:'MA-86 - Retargeting Ads',
                    time_detals:'New Dashboard Design - 9:24PM by',
                    strong_bold:'You',
                    text_areacontent:'',
                    ti_class:'ti-success',
                    class:'',
                }
            ],
            coupon:{
                title:'GET 10% OFF',
                content:'subscribe to our newsletter & receive a coupon',
                msg:'no thanks',
                purchase:'* $50 minimum purchase'
            },
            timeline_sharing:{
                heading:'Timeline sharing',
                title:'Roles',
                sub_title:'Access limited to everyone of select role(s)',
                group:[
                    {
                        name:'Makers',
                        sub_cotent:'Everyone assigned the maker role',
                        images:[
                            require(`@/assets/images/xs/avatar1.jpg`),
                            require(`@/assets/images/xs/avatar2.jpg`),
                        ]
                    },
                    {
                        name:'Contributors',
                        sub_cotent:'Everyone assigned the contributor role',
                        images:[
                            require(`@/assets/images/xs/avatar3.jpg`),
                        ]
                    },
                    {
                        name:'Contributors',
                        sub_cotent:'Everyone assigned the contributor role',
                        images:[
                           
                        ]
                    }
                ]
            },
            profile:{
                name:'Manuella Nevoresky',
                designation:'CEO - FOUNDER',
                icons:[
                    'fa fa-facebook-f',
                    'fa fa-twitter',
                    'fa fa-instagram',
                    'fa fa-linkedin'
                ]
            },
            server:{
                title:'Server',
                per:'62',
                tb:'of 1Tb',
                content:'6% higher than last month',
                class:'bg-danger',
                width:'62'
            },
            recent_Files:{
                file_title:'Recent Files',
                view:'View All',
                lists:[
                    {
                        title:'Annual Sales Report 2018-19',
                        class:'fa fa-file-pdf-o fa-lg text-danger',
                        mb:'.pdf, 5.3 MB'
                    },
                    {
                        title:'Complete Product Sheet',
                        class:'fa fa-file-excel-o fa-lg text-success',
                        mb:'.xls, 2.1 MB'
                    },
                    {
                        title:'Marketing Guidelines',
                        class:'fa fa-file-word-o fa-lg text-info',
                        mb:'.doc, 2.3 MB'
                    },
                    {
                        title:'Brand Photography',
                        class:'fa fa-file-zip-o fa-lg',
                        mb:'.zip, 30.5 MB'
                    }
                ]
            },
            latest_news:
            {
                title:'Latest News',
                view:'View All',
                news_detals:[
                    {
                        img: require(`@/assets/images/xs/avatar7.jpg`),
                        text:'There are many variations of passages',
                        time:'10:11 AM',
                    },
                    {
                        img: require(`@/assets/images/xs/avatar3.jpg`),
                        text:'Contrary to popular belief, Lorem Ipsum is not simply random text',
                        time:'10:11 AM',
                    },
                    {
                        img: require(`@/assets/images/xs/avatar1.jpg`),
                        text:'There are many variations of passages',
                        time:'10:11 AM',
                    },
                    {
                        img: require(`@/assets/images/xs/avatar2.jpg`),
                        text:'Contrary to popular belief, Lorem Ipsum is not simply random text',
                        time:'10:11 AM',
                    }
                ]
            },
            notifications_details:{
                title:'Notifications',
                view:'View All',
                lists:[
                    {
                        icon: 'fa fa-info-circle fa-lg',
                        innerhtml: 'Campaign <strong class="text-primary">Holiday Sale</strong> is nearly reach budget limit.',
                        time:'10:00 AM Today'
                    },
                    {
                        icon: 'fa fa-thumbs-up fa-lg',
                        innerhtml: 'Your New Campaign <strong class="text-primary">Holiday Sale</strong> is approved.',
                        time:'11:30 AM Today'
                    },
                    {
                        icon: 'fa fa-pie-chart fa-lg',
                        innerhtml: 'Website visits from Twitter is <strong class="text-danger">27% higher</strong> than last week.',
                        time:'04:00 PM Today'
                    },
                    {
                        icon: 'fa fa-warning fa-lg',
                        innerhtml: '<strong class="text-warning">Error</strong> on website analytics configurations',
                        time:'Yesterday'
                    },
                ]
            },
            job_alert:{
                title:'Create Job Alert',
                sub_title:'Create a job alert now and never miss a job',
                button_title:'Create Job Alerts'
            },
            new_items:{
                count:'521',
                items_title:'New items'
            },
            notification_preferences:{
                title:'Notification preferences',
                sub_content:'Control all our newsletter and email related notifications to your email',
                lists:[
                    'Monthly Newsletter',
                    'Comment Notifications',
                    'Email Preferences',
                    'EST Timezone'
                ]
            },
            domain:{
                title:'Domians',
                sub_title:'Total registered Domain',
                count:'3',
                class:'bg-info',
                width:'62'
            },
            growths:[
                {
                    class:'bg-success',
                    icon:'fa fa-area-chart',
                    title:'Growth',
                    per:'62%'
                },
                {
                    class:'bg-warning',
                    icon:'fa fa-shopping-cart',
                    title:'Total Sales',
                    per:'$3205'
                }
            ],
            received:{
                title:'You have received $860',
                time:'Mar 20,2020'
            },
            google:{
                month:'18th Feb',
                innerhtml:'Now Get <span class="text-danger">Up to 70% Off</span> on buy',
                icontitle:'- post form WrapTheme'
            },
            facebook:{
                title:'@ThemeMakker',
                count:'23K',
            },
            author:{
                title:'Author',
                innerhtml:'Hello, I am<br> Rachel Roth',
                img: require(`@/assets/images/xs/avatar1.jpg`),
                sub_title:'I design and develop services for customers of all sizes, specializing in creating stylish.'
            },
            sourcing:{
                title:'Out Sourcing',
                contact:'16 Contacts',
                images:[
                    require(`@/assets/images/xs/avatar1.jpg`),
                    require(`@/assets/images/xs/avatar2.jpg`),
                    require(`@/assets/images/xs/avatar3.jpg`),
                    require(`@/assets/images/xs/avatar4.jpg`),
                    require(`@/assets/images/xs/avatar5.jpg`),
                    require(`@/assets/images/xs/avatar6.jpg`),
                    require(`@/assets/images/xs/avatar7.jpg`),
                    require(`@/assets/images/xs/avatar8.jpg`),
                    require(`@/assets/images/xs/avatar9.jpg`),
                    require(`@/assets/images/xs/avatar10.jpg`),
                ]
            },
            order:{
                title:'ORDERS',
                count:'1,587',
                per:'11',
                sub_title:'+11%From previous period',
            },
            work_reports:
            {
                title:'Work report',
                lists:[
                    {
                        li_class: 'mb-4',
                        count:'54',
                        task:'Today Works',
                        icon_class:'bg-success',
                        width:'87'
                    },
                    {
                        li_class: 'mb-4',
                        count:'27',
                        task:'Open Tasks',
                        icon_class:'bg-info',
                        width:'34'
                    },
                    {
                        li_class: 'mb-4',
                        count:'102',
                        task:'Closed Tasks',
                        icon_class:'bg-primary',
                        width:'54'
                    },
                    {
                        li_class: 'mb-0',
                        count:'1024 h',
                        task:'Total spent time',
                        icon_class:'bg-danger',
                        width:'67'
                    },
                ]
            },
            profiles:{
                img: require(`@/assets/images/profile_av.png`),
                name:'Chris Fox',
                email:'chris.fox@gamil.com',
                logout:'Sign out',
                lists:[
                    {
                        icon:'fa fa-user',
                        title:'Profile & account'
                    },
                    {
                        icon:'fa fa-gear',
                        title:'Settings'
                    },
                    {
                        icon:'fa fa-tag',
                        title:'Customization'
                    },
                    {
                        icon:'fa fa-users',
                        title:'Manage team'
                    },
                    {
                        icon:'fa fa-calendar',
                        title:'My Events'
                    },
                    {
                        icon:'fa fa-credit-card',
                        title:'My Statements'
                    }
                ]
            },
            card_lightning:{
                degree:'10°',
                location:'Melbourne, FL 32904',
                month:'Saturday 16 January'
            }
        }
    }
}
</script>
<style scoped>

</style>